import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { processActions } from '../../_actions/process.actions';
import { getCurrentState, getIsFormSubmit } from '../../_helpers/session';
import { history } from '../../_helpers/history';

class CustomForm extends React.Component
{
  constructor (props) {
    super (props);

    this.state = {
      data: {},
      file: null,
      fields: {}
    };

    document.addEventListener("keydown", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    const slug = history.location.pathname;

    if (slug.includes('customer/')) {
      if (event.keyCode === 9 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements[index + 2]) {
          if (form.elements[index+2].tagName !== 'INPUT') {
            form.elements[1].focus();
          } else {
            form.elements[index + 2].focus();
          }
        } else if (form.elements[index + 1]) {
          if (form.elements[index+1].tagName && form.elements[index+1].tagName === 'INPUT') {
            form.elements[1].focus();
          } else {
            form.elements[index + 1].focus();
          }
        }
        event.preventDefault();
      }
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let state = this.state;
    state['data'][e.target.name] = e.target.value;

    const { method, from } = this.props;

    if (method == 'import') {
      state['file'] = e.target.files[0];
    }

    if (from == 'modal') {
      this.props.onChange(e.target.name, e.target.value);

      if (method == 'import') {
        this.props.onChange('file', e.target.files[0]);
      }
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const processId = this.props.processId || e.target.dataset.id;
    const method = this.props.method || e.target.dataset.method;

    if (e.target.type == 'submit') {
      if (method == 'import') {
        this.props.submitForm(processId, this.state.file, method);
      } else {
        this.props.submitForm(processId, this.state.data, method);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.alert != this.props.alert) {
      if (nextProps.alert.type == 'alert-success') {
        let state = this.state;
        state.data = {};
      }
    }

    if (nextProps.contents != this.props.contents && nextProps.contents.items) {
      const { contents, from, id } = nextProps;
      let state = this.state;
      let fields = this.props.fields;
    
      if (from == 'content' && contents.items) {
        fields = contents.items.form_fields_for_form;
      }

      state['fields'] = fields;
      
      if (id && fields[0]) {
        state['data'][fields[0].name] = id;
      }
    }
  }


  UNSAFE_componentWillMount() {
    let state = this.state;
    const isFormSubmit = getIsFormSubmit();

    if (isFormSubmit == 'true') {
      const localRecords = getCurrentState();

      // Original Record
      if (localRecords.method == 'edit') {
        for (const [key, value] of Object.entries(localRecords.items)) {
          state['data'][key] = value;
        }
      }

      // Modified Record
      for (const [key, value] of Object.entries(this.props.defaultValues)) {
        state['data'][key] = value;
      }

      state['fields'] = localRecords.formFields;
    } else {

      if ((this.props.method == 'edit' && this.props.process && this.props.process.items)) {
        
        for (const [key, value] of Object.entries(this.props.process.items)) {
          state['data'][key] = value;
        }
      }

      const { contents, from, id } = this.props;
      
      let fields = this.props.fields;
      
      if (from == 'content' && contents.items) {
        fields = contents.items.form_fields_for_form;
      }

      state['fields'] = fields;
    }
  }

  componentWillUnmount() {
    
    let state = this.state;
    state['data'] = {};
    state['file'] = null;
    state['fields'] = {};

    document.removeEventListener("keydown", this.keyDownHandler);
  }

  render () {
    const { buttons, id, editable } = this.props;
    const formType = ['checkbox', 'select', 'radio'];
    const { fields } = this.state;
    
    if (fields && (Object.keys(fields).length > 0)) {

      const totalFields = Object.keys(fields).length;
      const fieldCount = totalFields > 10 ? Math.floor((totalFields / 2) - 1) : totalFields;
      let counter = 0;

      const FormControl = (props) => {
        
        if (editable === 'false') {
          return <Form.Control 
              size="sm" 
              type={props.field.type}
              name={props.field.name} 
              placeholder={"-"} 
              onBlur={(e) => this.handleChange(e)} 
              defaultValue={this.state.data[props.field.name] || ''}
              readOnly
            />
        }

        return <>
          {props.field.type == 'checkbox' &&
            <Form.Check
              size="sm"  
              data-id={props.field.id} 
              type={props.field.type} 
              label={props.field.name} 
              name={props.field.name} 
              onChange={(e) => this.handleChange(e)}   
            /> 
          }{props.field.type == 'radio' &&
            <Form.Control 
              as="select" 
              name={props.field.name} 
              label={props.field.name} 
              defaultValue={this.state.data[props.field.name] || ''}
              onChange={(e) => this.handleChange(e)} 
            >
              <option value=""></option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Form.Control>
          }{props.field.type == 'select' && 
            <Form.Control 
              as="select" 
              name={props.field.name} 
              label={props.field.name} 
              defaultValue={this.state.data[props.field.name] || ''}
              onChange={(e) => this.handleChange(e)} 
            >
              <option value=""></option>
              {props.field.options.map(option => 
                <option value={option.value}>{option.name}</option>
              )}
            </Form.Control>
          }{! formType.includes(props.field.type) && 
            <Form.Control 
              size="sm" 
              type={props.field.type}
              name={props.field.name} 
              placeholder={"Enter "+props.field.label} 
              onBlur={(e) => this.handleChange(e)} 
              defaultValue={this.state.data[props.field.name] || ''}
            />
          }
        </>;
      };

      return <>
        <Container>
          <Form onSubmit={ this.handleFormSubmit }>
            {fields.map((field, index) => {
              counter++;

              if (totalFields > 10) {
                if (index <= fieldCount) {
                  const pairedFieldIndex = fieldCount + counter;
                  const pairedField = fields[pairedFieldIndex];
                  
                  return <Form.Group as={Row} key={field.id}>
                    <Form.Label column sm="2">{field.type != 'checkbox' && field.label}<span>{field.is_required == 1 ? '*' : ''}</span></Form.Label>
                    <Col sm="4" className="float-left">
                      <FormControl field={field} index={index} />
                    </Col>
                    {pairedField && <>
                      <Form.Label column sm="2">{pairedField.type != 'checkbox' && pairedField.label}<span>{field.is_required == 1 ? '*' : ''}</span></Form.Label>
                      <Col sm="4" className="float-left">
                        <FormControl field={pairedField} index={pairedFieldIndex} />
                      </Col>
                    </>}
                  </Form.Group>;
                }
              }  
              else {
                return <Form.Group as={Row} key={field.id}>
                  <Form.Label column sm="2">{field.type != 'checkbox' && field.label}<span>{field.is_required == 1 ? '*' : ''}</span></Form.Label>
                  <Col sm="4" className="float-left">
                    <FormControl field={field} index={index} />
                  </Col>
                </Form.Group>;
              }
            })}
            {buttons && buttons.map(button =>
              <Form.Group as={Row}>
                <Col sm="3">
                  <Button 
                    variant="primary" 
                    type={button.button_type.substring(12) || "submit"} 
                    className="float-sm-left" 
                    data-id={button.id}
                    method={button.url}
                    onClick={this.handleFormSubmit}
                  >
                    {button.name}
                  </Button>
                </Col>
              </Form.Group>
            )}
          </Form>
        </Container>
      </>;
    }

    return <></>;
  }
}

function mapState(state) {
  const { contents, process, alert } = state;
  return { contents, process, alert };
}

const actionCreators = {
  submitForm: processActions.submitForm
}

export default connect(mapState, actionCreators)(CustomForm);