import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { contentActions } from '../../../_actions/content.actions';
import { searchActions } from '../../../_actions/search.actions';
import ReportFilter from '../ReportFilter';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import SupplierDueinPDF from './SupplierDueinPDF';
import DownloadCSV from '../../../components/CSV/DownloadCSV';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


const searchButton = {
  name: 'search_supplier',
  type: 'button',
  label: 'Search'
};

const columns = [
  { 
    dataField: 'id', 
    text: 'PRODUCT ID',  
    headerStyle: (colum, colIndex) => {
      return { width: '20%', textAlign: 'center' };
    },
    sort: true
  }, { 
    dataField: 'code', 
    text: 'CODE',  
    headerStyle: (colum, colIndex) => {
      return { width: '20%', textAlign: 'center' };
    },
    sort: true
  }, { 
    dataField: 'description', 
    text: 'DESCRIPTION',  
    headerStyle: (colum, colIndex) => {
      return { width: '40%', textAlign: 'center' };
    },
    sort: true 
  }, { 
    dataField: 'no_of_stocks', 
    text: 'STOCKS',  
    headerStyle: (colum, colIndex) => {
      return { width: '20%', textAlign: 'center' };
    },
    sort: true 
  },
];

const headers = [
  { label: "SUPPLIER ID", key: "supplier_id" },
  { label: "COMPANY", key: "company" },
  { label: "PRODUCT ID", key: "id" },
  { label: "CODE", key: "code" },
  { label: "DESCRIPTION", key: "description" },
  { label: "STOCKS", key: "no_of_stocks" },
];

const { SearchBar } = Search;

class SupplierDueinReport extends React.Component
{
  constructor (props) {
    super (props);

    this.props.getSupplierDueInDefault();

    this.state = {
      suppliers: [],
      reports: [],
      searched: false
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.items && this.props.items !== prevProps.items) {
      this.setState({ reports: this.props.items, searched: true });
    }

    if (this.props.list && this.props.list !== prevProps.list) {
      this.setState({ suppliers: this.props.list });
    }
  }

  onSearch = (data) => {

    if (data.supplier_id == 'All') {
      data = '';
    }
    this.props.getWithFilter(data)
    
  }

  render () {
    const { suppliers, reports } = this.state;

    const fields = [{
      name: 'supplier_id',
      type: 'select',
      reference_form: '11',
      label: 'Supplier:',
      placeholder: 'Select a supplier...',
      options: suppliers || []
    }];

    return <div className="content" key="supplier-product-order-report-page">
      <h5>Stock by Supplier</h5>
      <Container>
        <Row>
          <Col xs="10">
            { suppliers && suppliers.length > 0 && <ReportFilter fields={fields} searchButton={searchButton} search={ this.onSearch } />}
          </Col>
          <Col xs="2">
            <DownloadCSV label="Download CSV" data={ reports['csv'] ? reports['csv'] : [] } headers={ headers } filename={ "supplier_order_report" } />
          </Col>
        </Row>
      </Container>
      <div style={{ width: '600px'}}>
        { reports && reports['report'] && reports['report'].length > 0 && reports['report'].map((report, key) => {
          const company = report[0]['company'];
          return <ToolkitProvider
            keyField="product_supplier_id"
            data={ report }
            columns={ columns }
            search
            bootstrap4
          >
            { props => {
              return <Container>
                <Row>
                  <Col sm="8">
                    <h6>{ company }</h6>
                  </Col>
                  <Col sm="4" className="table-search">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                </Row>
                <BootstrapTable 
                  { ...props.baseProps }
                  striped
                  noDataIndication={ () => <i>No records found.</i> }
                  
                />
              </Container>
            }}
          </ToolkitProvider>
        })}
      </div>
    </div>;
  }
}

function mapState(state) {
  const { contents, search } = state;
  const { items } = contents;
  const { list } = search;
  return { items, list };
}

const actionCreators = {
  getSupplierDueInDefault: contentActions.getSupplierDueInDefault,
  searchAll: searchActions.searchAll,
  getWithFilter: contentActions.getWithFilter
}

export default connect(mapState, actionCreators)(SupplierDueinReport);