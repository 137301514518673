import React from 'react';
import { beautifyDate } from '../../../_helpers/placeOrder';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    color: "#343a40"
  },
  section: {
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 10
  },
  subSection: {
    marginLeft: 10,
    paddingLeft: 10,
    fontStyle:'italic',
    fontSize: 9
  },
  viewTable: {
    margin: 10,
    padding: 10,
    width: "auto",
  },
  tableCell: {
    fontSize: 9,
    paddingTop:3,
    paddingBottom:3,
    color: 'black'
  },
  dataTableCell: {
    fontSize: 9,
    marginLeft:1,
    paddingTop:3,
    paddingBottom:3
  }
});


class SupplierDueinPDF extends React.Component
{
  render () {
    const { reports } = this.props;
    const today = new Date();

    return <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <Text>Suppliers Product Due Report  </Text>
        </View>
        <View style={styles.subSection}>
          <Text>as of { beautifyDate(today) }</Text>
        </View>
        <View style={styles.viewTable}>
        { reports && 
          <Table data={reports}>
            <TableHeader textAlign={"center"}>
              <TableCell style={styles.tableCell} weighting={0.6}>SUPPLIER ID</TableCell>
              <TableCell style={styles.tableCell} weighting={1}>COMPANY</TableCell>
              <TableCell style={styles.tableCell} weighting={0.6}>PRODUCT ID</TableCell>
              <TableCell style={styles.tableCell} weighting={0.6}>PRODUCT CODE</TableCell>
              <TableCell style={styles.tableCell} weighting={1}>DESCRIPTION</TableCell>
              <TableCell style={styles.tableCell} weighting={1}>STOCKS</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell style={styles.dataTableCell}  weighting={0.6} getContent={(r) => r.supplier_id}/>
              <DataTableCell style={styles.dataTableCell}  weighting={1} getContent={(r) => r.company}/>
              <DataTableCell style={styles.dataTableCell}  weighting={0.6} getContent={(r) => r.id}/>
              <DataTableCell style={styles.dataTableCell}  weighting={0.6} getContent={(r) => r.code}/>
              <DataTableCell style={styles.dataTableCell}  weighting={1} getContent={(r) => r.description}/>
              <DataTableCell style={styles.dataTableCell}  weighting={1} getContent={(r) => r.no_of_stocks}/>
            </TableBody>
          </Table>
        }
        </View>
      </Page>
    </Document>;
  }
}

export default SupplierDueinPDF;