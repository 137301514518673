import client from '../api/ApiClient';

export const orderService = {
  getRoundOrders,
  getRoundOrdersByDriver
}

async function getRoundOrders(date, day) {
  const url = process.env.REACT_APP_API_URL+'/order/place/process/55/item/all';
  // const url = process.env.REACT_APP_API_URL+'/rounds?date='+date+'&day='+day;
  return await client.get(url);
}

async function getRoundOrdersByDriver(driverId, roundId, roundNo, roundDate) {
  const url = process.env.REACT_APP_API_URL+'/orders?driverId='+driverId+'&roundId='+roundId+'&roundNo='+roundNo+'&roundDate='+roundDate;
  return await client.get(url);
}
