import { processConstants } from "../_constants/process.constants";
import { processService } from "../_services/process.service";
import { alertActions } from "./alert.actions";
import { history } from "../_helpers/history";
import Error from "../_helpers/error";
import { saveDriverRoundsToStorage } from "../_helpers/placeOrder";
import {
  setChecklist,
  setProductsForSearch,
  setSuppliersForSearch,
} from "../_helpers/session";

export const processActions = {
  getForm,
  submitForm,
  deleteRecord,
  clearForm,
  getFormById,
  updateRecord,
  disableRecord,
  getProductSuppliers,
  getSuppliersProduct,
  clearSuppliers,
  clearProducts,
  submitBulkSave,
  updateRoundSchedule,
  updateDriverRounds,
  updateCustomerChecklist,
  updateDriverInfo,
  getPurchaseModalRecord,
  savePurchaseOrder,
  getPurchaseOrders,
  updatePurchaseOrders,
  getPurchaseOrderDetails,
  updatePurchaseOrder,
  getSuppliersForSearch,
  savePurchaseOrders,
  getProductCategoriesForSearch,
  getSupplierAndCategoriesOptions,
  getProductSuppliersRecord,
  updateCostPrice,
  getProductsForSearch,
  saveNotes,
  extra,
};

function getForm(processId, method) {
  const slug = history.location.pathname.substring(1);
  const form = { slug, processId, method };

  if (method == "import") {
    return (dispatch) => {
      const data = {
        name: "Import",
        form_fields_for_form: [
          {
            id: 1,
            type: "file",
            label: "Import File",
            name: "file",
          },
        ],
      };
      dispatch(success(data));
    };
  }

  return (dispatch) => {
    dispatch(request(form));

    processService.getForm(slug, processId, method).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch(success(response.data.data));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function request(form) {
    return { type: processConstants.API_GET_REQUEST, form };
  }
  function success(data) {
    return { type: processConstants.API_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function submitForm(processId, data, method) {
  console.log("Process Id --->", processId);
  console.log("Data --->", data);
  console.log("Method --->", method);
  let slug = history.location.pathname.substring(1);
  if (processId == processConstants.PROCESS_ADD_PRODUCT) {
    slug = "product/add";
  }

  let uri = slug + "/process/" + processId;
  if (processId == processConstants.PROCESS_ADD_PRODUCT_STOCKS) {
    uri = "product/stocks";
  }

  const form = { slug, processId, data, method };
  return (dispatch) => {
    dispatch(request(form));
    dispatch(alertActions.clear());

    if (processId == processConstants.PROCESS_IMPORT_CHECKLIST)
      uri = "checklist/import";
    else if (processId == processConstants.PROCESS_SUPPLIER_PRICE_IMPORT)
      uri = "supplier/product/import";
    else if (processId == processConstants.PROCESS_PRODUCT_BULK_UPDATE)
      uri = "product/bulk/update";

    processService.submitForm(uri, data, method).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch(success(response.data.data));
          dispatch(alertActions.success("Record successfully saved"));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.errorFields(e.message, e.errorFields));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.errorFields(e.message, e.errorFields));
      }
    );
  };

  function request(form) {
    return { type: processConstants.API_POST_REQUEST, form };
  }
  function success(form) {
    return { type: processConstants.API_POST_SUCCESS, form };
  }
  function failure(error, payload) {
    return { type: processConstants.API_POST_FAILURE, error, payload };
  }
}

function deleteRecord(processId, id) {
  const slug = history.location.pathname.substring(1);
  const form = { slug, processId, id };
  return (dispatch) => {
    dispatch(request(form));

    let uri = slug + "/process/" + processId + "/item/" + id;

    processService.deleteRecord(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch(success(response.data.data));
          dispatch(alertActions.success("Record successfully deleted."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function request(form) {
    return { type: processConstants.API_POST_REQUEST, form };
  }
  function success(form) {
    return { type: processConstants.API_POST_SUCCESS, form };
  }
  function failure(error, payload) {
    return { type: processConstants.API_POST_FAILURE, error, payload };
  }
}

function clearForm() {
  return { type: processConstants.CLEAR_FORM };
}

function getFormById(id, processId, method) {
  if (
    processId == processConstants.PROCESS_ADD_PRODUCT_STOCKS ||
    processId == processConstants.PROCESS_SHOW_PRODUCTS_OF_SUPPLIER
  ) {
    return { type: processConstants.NO_ACTION };
  }

  const slug = history.location.pathname.substring(1);
  const form = { slug, id, processId, method };
  let params = "";

  if (processId == processConstants.PROCESS_SHOW_ORDER_DETAILS) {
    params = "?idName=order_id";
  }

  let uri = slug + "/process/" + processId + "/item/" + id + params;

  if (method != "show") {
    uri = uri + "/" + method;
  }

  return (dispatch) => {
    dispatch(request(form));

    processService.getFormById(uri, method).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch(success(response.data.data || {}, response.data.form));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(form) {
    return { type: processConstants.API_GET_REQUEST, form };
  }
  function success(data, extra) {
    return { type: processConstants.API_GET_SUCCESS, data, extra };
  }
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function updateRecord(id, processId, data, method) {
  const slug = history.location.pathname.substring(1);
  const form = { slug, id, processId, data, method };
  return (dispatch) => {
    dispatch(request(form));

    let uri =
      processId == processConstants.PROCESS_EDIT_PRODUCT
        ? "product/" + id
        : slug + "/process/" + processId + "/item/" + id;
    console.log("Data <--->", data);
    console.log("Uri --->", uri);

    processService.updateRecord(uri, data).then(
      (response) => {
        console.log("Response <---->", response);
        if (response.data.status == "success") {
          console.log("Response <---->", response);
          dispatch(success(response.data.data));
          dispatch(alertActions.success("Record successfully updated."));
        } else {
          console.log("Response <---->", response);
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.errorFields(e.message, e.errorFields));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.errorFields(e.message, e.errorFields));
      }
    );
  };

  function request(form) {
    return { type: processConstants.API_POST_REQUEST, form };
  }
  function success(form) {
    return { type: processConstants.API_POST_SUCCESS, form };
  }
  function failure(error, payload) {
    console.log("Failure Case ---->", payload);
    console.log("Error --->", error);
    return { type: processConstants.API_POST_FAILURE, error, payload };
  }
}

function disableRecord(processId, id) {
  const data = { status: 0 };
  return updateRecord(id, processId, data, "edit");
}

function getProductSuppliers(id) {
  const uri = "product/" + id + "/suppliers";

  return (dispatch) => {
    dispatch({ type: processConstants.API_GET_REQUEST_SUPPLIERS, id });

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_SUPPLIERS,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: processConstants.API_GET_FAILURE_SUPPLIERS, error };
  }
}

function clearSuppliers() {
  return { type: processConstants.CLEAR_SUPPLIERS };
}

function getSuppliersProduct(id) {
  const uri = "supplier/" + id + "/products";

  return (dispatch) => {
    dispatch({ type: processConstants.API_GET_REQUEST_PRODUCTS, id });

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_PRODUCTS,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: processConstants.API_GET_FAILURE_PRODUCTS, error };
  }
}

function clearProducts() {
  return { type: processConstants.CLEAR_PRODUCTS };
}

function submitBulkSave() {}

function updateRoundSchedule(roundId, driverId, schedule) {
  const uri = "round/schedule/bulk";
  const newSchedule = [];

  schedule.forEach((sched) => {
    const newSched = {
      round_id: roundId,
      driver_id: driverId,
      customer_id: sched.customer_id,
    };
    newSchedule.push(newSched);
  });

  const data = {
    delete: { roundId: roundId, driverId: driverId },
    store: newSchedule,
  };

  return (dispatch) => {
    dispatch({ type: processConstants.API_POST_REQUEST_ROUND_SCHEDULE, data });

    processService.submitForm(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_POST_SUCCESS_ROUND_SCHEDULE,
            form: response.data.data,
          });
          dispatch(alertActions.success("Record successfully updated."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: processConstants.API_POST_FAILURE_ROUND_SCHEDULE, error };
  }
}

function updateDriverRounds(data) {
  const uri = "driver/rounds/bulk";

  return (dispatch) => {
    dispatch({ type: processConstants.API_POST_REQUEST_DRIVER_ROUNDS, data });

    processService.submitForm(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          localStorage.removeItem("__driver__list");
          localStorage.setItem(
            "__driver__list",
            JSON.stringify(saveDriverRoundsToStorage(response.data.data))
          );
          dispatch({
            type: processConstants.API_POST_SUCCESS_DRIVER_ROUNDS,
            form: response.data.data,
          });
          dispatch(alertActions.success("Record successfully updated."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: processConstants.API_POST_FAILURE_DRIVER_ROUNDS, error };
  }
}

function updateCustomerChecklist(customerId, data) {
  const uri = "checklist/customer/" + customerId;

  return (dispatch) => {
    dispatch({
      type: processConstants.API_POST_REQUEST_CUSTOMER_CHECKLIST,
      data,
    });

    processService.submitForm(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          setChecklist(customerId, response.data.data);
          dispatch({
            type: processConstants.API_POST_SUCCESS_CUSTOMER_CHECKLIST,
            form: response.data.data,
          });
          dispatch(alertActions.success("Record successfully updated."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return {
      type: processConstants.API_POST_FAILURE_CUSTOMER_CHECKLIST,
      error,
    };
  }
}

function updateDriverInfo(data) {
  const uri = "driver/" + data.id;

  return (dispatch) => {
    dispatch({ type: processConstants.API_POST_REQUEST, data });

    processService.updateRecord(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          const localKey = "__driver__list";
          let drivers = JSON.parse(localStorage.getItem(localKey));
          let updatedDriver = drivers.find((obj) => obj.id == data.id);
          updatedDriver.full_name = data.name;

          drivers = [
            ...drivers.filter((obj) => obj.id != data.id),
            updatedDriver,
          ];
          drivers.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
          localStorage.setItem(localKey, JSON.stringify(drivers));

          dispatch({
            type: processConstants.API_POST_SUCCESS_DRIVER_INFO,
            form: response.data.data,
          });
          dispatch(alertActions.success("Record successfully updated."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: processConstants.API_POST_FAILURE, error };
  }
}

function getPurchaseModalRecord() {
  const uri = "product/purchase/form";

  return (dispatch) => {
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_PO_FORM,
            form: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function savePurchaseOrder(data) {
  const uri = "product/purchase/order";

  return (dispatch) => {
    dispatch({ type: processConstants.API_POST_REQUEST, data });

    processService.submitRecords(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_POST_SUCCESS_PO_FORM,
            form: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_POST_FAILURE, error };
  }
}

function getPurchaseOrders() {
  const uri = "product/purchase/all";

  return (dispatch) => {
    dispatch({ type: processConstants.API_GET_REQUEST });
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_PO_ALL,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function getPurchaseOrderDetails(purchaseId) {
  const uri = "product/purchase/" + purchaseId + "/details";

  return (dispatch) => {
    dispatch({ type: processConstants.API_GET_REQUEST });
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_PO_DETAILS,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function updatePurchaseOrders(data) {
  const uri = "product/purchase/form";

  return (dispatch) => {
    dispatch({ type: processConstants.API_PUT_REQUEST, data });
    processService.updateRecord(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_PUT_SUCCESS_PO_FORM,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_PUT_FAILURE, error };
  }
}

function updatePurchaseOrder(uri, data, sendEmailOnly) {
  return (dispatch) => {
    dispatch({ type: processConstants.API_PUT_REQUEST, data });
    processService.submitRecords(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_POST_SUCCESS_PO,
            data: response.data.data,
          });
          if (sendEmailOnly) {
            dispatch(alertActions.success("Email sent."));
          } else {
            dispatch(
              alertActions.success("Purchase Order successfully updated.")
            );
          }
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_PUT_FAILURE, error };
  }
}

function getSuppliersForSearch() {
  const uri = "supplier/forSelect";

  return (dispatch) => {
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          setSuppliersForSearch(response.data.data);
          dispatch({
            type: processConstants.API_GET_SUCCESS_SUPPLIER_LIST_SELECT,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function extra(data) {
  return (dispatch) => {
    dispatch({ type: processConstants.EXTRA, data: data });
  };
}

function savePurchaseOrders(data, sendEmail) {
  const uri = "product/purchase/orders" + (sendEmail ? "?send_email=1" : "");

  return (dispatch) => {
    dispatch({ type: processConstants.API_POST_REQUEST, data });

    processService.submitRecords(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_POST_SUCCESS_PO_FORM,
            form: response.data.data,
          });
          dispatch(
            alertActions.success("Purchase Order successfully created.")
          );
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_POST_FAILURE, error };
  }
}

function getProductCategoriesForSearch() {
  const uri = "category/forSelect";

  return (dispatch) => {
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_CATEGORY_LIST_SELECT,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function getSupplierAndCategoriesOptions() {
  return (dispatch) => {
    Promise.all([
      dispatch(getSuppliersForSearch()),
      dispatch(getProductCategoriesForSearch()),
    ]);
  };
}

function getProductSuppliersRecord(productId) {
  const uri = "product/stocks/" + productId;

  return (dispatch) => {
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_GET_SUCCESS_PRODUCT_STOCKS,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}

function updateCostPrice(productId, data) {
  const uri = "product/stocks/" + productId;

  return (dispatch) => {
    dispatch({ type: processConstants.API_POST_REQUEST, data });

    processService.updateRecord(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_POST_SUCCESS_PRODUCT_STOCKS,
            form: response.data.data,
          });
          dispatch(alertActions.success("Record successfully updated."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: processConstants.API_POST_FAILURE, error };
  }
}

function saveNotes(orderId, notes) {
  const uri = "order/notes/" + orderId;
  const data = { id: orderId, notes: notes };

  return (dispatch) => {
    processService.updateRecord(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: processConstants.API_POST_SUCCESS_ORDER_NOTES,
            form: response.data.data,
          });
        }
      },
      (error) => {}
    );
  };
}

function getProductsForSearch(supplierId, includeNoSupplier) {
  let params = "";

  if (supplierId) {
    params = "?supplier_id=" + supplierId;

    if (includeNoSupplier) {
      params = params + "&include_no_supplier=1";
    }
  }

  const uri = "product/forSelect" + params;

  return (dispatch) => {
    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status == "success") {
          setProductsForSearch(response.data.data);
          dispatch({
            type: processConstants.API_GET_SUCCESS_PRODUCT_LIST_SELECT,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };
  function failure(error) {
    return { type: processConstants.API_GET_FAILURE, error };
  }
}
