import React from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userActions } from '../_actions/user.actions';
import { history } from '../_helpers/history';

class Login extends React.Component {

  constructor(props) {
    super (props);

    const token = JSON.parse(localStorage.getItem('token'));
    if (token && this.props.loggedIn && this.props.user && this.props.user.token) {
      history.push('/place');
    } else {
      this.props.logout();

      this.state = {
        username: '',
        password: '',
        submitted: false
      }

      this.handleChange = this.handleChange.bind(this);
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]:value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;

    if (username && password) {
      this.props.login(username, password);
    }
  }

  render () {
    return <>
      <Form className="loginForm border" onSubmit={this.handleSubmit}>    
        <Form.Group as={Row}>
          <Form.Label column sm="2">USERNAME</Form.Label>
            <Col sm="10">
              <Form.Control type="text" name="username" placeholder="Enter username"  onChange={this.handleChange}></Form.Control>
            </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">PASSWORD</Form.Label>
          <Col sm="10">
            <Form.Control type="password" name="password" placeholder="Enter password" onChange={this.handleChange}></Form.Control>
          </Col>
        </Form.Group>
        <div className="text-right">
          <Button variant="secondary" type="submit">Submit</Button>
        </div>
      </Form></>;
  }
}

function mapState(state) {
  const { authentication } = state;
  const { loggingIn, loggedIn, user } = authentication;
  return { loggingIn, loggedIn, user };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

export default connect(mapState, actionCreators)(Login);
