import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { connect } from 'react-redux';
import { modalActions } from '../../../_actions/modal.actions';
import { getProductIdsFromList } from '../../../_helpers/checklist';

class ProductModal extends React.Component
{
  constructor () {
    super ();

    this.state = {
      loading: false,
      selected: [],
      products: [],
      checklist: [],
      readOnlyIds: []
    }

    this.handleStartLoading = this.handleStartLoading.bind(this);
    this.handleStopLoading  = this.handleStopLoading.bind(this);
    this.handleAddProducts  = this.handleAddProducts.bind(this);
    this.handleAddChecklist = this.handleAddChecklist.bind(this);
    this.handleAddReadOnlyIds = this.handleAddReadOnlyIds.bind(this);
    this.handleRemoveChecklist = this.handleRemoveChecklist.bind(this);
  }

  handleStartLoading = () => { this.setState({ loading: true }) }
  handleStopLoading  = () => { this.setState({ loading: false }) }
  handleAddProducts  = (products) => { this.setState({ products: products }) }
  handleAddChecklist = (data) => {
    let checklist = this.state.checklist;
    checklist[data.id] = data;
  }
  handleRemoveChecklist = (id) => {
    let checklist = this.state.checklist;
    delete checklist[id];
  }
  handleAddReadOnlyIds = (ids) => { this.setState({ readOnlyIds: ids }) }
  handleSelectedChange = (id, isSelected) => {
    if (isSelected && !this.state.selected.includes(id)) {
      this.setState(() => ({
        selected: [...this.state.selected, id]
      }));
    } else if (! isSelected) {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== id)
      }));
    }
  }

  componentWillMount () {
    this.handleStartLoading();

    const { items, checklist, saved } = this.props;

    if (saved) {
      this.props.onSubmit();
    } else if (items) {
      this.handleStopLoading();
      this.handleAddProducts(items);
      this.handleAddReadOnlyIds(getProductIdsFromList(checklist));
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.result && nextProps.result != this.props.result) {
      this.props.handleClose();
    }
  }

  handleSubmit = () => {
    const { customerId } = this.props;
    const { checklist }  = this.state; 
    this.props.addChecklist(customerId, checklist);
  }

  handleChange = (e, row) => {
    e.preventDefault();
    
    this.handleAddChecklist({
      id: row.id,
      value: e.target.value,
      record: row
    });
  }

  render () {
    const { show, onHide, handleClose } = this.props;
    const { loading, products, checklist, readOnlyIds } = this.state;
    const columns = [
      { dataField: 'id', text: 'PRODUCT ID' },
      { dataField: 'code', text: 'CODE' },
      { dataField: 'description', text: 'DESCRIPTION' },
      { 
        dataField: 'price', 
        text: 'PRICE',
        formatter: (cell, row) => {
          return row.currency+" "+cell
        }
      },
      { dataField: 'vat_rate', text: 'VAT' },
      { dataField: 'no_of_stocks', text: 'NO. OF STOCKS' },
      { 
        dataField: 'discount_percentage',
        text: 'DISCOUNT (%)',
        formatter: (cell, row) => {
          if (readOnlyIds.includes(row.id)) {
            return '';
          }
          return <Form.Group className="table-form-group">
            <Form.Control 
              type="text" 
              pattern="[0-9]*" 
              defaultValue={checklist[row.id] && checklist[row.id].value ? checklist[row.id].value : ''} 
              onInput={e => this.handleChange(e, row)}
              readOnly={checklist[row.id] ? false : true}
            />
          </Form.Group>;
        }
      }
    ];
    const selectRow = {
      mode: 'checkbox',
      style: { backgroundColor: '#c8e6c9' },
      nonSelectable: readOnlyIds,
      hideSelectAll: true,
      selected: this.state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.handleAddChecklist({
            id: row.id,
            value: '',
            record: row
          });
          this.setState(() => ({
            selected: [...this.state.selected, row.id]
          }));
        } else {
          this.handleRemoveChecklist(row.id);
          this.setState(() => ({
            selected: this.state.selected.filter(x => x !== row.id)
          }));
        }
      }
    };

    return <>
      <Modal
        show={ show }
        onHide={ onHide }
        backdrop="static"
        keyboard={ false }
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title><h6>Add New Product on Checklist</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { products && <>
            <BootstrapTable 
              keyField="id"
              data={products}
              columns={columns}
              noDataIndication={ () => <i>No records found.</i> }
              loading={loading}
              overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
              selectRow={ selectRow }
            />
          </> }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>Close</Button>
          <Button variant="primary" onClick={ this.handleSubmit }>Save</Button>
        </Modal.Footer>
      </Modal>
    </>;
  }
}

function mapState (state) {
  const { modal } = state;
  const { items, result, loading, saved } = modal;
  return { items, result, loading, saved };
}

const actionCreators = {
  clearProducts: modalActions.clearProducts,
  addChecklist: modalActions.addChecklist
}

export default connect(mapState, actionCreators)(ProductModal);