import { Button, Container, Modal, Row } from "react-bootstrap";
import React from "react";
import { formatDateForTable } from "../../../_helpers/calendar";
import BootstrapTable from "react-bootstrap-table-next";
import { currencySymbol } from "../../../_helpers/content";

class OrderDetailsModal extends React.Component {
  render() {
    const { order } = this.props;
    const { details, customer } = this.props.details;
    console.log(order);
    console.log(order.type.toLowerCase() == "account" ? "qty_invoice" : "qty_cash");
    console.log(details);
    const columns = [
      {
        dataField: "code",
        text: "CODE",
        footer: "",
        footerClasses: "footer-borderless",
      },
      {
        dataField: "description",
        text: "DESCRIPTION",
        footer: "",
        footerClasses: "footer-borderless",
      },
      {
        dataField: order.type.toLowerCase() == "account" ? "qty_invoice" : "qty_cash",
        text: "QUANTITY",
        footer: "",
        footerClasses: "footer-borderless",
      },
      {
        dataField: "price",
        text: "PRICE " + currencySymbol,
        footer: "",
        footerClasses: "footer-borderless",
      },
      {
        dataField: "discount_amount",
        text: "DISCOUNT " + currencySymbol,
        footer: "",
        footerClasses: "footer-borderless",
      },
      {
        dataField: "discount_percentage",
        text: "DISCOUNT %",
        footer: "TOTAL AMOUNT " + currencySymbol,
        footerClasses: "footer-bordered",
      },
      {
        dataField: order.type.toLowerCase() == "account" ? "invoice_amount" : "cash_amount",
        text: "SUBTOTAL " + currencySymbol,
        footer: (columnData) =>
          columnData
            .reduce((acc, item) => Number(acc) + Number(item), 0)
            .toFixed(2),
      },
    ];

    return (
      <>
        {this.props.show && (
          <Modal
            show={this.props.show}
            onHide={this.props.onClose}
            backdrop="static"
            keyboard={false}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Order Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="div-2">
                <div className="column-70">
                  <Container>
                    <Row>{customer.company}</Row>
                    {customer.address_line_1 && (
                      <Row>{customer.address_line_1}</Row>
                    )}
                    {customer.address_line_2 && (
                      <Row>{customer.address_line_2}</Row>
                    )}
                    {customer.address_line_3 && (
                      <Row>{customer.address_line_3}</Row>
                    )}
                    {customer.country && <Row>{customer.country}</Row>}
                    {customer.post_code && <Row>{customer.post_code}</Row>}
                  </Container>
                </div>
                <div className="column-30">
                  <table>
                    <tr>
                      <th>Type:</th>
                      <td>{order["type"]}</td>
                      <td></td>
                      <th>Order #:</th>
                      <td>{order["id"]}</td>
                    </tr>
                    <tr>
                      <th>Round No.:</th>
                      <td>{order["round_no"]}</td>
                      <td>&nbsp;</td>
                      <th>Driver:</th>
                      <td>{order["driver"]}</td>
                    </tr>
                    <tr>
                      <th>Invoice No.:</th>
                      <td>{order["invoice_number"]}</td>
                      <td></td>
                      <th>Delivery Date:</th>
                      <td>
                        {formatDateForTable(new Date(order["delivery_date"]))}
                      </td>
                    </tr>
                    <tr>
                      <th>Account Code:</th>
                      <td>{order["company_ref"]}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <br />
              <BootstrapTable
                keyField="code"
                columns={columns}
                data={details}
              />
              <br />
              <br />
              {order["notes"] && (
                <table>
                  <thead>
                    <tr>
                      <th>Notes:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{order["notes"]}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onClose();
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default OrderDetailsModal;
