import { alertConstants } from "../_constants/alert.constants";

export const alertActions = {
  success,
  error,
  clear,
  errorFields,
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

function errorFields(message, fields) {
  console.log("Message --->", message);
  console.log("Fields --->", fields);
  return { type: alertConstants.ERROR_W_FIELDS, message, fields };
}
