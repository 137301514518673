import React from 'react';
import { connect } from 'react-redux';
import { contentActions } from '../_actions/content.actions';
import { navigateActions } from '../_actions/navigate.actions';
import DynamicTable from '../components/DynamicTable';
import DynamicTop from '../components/DynamicTop';
import CustomForm from '../components/Form/CustomForm';
import { processLocation } from '../_helpers/content';
import CustomTab from '../components/Tab/CustomTab';
import PlaceOrder from './order/PlaceOrder';
import { history } from '../_helpers/history';
import BulkUpdate from './order/BulkUpdate';
import Customers from './checklist/Customers';

const initialState = {
  title: '',
  componentType: ''
}

class Content extends React.Component 
{
  constructor () {
    super();

    this.state = initialState;
  }

  componentDidMount() {

    if (this.props.location.state) {
      this.props.getSelectedNav(
        this.props.location.state.key,
        this.props.location.state.parentId,
        this.props.user.navigation
      );
    } else {
      this.props.getSelectedNavByUri(this.props.user.navigation);
    }

    const currentUri = history.location.pathname.substring(1);
    if (currentUri != 'order/place') {
      this.props.getContent();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      
      if (this.props.location.state) {
        this.props.getSelectedNav(
          this.props.location.state.key,
          this.props.location.state.parentId,
          this.props.user.navigation
        );
      } else {
        this.props.getSelectedNavByUri(this.props.user.navigation);
      }

      const currentUri = history.location.pathname.substring(1);
      if (currentUri != 'order/place' && currentUri != 'customer/checklist') {
        this.props.getContent();
      }
    } else if (prevProps.alert !== this.props.alert) {
      if (this.props.alert.type == 'alert-success') {
        const currentUri = history.location.pathname.substring(1);
        if (currentUri != 'order/place') {
          this.props.getContent();
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedNav) {
      const { selectedNav, section } = nextProps;
      const { name } = selectedNav;
      this.setState({ title: name || '' });

      if (section) {
        this.setState({ componentType: section.component_type });
      }
    }
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  render () { 
    const { section, processes } = this.props;
    let processButtons = [];

    if (processes) {
      processButtons = processLocation(processes);
    }

    const currentUri = history.location.pathname.substring(1);
    if (currentUri == 'order/place') {
      return <div className="content">
        { section && <PlaceOrder buttons={processButtons} /> }
      </div>;
    } else if (currentUri == 'order/update') {
      return <div className="content">
        { section && <BulkUpdate buttons={processButtons} /> }
      </div>;
    } else if (currentUri == 'customer/checklist') {
      return <div className="content">
        { section && <Customers buttons={processButtons} /> }
      </div>;
    }

    // Modify to allow multiple sections
    return <div className="content">
      <h5>{this.state.title}</h5>
      {this.state.componentType == 'table' && section && <DynamicTable buttons={processButtons} />}
      {this.state.componentType == 'form' && section && <CustomForm buttons={processButtons.form} from="content" method="create" />}
    </div>;
  }
}

function mapState(state) {
  const { nav, contents, authentication, alert } = state;
  const { user } = authentication;
  const { selectedNav, section, processes } = nav;
  return { user, contents, selectedNav, section, processes, alert };
}

const actionCreators = {
  getSelectedNav: navigateActions.getSelectedNav,
  getSelectedNavByUri: navigateActions.getSelectedNavByUri,
  getContent: contentActions.getAll,
  getProcessPosition: contentActions.getProcessPosition
};

export default connect(mapState, actionCreators)(Content);