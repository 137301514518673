import { cartConstants } from '../_constants/cart.constants';

export const cartActions = {
  addToCart,
  clearCart
}

function addToCart (cart) {

  if (cart && cart.customerInfo && cart.customerInfo.action) {
    delete cart.customerInfo.action;
  }
  
  localStorage.setItem('cart', JSON.stringify(cart));
  return { type: cartConstants.ADD_TO_CART, cart};
}

function clearCart () {
  localStorage.removeItem('cart');
  return { type: cartConstants.CLEAR};
}

function getCart () {
  const cart = localStorage.getItem('cart');
  return { type: cartConstants.GET_CART, cart}
}