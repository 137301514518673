import React from 'react'; 
import { connect } from 'react-redux';
import { Tabs, Tab, Container } from 'react-bootstrap';
import DynamicTable from '../DynamicTable';
import CustomForm from '../Form/CustomForm';

class CustomTab extends React.Component
{
  constructor (props) {
    super(props);

    this.state = {
      key: '',
      dayKey: '',
    }
  }

  setDefaultKey = () => {
    const { section } = this.props;

    // if (section instanceof Array) {
    //   section.map(function(value, index) {
    //     if (value.order == 1) {
    //       let state = this.state;
    //       state['key'] = value.id;
    //       return;
    //     }
    //   })
    // }
  }

  componentWillMount() {
    this.setDefaultKey();
  }

  render () {
    const { section } = this.props;

    return <></>;
    // return <Tabs  
    //   id={id} 
    //   activeKey={this.state.key} 
    //   onSelect={(k) => this.setState({ key: k })}
    // >
    // {section.map(function(value, index) {
    //   <Tab eventKey={value.key} title={value.title}>
    //     <Container fluid className="tabs-content">
    //     {(value.component_type.indexOf("table") > -1) && <DynamicTable />}
    //     {(value.component_type.indexOf("form") > -1) && <CustomForm from="tab" />}
    //     </Container>
    //   </Tab>
    // })}
    // </Tabs>;
  }
}

function mapState (state) {
  const { nav } = state;
  const { section } = nav;
  return { section };
}

export default connect(mapState, null)(CustomTab);