import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { processActions } from '../_actions/process.actions';
import { alertActions } from '../_actions/alert.actions';
import CustomModal from '../components/Modal/CustomModal';
import { history } from '../_helpers/history';
import { clearCurrentState, setIsFormSubmit } from '../_helpers/session';
import { processConstants } from '../_constants/process.constants';
import AddNewProductModal from '../pages/product/AddNewProductModal';

class DynamicTop extends React.Component
{
  constructor (props) {
    super(props);

    this.state = {
      form: [],
      modalInfo: [],
      showModal: false,
      selectedModal: '',
      selectedModalId: '',
      categories: [],
      suppliers: [],
      hasProps: false
    }
  }

  // componentWillReceiveProps (nextProps) {
  //   if (nextProps.items !== this.props.items) {
  //     if (nextProps.items) {
  //       this.setState({ form: nextProps.items, showModal: true });
  //     }
  //   }
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.items && this.props.items !== prevProps.items) {
      this.setState({ form: this.props.items, showModal: true });
    } else if (this.props.process && this.props.process != prevProps.process) {
      
      if (this.props.process.suppliers && this.props.process.suppliers != prevProps.process.suppliers) {
        this.setState({ suppliers: this.props.process.suppliers }, () => {
          if (this.state.categories.length > 0) {
            this.setState({ showModal: true });
          }
        });
      } else if (this.props.process.categories) {
        this.setState({ categories: this.props.process.categories }, () => {
          if (this.state.suppliers.length > 0) {
            this.setState({ showModal: true });
          }
        });
      }
    }

    
  }

  handleButtonOnClick = (event) => {
    event.preventDefault();

    let state = this.state;
    state['selectedModal'] = event.target.dataset.method;
    state['selectedModalId'] = event.target.dataset.id;

    this.props.clearAlerts();
    this.setState({ hasProps: true });
    
    if (event.target.dataset.id == processConstants.PROCESS_ADD_PRODUCT_MODAL) {
      this.props.getSupplierAndCategoriesOptions();
    } else {
      this.props.getForm(event.target.dataset.id, event.target.dataset.method);
    }
  }

  render () {
    const { buttons } = this.props;

    const ShowModal = (props) => {
      
      const handleCloseModal = () => {
        this.setState({ 
          showModal: false, 
          hasProps: false
        });
        setIsFormSubmit(false);
        clearCurrentState();
      };

      if (props.method == this.state.selectedModal && props.id == this.state.selectedModalId && this.state.hasProps == true) {
        if(props.processId == processConstants.PROCESS_ADD_PRODUCT_MODAL) {
          return <AddNewProductModal 
            show={this.state.showModal}
            onHide={handleCloseModal}
            title= {props.title}
            handleClose={handleCloseModal}
            processId={props.processId}
            categories={this.state.categories}
            suppliers={this.state.suppliers}
          />
        } else {
          return <CustomModal 
              show={this.state.showModal}
              onHide={handleCloseModal}
              title= {props.title}
              content={this.state.form}
              handleClose={handleCloseModal}
              close="Close"
              save="Submit"
              processId={props.processId}
              method={props.method}
              displayType="form"
            />
        }
      } else {
        return <></>;
      }
    }

    if (buttons) {
      return <>
          {buttons.map(process =>
            <>
            {process.button_type.indexOf('button') > -1 && 
              <Button 
                key={process.id}
                variant="primary"
                className="float-sm-right buttonMain" 
                data-id={process.id} 
                data-method={process.url}
                onClick={(e) => this.handleButtonOnClick(e)}
              >
                {process.name}
              </Button>}
            {this.state.showModal && <ShowModal id={process.id} method={process.url} processId={process.id} title={process.name} />}
            </>
          )}
      </>;
    }
    return <></>;
  }
}

function mapState(state) {
  const { process } = state;
  const { items } = process;
  return { process, items };
}

const actionCreators = {
  getForm: processActions.getForm,
  clearAlerts: alertActions.clear,
  getSupplierAndCategoriesOptions: processActions.getSupplierAndCategoriesOptions
}

export default connect(mapState, actionCreators)(DynamicTop);