import client from '../api/ApiClient';

export const contentService = {
  getAll,
  getFormFields,
  getWithFilter
}

async function getAll (slug) {
  const url = process.env.REACT_APP_API_URL+'/'+slug;
  return await client.get(url);
}

async function getFormFields (id) {
  const url = process.env.REACT_APP_API_URL+'/form/'+id;
  return await client.get(url);
}

async function getWithFilter (slug, filter) {
  const url = process.env.REACT_APP_API_URL+'/'+slug+'?'+filter;
  return await client.get(url);
}