import React from 'react';
import { Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { currencySymbol } from '../../../_helpers/content';

class EditProductStocks extends React.Component
{
  state = {
    costPrice: []
  }

  onInputChange = (e, row) => {
    e.preventDefault();
    const costPrice = { id: row.id, cost_price: e.target.value, product_id: row.product_id, company: row.company };
    this.setState({ costPrice: [...this.state.costPrice.filter((obj) => obj.id != row.id), costPrice]})
    this.props.onChange(costPrice);
  }  

  render () {
    const columns = [
      { dataField: 'supplier_id', text: 'SUPPLIER ID', editable: false },
      { dataField: 'company', text: 'COMPANY', editable: false },
      { 
        dataField: 'cost_price', 
        text: 'COST PRICE '+currencySymbol, 
        editable: true,
        formatter: (cell, row) => {
          const defaultValue = this.state.costPrice.find((obj) => obj.id == row.id);
          return defaultValue && defaultValue.cost_price ? defaultValue.cost_price : row.cost_price;
        },
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
          const defaultValue = this.state.costPrice.find((obj) => obj.id == row.id);
          return <Form.Control 
            type="text"
            name="cost_price"
            placeholder="Input Cost Price"
            defaultValue={defaultValue && defaultValue.cost_price ? defaultValue.cost_price : value}
            onChange={ (e) => this.onInputChange(e, row)}
          />
        }
      },
      { dataField: 'no_of_stocks', text: 'NO. OF STOCKS', editable: false }
    ];

    return <>
      <BootstrapTable 
        columns={ columns }
        data={ this.props.data }
        keyField="supplier_id"
        noDataIndication={ () => <i>No records found.</i> }
        cellEdit={ cellEditFactory({
          mode: 'click',
          blurToSave: false
        }) }
      />
    </>;
  }
}

export default EditProductStocks;