export const daysOfTheWeek = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ];

export const monthsLong = [ 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const monthsShort = [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const getWeekDate = (selected) => {

  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  let count = 0;
  
  switch (selected) {
    case 'past':
      const pastDateCount = currentDay + 7;
      currentDate.setDate(currentDate.getDate() - pastDateCount);
    break;
    case 'present':
      count = currentDay;
      const presentDateCount = currentDay;
      currentDate.setDate(currentDate.getDate() - presentDateCount);
    break;
    case 'future':
      const futureDateCount = 7 - currentDay;
      currentDate.setDate(currentDate.getDate() + futureDateCount)
    break;
  }
  
  return {
    defaultDay: count,
    dates: { 
      sunday    : {
        default: formatDate(currentDate),
        formatted: formatDate(currentDate, true)
      },
      monday    : {
        default: formatDate(setDate(currentDate, 1)),
        formatted: formatDate(currentDate, true)
      },
      tuesday   : {
        default: formatDate(setDate(currentDate, 1)),
        formatted: formatDate(currentDate, true)
      },
      wednesday : {
        default: formatDate(setDate(currentDate, 1)),
        formatted: formatDate(currentDate, true)
      },
      thursday  : {
        default: formatDate(setDate(currentDate, 1)),
        formatted: formatDate(currentDate, true)
      },
      friday    : {
        default: formatDate(setDate(currentDate, 1)),
        formatted: formatDate(currentDate, true)
      },
      saturday  : {
        default: formatDate(setDate(currentDate, 1)),
        formatted: formatDate(currentDate, true)
      },
    }
  }

}

const setDate = (date, count, operation) => {
  if (! operation) {
    operation = 'add';
  }

  const newDate = date;

  switch (operation) {
    case 'add':
      date.setDate(newDate.getDate() + count);
      break;
    case 'minus':
      if (count > newDate.getDate()) {
        date.setDate(count - newDate.getDate());  
      } else {
        date.setDate(newDate.getDate() - count);
      }
      break;
    default:
      date.setDate(newDate.getDate() + count);
      break;
    
  }

  return date;
}

const formatDate = (date, beautify) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  if (beautify) {
    return monthsLong[month].charAt(0).toUpperCase()+monthsLong[month].substring(1)+' '+day+', '+year;
  }

  return year.toString().substring(2)+((month+1) >= 10 ? '' : '0')+(month+1)+(day >= 10 ? '' : '0')+day;
}

export const getDriverRounds = (drivers, dateId, date) => {
  drivers.map((driver) => {
    driver.date = date;
    driver.round = dateId+driver.id;
  })

  return drivers;
}

export const getDayId = (day) => {
  const newDay = day.toLowerCase();
  return daysOfTheWeek.indexOf(newDay)+1;
}

export const formatDeliveryDate = (roundNo) => {
  const year = "20"+roundNo.substring(0, 2);
  const month = monthsLong[Number(roundNo.substring(2, 4))-1];
  const day = roundNo.substring(4, 6);

  return month.toUpperCase()+" "+day+", "+year;
}

export const formatDeliveryDateForQuery = (roundNo) => {
  const year = "20"+roundNo.substring(0, 2);
  const month = roundNo.substring(2, 4);
  const day = roundNo.substring(4, 6);

  return year+"-"+month+"-"+day;
}

export const formatDateForQuery = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return year.toString()+"-"+((month+1) >= 10 ? '' : '0')+(month+1)+"-"+(day >= 10 ? '' : '0')+day;
}

export const generateRoundNo = (driverId, date) => {
  const roundNo = formatDate(date)+driverId;

  return roundNo;
}

export const getRoundIdByDate = (date) => {
  const day = date.getDay();
  return day+1;
}

export const getRoundIdByRoundNo = (roundNo) => {
  const date = new Date(formatDeliveryDateForQuery(roundNo));
  return getRoundIdByDate(date);
}

export const formatDateForPrinting = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dateNo = date.getDate();
  const day = date.getDay();

  return daysOfTheWeek[day].charAt(0).toUpperCase()+daysOfTheWeek[day].substring(1)+' '+(dateNo >= 10 ? '' : '0')+dateNo+'-'+((month+1) >= 10 ? '' : '0')+(month+1)+'-'+year
}

export const formatDateForTable = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dateNo = date.getDate();

  return (dateNo >= 10 ? '' : '0')+dateNo+'-'+((month+1) >= 10 ? '' : '0')+(month+1)+'-'+year
}