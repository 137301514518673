import React from 'react';

class TableHeader extends React.Component
{
  render() {
    const { columns } = this.props;
    return <thead>
      <tr>
        { columns.length > 0 && columns.map((column) => <th>{ column.text }</th> )}
      </tr>
    </thead>
  }
}

export default TableHeader;