export const orderConstants = {
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_DELIVERY_DATE: 'SET_DELIVERY_DATE',
  GET_ROUND_ORDERS: 'GET_ROUND_ORDERS',

  CLEAR_ORDERS: 'CLEAR_ORDERS',

  API_GET_REQUEST_ROUNDS: 'API_GET_REQUEST_ROUNDS',
  API_GET_SUCCESS_ROUNDS: 'API_GET_SUCCESS_ROUNDS',
  API_GET_FAILURE_ROUNDS: 'API_GET_FAILURE_ROUNDS',

  API_GET_REQUEST_BY_ROUND_DRIVER: 'API_GET_REQUEST_BY_ROUND_DRIVER',
  API_GET_SUCCESS_BY_ROUND_DRIVER: 'API_GET_SUCCESS_BY_ROUND_DRIVER',
  API_GET_FAILURE_BY_ROUND_DRIVER: 'API_GET_FAILURE_BY_ROUND_DRIVER',

  API_POST_REQUEST_BY_ROUND_DRIVER: 'API_POST_REQUEST_BY_ROUND_DRIVER',
  API_POST_SUCCESS_BY_ROUND_DRIVER: 'API_POST_SUCCESS_BY_ROUND_DRIVER',
  API_POST_FAILURE_BY_ROUND_DRIVER: 'API_POST_FAILURE_BY_ROUND_DRIVER',

  API_POST_REQUEST_BULK_ORDERS: 'API_POST_REQUEST_BULK_ORDERS',
  API_POST_SUCCESS_BULK_ORDERS: 'API_POST_SUCCESS_BULK_ORDERS',
  API_POST_FAILURE_BULK_ORDERS: 'API_POST_FAILURE_BULK_ORDERS',

  API_GET_REQUEST_INVOICE_ORDER_DETAILS: 'API_GET_REQUEST_INVOICE_ORDER_DETAILS',
  API_GET_SUCCESS_INVOICE_ORDER_DETAILS: 'API_GET_SUCCESS_INVOICE_ORDER_DETAILS',
  API_GET_FAILURE_INVOICE_ORDER_DETAILS: 'API_GET_FAILURE_INVOICE_ORDER_DETAILS',

  API_POST_REQUEST_ORDER_DISCOUNT: 'API_POST_REQUEST_ORDER_DISCOUNT',
  API_POST_SUCCESS_ORDER_DISCOUNT: 'API_POST_SUCCESS_ORDER_DISCOUNT',
  API_POST_FAILURE_ORDER_DISCOUNT: 'API_POST_FAILURE_ORDER_DISCOUNT',
}