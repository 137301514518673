import { placeConstants } from "../_constants/place.constants";
import { processConstants } from "../_constants/process.constants";
import { history } from "../_helpers/history";
import { saveRoundDriversToStorage } from "../_helpers/placeOrder";
import {
  getAccounts,
  getChecklist,
  getSchedule,
  setAccounts,
  setChecklist,
  setOrders,
  setSchedule,
  getOrders as getSessionOrders,
  getProducts as getSessionProducts,
  setOrderDetails as setSessionOrderDetails,
  getPaperWorksCreated,
  setPaperWorksCreated,
  setOrderDetails,
} from "../_helpers/session";
import { processService } from "../_services/process.service";
import { alertActions } from "./alert.actions";
import Error from "../_helpers/error";

export const placeActions = {
  getSchedules,
  getOrders,
  getChecklistByCustomerId,
  getRoundStatus,
  savePaperWorksStatus,
  getOrderRecords,
  isValidRoundNo,
  addOrderDetailsAndChecklist,
  getPaperWorksPdf,
  generatePaperworks,
  getInvoice,
  generateRoundInvoices,
  generateInvoices,
  generateInvoice,
  emailInvoice,
  printNotesByRoundNo,
  printNotesByOrderId,
  taggedAsPrinted,
  printOrderInvoice,
  printRoundInvoices,
  printDriversProductList,
  saveSorting,
};

function getSchedules() {
  const schedule = getSchedule();

  if (schedule) {
    return (dispatch) => {
      dispatch({ type: placeConstants.GET_SCHEDULE_LOCAL, schedule });
    };
  }

  const slug = "place"; // history.location.pathname.substring(1);
  const method = processConstants.PROCESS_METHOD_SHOW;
  const uri =
    slug + "/process/" + processConstants.PROCESS_SHOW_SCHEDULES + "/item/all";

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_SCHEDULE });

    processService.getFormById(uri, method).then(
      (response) => {
        if (response.data.status === "success") {
          const schedule = response.data.data;
          setSchedule(schedule);
          dispatch({ type: placeConstants.API_GET_SUCCESS_SCHEDULE, schedule });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_SCHEDULE, error };
  }
}

function getOrders(roundNo, roundId) {
  const uri = "orders/round/" + roundNo + "/roundId/" + roundId;
  let orders = getSessionOrders(roundNo);

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_ORDERS });

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status === "success") {
          const orderResponse = response.data.data.orders;
          setOrderDetails(response.data.data.order_details);
          setOrders(roundNo, orderResponse);
          dispatch({
            type: placeConstants.API_GET_SUCCESS_ORDERS,
            orders: orderResponse,
            orderDetails: response.data.data.order_details || [],
            paperWorksGenerated: response.data.data.paperworks_generated || "0",
            total: response.data.data.total || {},
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_ORDERS, error };
  }

  function setOrderDetails(orderDetails) {
    for (const orderId in orderDetails) {
      setSessionOrderDetails(orderId, orderDetails[orderId]);
    }
  }
}

function getChecklistByCustomerId(customerId) {
  console.log('getChecklistByCustomerId');
  const checklist = getChecklist(customerId);

  const slug = "place";
  const method = processConstants.PROCESS_METHOD_SHOW;
  const uri =
    slug +
    "/process/" +
    processConstants.PROCESS_SHOW_CHECKLIST +
    "/item/" +
    customerId;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_CHECKLIST });

    processService.getFormById(uri, method).then(
      (response) => {
        if (response.data.status === "success") {
          const checklist = response.data.data;
          setChecklist(customerId, checklist);
          dispatch({
            type: placeConstants.API_GET_SUCCESS_CHECKLIST,
            checklist,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_SCHEDULE, error };
  }
}

function savePaperWorksStatus(roundNo, status) {
  const slug = history.location.pathname.substring(1);
  const processId = processConstants.PROCESS_SAVE_ROUND_STATUSES;
  const method = processConstants.PROCESS_METHOD_CREATE;
  const data = {
    round_no: roundNo,
    paperworks_generated: status,
  };
  const form = { slug, processId, data, method };

  return (dispatch) => {
    dispatch(request(form));

    let uri = slug + "/process/" + processId;

    processService.submitForm(uri, data, method).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch(success(response.data.data));
          dispatch(alertActions.success("Record successfully saved"));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function request(form) {
    return { type: placeConstants.API_POST_REQUEST_ROUND_STATUSES, form };
  }
  function success(data) {
    return { type: placeConstants.API_POST_SUCCESS_ROUND_STATUSES, data };
  }
  function failure(error) {
    return { type: placeConstants.API_POST_FAILURE_ROUND_STATUSES, error };
  }
}

function getRoundStatus(roundNo) {
  const roundStatus = getPaperWorksCreated(roundNo);
  const uri = "orders/roundStatus/" + roundNo;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_ROUND_STATUSES });

    if (roundStatus) {
      dispatch({
        type: placeConstants.API_GET_SUCCESS_ROUND_STATUSES,
        roundStatus,
      });
    }

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status === "success") {
          const paperworks_status =
            response.data.data[0] &&
            response.data.data[0].paperworks_generated == 1
              ? true
              : false;
          setPaperWorksCreated(roundNo, Boolean(paperworks_status));
          dispatch({
            type: placeConstants.API_GET_SUCCESS_SCHEDULE,
            paperworks_status,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );

    function failure(error) {
      return { type: placeConstants.API_GET_FAILURE_ROUND_STATUSES, error };
    }
  };
}

function getOrderRecords(roundNo, roundId) {
  return (dispatch) => {
    Promise.all([
      dispatch(getOrders(roundNo, roundId)),
      // dispatch(getRoundStatus(roundNo))
    ]);
  };
}

function isValidRoundNo(roundNo) {
  const uri = "round/validate/" + roundNo;
  let isValid = false;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_VALIDATE });

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status === "success") {
          if (response.data.data && response.data.data.length > 0)
            isValid = true;

          dispatch({
            type: placeConstants.API_GET_SUCCESS_VALIDATE,
            isValid: isValid,
            roundNo: roundNo,
          });

          if (!isValid) {
            dispatch(alertActions.error("Record not found."));
          }
        } else {
          const e = Error(response);
          dispatch(failure(isValid, roundNo));
          dispatch(alertActions.error("Record not found."));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(isValid, roundNo));
        dispatch(alertActions.error("Record not found."));
      }
    );

    function failure(isValid, roundNo) {
      return {
        type: placeConstants.API_GET_FAILURE_VALIDATE,
        isValid,
        roundNo,
      };
    }
  };
}

function addOrderDetailsAndChecklist(orderId, customerId, record) {
  const checklist = {
    customer_id: customerId,
    product_id: record.id,
    discount_percentage: record.discountPercentage,
    customer_price: record.customerPrice,
    status: record.status || "Active",
  };

  const orderDetails = {
    order_id: orderId,
    product_id: record.id,
    price: record.price,
    discount_percentage: record.discountPercentage || "",
    discount_amount: record.customerPrice || "",
    qty_invoice: record.qtyInvoice || "",
    qty_cash: record.qtyCash || "",
    invoice_amount: record.invoiceAmount || "",
    cash_amount: record.cashAmount || "",
    product_supplier_id: record.productSupplierId || "",
    status: record.status || "Active",
  };

  const data = {
    checklist: checklist,
    orderDetails: orderDetails,
  };
  const uri = "order/details/checklist/" + orderId;
  return (dispatch) => {
    dispatch({ type: placeConstants.API_POST_REQUEST_ORDER_DETAILS_CHECKLIST });

    processService.submitForm(uri, { data: data }).then(
      (response) => {
        if (response.data.status === "success") {
          // const newChecklist = response.data.data.checklist;
          const newOrderDetails = response.data.data.order_details;
          // const currChecklist = getChecklist(customerId);
          // setChecklist(customerId, [...(currChecklist), newChecklist]);
          setOrderDetails(orderId, newOrderDetails);
          dispatch({
            type: placeConstants.API_POST_SUCCESS_ORDER_DETAILS_CHECKLIST,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );

    function failure(error) {
      return {
        type: placeConstants.API_POST_FAILURE_ORDER_DETAILS_CHECKLIST,
        error,
      };
    }
  };
}

function getPaperWorksPdf(roundNo) {
  return (dispatch) => {
    dispatch({ type: placeConstants.API_POST_REQUEST_PAPERWORKS_GENERATE });

    const uri = "download/" + roundNo;

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status === "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_PAPERWORKS_GENERATE,
            data: response.data.data.path,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );

    function failure(error) {
      return {
        type: placeConstants.API_POST_FAILURE_PAPERWORKS_GENERATE,
        error,
      };
    }
  };
}

function generatePaperworks(roundNo, status) {
  return (dispatch) => {
    Promise.all([
      dispatch(savePaperWorksStatus(roundNo, status)),
      dispatch(getPaperWorksPdf(roundNo)),
    ]);
  };
}

function getInvoice(orderId, type) {
  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_INVOICE });

    const uri = "order/" + orderId + "/invoice";

    processService.getFormById(uri).then(
      (response) => {
        if (response.data.status === "success") {
          dispatch({
            type: placeConstants.API_GET_SUCCESS_INVOICE,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );

    function failure(error) {
      return { type: placeConstants.API_GET_FAILURE_INVOICE, error };
    }
  };
}

function generateInvoices(orderId, roundNo) {
  const uri = "invoice/order/" + orderId;
  const data = { id: orderId, round_no: roundNo };

  return (dispatch) => {
    dispatch({ type: placeConstants.API_POST_REQUEST_GENERATE_INVOICE, data });

    processService.submitForm(uri, data).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_GENERATE_INVOICE,
            data: response.data.data,
          });
          const data = response.data.data[0];
          let message = "No invoice generated.";
          let noInvoice = true;

          if (data.invoice_no && data.cash_invoice_no) {
            message = "Account invoice and cash invoice successfully generated";
            noInvoice = false;
          } else if (data.invoice_no) {
            message = "Account invoice successfully generated";
            noInvoice = false;
          } else if (data.cash_invoice) {
            message = "Cash invoice successfully generated";
            noInvoice = false;
          }

          if (noInvoice) {
            dispatch(alertActions.error(message));
          } else {
            dispatch(alertActions.success(message));
          }
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_POST_FAILURE_GENERATE_INVOICE, error };
  }
}

function generateInvoice(orderId, isAccount) {
  const uri = "invoice/order/" + orderId + (isAccount ? "/account" : "/cash");

  return (dispatch) => {
    dispatch({
      type: placeConstants.API_POST_REQUEST_GENERATE_INVOICE,
      data: {},
    });

    processService.submitForm(uri, {}).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_GENERATE_INVOICE,
            data: response.data.data,
          });
          const data = response.data.data[0];

          if (isAccount && data.invoice_no) {
            dispatch(
              alertActions.success("Account invoice successfully generated")
            );
          } else if (!isAccount && data.cash_invoice_no) {
            dispatch(
              alertActions.success("Cash invoice successfully generated")
            );
          } else {
            dispatch(alertActions.error("No invoice generated"));
          }
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_POST_FAILURE_GENERATE_INVOICE, error };
  }
}

function emailInvoice(orderId) {
  const uri = "invoice/email/" + orderId;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_POST_REQUEST_EMAIL_INVOICE, data: {} });

    processService.submitForm(uri, {}).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_EMAIL_INVOICE,
            data: response.data.data,
          });
          dispatch(alertActions.success("Email sent."));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_POST_FAILURE_EMAIL_INVOICE, error };
  }
}

function generateRoundInvoices(roundNo) {
  const uri = "invoice/round/" + roundNo;

  return (dispatch) => {
    dispatch({
      type: placeConstants.API_POST_REQUEST_GENERATE_ROUND_INVOICE,
      data: {},
    });

    processService.submitForm(uri, {}).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_GENERATE_ROUND_INVOICE,
            data: response.data.data,
          });
          const data = response.data.data;

          if (data.invoices) {
            dispatch(
              alertActions.success("Generate round invoice successfully")
            );
          } else {
            dispatch(alertActions.error("No invoice generated"));
          }
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return {
      type: placeConstants.API_POST_FAILURE_GENERATE_ROUND_INVOICE,
      error,
    };
  }
}

function printNotesByRoundNo(roundNo) {
  const uri = "orders/notes/" + roundNo;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_PRINT_NOTES, data: {} });

    processService.get(uri).then(
      (response) => {
        if (response.data.status == "success") {
          console.log(response.data.data);
          dispatch({
            type: placeConstants.API_GET_SUCCESS_PRINT_NOTES,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_PRINT_NOTES, error };
  }
}

function printNotesByOrderId(orderId) {
  const uri = "orders/note/" + orderId;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_PRINT_NOTES, data: {} });

    processService.get(uri).then(
      (response) => {
        if (response.data.status == "success") {
          console.log(response.data.data);
          dispatch({
            type: placeConstants.API_GET_SUCCESS_PRINT_NOTES,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_PRINT_NOTES, error };
  }
}

function taggedAsPrinted(id) {
  const uri = "invoice/" + id + "/printed/1";

  return (dispatch) => {
    dispatch({ type: placeConstants.API_POST_REQUEST_PRINT_INVOICE, data: {} });

    processService.submitForm(uri, {}).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_PRINT_INVOICE,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_POST_FAILURE_PRINT_INVOICE, error };
  }
}

function printOrderInvoice(orderId) {
  const uri = "invoice/download/" + orderId;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_PRINT_INVOICE, data: {} });

    processService.get(uri).then(
      (response) => {
        console.log("Response --->", response);
        if (response.data.status == "success") {
          console.log(
            "Print Invoice response.data.data --->",
            response.data.data
          );
          dispatch({
            type: placeConstants.API_GET_SUCCESS_PRINT_INVOICE,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_PRINT_INVOICE, error };
  }
}

function printRoundInvoices(roundNo) {
  const uri = "invoice/download/round/" + roundNo;

  return (dispatch) => {
    dispatch({ type: placeConstants.API_GET_REQUEST_PRINT_INVOICE, data: {} });

    processService.get(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_GET_SUCCESS_PRINT_INVOICE,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_PRINT_INVOICE, error };
  }
}

function printDriversProductList(roundNo) {
  const uri = "orders/drivers/list/" + roundNo;

  return (dispatch) => {
    dispatch({
      type: placeConstants.API_GET_REQUEST_PRINT_DRIVERS_LIST,
      data: {},
    });

    processService.get(uri).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_GET_SUCCESS_PRINT_DRIVERS_LIST,
            data: response.data.data,
          });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }
    );
  };

  function failure(error) {
    return { type: placeConstants.API_GET_FAILURE_PRINT_DRIVERS_LIST, error };
  }
}

function saveSorting(data) {
  const uri = "orders/sort";

  return (dispatch) => {
    dispatch({ type: placeConstants.API_POST_REQUEST_ORDER_SORT });

    processService.updateRecord(uri, { data: data }).then(
      (response) => {
        if (response.data.status == "success") {
          dispatch({
            type: placeConstants.API_POST_SUCCESS_ORDER_SORT,
            data: response.data.data,
          });
          dispatch(alertActions.success("Order sorting successfully saved."));
        }
      },
      (error) => {
        const e = Error(error);
        dispatch({ type: placeConstants.API_POST_FAILURE_ORDER_SORT });
        dispatch(alertActions.error(e.message));
      }
    );
  };
}
