import { userConstants } from '../_constants/user.constants';
import { userService } from '../_services/user.service';
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';

export const userActions = {
  login,
  logout
};

function login(username, password) {
  return dispatch => {
      dispatch(request({ username }));

      userService.login(username, password)
          .then(
              user => { 
                  dispatch(success({ 
                    name: user.data.name, 
                    navigation: JSON.parse(localStorage.getItem('navigation')), 
                    token: user.data.token.access_token
                  }));
                  history.push('/place');
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error("Invalid credentials. Please try again."));
              }
          );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}