import React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ReactDatePicker from "react-datepicker";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import SingleSelect from "../../../components/Form/SingleSelect";
import TableComponent from "../../../components/Table/TableComponent";
import { processActions } from "../../../_actions/process.actions";
import {
  formatDateForQuery,
  formatDateForTable,
} from "../../../_helpers/calendar";
import { currencySymbol } from "../../../_helpers/content";
import { getProducts } from "../../../_helpers/session";

const uri = {
  update: "product/purchase/order/$$", // POST update and update_w_email, send email, mark as completed
  clone: "product/purchase/order", // POST same as saving new
};

const computeOverallTotal = (purchased) => {
  let total = 0;
  purchased.forEach((product) => {
    const subtotal = !isNaN(product.total_amount)
      ? Number(product.total_amount)
      : 0;
    total = total + subtotal;
  });

  return total.toFixed(2);
};

const computeOverallSubtotal = (purchased) => {
  let total = 0;
  purchased.forEach((product) => {
    const subtotal = !isNaN(product.subtotal) ? Number(product.subtotal) : 0;
    total = total + subtotal;
  });

  return total.toFixed(2);
};

const computeOverallVat = (purchased) => {
  let totalVat = 0;
  purchased.forEach((product) => {
    const vat = !isNaN(product.vat) ? Number(product.vat) : 0;
    const quantity = !isNaN(product.quantity) ? Number(product.quantity) : 0;
    totalVat = totalVat + vat * quantity;
  });

  return totalVat.toFixed(2);
};

const computeTotal = (quantity, price, vat) => {
  const qty = !isNaN(quantity) ? Number(quantity) : 0;
  const amount = !isNaN(price) ? Number(price) : 0;
  const evat = !isNaN(vat) ? Number(vat) : 0;

  return (
    (parseFloat(qty) * (parseFloat(amount) + parseFloat(evat))).toFixed(2) || 0
  );
};

class PurchaseModalUpdate extends React.Component {
  constructor(props) {
    super(props);

    const deliveryDate = this.props.rowSelected.date_to_deliver
      ? new Date(this.props.rowSelected.date_to_deliver.replace(" ", "T"))
      : "";
    this.state = {
      purchaseId: this.props.rowSelected.id,
      origDetails: this.props.details,
      computedDetails: this.props.details,
      updatedDetails: [],
      deletedDetails: [],
      loading: this.props.loading || false,
      invoiceNo: this.props.rowSelected.invoice_no || "",
      deliveryDate: deliveryDate,
      newDetails: [],
      newProduct: {},
      newProductReadOnly: true,
    };

    this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
  }

  updateComputedDetails = (id, attributes) => {
    var index = this.state.computedDetails.findIndex((x) => x.id === id);
    if (index === -1) {
      return;
    } else {
      this.setState({
        computedDetails: [
          ...this.state.computedDetails.slice(0, index),
          Object.assign({}, this.state.computedDetails[index], attributes),
          ...this.state.computedDetails.slice(index + 1),
        ],
      });
    }
  };

  handleOnKeyUp = (e, row) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    if (name == "invoice_no") {
      this.setState({ invoiceNo: value });
    } else {
      const id = e.target.dataset.id;
      let record =
        (this.state.updatedDetails.length > 0 &&
          this.state.updatedDetails.filter((obj) => obj.id == id)[0]) ||
        {};
      let computedRecord =
        this.state.computedDetails.filter((obj) => obj.id == id)[0] || {};

      if (record) {
        record.id = id;
        record[name] = value;
      } else {
        record = {
          id: id,
          [name]: value,
        };
      }

      const subtotal = computeTotal(
        record.quantity || computedRecord.quantity || 0,
        record.price || computedRecord.price || 0
      );
      const totalAmount = computeTotal(
        record.quantity || computedRecord.quantity || 0,
        record.price || computedRecord.price || 0,
        record.vat || computedRecord.vat || 0
      );

      record.subtotal = subtotal;
      record.total_amount = totalAmount;

      computedRecord[name] = value;
      computedRecord.subtotal = subtotal;
      computedRecord.total_amount = totalAmount;

      this.setState(
        {
          updatedDetails: [
            ...this.state.updatedDetails.filter((obj) => obj.id != id),
            record,
          ],
        },
        () => {
          this.updateComputedDetails(id, computedRecord);
        }
      );
    }
  };

  handleUpdateOnClick = (e, doPurchase, doEmail, delivered) => {
    e.preventDefault();

    const { rowSelected } = this.props;
    const { updatedDetails, newDetails, deletedDetails } = this.state;

    let data = {
      products: updatedDetails,
      new_products: newDetails,
      deleted_products: deletedDetails,
      purchase_details: {
        id: rowSelected.id,
        invoice_no: this.state.invoiceNo,
        date_to_deliver: formatDateForQuery(new Date(this.state.deliveryDate)),
      },
    };
    console.log("Updated Data -->>>>>>>", data);
    if (doPurchase && doPurchase == true) {
      data.purchase_details.on_order = "1";
    }

    if (doEmail && doEmail == true) {
      data.purchase_details.emailed = "1";
    }

    if ((delivered && delivered == true) || this.state.invoiceNo) {
      data.purchase_details.on_order = "0";
      data.purchase_details.delivered = "1";
    }

    this.props.updatePurchaseOrder(
      uri.update.replace("$$", rowSelected.id),
      data,
      doEmail && doEmail == true ? true : false
    );
    this.props.onClose();
  };

  handleCloneOnClick = (e) => {
    e.preventDefault();

    const { rowSelected } = this.props;
    const { origDetails, updatedDetails } = this.state;
    let clonedOrder = [];

    origDetails.forEach((product, key) => {
      const index = updatedDetails.findIndex((obj) => obj.id == product.id);
      const n_product =
        index !== -1 ? [...updatedDetails[index], ...product] : product;

      clonedOrder[key] = n_product;
      delete clonedOrder[key].id;
      delete clonedOrder[key].purchase_id;
    });

    let data = {
      products: clonedOrder,
      purchase_details: rowSelected,
    };

    // delete data.purchase_details.id;
    this.setState({ loading: true }, () => {
      this.props.savePurchaseOrder(data);
      this.props.onClose();
    });
  };

  handleSelectChange = (selected) => {
    const product = getProducts().find((obj) => obj.id == selected.value);
    this.setState((prevState) => ({
      newProduct: {
        ...product,
        id: "new-" + selected.value,
        product_id: selected.value,
        product_code: product.code,
      },
      newProductReadOnly: false,
    }));
  };

  handleAddProductOnKeyUp = (e, row) => {
    let newProduct = this.state.newProduct;
    newProduct[e.target.name.substring(4)] = e.target.value;
    newProduct["total_amount"] = computeTotal(
      newProduct.quantity,
      newProduct.price,
      newProduct.vat
    );

    this.setState({ newProduct: newProduct });
  };

  handleAddProductOnClick = (e) => {
    e.preventDefault();

    this.setState({
      computedDetails: [...this.state.computedDetails, this.state.newProduct],
      newDetails: [...this.state.newDetails, this.state.newProduct],
      newProduct: {},
      newProductReadOnly: true,
    });

    const inputToResetValue = [
      "add-quantity",
      "add-price",
      "add-vat",
      "add-total_amount",
    ];
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      if (inputToResetValue.includes(input.name)) {
        return (input.value = "");
      }
    });
  };

  handleDeleteOnClick = (e, row) => {
    e.preventDefault();

    this.setState(
      {
        computedDetails: [
          ...this.state.computedDetails.filter((obj) => obj.id != row.id),
        ],
      },
      () => {
        if (!isNaN(row.id)) {
          this.setState({
            deletedDetails: [...this.state.deletedDetails, row],
          });
        } else {
          this.setState({
            newDetails: [
              ...this.state.newDetails.filter((obj) => obj.id != row.id),
            ],
          });
        }
      }
    );
  };

  onDateChange = (date) => {
    this.setState({ deliveryDate: date });
  };

  render() {
    const { computedDetails } = this.state;
    const extraTr = [
      [
        {
          colSpan: 2,
          dataField: "newProduct",
          formType: "singleselect",
          options: this.props.product_list_select,
          placeholder: "Add Product",
          onChange: this.handleSelectChange,
          resetValue: this.state.newProductReadOnly,
        },
        {
          colSpan: 1,
          dataField: "add-quantity",
          formType: "input",
          readOnly: this.state.newProductReadOnly,
          onKeyUp: this.handleAddProductOnKeyUp,
          defaultValue: this.state.newProduct.quantity || "",
        },
        {
          colSpan: 1,
          dataField: "add-price",
          formType: "input",
          readOnly: this.state.newProductReadOnly,
          onKeyUp: this.handleAddProductOnKeyUp,
          defaultValue: this.state.newProduct.cost_price || "",
        },
        {
          colSpan: 1,
          dataField: "add-vat",
          formType: "input",
          readOnly: this.state.newProductReadOnly,
          onKeyUp: this.handleAddProductOnKeyUp,
          defaultValue:
            this.state.newProduct.vat && this.state.newProduct.vat > 0
              ? this.state.newProduct.vat
              : "",
        },
        {
          colSpan: 1,
          dataField: "add-totalAmount",
          value: this.state.newProduct.total_amount || "",
        },
        {
          colSpan: 1,
          dataField: "process",
          formType: "button",
          icon: "fas fa-plus-circle icon",
          readOnly: this.state.newProductReadOnly,
          onClick: this.handleAddProductOnClick,
          defaultValue: "",
        },
      ],
      [
        {
          colSpan: 6,
          value: "Subtotal " + currencySymbol,
          alignRight: true,
          bold: true,
        },
        {
          colSpan: 1,
          value: computeOverallSubtotal(computedDetails),
          bold: true,
        },
      ],
      [
        {
          colSpan: 6,
          value: "VAT " + currencySymbol,
          alignRight: true,
          bold: true,
        },
        { colSpan: 1, value: computeOverallVat(computedDetails), bold: true },
      ],
      [
        {
          colSpan: 6,
          value: "Total Amount " + currencySymbol,
          alignRight: true,
          bold: true,
        },
        { colSpan: 1, value: computeOverallTotal(computedDetails), bold: true },
      ],
    ];

    const readOnly =
      this.props.rowSelected.delivered == "1" ||
      this.props.rowSelected.delivered == "COMPLETE"
        ? true
        : false;
    const columns = [
      { dataField: "product_code", text: "PRODUCT CODE" },
      { dataField: "description", text: "DESCRIPTION" },
      {
        dataField: "quantity",
        text: "QUANTITY",
        formType: "input",
        readOnly: readOnly,
      },
      {
        dataField: "price",
        text: "PRICE " + currencySymbol,
        formType: "input",
        readOnly: readOnly,
      },
      {
        dataField: "vat",
        text: "VAT " + currencySymbol,
        formType: "input",
        readOnly: readOnly,
      },
      { dataField: "total_amount", text: "AMOUNT " + currencySymbol },
      {
        dataField: "process",
        text: "",
        process: "delete",
        onClick: this.handleDeleteOnClick,
      },
    ];

    return (
      <>
        {this.state.loading && (
          <Loader
            className="loader"
            type="Oval"
            color="#db3d44"
            height={100}
            width={100}
          />
        )}
        <Modal
          show={this.props.showUpdateModal}
          onHide={this.props.onClose}
          backdrop="static"
          keyboard={false}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h6>
                Purchase Order # {this.state.purchaseId} -{" "}
                {this.props.rowSelected.company}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form.Group as={Row} key={"purchase-invoice-no"}>
                <Form.Label column sm="2">
                  Invoice No.:
                </Form.Label>
                <Col sm="4" className="float-left">
                  <Form.Control
                    type="input"
                    name="invoice_no"
                    onKeyUp={(e) => this.handleOnKeyUp(e)}
                    defaultValue={this.state.invoiceNo || ""}
                  />
                </Col>
                <Form.Label column sm="2">
                  Delivery Date:
                </Form.Label>
                <Col sm="3">
                  <ReactDatePicker
                    dateFormat={"dd/MMM/yyyy"}
                    selected={this.state.deliveryDate}
                    onChange={(date) => this.onDateChange(date)}
                    placeholderText="Choose date to deliver/collect"
                    closeOnScroll={true}
                    shouldCloseOnSelect={true}
                  />
                </Col>
              </Form.Group>
            </Container>
            <TableComponent
              columns={columns}
              data={computedDetails}
              onKeyUp={this.handleOnKeyUp}
              extra={extraTr}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.handleUpdateOnClick(e)}
            >
              Update
            </Button>
            {readOnly == false && (
              <Button
                variant="primary"
                onClick={(e) => this.handleUpdateOnClick(e, true, true)}
              >
                Update and Purchase
              </Button>
            )}
            <Button
              variant="primary"
              onClick={(e) => this.handleCloneOnClick(e)}
            >
              Clone
            </Button>
            {readOnly == false && (
              <Button
                variant="primary"
                onClick={(e) => this.handleUpdateOnClick(e, false, true)}
              >
                Email Supplier
              </Button>
            )}
            {readOnly == false && (
              <Button
                variant="primary"
                onClick={(e) => this.handleUpdateOnClick(e, false, false, true)}
              >
                Mark as Completed
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapState(state) {
  const { process } = state;
  const { items, po_form, created, loading, product_list_select } = process;
  return { state, loading, items, po_form, created, product_list_select };
}

const actionCreators = {
  savePurchaseOrder: processActions.savePurchaseOrder,
  updatePurchaseOrder: processActions.updatePurchaseOrder,
};

export default connect(mapState, actionCreators)(PurchaseModalUpdate);
