import React from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import SingleSelect from "../../../../components/Form/SingleSelect";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import LinkTooltip from "../../../../components/LinkTooltip";
import { placeActions } from "../../../../_actions/place.actions";
import { sessionActions } from "../../../../_actions/session.actions";
import { processActions } from "../../../../_actions/process.actions";
import styled, { css } from "styled-components";
import arrayMove from "array-move";
import {
  color,
  computeCustomerPriceByPercentage,
  computeDiscountPercentageByCustomerPrice,
  computeSubtotal,
  getFloatValue,
  getStringValue,
  isEmpty,
} from "../../../../_helpers/placeOrder";
import {
  getOrderDetails,
  getPaymentInStorage,
  getPaymentInStorageKey,
  getProducts,
  getStocks,
  removeOrderChecklistInStorage,
  saveOrderChecklistInStorage,
  savePaymentInStorage,
  setRoundTotalPayment,
} from "../../../../_helpers/session";
import {
  orderChecklistSession,
  removeChecklistSession,
} from "../../../../_helpers/sessionFormatter";

const currencySymbol = "£";

const getValue = (item) => {
  return item.value || 0;
};

const total = (prev, next) => {
  return (parseFloat(prev) + parseFloat(next)).toFixed(2);
};

const computeCurrentStocks = (currentStocks, qtyCash, qtyInvoice) => {
  return (
    Number(currentStocks || 0) -
    (Number(qtyCash || 0) + Number(qtyInvoice || 0))
  );
};

const getValues = (orderDetails, column) => {
  let data = [];
  if (orderDetails) {
    orderDetails.forEach((order) => {
      data.push({
        id: order.checklist_id,
        value: order[column],
      });
    });
  }

  return data;
};

const removeDetailsSessionOnLoad = (orderDetails, roundNo, customerId) => {
  orderDetails.forEach((details) => {
    const records = removeChecklistSession(
      details.id,
      customerId,
      roundNo,
      details.checklist_id
    );
  });
};

class Checklist extends React.Component {
  constructor(props) {
    super(props);

    removeDetailsSessionOnLoad(
      this.props.orderDetails,
      this.props.roundNo,
      this.props.customerId
    );

    this.state = {
      activeRow: null,
      customerId: this.props.customerId,
      roundNo: this.props.roundNo,
      orderId: this.props.orderId,
      orders: this.props.orders || [],
      orderDetails: this.props.orderDetails || [],
      readOnly: this.props.readOnly,
      loading: true,
      orderAmount: this.props.orders.cash_amount || 0,
      invoiceOrderAmount: this.props.orders.invoice_amount || 0,
      totalAmount:
        parseFloat(this.props.orders.invoice_amount || 0) +
        parseFloat(this.props.orders.old_balance || 0),
      status: getValues(this.props.orderDetails, "status") || [],
      discountPercentage:
        getValues(this.props.orderDetails, "discount_percentage") || [],
      customerPrice:
        getValues(this.props.orderDetails, "discount_amount") || [],
      qtyInvoice: getValues(this.props.orderDetails, "qty_invoice") || [],
      invoiceAmount: getValues(this.props.orderDetails, "invoice_amount") || [],
      qtyCash: getValues(this.props.orderDetails, "qty_cash") || [],
      cashAmount: getValues(this.props.orderDetails, "cash_amount") || [],
      stocks: [],
      supplier: getValues(this.props.orderDetails, "product_supplier_id") || [],
      productSuppliers: [],
      updatedValue: false,
      addRow: {},
      productStocks: [],
      productOptions: getProducts() || [],
      newRows: [],
      productIds: [],
      hasUpdatedStocks: false,
      hideColumn: false,
      resetValue: false,
    };

    this.props.getSupplierStocks();
    this.props.getProductsSearch();
    document.addEventListener("keydown", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    // if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
    //   const rowCounter = event.target.dataset.counter;
    //   const rowArr = rowCounter.split("-");
    //   const nextRowCounter = rowArr[0] + "-" + (parseInt(rowArr[1]) + 10);

    //   const element = document.querySelector(
    //     "[data-counter='" + nextRowCounter + "']"
    //   );

    //   if (element) {
    //     element.focus();
    //   }
    // }
    if (![37,38,39,40].includes(event.keyCode)) {
      return;
    }

    let allElements = document.querySelectorAll('.navigable-by-keys-checklist');
    if (allElements.length == 0) {
      return;
    }

    console.log('keyDownHandler', event.keyCode);

    let currentKey = null;
    let nextKey = null;
    for (let elKey = 0; elKey < allElements.length; elKey++) {
      if (event.target == allElements[elKey]) {
        currentKey = elKey;
      }
    }

    switch (event.keyCode) {
      case 37: // left
        nextKey = currentKey - 1;
      break;
      case 38: // up
        nextKey = currentKey - 2;
      break;
      case 39: // right
        nextKey = currentKey + 1;
      break;
      case 40: // down
        nextKey = currentKey + 2;

      break;
    }
    if (allElements[nextKey]) {
      console.log({
        'keyCode': event.keyCode,
        'currentKey': currentKey,
        'nextKey': nextKey,
        'target': event.target,
        'allElements[nextKey]': allElements[nextKey],
        'tagName': document.activeElement.tagName,
    });
      allElements[nextKey].focus();
      allElements[nextKey].focus();
    } else {
      console.log("OOB, ignoring", nextKey, allElements[nextKey]);
    }

  };
  shouldComponentUpdate(nextProps, nextState) {
    if ((document.activeElement.tagName).toLowerCase() == 'input') {
      return false;
    }
    console.log('shouldComponentUpdate', document.activeElement.tagName);
    return true;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.session &&
      this.props.session !== prevProps.session &&
      this.props.session.type &&
      this.props.session.type == "stocks"
    ) {
      this.setState({
        productStocks: this.props.session.productStocks,
        hasUpdatedStocks: true,
      });
    }

    if (prevState.updatedValue != this.state.updatedValue) {
      this.setState({ updatedValue: false });
    }

    if (
      this.props.place &&
      prevProps.place !== this.props.place &&
      this.props.place.orderDetailsChecklist
    ) {
      const orderDetails =
        this.props.place.orderDetails || getOrderDetails(this.state.orderId);
      this.setState({
        orderDetails: orderDetails,
      });
    }
    //supplier: [...this.state.supplier, { id: newRow.id, value: newRow.productSupplierId}]
  }

  handleInputChange = (e, row) => {
    const id = parseInt(e.target.dataset.id);
    const column = e.target.dataset.column;
    const value = e.target.value;
    const stateValue = { id: id, value: value };
    const states = [
      "discountPercentage",
      "customerPrice",
      "qtyInvoice",
      "invoiceAmount",
      "qtyCash",
      "cashAmount",
      "stocks",
    ];

    if (row[e.target.name] == value) {
      return;
    }

    saveOrderChecklistInStorage(
      this.state.customerId,
      this.state.roundNo,
      id,
      column,
      value
    );
    this.setState({
      [column]: [
        ...this.state[column].filter((obj) => obj.id != id),
        stateValue,
      ],
    });

    if (column == "discountPercentage" || column == "customerPrice") {
      const stateToUpdate =
        column == "discountPercentage" ? "customerPrice" : "discountPercentage";
      const computedDiscount =
        column == "discountPercentage"
          ? computeCustomerPriceByPercentage(row.price, value)
          : computeDiscountPercentageByCustomerPrice(row.price, value);
      const computedDiscountState = { id: id, value: computedDiscount };

      if (this.state[stateToUpdate]) {
        this.setState(() => ({
          [stateToUpdate]: [
            ...this.state[stateToUpdate].filter((obj) => obj.id != id),
            computedDiscountState,
          ],
        }));
      } else {
        this.setState({ [stateToUpdate]: computedDiscountState });
      }

      const savedInvoiceQty = this.state.qtyInvoice.find((obj) => obj.id == id);
      const computedInvoiceAmount =
        Number(
          computeSubtotal(
            (column == "discountPercentage" ? computedDiscount : value) ||
              row.price,
            savedInvoiceQty && savedInvoiceQty.value ? savedInvoiceQty.value : 0
          )
        ) || "";
      const computedInvoiceAmountState = {
        id: id,
        value: computedInvoiceAmount,
      };

      const savedCashQty = this.state.qtyCash.find((obj) => obj.id == id);
      const computedCashAmount =
        Number(
          computeSubtotal(
            (column == "discountPercentage" ? computedDiscount : value) ||
              row.price,
            savedCashQty && savedCashQty.value ? savedCashQty.value : 0
          )
        ) || "";
      const computedCashAmountState = { id: id, value: computedCashAmount };

      if (this.state.invoiceAmount) {
        this.setState(() => ({
          invoiceAmount: [
            ...this.state.invoiceAmount.filter((obj) => obj.id != id),
            computedInvoiceAmountState,
          ],
        }));
      } else {
        this.setState({ invoiceAmount: computedInvoiceAmountState });
      }

      if (this.state.cashAmount) {
        this.setState(() => ({
          cashAmount: [
            ...this.state.cashAmount.filter((obj) => obj.id != id),
            computedCashAmountState,
          ],
        }));
      } else {
        this.setState({ cashAmount: computedCashAmountState });
      }

      saveOrderChecklistInStorage(
        this.state.customerId,
        this.state.roundNo,
        id,
        stateToUpdate,
        computedDiscount
      );
      saveOrderChecklistInStorage(
        this.state.customerId,
        this.state.roundNo,
        id,
        "invoiceAmount",
        computedInvoiceAmount
      );
      saveOrderChecklistInStorage(
        this.state.customerId,
        this.state.roundNo,
        id,
        "cashAmount",
        computedCashAmount
      );

      document.querySelector("#discountPercentage" + id).value =
        column == "discountPercentage" ? value : computedDiscount;
      document.querySelector("#customerPrice" + id).value =
        column == "customerPrice" ? value : computedDiscount;
    } else if (column == "qtyInvoice" || column == "qtyCash") {
      const stateToUpdate =
        column == "qtyInvoice" ? "invoiceAmount" : "cashAmount";

      const currStock =
        column == "qtyCash"
          ? this.state.qtyInvoice.find((obj) => obj.id == id)
          : this.state.qtyCash.find((obj) => obj.id == id);
      const newStock =
        parseFloat(row.no_of_stocks) -
        (((currStock && currStock.value && parseFloat(currStock.value)) || 0) +
          (parseFloat(value || 0) || 0));
      const newStockState = { id: id, value: newStock };

      const discountedPrice = this.state.customerPrice.find(
        (obj) => obj.id == id
      );
      const price =
        discountedPrice && discountedPrice.value
          ? discountedPrice.value
          : row.customer_price || row.price;
      const computedAmount = computeSubtotal(price, value);
      const computedAmountState = { id: id, value: computedAmount };

      this.setState(
        {
          stocks: [
            ...this.state.stocks.filter((obj) => obj.id != id),
            newStockState,
          ],
          [stateToUpdate]: [
            ...this.state[stateToUpdate].filter((obj) => obj.id != id),
            computedAmountState,
          ],
        },
        () => {
        }
      );

      saveOrderChecklistInStorage(
        this.state.customerId,
        this.state.roundNo,
        id,
        stateToUpdate,
        computedAmount
      );
      saveOrderChecklistInStorage(
        this.state.customerId,
        this.state.roundNo,
        id,
        "stocks",
        newStock
      );
    } else if (column == "status" && value == "Remove") {
      states.forEach((state) => {
        this.setState({
          [state]: [...this.state[state].filter((obj) => obj.id != id)],
        });
        removeOrderChecklistInStorage(
          this.state.customerId,
          this.state.roundNo,
          id,
          state
        );
      });
    }

    this.setState({ updatedValue: true }, () => {
      const { orderAmount, invoiceAmount, cashAmount } = this.state;
      const totalInvoiceAmount =
        (invoiceAmount &&
          invoiceAmount.length > 0 &&
          invoiceAmount.map(getValue).reduce(total)) ||
        0;
      const totalCashAmount =
        (cashAmount &&
          cashAmount.length > 0 &&
          cashAmount.map(getValue).reduce(total)) ||
        0;
      const overAllAmount =
        parseFloat(totalInvoiceAmount) +
        parseFloat(this.state.orders.old_balance);

      this.setState({
        orderAmount: totalCashAmount,
        totalAmount: overAllAmount,
        invoiceOrderAmount: totalInvoiceAmount,
      });

      setRoundTotalPayment(
        this.state.roundNo,
        this.state.customerId,
        "orderAmount",
        totalCashAmount
      );
      // setRoundTotalPayment(this.state.roundNo, this.state.customerId, 'balanceAmount', this.state.orders.old_balance);
      setRoundTotalPayment(
        this.state.roundNo,
        this.state.customerId,
        "totalAmount",
        overAllAmount
      );
      setRoundTotalPayment(
        this.state.roundNo,
        this.state.customerId,
        "invoiceOrderAmount",
        totalInvoiceAmount
      );

      if (totalInvoiceAmount > 0 || totalCashAmount > 0) {
        let orderStatus = getPaymentInStorage(
          this.state.roundNo,
          this.state.customerId,
          "status"
        );
        orderStatus = orderStatus ? orderStatus.toLowerCase() : "";

        if (!["order placed", "no order required"].includes(orderStatus)) {
          savePaymentInStorage(
            this.state.roundNo,
            this.state.customerId,
            "status",
            "Order Placed"
          );

          const key = getPaymentInStorageKey(
            this.state.roundNo,
            this.state.customerId,
            "status"
          );
          this.props.updateOrders(
            this.props.orderId,
            "status",
            key,
            "Order Placed",
            this.state.roundNo
          );
        }
      }
    });
  };

  handleOnBlur = (e, row) => {
    e.preventDefault();

    if (isEmpty(row[e.target.name]) && isEmpty(e.target.value)) {
      return;
    } else if (row[e.target.name] == e.target.value) {
      return;
    }

    const { orderId, roundNo, customerId } = this.state;
    const id = parseInt(e.target.dataset.id);

    const value = document.querySelector("#productSuppliers" + id)
      ? document.querySelector("#productSuppliers" + id).value
      : null;
    const stateValue = { id: id, value: value };

    // this.setState({
    //   supplier: [
    //     ...this.state.supplier.filter((obj) => obj.id !== id),
    //     stateValue,
    //   ],
    // });

    saveOrderChecklistInStorage(
      customerId,
      roundNo,
      id,
      "productSupplierId",
      value
    );

    this.props.updateOrderDetails(row.id, customerId, roundNo, id, orderId);
  };

  handleProductSupplierChange = (e, row) => {
    const id = parseInt(e.target.dataset.id);
    const column = e.target.dataset.column;
    const value = e.target.value;
    const stateValue = { id: id, value: value };
    this.setState({
      supplier: [
        ...this.state.supplier.filter((obj) => obj.id !== id),
        stateValue,
      ],
    });
    saveOrderChecklistInStorage(
      this.state.customerId,
      this.state.roundNo,
      e.target.dataset.id,
      e.target.dataset.column,
      e.target.value
    );
  };

  handleAddProductOptionChange = (value) => {
    let newProduct = this.state.productOptions.find(
      (obj) => obj.id == value.value
    );

    const suppliers = getStocks(newProduct.id) || [];
    const hasSupplier =
      suppliers &&
      suppliers.length > 0 &&
      suppliers.filter((obj) => obj.supplier_id == true).length > 0
        ? true
        : false;

    if (hasSupplier) {
      newProduct.productSupplierId = suppliers[0].supplier_id;
    }

    newProduct.status = "Active";
    return;
    this.setState({ addRow: newProduct, resetValue: false });
  };

  handleAddRowInputChange = (e) => {
    let addRow = this.state.addRow;
    const column = e.target.dataset.column;
    const value = e.target.value;
    addRow[column] = value;

    if (column == "discountPercentage") {
      addRow.customerPrice = computeCustomerPriceByPercentage(
        addRow.price,
        value
      );
    } else if (column == "customerPrice") {
      addRow.discountPercentage = computeDiscountPercentageByCustomerPrice(
        addRow.price,
        value
      );
    } else if (column == "qtyInvoice") {
      addRow.invoiceAmount = Number(
        parseFloat(addRow.customerPrice || addRow.price) * parseInt(value)
      ).toFixed(2);
    } else if (column == "qtyCash") {
      addRow.cashAmount = Number(
        parseFloat(addRow.customerPrice || addRow.price) * parseInt(value)
      ).toFixed(2);
    }

    this.setState({ addRow: addRow });
  };

  handleAddProductOnClick = (e) => {
    console.log(e);
    e.preventDefault();
    const newRow = this.state.addRow;
    newRow.cash_amount = newRow.cashAmount;
    newRow.invoice_amount = newRow.invoiceAmount;
    newRow.discount_percentage = newRow.discountPercentage;
    newRow.discount_amount = newRow.customerPrice;
    newRow.qty_invoice = newRow.qtyInvoice;
    newRow.qty_cash = newRow.qtyCash;
    newRow.product_supplier_id = newRow.productSupplierId;

    this.setState({ resetValue: true });
    if (newRow.cashAmount) {
      this.setState({
        cashAmount: [
          ...this.state.cashAmount,
          { id: newRow.id, value: newRow.cashAmount },
        ],
      });
    }

    if (newRow.invoiceAmount) {
      this.setState({
        invoiceAmount: [
          ...this.state.invoiceAmount,
          { id: newRow.id, value: newRow.invoiceAmount },
        ],
      });
    }

    let orderDetails = this.state.orderDetails;
    this.setState({
      newRows: [...this.state.newRows, newRow],
      productOptions: [
        ...this.state.productOptions.filter((obj) => obj.id !== newRow.id),
      ],
      addRow: {},
      orderDetails: orderDetails ? [...orderDetails, newRow] : newRow,
      checklist:
        this.state.checklist && this.state.checklist.length > 0
          ? [...this.state.checklist, newRow]
          : newRow,
    });

    const inputToResetValue = [
      "add_discount_amount",
      "add_discount_percentage",
      "add_qty_invoice",
      "add_invoice_amount",
      "add_qty_cash",
      "add_cash_amount",
    ];
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      if (inputToResetValue.includes(input.name)) {
        return (input.value = "");
      }
    });

    Array.from(document.querySelectorAll("select")).forEach((select) => {
      if (select.name == "productCodes") {
        return (select.value = "");
      }
    });

    this.props.addOrderDetails(
      this.state.orderId,
      this.state.customerId,
      newRow
    );
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    let orderDetails = this.state.orderDetails;
    console.log("Orders --->", orderDetails);
    console.log("oldIndex --->", oldIndex);
    console.log("New Index --->", newIndex);
    this.setState(({ orderDetails }) => ({
      orderDetails: arrayMove(orderDetails, oldIndex, newIndex),
    }));
  };

  handleOnRowClick = (e, row) => {
    if (e.target.dataset.event === "postits") {
      let readOnly = false;
      if (this.state.hasPaperWorks) readOnly = true;
      if (!!row.cash_invoice_no || !!row.invoice_no) readOnly = true;

      this.props.onRowClick("postits", {
        orderId: row.id,
        orders: row,
        orderDetails: this.state.orderDetails[row.id] || [],
        readOnly: readOnly,
        customerId: row.customer_id,
        roundNo: this.state.roundNo,
        onUpdate: this.onUpdate,
        state: this.state,
      });
    }
  };

  render() {
    const { orderDetails, updatedValue, productOptions, addRow, hideColumn } =
      this.state;
    const addSuppliers = addRow.id ? getStocks(addRow.id) : [];
    const addReadOnly = addSuppliers && addSuppliers.length > 0 ? false : true;
    console.log("Props --->", this.props);
    let counter = 0;
    let rowCounter = 0;
    const Handle = styled.div`
      flex: none;
      width: 7.5px;
      height: 100%;

      &::before {
        content: "...";
        border-left: 4px dotted #ccc;
        display: block;
        height: 15px;
        margin: 15px 3px;
      }

      &:hover::before {
        border-color: #696969;
      }
    `;
    
    const SortableList = SortableContainer(({ items }) => {
      items = items.filter((value, index) => {
        const product = this.state.productOptions.filter(obj => {
          return obj.id === value.product_id
        });
        return product.length > 0;        
      });
      return (
        <tbody>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              record={value}
              onClick={(e) => this.handleClick(e, value)}
            />
          ))}
        </tbody>
      );
    });

    const SortableHandleColumn = SortableHandle((children) => <Handle />);
    let totalCash = 0,
      totalCheque = 0,
      totalInvoice = 0;
    console.log("This State --->", this.state);
    const SortableItem = SortableElement(({ record }) => {
      // const status = getStringValue(
      //   this.state.status.find((obj) => obj.id == record.customer_id),
      //   record.status
      // );




      //console.log('prodictID', product);

      const discountPercentage = getFloatValue(
        this.state.discountPercentage.find(
          (obj) => obj.id == record.checklist_id
        ),
        record.discount_percentage
      );
      const customerPrice = getFloatValue(
        this.state.customerPrice.find((obj) => obj.id == record.checklist_id),
        record.discount_amount
      );
      const qtyInvoice = getStringValue(
        this.state.qtyInvoice.find((obj) => obj.id == record.checklist_id),
        record.qty_invoice
      );
      const invoiceAmount = getFloatValue(
        this.state.invoiceAmount.find((obj) => obj.id == record.checklist_id),
        record.invoice_amount || "0"
      );
      const qtyCash = getStringValue(
        this.state.qtyCash.find((obj) => obj.id == record.checklist_id),
        record.qty_cash
      );
      const cashAmount = getFloatValue(
        this.state.cashAmount.find((obj) => obj.id == record.checklist_id),
        record.cash_amount || "0"
      );
      const productStocks = getStocks(record.product_id) || [];
      const fieldReadOnly =
        productStocks && productStocks.length > 0 ? this.state.readOnly : true;
      const stocks = getStringValue(
        this.state.stocks.find((obj) => obj.id == record.checklist_id),
        computeCurrentStocks(record.no_of_stocks, qtyCash, qtyInvoice)
      );
      const status = getStringValue(
        this.state.status.find((obj) => obj.id == record.checklist_id),
        record.status
      );

      rowCounter += 10;

      return (
        <tr
          key={record.id}
          onClick={(e) => {
            console.log("Row Click ---->", record.id);
            // this.setState({
            //   activeRow: record.id,
            // });
            this.handleOnRowClick(e, record);
          }}
          className={`${
            this.state.activeRow == record.id ? "active_tableRow" : null
          }`}
        >
          <td>
            <SortableHandleColumn />
          </td>
          <td>{record.code}</td>
          <td>{record.description}</td>
          <td>{record.price}</td>

          {!hideColumn ? (
            <td data-id={record.id} data-event="postits">
              <Form.Group
                key={"discount_percentage-" + record.checklist_id}
                className="table-form-group"
              >
                <Form.Control
                  type="input"
                  id={"discountPercentage" + record.checklist_id}
                  name="discount_percentage"
                  data-id={record.checklist_id}
                  data-column="discountPercentage"
                  data-counter={"input-" + (parseInt(rowCounter) + 1)}
                  onKeyUp={(e) => this.handleInputChange(e, record)}
                  onBlur={(e) => this.handleOnBlur(e, record)}
                  defaultValue={discountPercentage}
                  {...(updatedValue &&
                  discountPercentage &&
                  discountPercentage.value
                    ? { value: discountPercentage.value }
                    : "")}
                  readOnly={fieldReadOnly}
                  {...(!fieldReadOnly
                    ? { tabIndex: parseInt(counter) + 1 }
                    : "")}
                />
              </Form.Group>
            </td>
          ) : null}
          {!hideColumn ? (
            <td>
              <Form.Group
                key={"customer-price-" + record.id}
                className="table-form-group"
              >
                <Form.Control
                  type="input"
                  id={"customerPrice" + record.checklist_id}
                  name="discount_amount"
                  data-id={record.checklist_id}
                  data-column="customerPrice"
                  data-counter={"input-" + (parseInt(rowCounter) + 2)}
                  onKeyUp={(e) => this.handleInputChange(e, record)}
                  onBlur={(e) => this.handleOnBlur(e, record)}
                  defaultValue={customerPrice}
                  {...(updatedValue && customerPrice && customerPrice.value
                    ? { value: customerPrice.value }
                    : "")}
                  readOnly={fieldReadOnly}
                  {...(!fieldReadOnly
                    ? { tabIndex: parseInt(counter) + 1 }
                    : "")}
                />
              </Form.Group>
            </td>
          ) : null}
          {!hideColumn ? (
            <td>{record.company ? record.company : <i>Not set</i>}</td>
          ) : null}
          {stocks < 0 ? (
            <td style={{ color: "red" }}>{stocks}</td>
          ) : (
            <td>{stocks}</td>
          )}
          <td>
            <Form.Group
              key={"qty-invoice-" + record.id}
              className="table-form-group"
            >
              <Form.Control
                type="input"
                name="qty_invoice"
                data-id={record.checklist_id}
                autocomplete='off'
                className="navigable-by-keys-checklist"

                data-column="qtyInvoice"
                data-counter={"input-" + (parseInt(rowCounter) + 3)}
                onKeyUp={(e) => this.handleInputChange(e, record)}
                onBlur={(e) => this.handleOnBlur(e, record)}
                defaultValue={qtyInvoice}
                readOnly={fieldReadOnly}
                {...(!fieldReadOnly ? { tabIndex: parseInt(counter) + 1 } : "")}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group
              key={"new-balance-" + record.id}
              className="table-form-group"
            >
              <Form.Label>{currencySymbol + " " + invoiceAmount}</Form.Label>
            </Form.Group>
          </td>
          <td>
            <Form.Group
              key={"qty-cash-" + record.id}
              className="table-form-group"
            >
              <Form.Control
                type="input"
                name="qty_cash"
                autocomplete='off'
                data-id={record.checklist_id}
                data-column="qtyCash"
                className="navigable-by-keys-checklist"

                data-counter={"input-" + (parseInt(rowCounter) + 4)}
                onKeyUp={(e) => this.handleInputChange(e, record)}
                onBlur={(e) => this.handleOnBlur(e, record)}
                defaultValue={qtyCash}
                readOnly={fieldReadOnly}
                {...(!fieldReadOnly ? { tabIndex: parseInt(counter) + 1 } : "")}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group
              key={"new-balance-" + record.id}
              className="table-form-group"
            >
              <Form.Label>{currencySymbol + " " + cashAmount}</Form.Label>
            </Form.Group>
          </td>
          <td>
            <Form.Group
              key={"status-" + record.id}
              className="table-form-group"
            >
              <Form.Control
                as="select"
                name="status"
                data-id={record.checklist_id}
                data-column="status"
                onChange={(e) => this.handleInputChange(e, record)}
                onBlur={(e) => this.handleOnBlur(e, record)}
                defaultValue={status}
                readOnly={this.state.readOnly}
                {...(!fieldReadOnly ? { tabIndex: parseInt(counter) + 1 } : "")}
              >
                <option value="Active">Active</option>
                <option value="Warning">Warning</option>
                <option value="Remove">Remove</option>
              </Form.Control>
            </Form.Group>
          </td>
          <td></td>
        </tr>
      );
    });
    console.log("Order Details -->", orderDetails);
    return (
      <>
        <Container className="container-tab">
          <Row>
            <Col>
              <b>ACCOUNT:</b>
            </Col>
            <Col>Order:</Col>
            <Col>
              <b>
                {currencySymbol +
                  " " +
                  parseFloat(this.state.invoiceOrderAmount).toFixed(2)}
              </b>
            </Col>
            <Col>Balance:</Col>
            <Col>
              <b>
                {currencySymbol +
                  " " +
                  parseFloat(this.state.orders.old_balance).toFixed(2)}
              </b>
            </Col>
            <Col>Total:</Col>
            <Col>
              <b>
                {currencySymbol +
                  " " +
                  parseFloat(this.state.totalAmount).toFixed(2)}
              </b>
            </Col>
            <Col></Col>
            <Col> | </Col>
            <Col></Col>
            <Col>
              <b>CASH:</b>
            </Col>
            <Col>Order:</Col>
            <Col>
              <b>
                {currencySymbol +
                  " " +
                  parseFloat(this.state.orderAmount).toFixed(2)}
              </b>
            </Col>
            <Col sm="2">
              <span
                style={{
                  textAlign: "right",
                  color: "#007bff",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onClick={(e) => {
                  this.setState({ hideColumn: !this.state.hideColumn });
                }}
              >
                <u>{this.state.hideColumn ? "Show Columns" : "Hide Columns"}</u>
              </span>
            </Col>
          </Row>
          <Row>
            <Table size="sm" bordered>
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th></th>
                  <th>PRODUCT CODE</th>
                  <th>DESCRIPTION</th>
                  {!hideColumn ? <th>REG. PRICE</th> : null}
                  {!hideColumn ? <th>DISCOUNT (%)</th> : null}
                  {!hideColumn ? <th>CUSTOMER PRICE</th> : null}
                  {!hideColumn ? <th>SUPPLIER</th> : null}
                  <th>OVERALL STOCKS</th>
                  <th>QTY. INVOICE</th>
                  <th>INVOICE AMOUNT</th>
                  <th>QTY. CASH</th>
                  <th>CASH AMOUNT</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              {orderDetails && orderDetails.length > 0 && (
                <SortableList
                  items={orderDetails}
                  onSortEnd={this.onSortEnd}
                  shouldCancelStart={this.handleClick}
                  useDragHandle
                />
              )}
            </Table>
          </Row>
        </Container>
      </>
    );
  }
}

function mapState(state) {
  const { place, session, process } = state;
  const { product_list_select } = process;
  return { place, session, product_list_select };
}

const actionCreators = {
  getProductStocks: sessionActions.getProductStocks,
  updateOrders: sessionActions.updateOrders,
  updateOrderDetails: sessionActions.updateOrderRecords,
  addOrderDetails: placeActions.addOrderDetailsAndChecklist,
  getSupplierStocks: sessionActions.getSupplierStocks,
  getProductsSearch: processActions.getProductsForSearch,
};

export default connect(mapState, actionCreators)(Checklist);
