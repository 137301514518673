import React from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TableComponent from '../../../components/Table/TableComponent';
import TableHeader from '../../../components/Table/TableHeader';
import { processActions } from '../../../_actions/process.actions';
import { processConstants } from '../../../_constants/process.constants';
import { formatDateForTable } from '../../../_helpers/calendar';
import { currencySymbol } from '../../../_helpers/content';
import PurchaseModal from './PurchaseModal';
import PurchaseModalUpdate from './PurchaseModalUpdate';

const typeName = (type) => {
  if (type == 1) {
    return 'Purchased';  
  } else if (type == 3) {
    return 'Completed';
  }
}

const { SearchBar } = Search;

class PurchaseOrder extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showUpdateModal: false,
      suppliers: [],
      products: [],
      productSupplierMapping: [],
      data: [],
      markAsCompleted: [],
      rowSelected: {},
      details: [],
      invoices: [],
      productList: []
    }

    this.props.getPurchaseOrders();
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.po_form && this.props.items && this.props.items !== prevProps.items) {
      this.setState({
        suppliers: this.props.items.suppliers,
        products: this.props.items.products,
        productSupplierMapping: this.props.items.product_supplier_mapping,
        showModal: true
      })
    }

    if (this.props.po_content && this.props.po_content !== prevProps.po_content) {
      let data = this.props.items;
      data.forEach((item, key) => {
        data[key]['date_created'] = formatDateForTable(new Date(item.date_created.replace(' ', 'T')));
        data[key]['date_ordered'] = item.date_ordered ? formatDateForTable(new Date(item.date_ordered.replace(' ', 'T'))) : '';
        data[key]['date_to_deliver_formatted'] = item.date_to_deliver ? formatDateForTable(new Date(item.date_to_deliver.replace(' ', 'T'))) : '';
        data[key]['on_order'] = item.on_order == '1' ? 'ON-ORDER' : '';
        data[key]['delivered'] = item.delivered == '1' || item.invoice_no ? 'COMPLETE' : '';
        data[key]['emailed'] = item.emailed == '1' ? 'YES' : '';
        data[key]['invoice_flag'] = item.invoice_no ? true : false;
      });
      this.setState({ data: data, invoices: [] });
    }

    if (this.props.created && this.props.created !== prevProps.created) {
      this.setState({ showUpdateModal: false });

      const toastrOptions = {
        icon: 'info', 
        status: 'info'
      }
      toastr.light('', 'Orders successfully created.', toastrOptions);
      this.props.getPurchaseOrders();
    }

    if (this.props.po_details && this.props.items && this.props.items !== prevProps.items) {
      this.setState({ details: this.props.items }, () => {
        if (this.state.productList.length > 0) {
          this.setState({ showUpdateModal: true });
        }
      });
    }
    if (this.props.product_list_select && this.props.product_list_select !== prevProps.product_list_select) {
      this.setState({ productList: this.props.product_list_select }, () => {
        if (this.state.details.length > 0) {
          this.setState({ showUpdateModal: true });
        }
      });
    }
  }

  handleButtonOnClick = (e) => {
    this.props.getPurchaseModalRecord();
  }

  handleMarkCompletedOnChecked = (e, row) => {
    if (e.target.checked) {
      this.setState({ markAsCompleted: [...this.state.markAsCompleted, e.target.id] });
    } else {
      this.setState({ markAsCompleted: [...this.state.markAsCompleted.filter((obj) => obj.id != e.target.id)] })
    }
  }

  handleSaveChangesOnClick = (e) => {
    let invoices = [];

    this.state.invoices.forEach((invoice) => {
      if (invoice.invoice_no) {
        invoice.delivered = '1';
        invoice.on_order = '0';
        invoices.push(invoice);
      }
    })

    this.props.updatePurchaseOrders(invoices);
  }

  onRowClick = (e, row) => {
    if (e.target.type != 'text' && e.target.type != 'checkbox') {
      e.preventDefault();
      this.setState({ rowSelected: row });
      this.props.getProductsForSearch(row.supplier_id, true);
      this.props.getPurchaseOrderDetails(row.id);
    }
  }

  onCheckChange = (e, row) => {
    if (e.target.checked) {
      this.setState({ invoices: [...this.state.invoices, { id: row.id }]})
    } else {
      this.setState({ invoices: [...this.state.invoices.filter((obj) => obj.id != row.id)] })
    }    
  }

  onKeyUp = (e, row) => {
    e.preventDefault();
    this.setState({ invoices: [...this.state.invoices.filter((obj) => obj.id != row.id), { id: row.id, invoice_no: e.target.value}] })
  }

  render () {

    const { invoices } = this.state;

    const columns = [
      { dataField: 'id', text: 'ID', sort: true },
      { dataField: 'date_created', text: 'DATE', sort: true, editable: false },
      { dataField: 'company', text: 'SUPPLIER', sort: true, editable: false },
      { dataField: 'total_amount', text: 'AMOUNT '+currencySymbol, sort: true, editable: false },
      { dataField: 'on_order', text: 'ON-ORDER', sort: true, editable: false },
      { dataField: 'date_ordered', text: 'PURCHASE DATE', sort: true, editable: false },
      { dataField: 'date_to_deliver_formatted', text: 'DELIVERY DATE', sort: true, editable: false },
      { dataField: 'delivered', text: 'DELIVERED', sort: true, editable: false },
      { 
        dataField: 'invoice_flag', 
        text: 'INVOICE RECEIVED',
        sort: true,
        editable: true,
        formatter: (cell, row, rowIndex) => {
          if (cell == true) {
            return "Yes"
          }

          const invoiceReceived = this.state.invoices.find((obj) => obj.id == row.id)
          return <div key={ 'invoice-flag-'+row.id } className="center">
            <Form.Check 
              type="checkbox" 
              id="invoice_flag" 
              aria-label="invoice_flag" 
              checked={ invoiceReceived || false }
              defaultChecked={ invoiceReceived ? true : false }   
              onChange={(e) => this.onCheckChange(e, row) }               
            />
          </div>
        },
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
          if (row.invoice_flag == true) {
            return "YES"
          }

          const invoiceReceived = this.state.invoices.find((obj) => obj.id == row.id)
          return <div key={ 'invoice-flag-'+row.id } className="center">
            <Form.Check 
              type="checkbox" 
              id="invoice_flag" 
              aria-label="invoice_flag" 
              defaultChecked={ invoiceReceived || false }   
              onChange={(e) => this.onCheckChange(e, row) } 
            />
            </div>
        }
      },
      { 
        dataField: 'invoice_no', 
        text: 'INVOICE NO.', 
        sort: true,
        editable: (content, row, rowIndex, columnIndex) => {
          if (invoices.find((obj) => obj.id == row.id)) {
            return true;
          }

          return false;
        },
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
          if (invoices.find((obj) => obj.id == row.id)) {
            return <div key={ 'invoice-flag-'+row.id } className="center">
              <Form.Control 
                type="input" 
                name="invoice_no"
                onKeyUp={ (e) => this.onKeyUp(e, row) }
              />
            </div>;
          }

          return row.invoice_no;
        },
        formatter: (cell, row, rowIndex) => {
          if (invoices.find((obj) => obj.id == row.id)) {
            return <div key={ 'invoice-flag-'+row.id } className="center">
              <Form.Control 
                type="input" 
                name="invoice_no"
                onKeyUp={ (e) => this.onKeyUp(e, row) }
              />
            </div>;
          }

          return row.invoice_no;
        }
      },
      { dataField: 'emailed', text: 'EMAILED', sort: true, editable: false },
    ];

    const ShowModal = (props) => {
      const handleCloseModal = () => {
        this.setState({ showModal: false });
      }

      return <PurchaseModal 
        onClose={ handleCloseModal }
        {...(this.state)}
      />;
    }

    const ShowUpdateModal = (props) => {
      const handleCloseModal = () => {
        this.setState({ showUpdateModal: false });
      }

      return <PurchaseModalUpdate 
        onClose={ handleCloseModal }
        {...(this.state)}
      />
    }

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        e.preventDefault();
        if (e.target.nodeName != 'INPUT') 
        this.onRowClick(e, row)
      }
    }

    return <div className="content">
      <h5>Purchase Orders</h5>
      { this.state.data && <ToolkitProvider
          keyField="id"
          data={ this.state.data }
          columns={ columns }
          search
          bootstrap4
        >
          {
            props => (
              <Container>
                <Row>
                    <Col sm="4" className="table-search">
                      <SearchBar { ...props.searchProps } />
                    </Col>
                  <Col sm="8">
                    <Button 
                      variant="primary"
                      className="float-sm-right buttonMain" 
                      data-id={ processConstants.PROCESS_MAKE_A_PURCHASE } 
                      data-method={ processConstants.PROCESS_METHOD_CREATE }
                      onClick={(e) => this.handleButtonOnClick(e)}
                    >
                      Make a Purchase
                    </Button>
                  </Col>
                </Row>
                <BootstrapTable 
                  { ...props.baseProps }
                  striped
                  headerClasses='bootstrap-table-header'
                  classes='bootstrap-table-data'
                  rowEvents={ rowEvents }
                  cellEdit={ cellEditFactory({ 
                    mode: 'click', 
                    blurToSave: true
                  }) }
                  noDataIndication={ () => <i>No records found.</i> }
                />
              </Container>
            )
          }
        </ToolkitProvider> 
      }
      <Button variant="primary" {...(this.state.invoices.length > 0 ? '' : {disabled: true})} onClick={ (e) => this.handleSaveChangesOnClick(e) }>Save Changes</Button>
      { this.state.showModal && <ShowModal /> }
      { this.state.showUpdateModal && <ShowUpdateModal /> }
    </div>;
  }
}

function mapState(state) {
  const { process } = state;
  const { items, po_form, po_content, po_details, created, product_list_select } = process;
  return { items, po_form, po_details, po_content, created, product_list_select };
}

const actionCreators = {
  getPurchaseModalRecord: processActions.getPurchaseModalRecord,
  getPurchaseOrders: processActions.getPurchaseOrders,
  updatePurchaseOrders: processActions.updatePurchaseOrders,
  getPurchaseOrderDetails: processActions.getPurchaseOrderDetails,
  getProductsForSearch: processActions.getProductsForSearch
}

export default connect(mapState, actionCreators)(PurchaseOrder);