import { cartConstants } from '../_constants/cart.constants';

let items =  JSON.parse(localStorage.getItem('cart'));
const initialState = items ? {items: items} : {};

export function cart(state = initialState, action) {
  switch (action.type) {
    case cartConstants.ADD_TO_CART: 
      const cart = action.cart;
      return {
        items: cart
      };
    case cartConstants.CLEAR:
      return {};
    default:
      return state;
  }
}