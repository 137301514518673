import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import MultiSelect from "../../components/Form/MultiSelect";
import SingleSelect from "../../components/Form/SingleSelect";
import { processActions } from "../../_actions/process.actions";
import { processConstants } from "../../_constants/process.constants";
import { currencySymbol } from "../../_helpers/content";

class AddNewProductForm extends React.Component {
  constructor(props) {
    super(props);

    const suppliers = this.props.isModal
      ? this.props.suppliers
      : this.props.process.suppliers
      ? this.props.process.suppliers
      : [];
    const categories = this.props.isModal
      ? this.props.categories
      : this.props.process.categories
      ? this.props.process.categories
      : [];

    this.state = {
      suppliers: suppliers,
      categories: categories,
      data: {},
      resetValue: false,
    };

    if (!this.props.isModal) {
      if (suppliers.length == 0 && categories.length == 0) {
        this.props.getSupplierAndCategoriesOptions();
      } else if (suppliers.length == 0) {
        this.props.getSuppliersForSearch();
      } else if (categories.length == 0) {
        this.props.getProductCategoriesForSearch();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.process && this.props.process != prevProps.process) {
      if (
        this.props.process.suppliers &&
        this.props.process.suppliers != prevProps.process.suppliers
      ) {
        this.setState({
          suppliers: this.props.process.suppliers,
          resetValue: false,
        });
      } else if (
        this.props.process.saveForm &&
        this.props.process.saveForm != prevProps.process.saveForm
      ) {
        this.setState({ data: {}, resetValue: true });
        document.getElementById("addProductForm").reset();
      } else if (this.props.process.categories) {
        this.setState({
          categories: this.props.process.categories,
          resetValue: false,
        });
      }
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ resetValue: false });
    this.props.submitForm(
      processConstants.PROCESS_ADD_PRODUCT,
      this.state.data
    );
  };

  handleChange = (e) => {
    e.preventDefault();

    let state = this.state;
    state["data"][e.target.name] = e.target.value;

    if (this.props.isModal) {
      this.props.onChange(e.target.name, e.target.value);
    }
  };

  handleSelectChange = (value) => {
    let data = this.state.data;
    data.category = value.value;

    if (this.props.isModal) {
      this.props.onChange("category", value.value);
    }
  };

  handleSuppliersSelectChange = (value) => {
    let data = this.state.data;
    data.suppliers = value.value;

    if (this.props.isModal) {
      this.props.onChange("suppliers", value.value);
    }
  };

  render() {
    return (
      <Form id="addProductForm" onSubmit={this.handleFormSubmit}>
        <Form.Group as={Row} key="addProductFormGroupCode">
          <Form.Label column sm="2">
            Product Code<span>*</span>
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="code"
              placeholder="Enter Product Code"
              onKeyUp={this.handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="addProductFormGroupDesc">
          <Form.Label column sm="2">
            Product Description<span>*</span>
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="description"
              placeholder="Enter Product Description"
              onKeyUp={this.handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="addProductFormGroupPrice">
          <Form.Label column sm="2">
            Recommended Sale Price {currencySymbol}
            <span>*</span>
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="price"
              placeholder="Enter Recommended Sale Prices"
              onKeyUp={this.handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="addProductFormGroupSize">
          <Form.Label column sm="2">
            Case/Size Descriptor
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="size_descriptor"
              placeholder="Enter Case/Size Descriptor"
              onKeyUp={this.handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="addProductFormGroupVat">
          <Form.Label column sm="2">
            VAT Percentage
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="vat_percentage"
              placeholder="Enter VAT Percentage"
              onChange={this.handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="addProductFormCategory">
          <Form.Label column sm="2">
            Product Category
          </Form.Label>
          <Col sm="4" className="float-left">
            <SingleSelect
              options={this.state.categories}
              placeholder="Select a Product Category"
              onChange={this.handleSelectChange}
              resetValue={this.state.resetValue}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="addProductFormGroupCompany">
          <Form.Label column sm="2">
            Supplier
          </Form.Label>
          <Col sm="4" className="float-left">
            <SingleSelect
              options={this.state.suppliers}
              placeholder="Select a Supplier"
              onChange={this.handleSuppliersSelectChange}
              resetValue={this.state.resetValue}
            />
          </Col>
        </Form.Group>
        {!this.props.isModal && (
          <Form.Group as={Row}>
            <Col sm="3">
              <Button
                variant="primary"
                type="submit"
                className="float-sm-left"
                onClick={this.handleFormSubmit}
              >
                Add Product
              </Button>
            </Col>
          </Form.Group>
        )}
      </Form>
    );
  }
}

function mapState(state) {
  const { process } = state;
  return { process };
}

const actionCreators = {
  getSupplierAndCategoriesOptions:
    processActions.getSupplierAndCategoriesOptions,
  getSuppliersForSearch: processActions.getSuppliersForSearch,
  getProductCategoriesForSearch: processActions.getProductCategoriesForSearch,
  submitForm: processActions.submitForm,
};

export default connect(mapState, actionCreators)(AddNewProductForm);
