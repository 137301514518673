import React from 'react';
import { Button, Col, Container, FormControl, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import SingleSelect from '../../../../components/Form/SingleSelect';
import { orderActions } from '../../../../_actions/order.actions';
import { currencySymbol } from '../../../../_helpers/content';

const computeDiscountedTotal = (totalAmount, discountAmount) => {
  if (! isNaN(totalAmount) && ! isNaN(discountAmount)) {
    return (parseFloat(totalAmount) - parseFloat(discountAmount));
  } else if (! isNaN(totalAmount) && isNaN(discountAmount)) {
    return parseFloat(totalAmount);
  }

  return 0;
}

class DiscountModal extends React.Component
{
  state = {
    resetValue: false,
    discountType: this.props.data.discount_type || null,
    discountPercentage: this.props.data.discount_percentage || null,
    discountAmount: this.props.data.discount_amount || null,
    newTotalAmount: computeDiscountedTotal(this.props.data.total_amount || 0, this.props.data.discount_amount || 0)
  }

  onChange = (selected) => {
    this.setState({ discountType: selected.value });
  }

  onInputChange = (e) => {
    e.preventDefault();
    
    const totalAmount = this.props.data.total_amount;
    const discount = (parseFloat(totalAmount || 0) * (parseFloat(e.target.value) / 100)).toFixed(2);
    
    if (this.state.discountType == 1) {
      this.setState({ 
        discountPercentage: e.target.value,
        discountAmount: discount,
        newTotalAmount: (parseFloat(totalAmount || 0) - discount).toFixed(2)
      });
    } else if (this.state.discountType == 2) {
      let percentage = 0;

      if (totalAmount == e.target.value) {
        percentage = 100;
      }
    
      const decrease = parseFloat(totalAmount) - parseFloat(e.target.value);
      const percent = decrease / parseFloat(totalAmount) * 100;
      percentage =  Number(percent.toFixed(2)) || '';

      this.setState({ 
        discountPercentage: percentage,
        discountAmount: parseFloat(e.target.value).toFixed(2),
        newTotalAmount: (parseFloat(totalAmount || 0) - parseFloat(e.target.value)).toFixed(2)
      });
    }
  }

  onClose = () => {
    this.setState({ 
      resetValue: true,
      discountType: null,
      discountPercentage: null,
      discountAmount: null,
      newTotalAmount: '-'
    });
    this.props.onClose();
  }

  onSubmit = (e) => {
    e.preventDefault();
    
    this.props.applyDiscounts(this.props.data.id, {
      id: this.props.data.id,
      discount_type: this.state.discountType,
      discount_percentage: this.state.discountPercentage,
      discount_amount: this.state.discountAmount
    })

    this.props.onClose();
  }

  render () {
    const options = [
      { value: 1, label: 'Discount by percentage' },
      { value: 2, label: 'Discount by amount'}
    ]

    const customStyles = {
      control: base => ({
        ...base,
        height: 25,
        minHeight: 25
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '25px',
      }),
    };

    let defaultDiscountType = null;

    if (this.state.discountType == 1) {
      defaultDiscountType = { value: 1, label: 'Discount by percentage' }
    } else if (this.state.discountType == 2) {
      defaultDiscountType = { value: 2, label: 'Discount by amount'}
    }

    return <Modal
      show={ this.props.show }
      onHide={ this.onClose }
      backdrop="static"
      keyboard={ false }
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply Discount for Order #{ this.props.data.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="container-tab">
          <Row className="row-padding">
            <Col sm="4">Order No.:</Col>
            <Col sm="8">{ this.props.data.id }</Col>
          </Row>
          <Row className="row-padding">
            <Col sm="4">Company:</Col>
            <Col sm="8">{ this.props.data.company }</Col>
          </Row>
          <Row className="row-padding">
            <Col sm="4">Total Amount:</Col>
            <Col sm="8">{ currencySymbol + ' ' + parseFloat(this.props.data.total_amount || 0).toFixed(2) }</Col>
          </Row>
          <Row className="row-padding">
            <Col sm="4">Discount Type:</Col>
            <Col sm="8">
              <SingleSelect 
                options={ options }
                placeholder="Select discount type"
                resetValue={ this.state.resetValue }
                defaultValue={ defaultDiscountType }
                onChange={ this.onChange }
                styles={ customStyles }
              />
            </Col>
          </Row>
          <Row className="row-padding">
            <Col sm="4">Discount Percentage:</Col>
            <Col sm="8">
              <FormControl 
                type="text"
                readOnly={ this.state.discountType === 1 ? false : true }
                defaultValue={ parseFloat(this.state.discountPercentage || 0).toFixed(2) }
                value={ this.state.discountPercentage || 0 }
                onChange={ (e) => this.onInputChange(e) }
              />
            </Col>
          </Row>
          <Row className="row-padding">
            <Col sm="4">Discount Amount:</Col>
            <Col sm="8">
              <FormControl 
                type="text"
                readOnly={ this.state.discountType === 2 ? false : true }
                defaultValue={ parseFloat(this.state.discountAmount || 0).toFixed(2) }
                value={ this.state.discountAmount || 0 }
                onChange={ (e) => this.onInputChange(e) }
              />
            </Col>
          </Row>
          <Row className="row-padding">
            <Col sm="4">Total Amount w/ Discount:</Col>
            <Col sm="8">{ currencySymbol + ' ' + this.state.newTotalAmount }</Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={ this.onClose }>Cancel</Button>
      <Button variant="primary" onClick={(e) => this.onSubmit(e) }>Apply</Button>
      </Modal.Footer>
    </Modal>
  }
}

const actionCreators = {
  applyDiscounts: orderActions.applyDiscounts
}

export default connect(null, actionCreators)(DiscountModal);