import React from 'react';
import PrivateRoute from './Route/PrivateRoute';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers/history';
import { alertActions } from './_actions/alert.actions';
import { Alert } from 'react-bootstrap';

import Header from './pages/Header';
import Login from './pages/Login';
import Home from './pages/Home';
import Content from './pages/Content';
import PlaceOrder from './pages/order/place/PlaceOrder';
import Rounds from './pages/round/Rounds';
import Loader from 'react-loader-spinner';
import Driver from './pages/driver/Drivers';

import 'font-awesome/css/font-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/main.css';
import { getIsFormSubmit } from './_helpers/session';
import Customers from './pages/checklist/Customers';
import {toastr} from 'react-redux-toastr'
import {bindActionCreators} from 'redux'
import {actions as toastrActions} from 'react-redux-toastr'
import PurchaseOrder from './pages/product/purchaseOrder/PurchaseOrder';
import SupplierReport from './pages/reports/supplier/SupplierReport';
import SupplierDueinReport from './pages/reports/supplierDuein/SupplierDueinReport';
import AddNewProduct from './pages/product/AddNewProduct';
// import ProductList from './pages/product/list/ProductList';
import InvoiceManagement from './pages/order/invoice/InvoiceManagement';

class App extends React.Component {
  constructor() {
      super();

      history.listen((location, action) => {
          // clear alert on location change
          this.props.clearAlerts();
      });

      this.isBackButtonClicked = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.alert && prevProps.alert !== this.props.alert && this.props.alert.type) {
      const isFormSubmit = getIsFormSubmit(); 
      const showAlert = isFormSubmit && isFormSubmit == 'true' ? false : true;
      let toastrType = this.props.alert.type.replace("alert-", "")||"info";

      if (toastrType == 'danger') {
        toastrType = 'error'
      }

      const toastrOptions = {
        icon: toastrType,
        status: toastrType
      }
      
      if (showAlert && ! this.props.alert.fields && this.props.alert.message != '') {
        toastr.light('', this.props.alert.message, toastrOptions);
      }
    }
  }

  componentDidMount() {
    
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);
  }
  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.isBackButtonClicked) {
      // Do nothing..
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  render() {
    const { alert, loading } = this.props;   
    const isFormSubmit = getIsFormSubmit(); 
    const showAlert = isFormSubmit && isFormSubmit == 'true' ? false : true;

    return <div>
      <Router history={history}>
        <Header />
        {alert.message && showAlert && alert.fields && alert.fields.length > 0 && <div className="content">
          <Alert variant={alert.type.replace("alert-", "")||"info"}>
            {alert.message}
            {alert.fields && alert.fields.length > 0 && <ul>
              {alert.fields.map((field) => {
                return <li>{field}</li>;
              })}
            </ul>}
          </Alert>
        </div>
        }
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/place" component={PlaceOrder} />
          <PrivateRoute exact path="/rounds/list" component={Rounds} />
          {/* <PrivateRoute exact path="/customer/checklist" component={Customers} /> */}
          <PrivateRoute exact path="/driver/list" component={Driver} />
          <PrivateRoute exact path="/product/purchased" component={PurchaseOrder} />
          <PrivateRoute exact path="/supplier/report" component={SupplierReport} />
          <PrivateRoute exact path="/supplier/duein" component={SupplierDueinReport} />
          <PrivateRoute exact path="/product/add" component={AddNewProduct} />
          {/* <PrivateRoute exact path="/product/list" component={ProductList} /> */}
          <PrivateRoute exact path="/orders/invoice" component={InvoiceManagement} />
          <PrivateRoute path="*" component={Content} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      { loading && <Loader
        className="loader"
        type="Oval"
        color="#db3d44"
        height={100}
        width={100}
      />}
    </div>;
  }
}

function mapState(state) {
  let loading = false;

  const { authentication, alert, process, contents, place, order, session } = state;
  const { user } = authentication;

  if ( (process && process.loading) 
    || (contents && contents.loading)
    || (place && place.loading)
    || (order && order.loading)
    || (session && session.loading)
  ) {
    loading = true;
  }
  return { user, alert, loading, session };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(App);