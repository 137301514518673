import client from '../api/ApiClient';

export const searchService = {
  searchByKeyword,
  searchAll
}

function searchByKeyword(keyword, slug, processId) {
  const url = process.env.REACT_APP_API_URL+'/'+slug+'/process/'+processId+'/item/search?key='+keyword;
  return client.get(url);
}

async function searchAll(slug, processId) {
  const url = process.env.REACT_APP_API_URL+'/'+slug+'/process/'+processId+'/item/all';
  return await client.get(url);
}
