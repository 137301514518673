
export const savePaymentInStorage = (roundNo, customerId, type, value) => {
  localStorage.setItem('__round__payment_update_'+roundNo+'_'+customerId+'__'+type, value);
}

export const getPaymentInStorage = (roundNo, customerId, type) => {
  return localStorage.getItem('__round__payment_update_'+roundNo+'_'+customerId+'__'+type);
}

export const saveOrderChecklistInStorage = (customerId, roundNo, checklistId, type, value) => {
  localStorage.setItem('__round__checklist_'+customerId+'_'+roundNo+'_'+checklistId+'_'+type, value);
}

export const getOrderChecklistInStorage = (customerId, roundNo, checklistId, type) => {
  return localStorage.getItem('__round__checklist_'+customerId+'_'+roundNo+'_'+checklistId+'_'+type);
}

export const setOrders = (roundNo, orders) => {
  localStorage.setItem('__round__orders__'+roundNo, JSON.stringify(orders));
}

export const getOrders = (roundNo) => {
  return JSON.parse(localStorage.getItem('__round__orders__'+roundNo));
}

export const removeOrders = (roundNo) => {
  localStorage.removeItem('__round__orders__'+roundNo);
}

// export const saveRoundSchedule = (roundNo, records) => {
//   localStorage.setItem('__round__schedule_'+roundNo, JSON.stringify(records));
// }

// export const getRoundSchedule = (roundNo) => {
//   return JSON.parse(localStorage.getItem('__round__schedule_'+roundNo));
// }

export const setRoundTotalPayment = (roundNo, customerId, type, value) => {
  localStorage.setItem('__round__total_payment__'+roundNo+'_'+customerId+'_'+type, value);
} 

export const getRoundTotalPayment = (roundNo, customerId, type) => {
  return localStorage.getItem('__round__total_payment__'+roundNo+'_'+customerId+'_'+type);
} 

export const setActivePlaceTab = (key) => {
  localStorage.setItem('__round__active__main', key);
}

export const getActivePlaceTab = () => {
  return localStorage.getItem('__round__active__main');
}

export const setPostitsCustomer = (roundNo, customerId, customerInfo) => {
  localStorage.setItem('__round__postits_account__'+roundNo+'_'+customerId, customerInfo);
}

export const getPostitsCustomer = (roundNo, customerId) => {
  return JSON.parse(localStorage.getItem('__round__postits_account__'+roundNo+'_'+customerId));
}

export const setPostitsChecklist = (roundNo, customerId, checklist) => {
  localStorage.setItem('__round__postits_checklist__'+roundNo+'_'+customerId, checklist);
}

export const getPostitsChecklist = (roundNo, customerId) => {
  return JSON.parse(localStorage.setItem('__round__postits_checklist__'+roundNo+'_'+customerId));
}

export const setAccounts = (accounts) => {
  localStorage.setItem('__accounts__info', JSON.stringify(accounts));
}

export const getAccounts = () => {
  return JSON.parse(localStorage.getItem('__accounts__info'));
}

export const setRoundDrivers = (roundDrivers) => {
  localStorage.setItem('__round_drivers', JSON.stringify(roundDrivers));
}

export const getRoundDrivers = () => {
  return JSON.parse(localStorage.getItem('__round_drivers'));
}

export const getDriverRounds = () => {
  return JSON.parse(localStorage.getItem('__driver_rounds'));
}

export const setSchedule = (schedule) => {
  localStorage.setItem('__round__schedule_all', JSON.stringify(schedule));
}

export const getSchedule = () => {
  return JSON.parse(localStorage.getItem('__round__schedule_all'));
}

export const setChecklist = (customerId, checklist) => {
  let list = [];
  if (! Array.isArray(checklist)) {
    list.push(checklist);
  } else {
    list = checklist;
  }

  localStorage.setItem('__round__checklist__'+customerId, JSON.stringify(list));
}

export const getChecklist = (customerId) => {
  return JSON.parse(localStorage.getItem('__round__checklist__'+customerId));
}

export const removeOrderChecklistInStorage = (customerId, roundNo, checklistId, type) => {
  localStorage.removeItem('__round__checklist_'+customerId+'_'+roundNo+'_'+checklistId+'_'+type);
}

export const setPostitsNotes = (roundNo, customerId, notes) => {
  localStorage.setItem('__round__postits__notes_'+roundNo+'_'+customerId, notes);
}

export const getPostitsNotes = (roundNo, customerId) => {
  return localStorage.getItem('__round__postits__notes_'+roundNo+'_'+customerId);
}

export const setPaperWorksCreated = (roundNo, status) => {
  localStorage.setItem('__round__paper__works_'+roundNo, status);
} 

export const getPaperWorksCreated = (roundNo) => {
  return localStorage.getItem('__round__paper__works_'+roundNo);
}

export const setOrderDetails = (orderId, orderDetails) => {
  localStorage.setItem('__round__order__details_'+orderId, JSON.stringify(orderDetails));
}

export const getOrderDetails = (orderId) => {
  return JSON.parse(localStorage.getItem('__round__order__details_'+orderId));
}

export const setIsFormSubmit = (isFormSubmit) => {
  localStorage.setItem('__process__is__form__submit', isFormSubmit);
}

export const getIsFormSubmit = () => {
  return localStorage.getItem('__process__is__form__submit'); 
}

export const setCurrentState = (state) => {
  localStorage.setItem('__process__saved__state', JSON.stringify(state));
}

export const getCurrentState = () => {
  return JSON.parse(localStorage.getItem('__process__saved__state'));
}

export const clearCurrentState = () => {
  localStorage.removeItem('__process__saved__state')
}

export const clearSchedule = () => {
  localStorage.removeItem('__round__schedule_all')
}

export const setProducts = (products) => {
  localStorage.setItem('__product__list', JSON.stringify(products));
}

export const getProducts = () => {
  return JSON.parse(localStorage.getItem('__product__list'));
}

export const getPaymentInStorageKey = (roundNo, customerId, type) => {
  return '__round__payment_update_'+roundNo+'_'+customerId+'__'+type;
}

export const getOrderChecklistInStorageKey = (customerId, roundNo, checklistId, type) => {
  return '__round__checklist_'+customerId+'_'+roundNo+'_'+checklistId+'_'+type;
}

export const setRoundSelected = (roundNo) => {
  localStorage.setItem('__round__selected', roundNo);
}

export const getRoundSelected = () => {
  return localStorage.getItem('__round__selected');
}

export const removeRoundSelected = () => {
  localStorage.removeItem('__round__selected');
}

export const setProductStocks = (productId, productStocks) => {
  localStorage.setItem('__product__stocks_'+productId, JSON.stringify(productStocks));
}

export const getStocks = (productId) => {
  return JSON.parse(localStorage.getItem('__product__stocks_'+productId));
}

export const setSuppliersForSearch = (suppliers) => {
  localStorage.setItem('__suppliers__for__search', JSON.stringify(suppliers));
}

export const getSuppliersForSearch = (suppliers) => {
  return JSON.parse(localStorage.getItem('__suppliers__for__search'));
}

export const setProductsForSearch = (products) => {
  localStorage.setItem('__products__for__search', JSON.stringify(products));
}

export const getProductsForSearch = () => {
  return JSON.parse(localStorage.getItem('__products__for__search'));
}