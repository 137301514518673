import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import MultiSelect from '../../components/Form/MultiSelect';
import AutoSuggest from '../../components/Search/AutoSuggest';

class ReportFilter extends React.Component
{
  constructor (props) {
    super(props);

    let defaultState = {
      id: ''
    };
    
    if (this.props.fields.length > 0) {
      this.props.fields.forEach(field => {
        if (field.type == 'datepicker' && field.default) {
          defaultState[field.name] = new Date(field.default);
        }
      });
    }
    this.state = defaultState;
  }

  onAutoSuggestChange = (id) => {
    this.setState({ id: id });
  }

  onSelectChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  onDateChange = (date, name) => {
    this.setState({ 
      [name]: date
    })
  }

  onSearchClick = (e) => {
    e.preventDefault();

    let data = {};

    this.props.fields.forEach((field) => {
      data[field.name] = this.state[field.name] || '';
    })

    this.props.search(data);
  }

  onMultiSelectChange = (name, value) => {
    this.setState({ 
      [name]: value
    });
  }
  render() {
    const { fields, searchButton } = this.props;
    const colCount = 2;
    
    return <>
      <Form>
        <Form.Group as={Row}>
        { fields.map((field) => {
          return <>
            { field.label && <Form.Label column sm={1}>{ field.label }</Form.Label> }
            { field.type == 'autosearch' && <Col sm={colCount}><AutoSuggest procId={ field.processId } onChange={ this.onAutoSuggestChange } /></Col> }
            { field.type == 'datepicker' && <Col sm={colCount}><ReactDatePicker 
                dateFormat={"dd/MMM/yyyy"}
                selected={ this.state[field.name] || field.default }
                onChange={ (date) => this.onDateChange(date, field.name) }
                placeholderText={field.placeholder}
                closeOnScroll={ true }
                shouldCloseOnSelect={ true }
                {...( field.name == 'end_date' ? {minDate:this.state.start_date || ''} : '')}
              /></Col>
            }
            { field.type == 'select' && <Col sm={colCount}>
              <Form.Control 
                as="select" 
                name={ field.name } 
                label={ field.name } 
                defaultValue={this.state[field.name]}
                onChange={(e) => this.onSelectChange(e)} 
              >
                <option value="">All</option>
                { field.options.map((option) => {
                  return <option value={option.id}>{option.name}</option>
                })}
              </Form.Control>
            </Col>}
            { field.type == 'multiselect' && <Col sm={colCount+1}>
              <MultiSelect options={ field.options } placeholder={ field.placeholder } onChange={ (value) => this.onMultiSelectChange(field.name, value) } />
            </Col>}
          </>
        })}
          <Col sm={colCount} onClick={ (e) => this.onSearchClick(e) }>
            <Button size="sm">{searchButton.label}</Button>
          </Col>
          
        </Form.Group>
      </Form>
    </>;
  }
}

export default connect(null, null)(ReportFilter);