import React from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { contentActions } from '../../../_actions/content.actions';
import { searchActions } from '../../../_actions/search.actions';
import { processConstants } from '../../../_constants/process.constants';
import { formatDateForQuery } from '../../../_helpers/calendar';
import ReportFilter from '../ReportFilter';
import { pdf } from '@react-pdf/renderer';
import SupplierPDF from './SupplierPDF';
import { saveAs } from 'file-saver';
import { processActions } from '../../../_actions/process.actions';
import ReactDatePicker from 'react-datepicker';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const ref = React.createRef();

const reportFields = [
  {
    name: 'start_date',
    type: 'datepicker',
    label: 'Start date:',
    placeholder: 'Select start date...',
    // default: new Date()
  },
  {
    name: 'end_date',
    type: 'datepicker',
    label: 'End date:',
    placeholder: 'Select end date...',
    // default: new Date()
  }
];

const searchButton = {
  name: 'search_supplier',
  type: 'button',
  label: 'Search'
};

const orderColumns = [
  { 
    dataField: 'id', 
    text: 'ORDER ID',
    headerStyle: (colum, colIndex) => { return { width: '110px' }; }
  },
  { 
    dataField: 'customer_id', 
    text: 'CUSTOMER ID',
    headerStyle: (colum, colIndex) => { return { width: '110px' }; }
  },
  { 
    dataField: 'company_ref', 
    text: 'ACCOUNT REF',
    headerStyle: (colum, colIndex) => { return { width: '110px' }; } 
  },
  { 
    dataField: 'company', 
    text: 'COMPANY',
    headerStyle: (colum, colIndex) => { return { width: '360px' }; } 
  },
  { 
    dataField: 'quantity', 
    text: 'QUANTITY',
    headerStyle: (colum, colIndex) => { return { width: '110px' }; } 
  },
  { 
    dataField: 'round_no', 
    text: 'ROUND NO',
    headerStyle: (colum, colIndex) => { return { width: '110px' }; } 
  },
];

const { SearchBar } = Search;

class SupplierReport extends React.Component
{
  constructor (props) {
    super(props);

    this.props.getSuppliersForSearch();

    this.state = {
      reports: [],
      suppliers: [],
      expanded: [],
      fields: reportFields,
      searched: false,
      startDate: '',
      endDate: '',
      qty_to_purchase: [],
      pdf: [],
      deliveryDate: ''
    }
  }

  onDateChange = (date) => { this.setState({ deliveryDate: date }) }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.items && this.props.items !== prevProps.items) {
      
      let qtyToPurchase = [];
      this.props.items.reports.filter((item, key) => {
        item.products.forEach((product) => {
          qtyToPurchase.push({ id: product.id, supplier_id: product.supplier_id, qty_to_purchase: product.qty_to_purchase })
        })
      })
      this.setState({ reports: this.props.items.reports, pdf: this.props.items.pdf || [], qty_to_purchase: qtyToPurchase, searched: true });
    } else if (this.props.process && this.props.process != prevProps.process) {
      
      if (this.props.process.suppliers && this.props.process.suppliers != prevProps.process.suppliers) {
        this.setState({ suppliers: this.props.process.suppliers });
      }
    } else if (this.props.created && this.props.created !== prevProps.created) {
      const toastrOptions = {
        icon: 'info', 
        status: 'info'
      }
      toastr.light('', 'Purchase Orders successfully created.', toastrOptions);
    }
  }

  onSearch = (data) => {

    if (! data.start_date) {
      alert('Please select start date');
    } else if (! data.end_date) {
      alert('Please select end date');
    } else {
      this.setState({
        startDate: data.start_date,
        endDate: data.end_date
      });

      data.start_date = formatDateForQuery(data.start_date);
      data.end_date = formatDateForQuery(data.end_date);
      this.props.getWithFilter(data)
    }
  }

  handleOnInputChange = (e, row) => {
    if (! isNaN(e.target.value)) {
      this.setState({ qty_to_purchase: [...this.state.qty_to_purchase.filter((obj) => obj.id != row.id), { id: row.id, supplier_id: row.supplier_id, qty_to_purchase: parseInt(e.target.value)}]})
    }
  }

  generatePurchaseOrder = (e, sendEmail) => {
    e.preventDefault();

    let data = [];
    const deliveryDate = formatDateForQuery(this.state.deliveryDate);
    this.state.qty_to_purchase.forEach((item) => {
      if (Number(item.qty_to_purchase) > 0) {
        item.delivery_date = deliveryDate;
        data.push(item);
      }
    })

    this.props.savePurchaseOrders(data, sendEmail ? true : false);
  }

  render() {
    const { reports, searched } = this.state;
    const fields = [{
      name: 'supplier_id',
      type: 'multiselect',
      reference_form: '11',
      label: 'Supplier:',
      placeholder: 'All',
      options: this.state.suppliers
    }];

    Array.prototype.push.apply(fields,reportFields);

    const productColumns = [
      { 
        dataField: 'id', 
        text: 'PRODUCT ID', 
        headerStyle: (colum, colIndex) => { return { width: '90px' }; } 
      },
      { 
        dataField: 'code', 
        text: 'CODE', 
        editable: false,
        headerStyle: (colum, colIndex) => { return { width: '90px' }; } 
      },
      { 
        dataField: 'description', 
        text: 'DESCRIPTION', 
        editable: false ,
        headerStyle: (colum, colIndex) => { return { width: '360px' }; } 
      },
      { 
        dataField: 'no_of_stocks', 
        text: 'QTY IN STOCKS', 
        editable: false,
        headerStyle: (colum, colIndex) => { return { width: '110px' }; } 
      },
      { 
        dataField: 'qty_on_postits', 
        text: 'QTY ON POSTITS', 
        editable: false,
        headerStyle: (colum, colIndex) => { return { width: '110px' }; }  
      },
      { 
        dataField: 'qty_to_order', 
        text: 'QTY TO ORDER', 
        editable: false,
        headerStyle: (colum, colIndex) => { return { width: '110px' }; }  
      },
      { 
        dataField: 'qty_to_purchase', 
        text: 'QTY SUBMITTED TO SUPPLIER', 
        formatter: (cell, row) => {
          const defaultValue = this.state.qty_to_purchase.find((obj) => obj.id == row.id);
          return defaultValue.qty_to_purchase || row.qty_to_purchase;
        },
        editable: true,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
          const defaultValue = this.state.qty_to_purchase.find((obj) => obj.id == row.id);
          return <Form.Control 
            type="text" 
            name="qty_submitted_to_supplier" 
            placeholder="Input qty submitted to supplier" 
            defaultValue = {defaultValue.qty_to_purchase || value} 
            onChange={(e) => this.handleOnInputChange(e, row)}  
          />;
        },
        headerStyle: (colum, colIndex) => { return { width: '200px' }; } 
      }
    ];

    const expandRow = {
      renderer: row => {

        if (row.orders) {
          return <Container style={{ backgroundColor: 'white'}}>
            <BootstrapTable 
              keyField="order_details_id"
              data={ row.orders }
              columns={ orderColumns }
            />
          </Container>
        }

        return <></>;
      },
      expanded: this.state.expanded,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: true
    }

    return <div className="content">
      <h5>Supplier Reports</h5>
      <Container>
        <Row>
          <Col xs="11" sm="10">
            { this.state.suppliers.length > 0 && <ReportFilter fields={fields} searchButton={searchButton} search={ this.onSearch } /> }
          </Col>
          <Col xs="1" sm="2">
            <Button 
              disabled={ this.state.searched ? false : true }
              onClick={async () => {
                const doc = <SupplierPDF {...this.state} />;
                const asPdf = pdf([]); // [] is important, throws without an argument
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                saveAs(blob, 'supplier_reports.pdf');
              }} 
              size="sm"
            >
              Print PDF
            </Button>
          </Col>
        </Row>
        <Row>
        <div style={{ width: '1100px'}}>
        { reports.map((item, key) => {
          return <ToolkitProvider
            keyField="id"
            data={ item.products }
            columns={ productColumns }
            search
            bootstrap4
          >
            { props => (
              <Container>
                <Row>
                  <Col sm="9">
                    <h6>{item.company}</h6>
                  </Col>
                  <Col sm="3" className="table-search">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                </Row>
                <BootstrapTable 
                  { ...props.baseProps }
                  noDataIndication={ () => <i>No records found.</i> }
                  classes='expandedRow'
                  expandRow={ expandRow }
                  cellEdit={ cellEditFactory({
                    mode: 'click',
                    blurToSave: false
                  }) }
                />
              </Container>
            )}
          </ToolkitProvider>
        })}
        </div>
        </Row>
        { searched && reports.length == 0 && <div><i>No records found.</i></div>}
      {/* { searched && <PDFViewer style={{ width:"100%", height:"300px"}}><SupplierPDF {...this.state} /></PDFViewer>} */}
        { reports && reports.length > 0 && <Form.Group as={Row} key="deliveryDate">
          <Form.Label column xs="1" sm="1">Delivery Date:</Form.Label>
          <Col xs="3" sm="2">
            <ReactDatePicker
              dateFormat={"dd/MMM/yyyy"}
              selected={ this.state.deliveryDate }
              onChange={ (date) => this.onDateChange(date) }
              placeholderText="Choose date to deliver/collect"
              closeOnScroll={ true }
              shouldCloseOnSelect={ true }
              readOnly={ this.state.searched ? false : true }
            />
          </Col>
          <Col xs="1" sm="1">
            <Button 
              disabled={ this.state.searched && this.state.deliveryDate != '' ? false : true }
              onClick={ (e) => this.generatePurchaseOrder(e) }
            >Purchase</Button>
          </Col>
          <Col xs="2" sm="3">
            <Button 
              disabled={ this.state.searched && this.state.deliveryDate != '' ? false : true }
              onClick={ (e) => this.generatePurchaseOrder(e, true) }
            >Purchase and Email</Button>
          </Col>
        </Form.Group> }
      </Container>
    </div>
  }
}

function mapState(state) {
  const { contents, search, process } = state;
  const { items } = contents;
  const { list } = search;
  const { created } = process;
  return { items, list, created, process };
}

const actionCreators = {
  getSuppliersForSearch: processActions.getSuppliersForSearch,
  getWithFilter: contentActions.getWithFilter,
  savePurchaseOrders: processActions.savePurchaseOrders
}

export default connect(mapState, actionCreators)(SupplierReport);