import { searchConstants } from "../_constants/search.constants";

export function search (state = {}, action) {
  switch (action.type) {
    case searchConstants.API_GET_REQUEST_SEARCH_ALL:
      return {
        loading: true
      }
    case searchConstants.API_GET_SUCCESS_SEARCH_ALL:
      return {
        processId: action.processId,
        list: action.list
      }
    case searchConstants.API_GET_FAILURE_SEARCH_ALL:
      return {
        list: action.error
      }
  }

  return state;
}
