import { processConstants } from "../_constants/process.constants";
import { history } from "./history";

export default function Error (error) {
  let errorFields = [];
  let errorMessage = '';
  let systemErrorMessage = error.toString();
  
  if (error.response) {
    if (error.response.status === 401) {
      // Unauthorized access
      logout();
      history.push('/login');
    } else if (error.response.status === 422) {
      // API validation failed on the request. Show errors
      if (error.response.data && error.response.data.data) {
        if (error.response.data.data.errors) {
          for (const field in error.response.data.data.errors) {
            errorFields.push(field + ': ' + error.response.data.data.errors[field]);
          }
        } else {
          for (const field in error.response.data.data) {
            errorFields.push(error.response.data.data[field]);
          }
        }
      }
      
      errorMessage = 'Please correct data in form';
      systemErrorMessage = error.response.data.data;

      if (error.response.request.responseURL.includes(processConstants.PROCESS_ADD_CUSTOMER_TO_ROUND)) {
        if (typeof error.response.data.data === 'object' && typeof error.response.data.data !== null) {
          errorMessage = 'Please select an account to add.';
        } else {
          errorMessage = 'Adding failed! '+error.response.data.data;
        }
      }
    } else if (error.response.data && error.response.data.message) {
      // Request made and server responded
      errorMessage = error.response.data.message.alert_warning || error.response.data.message.alert_danger;
    }
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = 'Error connecting to the API. Please contact your administrator to solve this problem.'
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = 'An error has occurred. Please contact your administrator to solve this problem.'
    systemErrorMessage = error.message;
  }

  return {
    errorFields: errorFields,
    message: errorMessage,
    system: systemErrorMessage
  }
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('navigation');
  localStorage.clear();
}