import { modalConstants } from '../_constants/modal.constants';
import { processConstants } from '../_constants/process.constants';
import { history } from '../_helpers/history';
import { processService } from '../_services/process.service';
import { alertActions } from './alert.actions';

export const modalActions = {
  getProducts,
  clearProducts,
  addChecklist
};

function getProducts () {
  const processId = processConstants.PROCESS_ADD_PRODUCT_ON_CHECKLIST;
  const slug = history.location.pathname.substring(1);
  const uri = slug+'/process/'+processId+'/item/all';
  const method = processConstants.PROCESS_METHOD_SHOW;
  const form = { processId, slug, uri, method };

  return dispatch => {
    dispatch(request(form));

    processService.getFormById(uri, method)
      .then (
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data))
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      );
  }

  function request(form) { return { type: modalConstants.API_GET_REQUEST_ITEMS, form } }
  function success(data) { return { type: modalConstants.API_GET_SUCCESS_ITEMS, data } }
  function failure(error) { return { type: modalConstants.API_GET_FAILURE_ITEMS, error } }
} 

function clearProducts () {
  return { type: modalConstants.API_CLEAR_ITEMS }
}

function addChecklist (customerId, checklist) {

  let data = [];
  checklist.map(function (items) {
    const datum = {
      customer_id: customerId,
      product_id: items.id,
      discount_percentage: items.value,
      status: 'Active'
    }
    data.push(datum);
  });

  const formData = { data };
  const slug = history.location.pathname.substring(1);
  const uri = slug+'/process/'+processConstants.PROCESS_SAVE_CHECKLISTS;

  return dispatch => {
    dispatch(request(formData));

    processService.submitRecords(uri, formData)
      .then (
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data))
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      )
  }

  function request(data) { return { type: modalConstants.API_POST_REQUEST_ITEMS, data } }
  function success(data) { return { type: modalConstants.API_POST_SUCCESS_ITEMS, data } }
  function failure(error) { return { type: modalConstants.API_POST_FAILURE_ITEMS, error } }
}