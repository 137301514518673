import { parseISO } from "date-fns";
import { getAccounts, getOrderChecklistInStorage, getPaperWorksCreated, getPaymentInStorage, getRoundTotalPayment } from "./session";

/** HELPERS */

export const getFloatValue = (item, defaultValue) => {

  let value = 0;

  if (item && item.value) {
    value = item.value;
  } else if (defaultValue) {
    value = defaultValue;
  } else {
    return '';
  }

  return parseFloat(value).toFixed(2);
}

export const getStringValue = (item, defaultValue) => {

  let value = '';

  if (item && item.value) {
    value = item.value;
  } else if (defaultValue) {
    value = defaultValue;
  }

  return value;
}

export const color = (status) => { 
  switch (status) {
    case 'Order Placed': 
      return 'green';
    case 'Call Back': 
      return 'yellow';
    case 'No Order Required': 
    case 'Remove':
      return 'red';
    case 'Warning':
      return 'orange';
    default: 
      return 'white';
  }
}

/** ROUNDS */

export const monthsShort = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getRoundDays = () => {
  let rounds = [];

  rounds.push ({ id: 1, day: 'Sunday' });
  rounds.push ({ id: 2, day: 'Monday' });
  rounds.push ({ id: 3, day: 'Tuesday' });
  rounds.push ({ id: 4, day: 'Wednesday' });
  rounds.push ({ id: 5, day: 'Thursday' });
  rounds.push ({ id: 6, day: 'Friday' });
  rounds.push ({ id: 7, day: 'Saturday' });

  return rounds;
}

export const roundDays = getRoundDays();

export const getRoundDefaultActiveKey = (date) => {
  const activeRound = roundDays.find( (round) => round.id == (date.getDay() + 1) ) || roundDays[0];
  return activeRound.id;
}

export const getActiveRoundWeekBySelected = (selected) => {

  const selectedLocal = localStorage.getItem('__round__active_week_selected');
  const activeRoundWeekLocal = JSON.parse(localStorage.getItem('__round__active_week'));
  
  if (selectedLocal && selectedLocal === selected && activeRoundWeekLocal) {
    activeRoundWeekLocal.selectedDate = parseISO(activeRoundWeekLocal.selectedDate);
    return activeRoundWeekLocal;
  }

  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  let count = 0;

  switch (selected) {
    case 'past':
      const pastDateCount = currentDay + 7;
      currentDate.setDate(currentDate.getDate() - pastDateCount);
    break;
    case 'present':
      count = currentDay;
      const presentDateCount = currentDay;
      currentDate.setDate(currentDate.getDate() - presentDateCount);
    break;
    case 'future':
      const futureDateCount = 7 - currentDay;
      currentDate.setDate(currentDate.getDate() + futureDateCount)
    break;
  }

  const activeRoundWeek = {
    defaultDay: count + 1,
    selectedDate: selected === 'present' ? new Date() : new Date(currentDate),
    dates: {
      sunday:   new Date(currentDate),
      monday:   new Date(setDate(currentDate, 1)),
      tuesday:  new Date(setDate(currentDate, 1)),
      wednesday:new Date(setDate(currentDate, 1)),
      thursday: new Date(setDate(currentDate, 1)),
      friday:   new Date(setDate(currentDate, 1)),
      saturday: new Date(setDate(currentDate, 1))
    }
  }

  localStorage.setItem('__round__active_week_date', '');
  localStorage.setItem('__round__active_week_selected', selected);
  localStorage.setItem('__round__active_week', JSON.stringify(activeRoundWeek));
  return activeRoundWeek;
}

export const getActiveRoundWeekByDate = (date) => {

  const selectedDate = new Date(date);
  const dateLocal = localStorage.getItem('__round__active_week_date');
  const activeRoundWeekLocal = JSON.parse(localStorage.getItem('__round__active_week'));

  if (dateLocal && dateLocal === date && activeRoundWeekLocal) {
    return activeRoundWeekLocal;
  }

  const day = date.getDay();
  date.setDate(date.getDate() - day);

  const activeRoundWeek = {
    defaultDay: day + 1,
    selectedDate: selectedDate,
    dates: {
      sunday:   new Date(date),
      monday:   new Date(setDate(date, 1)),
      tuesday:  new Date(setDate(date, 1)),
      wednesday:new Date(setDate(date, 1)),
      thursday: new Date(setDate(date, 1)),
      friday:   new Date(setDate(date, 1)),
      saturday: new Date(setDate(date, 1))
    }
  }

  localStorage.setItem('__round__active_week_date', date);
  localStorage.setItem('__round__active_week_selected', '');
  localStorage.setItem('__round__active_week', JSON.stringify(activeRoundWeek));
  return activeRoundWeek;
}

const setDate = (date, count, operation) => {
  if (! operation) {
    operation = 'add';
  }

  const newDate = date;

  switch (operation) {
    case 'add':
      date.setDate(newDate.getDate() + count);
      break;
    case 'minus':
      if (count > newDate.getDate()) {
        date.setDate(count - newDate.getDate());  
      } else {
        date.setDate(newDate.getDate() - count);
      }
      break;
    default:
      date.setDate(newDate.getDate() + count);
      break;
    
  }
  
  return date;
}

export const beautifyDate = (date) => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return (day >= 10 ? '' : '0')+day+' '+monthsShort[month]+'. '+year;

}

export const getRoundNo = (driverId, date) => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return year.toString().substring(2)+((month+1) >= 10 ? '' : '0')+(month+1)+(day >= 10 ? '' : '0')+day+driverId;
}

/** DRIVERS */

export const getDriversDefaultActiveKey = (drivers) => {
  return drivers[0].driver_id;
}

export const saveRoundDriversToStorage = (data) => {
  if (data) {
    let roundDrivers = [];
    data.forEach((element) => {
      let roundDriver = {};
      roundDriver[element.round_id] = element;

      if (roundDrivers[element.round_id]) {
        roundDrivers[element.round_id].splice(element.round_id, 0, element);
      } else {
        roundDrivers[element.round_id] = [element];
      }
    });

    localStorage.setItem('__round_drivers', JSON.stringify(roundDrivers));
    saveDriverRoundsToStorage(data);
    
    return roundDrivers;
  }

  return data;
} 

export const saveDriverRoundsToStorage = (data) => {
  const roundDayMap = {
    1: 'sunday',
    2: 'monday',
    3: 'tuesday',
    4: 'wednesday',
    5: 'thursday',
    6: 'friday',
    7: 'saturday'
  }

  if (data) {

    let drivers = [];
    data.forEach((element) => {
      
      if (drivers.find((obj) => obj.id == element.driver_id)) {
        let currDriver = drivers.find((obj) => obj.id == element.driver_id);
        currDriver[roundDayMap[element.round_id]] = true;
        drivers = [...drivers.filter((obj) => obj.id != element.driver_id), currDriver];
        
      } else {
        let driver = {
          id: element.driver_id,
          full_name: element.name,
          sunday: element.round_id == 1,
          monday: element.round_id == 2,
          tuesday: element.round_id == 3,
          wednesday: element.round_id == 4,
          thursday: element.round_id == 5,
          friday: element.round_id == 6,
          saturday: element.round_id == 7
        };
        drivers.push(driver);
      }
    });

    drivers = drivers.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)); 
    return drivers;
  }

  return data;
} 

/** PAYMENTS */

export const getSavedLocalPayments = (records, roundNo) => {
  let old_balance = [], todays_balance = [], total_balance = [], invoice_balance = [], cash = [], cheque = [], invoice = [], status = [];
  
  records.forEach((record) => {
    const oldBalance = getRoundTotalPayment(roundNo, record.customer_id, 'balanceAmount');
    const todaysBalance = getRoundTotalPayment(roundNo, record.customer_id, 'orderAmount');
    const totalBalance = getRoundTotalPayment(roundNo, record.customer_id, 'totalAmount');
    const invoiceBalance = getRoundTotalPayment(roundNo, record.customer_id, 'invoiceOrderAmount');
    const paidCash = getPaymentInStorage(roundNo, record.customer_id, 'cash');
    const paidCheque = getPaymentInStorage(roundNo, record.customer_id, 'cheque');
    const paidInvoice = getPaymentInStorage(roundNo, record.customer_id, 'invoice');
    const updatedStatus = getPaymentInStorage(roundNo, record.customer_id, 'status');

    if (oldBalance) {
      old_balance.push({ id: record.customer_id, value: oldBalance });
    }

    if (todaysBalance) {
      todays_balance.push({ id: record.customer_id, value: todaysBalance });
    }

    if (totalBalance) {
      total_balance.push({ id: record.customer_id, value: totalBalance });
    }

    if (invoiceBalance) {
      invoice_balance.push({ id: record.customer_id, value: invoiceBalance });
    }

    if (paidCash) {
      cash.push({ id: record.customer_id, value: paidCash });
    }

    if (paidCheque) {
      cheque.push({ id: record.customer_id, value: paidCheque });
    }

    if (paidInvoice) {
      invoice.push({ id: record.customer_id, value: paidInvoice });
    }

    if (updatedStatus) {
      status.push({ id: record.customer_id, value: updatedStatus });
    }
  })

  return { 
    oldBalance: old_balance, 
    todaysBalance: todays_balance, 
    totalBalance: total_balance, 
    invoiceBalance: invoice_balance,
    cash: cash, 
    cheque: cheque, 
    invoice: invoice, 
    status: status 
  };
}

export const getRoundSchedule = (roundId, driverId, schedule, customers) => {
  const roundSchedule = [];

  schedule.forEach((record) => {
    
    if (record.driver_id == driverId && record.round_id == roundId) {
      let sched = customers.find((obj) => obj.id == record.customer_id);
      roundSchedule.push({...sched, ...record});
    }
  })

  return roundSchedule;
}

export const computeTotalValue = (column, referenceId,  records, localRecords) => {

  let total = 0;

  records.forEach((record) => {
    const localRecord = localRecords.find((obj) => obj.id == record[referenceId]);
  
    if (localRecord && localRecord.value) {
      total = total + parseFloat(localRecord.value || 0);
    } else {
      total = total + parseFloat(record[column] || 0);
    }
  })

  return total.toFixed(2);
}

export const showGenerateDeliveryBtn = (roundNo, records, statuses) => {
  
  if (getPaperWorksCreated(roundNo) == true) {
    return true;
  }  

  if (! records || (records && records.length == 0)) {
    return false;
  }

  let show = true;
  const orderPlacedStatus = ['order placed', 'no order required'];
  statuses.forEach((status) => {
    if (! orderPlacedStatus.includes(status.value.toLowerCase())) {
      show = false; return;
    }
  })

  records.forEach((record) => {
    if (record.status == '') {
      show = false; return;
    } else if (! orderPlacedStatus.includes((record.status ? record.status : '').toLowerCase())) {
      const hasUpdatedStatus = statuses.find((obj) => obj.id == record.customer_id);
      if (! hasUpdatedStatus) {
        show = false; return;
      }
    }
  })

  return show;
}

/** POSTITS */

export const getCustomerDetails = (customerId) => {
  const customers = getAccounts();
  return customers.find((obj) => obj.id == customerId);
}

export const getSavedLocalOrderChecklist = (records, customerId, roundNo, orderDetails) => {
  let discountPercentage = [], customerPrice = [], qtyInvoice = [], invoiceAmount = [], qtyCash = [], cashAmount = [], stocks = [], status = [];
  
  if (records.length > 0) {
    records.forEach((record) => {
      const dPercentage = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'discountPercentage');
      const cPrice = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'customerPrice');
      const qInvoice = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'qtyInvoice');
      const iAmount = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'invoiceAmount');
      const qCash = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'qtyCash');
      const cAmount = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'cashAmount');
      const nStocks = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'stocks');
      const nStatus = getOrderChecklistInStorage(customerId, roundNo, record.checklist_id, 'status');
      const detail = orderDetails && orderDetails.length > 0 && orderDetails.find((obj) => obj.checklist_id == record.checklist_id) || {};

      discountPercentage.push({ id: record.checklist_id, value: dPercentage || record.discount_percentage });
      customerPrice.push({ id: record.checklist_id, value: cPrice || record.customer_price });
      qtyInvoice.push({ id: record.checklist_id, value: qInvoice || detail.qty_invoice });
      invoiceAmount.push({ id: record.checklist_id, value: iAmount || detail.invoice_amount });
      qtyCash.push({ id: record.checklist_id, value: qCash || detail.qty_cash });
      cashAmount.push({ id: record.checklist_id, value: cAmount || detail.cash_amount });
      stocks.push({ id: record.checklist_id, value: nStocks || record.no_of_stocks });
      status.push({ id: record.checklist_id, value: nStatus || detail.status });
    })
  }

  return { 
    discountPercentage: discountPercentage, 
    customerPrice: customerPrice,  
    qtyInvoice: qtyInvoice,
    invoiceAmount: invoiceAmount,
    qtyCash: qtyCash,
    cashAmount: cashAmount,
    stocks: stocks,
    status: status
  };
}

export const computeCustomerPriceByPercentage = (regularPrice, discountPercentage) => {
  return Number((parseFloat(regularPrice) - (parseFloat(regularPrice) * (parseFloat(discountPercentage) / 100))).toFixed(2)) || '';
}

export const computeDiscountPercentageByCustomerPrice = (regularPrice, customerPrice) => {
  if (regularPrice == customerPrice) {
    return 100;
  }

  const decrease = parseFloat(regularPrice) - parseFloat(customerPrice);
  const percentage = decrease / parseFloat(regularPrice) * 100;
  return Number(percentage.toFixed(2)) || '';
}

export const computeSubtotal = (price, quantity) => {
  return (parseFloat(price) * (parseFloat(quantity || 0) || 0)).toFixed(2);
}

export const isEmpty = (val) => {
  if (typeof val === "undefined") {
      return true;
  } else if (val === null) {
      return true;
  } else if (typeof val ==="boolean") {
      return val ? false : true;
  } else if (typeof val === "number") {
      return val == 0 ? true : false;
  } else if (typeof val === "string") {
      return val != '' ? false : true;
  } 

  return false;
}