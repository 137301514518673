import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';

import { searchActions } from '../../_actions/search.actions';

import Customer from './Customer';
import ChecklistTable from './ChecklistTable';
import Calendar from './Calendar';
import AutoSearch from '../../components/Search/AutoSearch';
import { orderActions } from '../../_actions/order.actions';


const PROCESS_SEARCH_CUSTOMER = 51;

class PlaceOrder extends React.Component
{
  constructor (props) {
    super(props);

    this.state = {
      customer: {},
      hasCustomerRecord: false,
      hasDataRecord: false,
      keyword: '',
      loading: false,
      searchId: '',
      searchValue: '',
      activeKey: 'calendar',
    }
  }

  componentWillMount () {
    let cart = this.props.cart || {};
    let state = this.state;

    if (cart && cart.items) {
      const customer = {
        customerId: cart.items.customerId,
        customerInfo: cart.items.customerInfo
      };
      state['customer'] = customer;
      state['hasCustomerRecord'] = true;

      this.props.searchByKeyword(cart.items.customerId, 54, this.state.customer.customerInfo);
    } else {
      
      const { customer } = this.props;

      if (customer && customer.row) {
        delete customer.row.action;
        cart.customerId = customer.row.id;
        cart.customerInfo = JSON.stringify(customer.row);

        const customerState = {
          customerId: customer.row.id,
          customerInfo: customer.row
        }

        state['customer'] = customerState;
        state['hasCustomerRecord'] = true;
        state['activeKey'] = 'checklist';

        this.props.searchByKeyword(customer.row.id, 54, customer.row);
      }
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.customer && nextProps.customer != this.props.customer && nextProps.customer.customer_id) {
      let state = this.state;
      const { customer } = nextProps;

      delete customer.action;
      
      const customerState = {
        customerId: customer.customer_id,
        customerInfo: customer
      }

      state['customer'] = customerState;
      state['hasCustomerRecord'] = true;
      state['activeKey'] = 'checklist';

      this.props.searchByKeyword(customer.customer_id, 54, customer);
    }

    if (nextProps.contents && nextProps.contents !== this.props.contents && nextProps.contents.from) {
      let state = this.state;

      if (nextProps.contents.from == 'main') {
        state['hasDataRecord'] = true;
      } else if (nextProps.contents.from == 'search') {
        if (nextProps.contents.processId == PROCESS_SEARCH_CUSTOMER) {
          
          if (nextProps.contents.items && Object.keys(nextProps.contents.items).length > 0) {
            state['hasCustomerRecord'] = true;
            state['hasDataRecord'] = false;
            console.log("props", nextProps.contents.items);
            this.props.searchById(nextProps.contents.keyword, 52, nextProps.contents.items);
          } else {
            state['hasCustomerRecord'] = false;
            state['hasDataRecord'] = false;
            state['loading'] = false
          }
        } else {
          if (nextProps.contents.payload) {

            let customerPayload = nextProps.contents.payload;
            if (nextProps.payload[0]) {
              customerPayload = nextProps.payload[0];
            }

            state['hasCustomerRecord'] = true;
            const customer = {
              customerId: customerPayload.id,
              customerInfo: customerPayload
            };
            state['customer'] = customer;

            if (nextProps.contents.items && Object.keys(nextProps.contents.items).length > 0) {
              state['hasDataRecord'] = true;
            }
            state['loading'] = false;
          }
        }
      }
    }
  }

  handleSearchSubmit = (keyword) => {
    let state = this.state;
    state['keyword'] = keyword;
    state['loading'] = true;
  }

  onSearch = (id, value) => {
    let state = this.state;
    state['searchId'] = id;
    state['searchValue'] = value;
    state['loading'] = true;

    this.props.searchById(id, PROCESS_SEARCH_CUSTOMER);
  }

  onTabSelect = (key) => {
    this.setState({ activeKey: key })
  }

  render () {
    const { payload, items, buttons } = this.props;
    const notFound = <i>No record found{this.state.keyword && payload ? ' for customer '+this.state.keyword+'.': '.'}</i>;
    const loading = <p><i>Loading ...</i></p>;

    return <>
      <h5>Place Order </h5>
      <Tabs defaultActiveKey="calendar" id="place-order" activeKey={this.state.activeKey} onSelect={(k) => this.onTabSelect(k)}>
        <Tab eventKey="calendar" title="Calendar">
          <Container className="container-tab">
            <Row>
              <Calendar />
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="checklist" title="Checklist">
          <Container className="container-tab">
            <Row>
              <AutoSearch procId="56" placeholder="Search account" onClick={this.onSearch} buttonTitle="Search" className="float-right" /> 
              { this.state.loading && loading }
              { this.state.hasCustomerRecord && this.state.customer.customerInfo && <Customer data={this.state.customer.customerInfo} /> }
              { this.state.hasCustomerRecord && ! items && <i>Loading..</i> }
              { (this.state.hasDataRecord && items) && <ChecklistTable checklist={items} buttons={buttons} customer={this.state.customer} /> }
            </Row>
          </Container>
        </Tab>
      </Tabs> 
    </>;
  }
}

function mapState(state) {
  const { contents, cart, order } = state;
  const { customer } = order;
  const { items, from, payload, processId } = contents
  return { contents, cart, customer, items, from, processId, payload, state };   
}

const actionCreators = {
  searchById: searchActions.searchById,
  searchByKeyword: searchActions.searchByKeyword,
  clearOrders: orderActions.clearOrders
}

export default connect(mapState, actionCreators)(PlaceOrder);