import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getRoundDrivers, getSchedule } from '../../../../_helpers/session';
import Accounts from './Accounts';

class DriversTab extends React.Component
{
  constructor () {
    super ();

    const roundDrivers = getRoundDrivers();
    this.state = {
      activeKey: "",
      showDrivers: roundDrivers ? true : false,
      hasSchedule: getSchedule() ? true : false,
      roundDrivers: roundDrivers,
      selectedDate: ''
    }
  }

  UNSAFE_componentWillMount () {
    const { round, selectedDate } = this.props;
    const roundDrivers = getRoundDrivers();

    if (round && roundDrivers && roundDrivers[round.id]) {
      if (this.props.selectedDriver) {
        this.setState({ activeKey: roundDrivers[round.id].find((obj) => obj.driver_id == this.props.selectedDriver).driver_id });
      } else {
        this.setState({ activeKey: roundDrivers[round.id][0].driver_id });
      }
    }

    if (selectedDate) {
      this.setState({ selectedDate: selectedDate });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.roundDrivers && prevProps.roundDrivers !== this.props.roundDrivers) {
      this.setState({ showDrivers: true, roundDrivers: getRoundDrivers() });
    }

    if (this.props.schedule && this.props.schedule !== prevProps.schedule) {
      this.setState({ hasSchedule: true });
    }

    if (this.props.selectedDriver && this.props.selectedDriver !== prevProps.selectedDriver && this.props.isValid == true) {
      const roundDrivers = getRoundDrivers();
      this.setState({ activeKey: roundDrivers[this.props.round.id].find((obj) => obj.driver_id == this.props.selectedDriver).driver_id });
    }
  }

  render () {
    const { activeKey, showDrivers, hasSchedule, roundDrivers } = this.state;
    const { round, selectedDate } = this.props;
    
    return <>
      <Container className="container-tab">
        { showDrivers && roundDrivers[round.id] 
          ? <Tabs 
              id="driversTab"
              defaultActiveKey={ activeKey || roundDrivers[round.id][0].driver_id }
              activeKey={activeKey || roundDrivers[round.id][0].driver_id }
              onSelect={ (key) => this.setState({ activeKey: key }) }
            >
              { roundDrivers[round.id].map((roundDriver) => {
                return <Tab key={ roundDriver.driver_id } eventKey={ roundDriver.driver_id } title={ roundDriver.name }>
                  { activeKey == roundDriver.driver_id && hasSchedule ? <Accounts roundId={ round.id } round={ roundDriver } date={ selectedDate } onRowClick={ this.props.onRowClick } /> : <>Loading..</>}
                </Tab>
              })}
            </Tabs>
          : <i>No driver's found.</i>
        }
      </Container>
    </>;
  }
}

function mapState (state) {
  const { session } = state;
  const { roundDrivers, schedule } = session;
  return { roundDrivers, schedule };
}


export default connect(mapState, null)(DriversTab);