export const getProductIdsFromList = (checklist) => {
  let productIds = checklist.map(value => value.product_id);
  return productIds;
}

export const generateChecklistToUpdate = (customerId, newRecords, discountPercentage, customerPrice, status) => {
  let data = {};
  let forAdd = [];
  let forUpdate = [];

  if (newRecords) {
    newRecords.forEach(record => {
      forAdd.push({
        customer_id: customerId,
        product_id: record.id,
        discount_percentage: parseFloat(record.discountPercentage) || '',
        customer_price: parseFloat(record.customerPrice) || '',
        status: record.status
      });  
    });

    data.store = forAdd;
  }

  if (discountPercentage) {
    discountPercentage.forEach(record => {
      let data = forUpdate.find((obj) => obj.id == record.id) || {};
      data.id = record.id;
      data.discount_percentage = record.value;

      forUpdate = [...forUpdate.filter((obj) => obj.id !== record.id), data];
    })
  }

  if (customerPrice) {
    customerPrice.forEach(record => {
      let data = forUpdate.find((obj) => obj.id == record.id) || {};
      data.id = record.id;
      data.customer_price = record.value;

      forUpdate = [...forUpdate.filter((obj) => obj.id !== record.id), data];
    })
  }

  if (status) {
    status.forEach(record => {
      let data = forUpdate.find((obj) => obj.id == record.id) || {};
      data.id = record.id;
      data.status = record.value;

      forUpdate = [...forUpdate.filter((obj) => obj.id !== record.id), data];
    })
  }

  if (forUpdate.length > 0) {
    data.update = forUpdate;
  }

  return data;
}