import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Alert, FormGroup, FormControl } from 'react-bootstrap';
import CustomForm from '../Form/CustomForm';
import { processActions } from '../../_actions/process.actions';
import BootstrapTable from 'react-bootstrap-table-next';
import { titleCase } from '../../_helpers/content';
import { processConstants } from '../../_constants/process.constants';
import AddProductStocksModal from '../../pages/product/AddProductStocksModal';
import { clearCurrentState, getCurrentState, getIsFormSubmit, setCurrentState, setIsFormSubmit } from '../../_helpers/session';
import ShowSuppliersProductModal from '../../pages/product/ShowSuppliersProductModal';

const columnsNotToShow = ['password', 'remember_token', 'deleted_at', 'created_at', 'updated_at'];
const excludedProcess = [processConstants.PROCESS_ADD_PRODUCT_STOCKS, processConstants.PROCESS_SHOW_PRODUCTS_OF_SUPPLIER];

class CustomModal extends React.Component 
{
  constructor (props) {
    super (props);

    this.state = {
      data: {},
      file: null,
      formFields: {},
      cart: {},
      showModal: false,
      items: [],
      record: []
    };
  }

  handleFormData = (key, value) => {
    let state = this.state;
    
    if (this.props.method == 'import') {
      state['file'] = value;
    } else {
      state['data'][key] = value;
    }
  }

  handleSubmit = (e) => {
    const { method, processId, record, process } = this.props;
    const { file, data, items, formFields } = this.state;

    setIsFormSubmit(true);

    if (record && record.action) {
      delete record.action;
    }

    const state = {
      data: data,
      items: items,
      processId: processId,
      method: method,
      record: record,
      formFields: formFields
    }
    setCurrentState(state);

    if (method == 'create') {
      this.props.submitForm(processId, data, method);
    } else if (method == 'destroy') {
      this.props.deleteRecord(processId, record.id);
    } else if (method == 'import') {
      this.props.submitForm(processId, file, method);
    } else if (method == 'edit') {
      const id = record.id;
      this.props.updateRecord(id, processId, data, method);
    } else if (method == 'disable') {
      this.props.disableRecord(processId, record.id);
    }
  }

  componentDidMount() {
    const { process, alert } = this.props;
    if (alert.type == 'alert-success') {
      setIsFormSubmit(false);
      clearCurrentState();
      this.props.clearForm();
      this.props.handleClose();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.alert && this.props.alert !== prevProps.alert) {
      if (this.props.alert.type == 'alert-success') {
        setIsFormSubmit(false);
          clearCurrentState();
        this.props.handleClose();
      }
    }
  }

  componentWillMount() {

    const { process, displayType, record } = this.props;
    const { items, form, loading } = process;
    
    if (! loading) {
      if (getIsFormSubmit() == 'true') {
        const localRecords = getCurrentState();
        this.setState({
          data: localRecords.data,
          items: localRecords.items,
          formFields: localRecords.formFields,
          record: localRecords.record
        })
      } else {
        let formFields = [];
        let state = this.state;

        if (displayType == 'form' && items) {
          formFields = items.form_fields_for_form;
        } else if (displayType == 'edit' && form) {
          formFields = form.form_fields;
        }

        state['formFields'] = formFields;
        state['items'] = items;
        state['record'] = record;
      }
    }

    
  }

  render () { 

    // if (excludedProcess.includes(Number(this.props.processId))) {
    if (Number(this.props.processId) == processConstants.PROCESS_ADD_PRODUCT_STOCKS) {
      return <AddProductStocksModal {...(this.props)} {...(this.state)} />;
    }

    if (Number(this.props.processId) == processConstants.PROCESS_SHOW_PRODUCTS_OF_SUPPLIER) {
      return <ShowSuppliersProductModal {...(this.props)} {...(this.state)} />
    }

    const { alert, show, onHide, title, process, message, handleClose, close, save, displayType, method, processId, editable } = this.props;
    const { form } = process;
    const { items, record, data } = this.state;
    let firstColumn = '';

    let formFields = [];
    if (displayType == 'form' && items) {
      formFields = items.form_fields_for_form;
    } else if (displayType == 'edit' && form) {
      formFields = form.form_fields;
    }
    
    const columns = [{dataField: '', text: ''}];
    if (items) {

      const headers = items && items[0] || {} ;
      for (const header in headers) {
        if (! columnsNotToShow.includes(header)) {
          const text = titleCase(header.replace(/_/gi, " "));
          const property = {dataField: header, text: text};
          columns.push(property);
        }

        if (firstColumn == '' || header == 'id') {
          firstColumn = header;
        }
      }
      columns.shift();
    }
    
    
    return <>
      { ! process.loading && <Modal 
        show={show} 
        onHide={onHide} 
        backdrop="static" 
        keyboard={false} 
        centered 
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title><h6>{title}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.message && 
            <Alert variant={alert.type.replace("alert-", "")}>
              {alert.message}
              {alert.fields && alert.fields.length > 0 && <ul>
                {alert.fields.map((field) => {
                  return <li>{field}</li>;
                })}
              </ul>}
            </Alert>}
          {process.loading && <em>Loading form...</em>}
          {(displayType == 'form' || displayType == 'edit') && formFields && 
            <CustomForm
              onChange={this.handleFormData}
              fields={formFields}
              from="modal"
              method={method}
              id={(record && record.id) ? record.id : null}
              processId={processId}
              defaultValues={ data }
              editable={ editable }
            />
          }
          {displayType == 'message' && <>{message}</>}
          {(displayType == 'info') && ! process.loading && items.length <= 0 && <i>No records found.</i>}
          {(displayType == 'info') && ! process.loading && items && items.length > 0 && <BootstrapTable 
            striped  
            keyField={firstColumn}
            data={items}
            columns={columns}
          />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>{close}</Button>
          {(displayType != 'info' ||  (displayType == 'edit' && editable !== false)) && <Button variant="primary" onClick={(e) => this.handleSubmit(e)}>{save}</Button>}
        </Modal.Footer>
      </Modal>}
      </>;
  }
}

function mapState (state) {
  const { process, alert } = state;
  return { process, alert };
}

const actionCreators = {
  submitForm: processActions.submitForm,
  deleteRecord: processActions.deleteRecord,
  clearForm: processActions.clearForm,
  updateRecord: processActions.updateRecord,
  disableRecord: processActions.disableRecord
}

export default connect(mapState, actionCreators)(CustomModal);