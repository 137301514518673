import React from 'react';
import { Button, Form } from 'react-bootstrap';
import SingleSelect from '../Form/SingleSelect';

class TableBody extends React.Component
{
  constructor (props) {
    super(props);

    var excludedOnRowClick = [];

    if (this.props.columns) {
      this.props.columns.map((column) => {
        if (column.formType) {
          excludedOnRowClick.push(column.dataField)
        }
      })
    }

    this.state = {
      excludedOnRowClick: excludedOnRowClick
    };
  }

  handleOnClick = (e, row) => {
    if (! this.state.excludedOnRowClick.find((obj) => obj == e.target.name)) {
      if(this.props.trFn && typeof this.props.trFn === 'function') {
        this.props.trFn(e, row);
      }
    }
  }

  handleOnCheckChange = (e, row) => {
    this.props.onCheckChange(e.target.checked);
  }

  render() {
    const { data, columns, noRecord, extra } = this.props;
    return <tbody>
      { data && data.length > 0 && data.map((item, i) => {
        return <tr onClick={ (e) => this.handleOnClick(e, item) } key={"tr-item-"+i}>
          { columns.map((column, index) => {
            if (column.formType && column.formType == 'input') {
              return <td className="table-row-action" name={ column.dataField }>
                <Form.Control 
                  type="input"
                  name={ column.dataField }
                  data-id={ item.id }
                  onKeyUp={ (e) => this.props.onKeyUp(e, item) }
                  defaultValue={ item[column.dataField] || '' }
                  readOnly={ column.readOnly || false }
                />
              </td>
            } else if (column.formType && column.formType == 'checkbox') {
              return <td className="table-row-action" name={ column.dataField }>
                <Form.Check 
                  name={ column.dataField }
                  aria-label="option 1"
                  defaultChecked={ item[column.dataField] }
                  onChange={ (e) => this.handleOnCheckChange(e, item) }
                />
              </td>
            } else if (column.actionType) {
              if (column.actionType == 'delete') {
                return <td>
                  <Button variant="link" size="sm" id={item.id} onClick={ (e) => this.props.onDelete(e, item) }>
                    <i id={item.id} className="fas fa-minus-circle icon-red" />
                  </Button>
                </td>
              }
            } else if (column.process) {
              return <td>
                <Button variant="link" size="sm" id={item.id} onClick={ (e) => column.onClick(e, item) }>
                  <i id={item.id} className="fas fa-minus-circle icon-red" />
                </Button>
              </td>;
            }

            return <td key={"td-item-"+index}>{ item[column.dataField] }</td>
          })}
        </tr>
      })}
      { ! data || (data && data.length == 0) && <tr>
        <td colSpan={ columns.length }><i>{ noRecord || 'No records found.' }</i></td>
      </tr> }
      { extra && extra.map((row) => {
        return <tr>
          { row.map((item) => {
            let style = { height: '10px' };
            if (item.alignRight) style.textAlign = 'right';
            if (item.bold) style.fontWeight = 'bold';

            if (item.formType) {

              switch (item.formType) {
                case 'singleselect':
                  const customStyles = {
                    control: base => ({
                      ...base,
                      height: 25,
                      minHeight: 25
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      margin: '0px',
                    }),
                    indicatorsContainer: (provided, state) => ({
                      ...provided,
                      height: '25px',
                    }),
                  };
                  return <td colSpan={item.colSpan} style={ style }>
                    <SingleSelect options={ item.options } placeholder={ item.placeholder } onChange={ item.onChange } resetValue={ item.resetValue } styles={ customStyles } />
                  </td> 
                case 'input':
                  return <td colSpan={item.colSpan} style={ style }>
                    <Form.Control 
                      type="input"
                      name={ item.dataField }
                      onKeyUp={ (e) => item.onKeyUp(e, item) }
                      defaultValue={ item.defaultValue || '' }
                      readOnly={ item.readOnly || false }
                    />
                  </td>
                case 'button':
                  return <td colSpan={item.colSpan} style={ style }>
                    <Button variant="link" size="sm" disabled={ item.readOnly } onClick={ item.onClick }>
                      <i className="fas fa-plus-circle icon" />
                    </Button>
                  </td>
              }
              return <td colSpan={item.colSpan} style={ style }>{ item.formType }</td>
            } 

            return <td colSpan={item.colSpan} style={ style }>{item.value}</td>
          })}
        </tr>
      })}
    </tbody>
  }
}

export default TableBody;