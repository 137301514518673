import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import LinkTooltip from "../../../../components/LinkTooltip";
import { placeActions } from "../../../../_actions/place.actions";
import { sessionActions } from "../../../../_actions/session.actions";
import {
  beautifyDate,
  color,
  getFloatValue,
  getRoundNo,
  getStringValue,
  showGenerateDeliveryBtn,
} from "../../../../_helpers/placeOrder";
import {
  getOrders,
  getPaymentInStorageKey,
  savePaymentInStorage,
  setPaperWorksCreated,
} from "../../../../_helpers/session";
import Popout from "react-popout";
import "./styles.css";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import styled, { css } from "styled-components";
import DiscountModal from "./DiscountModal";

const currencySymbol = "£";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const computeNewBalance = (invoice, cheque, total) => {
  let newBalance = 0;
  if (total) {
    newBalance = parseFloat(total) - (parseFloat(invoice) + parseFloat(cheque));
  } else {
    newBalance = parseFloat(invoice) + parseFloat(cheque);
  }
  return newBalance.toFixed(2);
};

const getStatuses = (orders) => {
  let statuses = [];
  if (orders) {
    orders.forEach((order) => {
      statuses.push({
        id: order.customer_id,
        value: order.status,
      });
    });
  }

  return statuses;
};

class Accounts extends React.Component {
  constructor(props) {
    super(props);

    const roundNo = getRoundNo(this.props.round.driver_id, this.props.date);
    const { roundId } = this.props;

    this.state = {
      loading: true,
      roundId: roundId,
      roundNo: roundNo,
      hasPaperWorks: false,
      orders: [],
      orderDetails: [],
      total: {},
      oldBalance: [],
      todaysBalance: [],
      totalBalance: [],
      invoiceBalance: [],
      cash: [],
      cheque: [],
      invoice: [],
      status: [],
      totalOrdersWithInvoice: 0,
      showPopout: false,
      popoutUrl: "",
      popoutId: "",
      action: "",
      showGenerateDeliveryBtn: false,
      printPage: false,
      invoiceNo: [],
      cashInvoiceNo: [],
      showDiscountModal: false,
      selectedRow: {},
    };

    this.handleOnPrint = this.handleOnPrint.bind(this);
    this.onApplyDiscountClick = this.onApplyDiscountClick.bind(this);
    this.onApplyDiscountClose = this.onApplyDiscountClose.bind(this);
    this.props.getOrders(roundNo, roundId);

    document.addEventListener("keydown", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    // if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
    //   const rowCounter = event.target.dataset.counter;
    //   const rowArr = rowCounter.split("-");
    //   const nextRowCounter = rowArr[0] + "-" + (parseInt(rowArr[1]) + 10);
    //   const element = document.querySelector(
    //     "[data-counter='" + nextRowCounter + "']"
    //   );

    //   if (element) {
    //     element.focus();
    //   }
    // }
    if (![37,38,39,40].includes(event.keyCode)) {
      return;
    }

    let allElements = document.querySelectorAll('.navigable-by-keys');

    if (allElements.length == 0) {
      return;
    }


    let currentKey = null;
    let nextKey = null;
    for (let elKey = 0; elKey < allElements.length; elKey++) {
      if (event.target == allElements[elKey]) {
        currentKey = elKey;
      }
    }

    switch (event.keyCode) {
      case 37: // left
        nextKey = currentKey - 1;
      break;
      case 38: // up
        nextKey = currentKey - 3;
      break;
      case 39: // right
        nextKey = currentKey + 1;
      break;
      case 40: // down
        nextKey = currentKey + 3

      break;
    }
    if (allElements[nextKey]) {
      console.log(nextKey);
      console.log(allElements[nextKey]);
      console.log('calling focus', allElements[nextKey],document.activeElement.tagName);
      allElements[nextKey].focus();
      console.log('focus called', allElements[nextKey], document.activeElement.tagName);
      allElements[nextKey].focus();
    } else {
      console.log("OOB, ignoring");
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if ((document.activeElement.tagName).toLowerCase() == 'input') {
      return false;
    }
    console.log('shouldComponentUpdate', document.activeElement.tagName);
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('componentDidUpdate');
    if (
      this.props.roundUpdated &&
      this.props.roundUpdated !== prevProps.roundUpdated
    ) {
      this.setState({ showGenerateDeliveryBtn: true, loading: false });
    } else if (
      this.props.place &&
      this.props.place !== prevProps.place &&
      this.props.place.paperworks_generated &&
      this.props.place.path
    ) {
      this.setState({ hasPaperWorks: true });
      openInNewTab(
        process.env.REACT_APP_API_URL.slice(0, -4) + "/" + this.props.place.path
      );
    } else if (
      this.props.printNotes &&
      this.props.printNotes != prevProps.printNotes &&
      this.props.place.path
    ) {
      openInNewTab(
        process.env.REACT_APP_API_URL.slice(0, -4) + "/" + this.props.place.path
      );
    } else if (
      this.props.printInvoice &&
      this.props.printInvoice != prevProps.printInvoice
    ) {
      if (this.props.place.items.filename) {
        const accountFile = this.props.place.items.filename.account;
        const cashFile = this.props.place.items.filename.cash;
        const roundFile = this.props.place.items.filename.round;

        let url = "";
        let taggedAsPrinted = false;

        if (accountFile) {
          taggedAsPrinted = true;
          url = process.env.REACT_APP_API_URL + "/../invoice/" + accountFile;
          openInNewTab(url);
        }

        if (cashFile) {
          taggedAsPrinted = true;
          url = process.env.REACT_APP_API_URL + "/../invoice/" + cashFile;
          openInNewTab(url);
        }

        if (roundFile) {
          taggedAsPrinted = false;
          url = process.env.REACT_APP_API_URL + "/../invoice/" + roundFile;
          openInNewTab(url);
          this.props.getOrders(this.state.roundNo, this.state.roundId);
        }

        if (taggedAsPrinted == true) {
          this.props.taggedAsPrinted(this.props.place.items.order.id);
        }
      } else {
        this.props.getOrders(this.state.roundNo, this.state.roundId);
      }
    } else if (
      this.props.printDriversList &&
      this.props.printDriversList != prevProps.printDriversList
    ) {
      if (this.props.place.items.path) {
        const url =
          process.env.REACT_APP_API_URL + "/../" + this.props.place.items.path;
        openInNewTab(url);
      }
    } else if (
      this.props.roundInvoice &&
      this.props.roundInvoice != prevProps.roundInvoice
    ) {
      this.props.getOrders(this.state.roundNo, this.state.roundId);
    } else if (this.props.orders && this.props.orders !== prevProps.orders) {
      const statuses = getStatuses(this.props.orders);

      this.setState({
        hasPaperWorks:
          this.props.paperWorksGenerated &&
          this.props.paperWorksGenerated &&
          this.props.paperWorksGenerated == "1"
            ? true
            : false,
        orders: this.props.orders,
        orderDetails: this.props.orderDetails || [],
        total: this.props.total || {},
        showGenerateDeliveryBtn:
          statuses.filter(
            (obj) =>
              !obj.value ||
              (obj.value.toLowerCase() != "order placed" &&
                obj.value.toLowerCase() != "no order required")
          ).length > 0
            ? false
            : true,
        oldBalance: [],
        todaysBalance: [],
        totalBalance: [],
        cashBalance: [],
        cash: [],
        cheque: [],
        invoice: [],
        status: statuses,
        loading: false,
        showPopout: false,
      });

      // TODO: Remove records on localstorage to make sure that all data are fresh
    } else if (
      this.props.place &&
      this.props.place !== prevProps.place &&
      this.props.place.order
    ) {
      const order = this.props.place.order[0];
      this.setState({ loading: false });

      let orders = this.state.orders;
      orders.forEach((obj, key) => {
        if (obj.id == order.id) {
          orders[key] = order;
        }
      });
    }
  }

  updateOrders = (id, attributes) => {
    return;

    var index = this.state.orders.findIndex((x) => x.id === id);
    if (index === -1) {
      return;
    } else {

      this.setState({
        orders: [
          ...this.state.orders.slice(0, index),
          Object.assign({}, this.state.orders[index], attributes),
          ...this.state.orders.slice(index + 1),
        ],
      });

    }
  };

  handleInputCashCheque = (e, row) => {

    e.preventDefault();
    const id = parseInt(e.target.dataset.id);
    const column = e.target.dataset.column;
    const value = e.target.value;
    const totalBalanceCheckRound = Math.round(row.total_balance).toString();
    if (e.key === 'Enter') {
      const stateValue = { id: id, value: value };
      this.setState({
        [column]: [...this.state[column].filter((obj) => obj.id != id), stateValue]
      }, () => {
        this.setState({
          showGenerateDeliveryBtn: this.state.status.filter((obj) => ! obj.value || (obj.value.toLowerCase() != 'order placed' && obj.value.toLowerCase() != 'no order required')).length > 0 ? false : true
        })
      });
    }

    savePaymentInStorage(this.state.roundNo, id, column, value);
  }

  handleInputChange = (e, row) => {
    e.preventDefault();

    const id = parseInt(e.target.dataset.id);
    const column = e.target.dataset.column;
    const value = e.target.value;

    const stateValue = { id: id, value: value };
    this.setState(
      {
        [column]: [
          ...this.state[column].filter((obj) => obj.id != id),
          stateValue,
        ],
      },
      () => {
        this.setState({
          showGenerateDeliveryBtn:
            this.state.status.filter(
              (obj) =>
                !obj.value ||
                (obj.value.toLowerCase() != "order placed" &&
                  obj.value.toLowerCase() != "no order required")
            ).length > 0
              ? false
              : true,
        });
      }
    );

    savePaymentInStorage(this.state.roundNo, id, column, value);
  };

  handleOnRowClick = (e, row) => {
    if (e.target.dataset.event === "postits") {
      let readOnly = false;
      if (this.state.hasPaperWorks) readOnly = true;
      if (!!row.cash_invoice_no || !!row.invoice_no) readOnly = true;

      this.props.onRowClick("postits", {
        orderId: row.id,
        orders: row,
        orderDetails: this.state.orderDetails[row.id] || [],
        readOnly: readOnly,
        customerId: row.customer_id,
        roundNo: this.state.roundNo,
        onUpdate: this.onUpdate,
        state: this.state,
      });
    }
  };

  handleOnBlur = (e, row) => {
    const column = e.target.dataset.column;
    const value = e.target.value;
    if (column == "cash") {
      let order = this.state.orders.find((obj) => obj.id == row.id);
      if (
        value != order.cash_paid &&
        (order.cash_invoice_no != null || order.cash_invoice_no != "")
      ) {
        order.cash_invoice_created_at = null;
        order.cash_invoice_no = null;
        this.updateOrders(row.id, order);
      }
    } else if (column == "invoice" || column == "cheque") {
      let order = this.state.orders.find((obj) => obj.id == row.id);

      if (
        ((column == "invoice" && value != order.invoice_paid) ||
          (column == "cheque" && value != order.cheque_paid)) &&
        (order.invoice_no != null || order.invoice_no != "")
      ) {
        order.invoice_created_at = null;
        order.invoice_no = null;
        order.invoice_paid = e.target.value;
        this.updateOrders(row.id, order);
      }
    }

    const key = getPaymentInStorageKey(
      this.state.roundNo,
      e.target.dataset.id,
      e.target.dataset.column
    );
    console.log('about to call updateOrders');

    this.props.updateOrders(
      row.id,
      e.target.name,
      key,
      e.target.value,
      this.state.roundNo
    );

  };

  handleOnPrint = (e) => {
    e.preventDefault();

    const { roundNo } = this.state;
    setPaperWorksCreated(roundNo, "true");
    this.props.savePaperWorks(roundNo, 1);
  };

  generateInvoice = (e, row, type) => {
    e.preventDefault();

    let authenticateBy = "authenticateByRound/" + this.state.roundNo;

    const url = process.env.REACT_APP_API_URL + "/xero/" + authenticateBy;
    this.setState({
      popoutUrl: url,
      showPopout: true,
      popoutId: row.id,
      action: type,
    });
  };

  onActionClick = (e, row, type) => {
    let url = process.env.REACT_APP_API_URL + "/xero/" + type + "/" + row.id;
    let taggedInvoice = false;

    if (type == "download") {
      this.props.printOrderInvoice(row.id);
    } else if (type == "email") {
      this.props.emailInvoice(row.id);
    } else {
      this.setState({
        popoutId: row.id,
        popoutUrl: url,
        showPopout: true,
      });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ orders }) => ({
      orders: arrayMove(orders, oldIndex, newIndex),
    }));
  };

  onApplyDiscountClick = (e, row) => {
    e.preventDefault();
    this.setState({
      showDiscountModal: true,
      selectedRow: row,
    });
  };

  onApplyDiscountClose = () => {
    this.setState({
      showDiscountModal: false,
      selectedRow: {},
    });
  };

  popupClosed = () => {
    this.setState({ showPopout: false, loading: true });

    const { action } = this.state;

    if (action == "round" || action == "syncAll") {
      this.props.getOrders(this.state.roundNo, this.state.roundId);
      return;
    } else {
      this.props.getInvoice(this.state.popoutId);
    }
  };

  printPage = (e) => {
    e.preventDefault();

    this.setState({ printPage: true }, () => {
      var title = this.state.roundNo + "-" + this.props.round.name; //{ roundNo } - { round.name };
      var printWindow = window.open("", "printRoundId", "height=400,width=600");
      printWindow.document.write("<html><head><title>" + title + "</title>");
      printWindow.document.write(
        '<link rel="stylesheet" href="./css/main.css" type="text/css" />'
      );
      // printWindow.document.write('<link rel="stylesheet" href="../../../../../bootstrap/dist/css/bootstrap.min.css" type="text/css" />');
      printWindow.document.write("</head><body >");
      printWindow.document.write(
        document.getElementById("printHeader").innerHTML
      );
      printWindow.document.write(
        document.getElementById("printTable").innerHTML
      );
      printWindow.document.write("</body></html>");
      printWindow.document.close(); // necessary for IE >= 10

      printWindow.focus();
      printWindow.print();
      printWindow.close();

      this.setState({ printPage: false });
    });
  };

  printNotes = (e, orderId) => {
    e.preventDefault();

    if (orderId && orderId == "all") {
      this.props.printNotesByRoundNo(this.state.roundNo);
    } else {
      this.props.printNotesByOrderId(orderId);
    }
  };

  printRoundInvoices = (e) => {
    e.preventDefault();
    this.props.printRoundInvoices(this.state.roundNo);
  };

  printDriversProductList = (e) => {
    e.preventDefault();
    this.props.printDriversProductList(this.state.roundNo);
  };

  generateRoundInvoices = (e) => {
    e.preventDefault();
    this.props.generateRoundInvoices(this.state.roundNo);
  };

  generateInvoices = (e, row) => {
    e.preventDefault();
    this.props.generateInvoices(row.id, this.state.roundNo);
  };

  generateAccountInvoice = (e, row) => {
    e.preventDefault();
    this.props.generateInvoice(row.id, true);
  };

  generateCashInvoice = (e, row) => {
    e.preventDefault();
    this.props.generateInvoice(row.id, false);
  };

  saveSorting = (e) => {
    e.preventDefault();

    let data = [];

    this.state.orders.forEach((order, i) => {
      data.push({ id: order.id, order: parseInt(i) + 1 });
    });

    this.props.saveSorting(data);
  };

  render() {
    const { round, date } = this.props;
    const { roundNo, orders } = this.state;
    let totalCash = 0,
      totalCheque = 0,
      totalInvoice = 0;
    let rowCounter = 0;

    const Handle = styled.div`
      flex: none;
      width: 7.5px;
      height: 100%;

      &::before {
        content: "";
        border-left: 4px dotted #ccc;
        display: block;
        height: 15px;
        margin: 15px 3px;
      }

      &:hover::before {
        border-color: #696969;
      }
    `;

    const SortableHandleColumn = SortableHandle((children) => <Handle />);

    const SortableItem = SortableElement(({ record }) => {
      const oldBalance = parseFloat(record.old_balance || "0").toFixed(2);
      const todaysBalance = getFloatValue(
        this.state.todaysBalance.find((obj) => obj.id == record.customer_id),
        record.invoice_amount || "0"
      );
      const totalBalance = (
        parseFloat(oldBalance) +
        parseFloat(todaysBalance) -
        parseFloat(record.discount_amount || 0)
      ).toFixed(2);
      const cashBalance = getFloatValue(
        this.state.cashBalance.find((obj) => obj.id == record.customer_id),
        record.cash_amount || "0"
      );
      const status = getStringValue(
        this.state.status.find((obj) => obj.id == record.customer_id),
        record.status
      );
      const cash = record.cash_invoice_no
        ? record.cash_paid
        : getFloatValue(
            this.state.cash.find((obj) => obj.id == record.customer_id),
            record.cash_paid
          );
      const cheque = record.invoice_no
        ? record.cheque_paid
        : getFloatValue(
            this.state.cheque.find((obj) => obj.id == record.customer_id),
            record.cheque_paid
          );
      const invoice = record.invoice_no
        ? record.invoice_paid
        : getFloatValue(
            this.state.invoice.find((obj) => obj.id == record.customer_id),
            record.invoice_paid
          );
      const newBalance =
        !isNaN(invoice) && !isNaN(cheque)
          ? (
              parseFloat(totalBalance) -
              (parseFloat(invoice || 0) + parseFloat(cheque || 0))
            ).toFixed(2)
          : "-";

      const disableAction =
        record.cash_invoice_no || record.invoice_no ? false : true;
      const generateInvoicesAction =
        !record.cash_invoice_no || !record.invoice_no ? true : false;

      totalCash = (parseFloat(totalCash || 0) + parseFloat(cash || 0)).toFixed(
        2
      );
      totalCheque = (
        parseFloat(totalCheque || 0) + parseFloat(cheque || 0)
      ).toFixed(2);
      totalInvoice = (
        parseFloat(totalInvoice || 0) + parseFloat(invoice || 0)
      ).toFixed(2);

      rowCounter += 10;
      return (
        <tr
          key={record.id}
          onClick={(e) => this.handleOnRowClick(e, record)}
          className={"table-color-" + color(status)}
        >
          <td data-id={record.id} data-event="postits">
            <SortableHandleColumn />
          </td>
          {!this.state.printPage && (
            <td
              data-id={record.id}
              data-event="postits"
              className="table-row-action"
            >
              <LinkTooltip
                icon="fas fa-file-invoice"
                message="Generate Invoice"
                onClick={(e) => this.generateInvoices(e, record)}
                disabled={!generateInvoicesAction}
              />
              <LinkTooltip
                icon={
                  "fas fa-file-download icon-" +
                  (record.has_printed ? "green" : "red")
                }
                message="Print Invoice"
                onClick={(e) => this.onActionClick(e, record, "download")}
                disabled={disableAction}
              />
              <LinkTooltip
                icon="fas fa-at"
                message="Email Invoice"
                onClick={(e) => this.onActionClick(e, record, "email")}
                disabled={disableAction}
              />
              <LinkTooltip
                icon="fas fa-sync-alt"
                message="Sync Invoice/s"
                onClick={(e) => this.onActionClick(e, record, "sync")}
              />
              <LinkTooltip
                icon="fas fa-percent"
                message="Apply discounts"
                onClick={(e) => this.onApplyDiscountClick(e, record)}
              />
            </td>
          )}
          <td data-id={record.id} data-event="postits">
            {record.id}
          </td>
          <td data-id={record.id} data-event="postits">
            {record.company_ref}
          </td>
          <td data-id={record.id} data-event="postits">
            {record.company}
          </td>
          <td data-id={record.id} data-event="postits">
            {record.contact_number || ""}
          </td>
          <td data-id={record.id} data-event="postits">
            {currencySymbol + " " + oldBalance}
          </td>
          <td data-id={record.id} data-event="postits">
            {currencySymbol + " " + todaysBalance}
          </td>
          <td data-id={record.id} data-event="postits">
            {currencySymbol +
              " " +
              (record.discount_amount ? record.discount_amount : "0.00")}
          </td>
          <td data-id={record.id} data-event="postits">
            {currencySymbol + " " + totalBalance}
          </td>
          <td>
            {record.invoice_no || (
              <Button
                variant="link"
                onClick={(e) => this.generateAccountInvoice(e, record)}
                style={{ fontSize: "12px", textDecoration: "underline" }}
                disabled={todaysBalance > 0 ? false : true}
              >
                Generate
              </Button>
            )}
          </td>
          <td>
            <Form.Group
              key={"invoice-" + record.id}
              className="table-form-group"
            >
              <Form.Control
                type="input"
                name="invoice_paid"
                className="navigable-by-keys"
                autoComplete="off"
                data-id={record.customer_id}
                data-column="invoice"
                data-counter={"input-" + (parseInt(rowCounter) + 1)}
                onKeyUp={(e) => this.handleInputCashCheque(e, record)}
                onBlur={(e) => this.handleOnBlur(e, record)}
                defaultValue={invoice}
                readOnly={record.is_sync == "1" ? true : false}
              />
            </Form.Group>
          </td>
          <td>
            <Form.Group
              key={"cheque-" + record.id}
              className="table-form-group"
            >
              <Form.Control
                type="input"
                name="cheque_paid"
                className="navigable-by-keys"
                autoComplete="off"
                data-id={record.customer_id}
                data-column="cheque"
                data-counter={"input-" + (parseInt(rowCounter) + 2)}
                onKeyUp={(e) => this.handleInputCashCheque(e, record)}
                onBlur={(e) => this.handleOnBlur(e, record)}
                defaultValue={cheque}
                readOnly={record.is_sync == "1" ? true : false}
              />
            </Form.Group>
          </td>
          <td data-id={record.id} data-event="postits">
            <Form.Group
              key={"new-balance-" + record.id}
              className="table-form-group"
            >
              <Form.Label>{currencySymbol + " " + newBalance}</Form.Label>
            </Form.Group>
          </td>
          <td></td>
          <td data-id={record.id} data-event="postits">
            {record.company_ref}
          </td>
          <td>
            {record.cash_invoice_no || (
              <Button
                variant="link"
                onClick={(e) => this.generateCashInvoice(e, record)}
                style={{ fontSize: "12px", textDecoration: "underline" }}
                disabled={cashBalance > 0 ? false : true}
              >
                Generate
              </Button>
            )}
          </td>
          <td data-id={record.id} data-event="postits">
            {currencySymbol + " " + cashBalance}
          </td>
          <td>
            <Form.Group key={"cash-" + record.id} className="table-form-group">
              <Form.Control
                type="input"
                name="cash_paid"
                className="navigable-by-keys"
                autoComplete="off"
                data-id={record.customer_id}
                data-column="cash"
                data-counter={"input-" + (parseInt(rowCounter) + 1)}
                onKeyUp={(e) => this.handleInputCashCheque(e, record)}
                onBlur={(e) => this.handleOnBlur(e, record)}
                defaultValue={cash}
                readOnly={record.is_sync == "1" ? true : false}
              />
            </Form.Group>
          </td>
          {!this.state.printPage && (
            <td>
              <Form.Group
                key={"status-" + record.id}
                className="table-form-group"
              >
                <Form.Control
                  as="select"
                  name="status"
                  data-id={record.customer_id}
                  data-column="status"
                  data-counter={"input-" + (parseInt(rowCounter) + 1)}
                  onChange={(e) => this.handleInputChange(e, record)}
                  onBlur={(e) => this.handleOnBlur(e, record)}
                  defaultValue={status}
                >
                  <option value="Not Contacted">Not Contacted</option>
                  <option value="Order Placed">Order Placed</option>
                  <option value="No Order Required">No Order Required</option>
                  <option value="Call Back">Call Back</option>
                </Form.Control>
              </Form.Group>
            </td>
          )}
        </tr>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      return (
        <tbody>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              record={value}
              onClick={(e) => this.handleClick(e, value)}
            />
          ))}
        </tbody>
      );
    });

    return (
      <>
        <div id="printRoundId" style={{ display: "none" }}></div>
        {this.state.showPopout && (
          <Popout
            url={this.state.popoutUrl}
            title="XERO Invoices"
            onClosing={this.popupClosed}
          />
        )}
        {this.state.loading && (
          <Loader
            className="loader"
            type="Oval"
            color="#db3d44"
            height={100}
            width={100}
          />
        )}
        <Container className="container-tab no-gutter" id="roundContainer">
          {this.state.hasPaperWorks && (
            <Row>
              <span>
                <i>
                  <b>*** Paper work has been created! </b>
                </i>
              </span>
            </Row>
          )}
          <Row id="printHeader">
            <Col xs="10">
              Date: {round.day_of_the_week} {beautifyDate(date)}
            </Col>
            <Col xs="2">
              Round: {roundNo} - {round.name}
            </Col>
          </Row>
          <Row></Row>
          <Row id="printTable">
            <Table size="sm" bordered>
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th rowSpan="2"></th>
                  {!this.state.printPage && <th rowSpan="2">ACTION</th>}
                  <th rowSpan="2">ORDER NO</th>
                  <th rowSpan="2">ACCT REF</th>
                  <th rowSpan="2">ACCOUNT NAME</th>
                  <th rowSpan="2">TEL NO</th>
                  <th rowSpan="2">OLD BALANCE</th>
                  <th rowSpan="2">TODAYS BALANCE</th>
                  <th rowSpan="2">TOTAL DISCOUNT</th>
                  <th rowSpan="2">TOTAL BALANCE</th>
                  <th rowSpan="2">INVOICE NUMBER</th>
                  <th colSpan="2">PAID</th>
                  <th rowSpan="2">NEW BALANCE</th>
                  <th rowSpan="2"></th>
                  <th rowSpan="2">ACCT REF</th>
                  <th rowSpan="2">CASH INVOICE NUMBER</th>
                  <th rowSpan="2">ORDER</th>
                  <th>PAID</th>
                  {!this.state.printPage && <th rowSpan="2">STATUS</th>}
                </tr>
                <tr>
                  <th>CASH</th>
                  <th>CHEQUE</th>
                  <th>CASH</th>
                </tr>
              </thead>
              {orders && orders.length > 0 && (
                <SortableList
                  items={orders}
                  onSortEnd={this.onSortEnd}
                  shouldCancelStart={this.handleClick}
                  useDragHandle
                />
              )}
              <tbody>
                {orders && orders.length > 0 && (
                  <tr>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    {!this.state.printPage && (
                      <td style={{ backgroundColor: "#696969" }}></td>
                    )}
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol +
                        " " +
                        (!isNaN(this.state.total.invoice_amount)
                          ? this.state.total.invoice_amount.toFixed(2)
                          : this.state.total.invoice_amount)}
                    </td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol +
                        " " +
                        (!isNaN(this.state.total.total_balance)
                          ? this.state.total.total_balance.toFixed(2)
                          : this.state.total.total_balance)}
                    </td>
                    <td style={{ backgroundColor: "#696969" }}></td>

                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol + " " + totalInvoice}
                    </td>
                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol + " " + totalCheque}
                    </td>
                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol +
                        " " +
                        computeNewBalance(
                          totalInvoice,
                          totalCheque,
                          this.state.total.total_balance
                        )}
                    </td>
                    <td></td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#696969" }}></td>
                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol +
                        " " +
                        this.state.total.cash_amount.toFixed(2)}
                    </td>
                    <td style={{ backgroundColor: "#DCDCDC" }}>
                      {currencySymbol + " " + totalCash}
                    </td>
                    {!this.state.printPage && (
                      <td style={{ backgroundColor: "#696969" }}></td>
                    )}
                  </tr>
                )}
                {!orders ||
                  (orders && orders.length == 0 && (
                    <tr>
                      <td colSpan="19">
                        <i>No records found.</i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Row>
        </Container>
        <Button size="sm" disabled={false} onClick={(e) => this.saveSorting(e)}>
          Save Sorting
        </Button>
        <Button
          size="sm"
          style={{ marginLeft: "15px" }}
          disabled={!this.state.showGenerateDeliveryBtn}
          onClick={(e) => this.handleOnPrint(e)}
        >
          Generate Delivery
        </Button>
        <Button
          size="sm"
          style={{ marginLeft: "15px" }}
          disabled={false}
          onClick={(e) => this.generateRoundInvoices(e)}
        >
          Generate Invoices
        </Button>
        <Button
          size="sm"
          style={{ marginLeft: "15px" }}
          disabled={false}
          onClick={(e) => this.generateInvoice(e, [], "syncAll")}
        >
          Sync All Invoices
        </Button>
        <Button
          size="sm"
          style={{ marginLeft: "15px" }}
          disabled={false}
          onClick={(e) => this.printRoundInvoices(e)}
        >
          Print All Invoices
        </Button>
        <Button
          size="sm"
          style={{ marginLeft: "15px" }}
          disabled={false}
          onClick={(e) => this.printDriversProductList(e)}
        >
          Print Driver Picking Lists
        </Button>
        <DropdownButton
          as={ButtonGroup}
          title="Print Delivery Notes"
          className="test-dropdown"
        >
          <Dropdown.Item
            eventKey="all"
            onClick={(e) => this.printNotes(e, "all")}
          >
            ALL
          </Dropdown.Item>
          <Dropdown.Divider />
          {orders &&
            orders.map((order) => (
              <Dropdown.Item
                eventKey={order.id}
                onClick={(e) => this.printNotes(e, order.id)}
              >
                {order.company}
              </Dropdown.Item>
            ))}
        </DropdownButton>
        <Button
          size="sm"
          style={{ marginLeft: "15px" }}
          onClick={(e) => this.printPage(e)}
        >
          Print Page
        </Button>
        {this.state.showDiscountModal && (
          <DiscountModal
            show={this.state.showDiscountModal}
            onClose={this.onApplyDiscountClose}
            data={this.state.selectedRow}
          />
        )}
      </>
    );
  }
}

function mapState(state) {
  const { place, session } = state;
  const {
    orders,
    orderDetails,
    paperWorksGenerated,
    total,
    roundUpdated,
    orderInvoice,
    roundInvoice,
    printNotes,
    printInvoice,
    printDriversList,
  } = place;
  return {
    session,
    place,
    orders,
    orderDetails,
    paperWorksGenerated,
    total,
    roundUpdated,
    orderInvoice,
    roundInvoice,
    printNotes,
    printInvoice,
    printDriversList,
  };
}

const actionCreators = {
  getOrders: placeActions.getOrderRecords,
  updateOrders: sessionActions.updateOrders,
  savePaperWorks: placeActions.generatePaperworks,
  getInvoice: placeActions.getInvoice,
  generateRoundInvoices: placeActions.generateRoundInvoices,
  generateInvoices: placeActions.generateInvoices,
  generateInvoice: placeActions.generateInvoice,
  emailInvoice: placeActions.emailInvoice,
  printNotesByRoundNo: placeActions.printNotesByRoundNo,
  printNotesByOrderId: placeActions.printNotesByOrderId,
  taggedAsPrinted: placeActions.taggedAsPrinted,
  printRoundInvoices: placeActions.printRoundInvoices,
  printOrderInvoice: placeActions.printOrderInvoice,
  printDriversProductList: placeActions.printDriversProductList,
  saveSorting: placeActions.saveSorting,
};

export default connect(mapState, actionCreators)(Accounts);
