import Error from '../_helpers/error';
import { sessionConstants } from "../_constants/session.constants";
import { processService } from "../_services/process.service";
import { checklistFormat, orderChecklistSession } from "../_helpers/sessionFormatter";
import { processConstants } from '../_constants/process.constants';
import { getOrderChecklistInStorageKey, getOrders, removeOrderChecklistInStorage, setChecklist, setOrderDetails, setOrders, getProducts as getSessionProducts, getSchedule as getSessionSchedules, getSchedule, setSchedule, setProducts, getAccounts, setAccounts, getStocks, setProductStocks } from '../_helpers/session';
import { placeConstants } from '../_constants/place.constants';
import { alertActions } from './alert.actions';
import { saveRoundDriversToStorage } from '../_helpers/placeOrder';

export const sessionActions = {
  updateChecklist,
  updateOrders,
  updateOrderDetails,
  getProducts,
  getSchedules,
  getSession,
  getCustomers,
  updateOrderRecords,
  getProductStocks,
  saveRoundState,
  getSupplierStocks
}

function updateChecklist () {
  const record = checklistFormat();

  if (record.data.length > 0) {
    return dispatch => {
      dispatch({ type: sessionConstants.API_POST_REQUEST_CHECKLISTS, record })

      const data = { data:record.data };
      const uri = 'place/process/'+processConstants.PROCESS_SAVE_CHECKLIST+'/item/all';
      processService.updateRecord(uri, data)
        .then (
          response => {
            if (response.data.status == 'success') {
              const responseData = response.data.data;
              delete responseData['status'];
              for (const customerId in responseData) {
                setChecklist(customerId, responseData[customerId])
              }
              
              dispatch({ type: sessionConstants.API_POST_SUCCESS_CHECKLISTS, data: response.data.data, payload: record });
            }
          }
        )
    }
  } else {
    return { type: sessionConstants.API_REQUEST_NONE, payload: record }
  }

  function failure (error, payload) { return { type: sessionConstants.API_POST_FAILURE_CHECKLISTS, error, payload } }
}

function updateOrders (id, field, localKey, value, roundNo) {

  const localValue = localStorage.getItem(localKey);
  //console.log([localValue, value])
  //if (localStorage.getItem(localKey) != value) {
    value = localValue;
  //} else {
  //  return;
 // }

  const data = { 
    id: id,
    [field]: value
  };

  return dispatch => {
    const uri = 'orders/'+id;

    processService.updateRecord(uri, { data: data })
      .then (
        response => {

          if (response.data.status == 'success') {
            // Check again if local value changes during update.
            // If no changes delete local record
            if (localStorage.getItem(localKey) == value) {
              localStorage.removeItem(localKey);

              let records = getOrders(roundNo);
              const recordIndex = records.findIndex((obj) => obj.id == response.data.data.orders.id);
              records[recordIndex] = response.data.data.orders;

              setOrders(roundNo, records);
            }
            dispatch({ type: sessionConstants.API_POST_SUCCESS_ORDERS, data: response.data.data, payload: data });
          }
        }
      )
  } 
}

function updateOrderDetails (id, customerId, roundNo, checklistId, orderId) {

  const records = orderChecklistSession(id, customerId, roundNo, checklistId);
  const payload = { records, id, customerId, roundNo, checklistId, orderId };

  if ( null === records.data.product_supplier_id || records.data.product_supplier_id == 'null')  {
    delete records.data.product_supplier_id;
  }
  
  return dispatch => {
    const uri = 'orders/detail/'+id;
    processService.updateRecord(uri, { data: records.data })
      .then (
        response => {
          if (response.data.status == 'success') {
            setOrderDetails(orderId, response.data.data);
            // Check again if local value changes during update.
            // If no changes delete local record
            for (const field in records.data) {
              if (field != 'id') {
                const localValue = getOrderChecklistInStorageKey(customerId, roundNo, checklistId, records.keysToUpdate[field]);

                if (localValue == records.data[field]) {
                  removeOrderChecklistInStorage(customerId, roundNo, checklistId, records.keysToUpdate[field]);
                  
                }
              }
            }
            dispatch({ type: sessionConstants.API_POST_SUCCESS_ORDER_DETAILS, data: response.data.data, payload: payload });
          }
        }
      )
  }
}

function getProducts () {
  const products = getSessionProducts();
  const uri = 'product/list';

  return dispatch => {
    dispatch({ type: sessionConstants.API_GET_REQUEST_PRODUCTS });

    if (products) {
      dispatch({ type: sessionConstants.GET_PRODUCTS_LOCAL, products })
    }

    processService.getFormById (uri)
      .then (
        response => {
          if (response.data.status === 'success') {
            const product = response.data.data;
            setProducts(product);
            dispatch({ type: sessionConstants.API_GET_SUCCESS_PRODUCTS, product })
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      )
  }

  function failure (error) { return { type: sessionConstants.API_GET_FAILURE_PRODUCTS, error }}

}

function getSchedules () {
  const schedules = getSessionSchedules();
  const slug = 'place';
  const method = processConstants.PROCESS_METHOD_SHOW;
  const uri = slug+'/process/'+processConstants.PROCESS_SHOW_SCHEDULES+'/item/all';

  return dispatch => {
    dispatch({ type: sessionConstants.API_GET_REQUEST_SCHEDULE });

    if (schedules) {
      dispatch({ type: sessionConstants.GET_SCHEDULE_LOCAL, schedules })
    }

    processService.getFormById (uri, method)
      .then (
        response => {
          if (response.data.status === 'success') {
            const schedule = response.data.data;
            setSchedule(schedule);
            dispatch({ type: sessionConstants.API_GET_SUCCESS_SCHEDULE, schedule })
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      )
  }

  function failure (error) { return { type: sessionConstants.API_GET_FAILURE_SCHEDULE, error }}

}

function getCustomers () {
  const customers = getAccounts();
  const method = processConstants.PROCESS_METHOD_SHOW;
  const uri = 'place/process/'+processConstants.PROCESS_SHOW_CUSTOMERS+'/item/all';
  
  return dispatch => {
    dispatch({ type: sessionConstants.API_GET_REQUEST_CUSTOMERS });

    if (customers) {
      dispatch({ type: sessionConstants.GET_CUSTOMERS_LOCAL, customers })
    }

    processService.getFormById (uri, method)
      .then (
        response => {
          if (response.data.status === 'success') {
            const customer = response.data.data;
            setAccounts(customer);
            dispatch({ type: sessionConstants.API_GET_SUCCESS_CUSTOMERS, customer })
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      )
  }

  function failure (error) { return { type: sessionConstants.API_GET_FAILURE_ROUND_DRIVERS, error }}
}

function getRoundDrivers () {

  const roundDrivers = JSON.parse(localStorage.getItem('__round_drivers'));
  const uri = 'place/process/'+processConstants.PROCESS_SHOW_ROUND_DRIVERS+'/item/all';
  const method = processConstants.PROCESS_METHOD_SHOW;
  
  return dispatch => {
    dispatch({ type: sessionConstants.API_GET_REQUEST_ROUND_DRIVERS });

    if (roundDrivers) {
      dispatch({ type: sessionConstants.GET_ROUND_DRIVERS_LOCAL, roundDrivers })
    }

    processService.getFormById (uri, method)
      .then (
        response => {
          if (response.data.status === 'success') {
            const roundDrivers = saveRoundDriversToStorage(response.data.data);
            dispatch ({ type: sessionConstants.API_GET_SUCCESS_ROUND_DRIVERS, roundDrivers })
          } else {
            const e = Error (response);
            dispatch (failure(e.system))
            dispatch (alertActions.error(e.message))
          }
        },
        error => {
          const e = Error (error);
          dispatch (failure(e.system))
          dispatch (alertActions.error(e.message))
        }
      )
  }

  function failure (error) { return { type: sessionConstants.API_GET_FAILURE_ROUND_DRIVERS, error }}
}

function updateOrderTotalAmounts (id, customerId, roundNo) {

  const totalAmount = localStorage.getItem('__round__total_payment__'+roundNo+'_'+customerId+'_totalAmount');
  const cashAmount = localStorage.getItem('__round__total_payment__'+roundNo+'_'+customerId+'_orderAmount');
  const invoiceAmount = localStorage.getItem('__round__total_payment__'+roundNo+'_'+customerId+'_invoiceOrderAmount');

  const data = {
    id: id,
    total_amount: totalAmount,
    cash_amount: cashAmount,
    invoice_amount: invoiceAmount
  };

  return dispatch => {
    const uri = 'orders/'+id;
    processService.updateRecord(uri, { data: data })
      .then (
        response => {
          if (response.data.status == 'success') {
            let records = getOrders(roundNo);
            const recordIndex = records.findIndex((obj) => obj.id == response.data.data.orders.id);
            records[recordIndex] = response.data.data.orders;
            setOrders(roundNo, records);
            dispatch({ type: sessionConstants.API_POST_SUCCESS_ORDERS, data: response.data.data, payload: data });
          }
        }
      )
  } 
}

function getProductStocks (ids) {
  const serializeIds = function(obj) {
    var str = [];

    obj.forEach((id) => {
      str.push('ids[]='+id)
    })  
    return str.join("&");
  }
  
  const uri = 'product/stocks?'+serializeIds(ids);

  return dispatch => {
    dispatch({ type: sessionConstants.API_GET_REQUEST_STOCKS });

    processService.getFormById (uri, '')
      .then (
        response => {
          if (response.data.status === 'success') {
            const data = response.data.data;

            for (var productId in data) {
              setProductStocks(productId, data[productId]);
            }
            dispatch({ type: sessionConstants.API_GET_SUCCESS_STOCKS, data: response.data.data })
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      )
  }

  function failure (error) { return { type: sessionConstants.API_GET_FAILURE_STOCKS, error }}
}

function getSession () {

  return dispatch => {
    Promise.all([
      dispatch(getCustomers()),
      dispatch(getSchedules()),
      dispatch(getRoundDrivers()),
      dispatch(getProducts())
    ])
  }
}

function updateOrderRecords (id, customerId, roundNo, checklistId, orderId) {
  return dispatch => {
    Promise.all([
      dispatch(updateOrderDetails(id, customerId, roundNo, checklistId, orderId)),
      // dispatch(updateOrderTotalAmounts(id, customerId, roundNo))
    ])
  }
}

function saveRoundState(state, props) {
  return dispatch => {
    dispatch({ type: sessionConstants.SAVE_ROUND_STATE, state: state, props: props });
  }
}

function getSupplierStocks() {
  const uri = 'supplier/stocks';

  return dispatch => {
    dispatch({ type: sessionConstants.API_GET_REQUEST_STOCKS });

    processService.getFormById (uri, '')
      .then (
        response => {
          if (response.data.status === 'success') {
            const data = response.data.data;

            for (var productId in data) {
              setProductStocks(productId, data[productId]);
            }
            dispatch({ type: sessionConstants.API_GET_SUCCESS_STOCKS, data: data })
          } else {
            const e = Error(response);
            dispatch(failure(e.system))
            dispatch(alertActions.error(e.message))
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system))
          dispatch(alertActions.error(e.message))
        }
      )
  }

  function failure (error) { return { type: sessionConstants.API_GET_FAILURE_STOCKS, error }}
}