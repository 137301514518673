import { checkoutConstants } from '../_constants/checkout.constants';
import { formatDeliveryDateForQuery } from '../_helpers/calendar';
import { checkoutService } from '../_services/checkout.service';
import { alertActions } from './alert.actions';
import Error from '../_helpers/error';

export const checkoutActions = {
  doCheckout
}

function doCheckout (data) {

  let orderDetails = refineOrderData(data.products);
  let splitOrders = {};
  let prepayment = data.totalAmount;

  if (data.paymentOption == 'split') {
    splitOrders.cash = data.inCashOrders;
    splitOrders.delivery = getOrdersForDelivery(orderDetails, data.inCashOrdersById);
    prepayment = data.prepayment;
  }

  const checkoutData = {
    customerId: data.customerId,
    orderDetails: orderDetails,
    splitOrders: splitOrders,
    currency: data.currency,
    subtotal: data.subtotal,
    vat: data.vatRate,
    totalAmount: data.totalAmount,
    deliveryDate: formatDeliveryDateForQuery(data.customerInfo.roundNo),
    roundId: data.customerInfo.roundId,
    driverId: data.customerInfo.driverId,
    roundNo: data.customerInfo.roundNo,
    paymentOption : data.paymentOption,
    prepayment: prepayment
  }

  return dispatch => {
    dispatch(request(checkoutData));

    checkoutService.doCheckout(checkoutData)
      .then(
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data));
            dispatch(alertActions.success('Record successfully saved'));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      );
  }

  function request(data) { return { type: checkoutConstants.API_CHECKOUT_REQUEST, data} }
  function success(order) { return { type: checkoutConstants.API_CHECKOUT_SUCCESS, order} }
  function failure(error) { return { type: checkoutConstants.API_CHECKOUT_FAILURE, error} }
}

function refineOrderData (products) {

  let orders = [];

  products.map(function(product) {
    const order = {
      id: product.data.product_id,
      code: product.data.code,
      quantity: product.quantity,
      currency: product.data.currency,
      price: product.data.price,
      discount_percentage: product.data.discount_percentage,
      discounted_price: product.data.discounted_price,
      vat_rate: product.data.vat_rate,
      subtotal: product.subtotal,
      total_amount: product.totalAmount
    };

    orders.push(order);
  })

  return orders;
}

function getOrdersForDelivery (orders, inCashOrders) {

  let forDelivery = [];

  orders.map(function(order) {

    if (inCashOrders.hasOwnProperty(order.id)) {
      const remainingQty = Number(order.quantity) - Number(order.quantity);

      if (remainingQty > 0) {
        const subtotal = parseFloat(order.discounted_price) * remainingQty;
        const totalAmount = subtotal; // add vat rate

        const tempOrder = order;
        tempOrder.quantity = remainingQty;
        tempOrder.subtotal = subtotal;
        tempOrder.totalAmount = totalAmount;

        forDelivery.push(tempOrder);
      }
    } else {
      forDelivery.push(order);
    }
  })

  return forDelivery;
}