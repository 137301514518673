import { navigateConstants } from '../_constants/navigate.constants';
import { history } from '../_helpers/history';

const slug = history.location.pathname.substring(1) || 'place';

export function nav (state = {slug}, action) {
    switch (action.type) {
      case navigateConstants.TO:
        return {
          slug: action.selected
        };
      case navigateConstants.SELECTED_NAVIGATION:
        return {
          slug,
          selectedNav: action.items.navigation,
          section: action.items.section,
          processes: action.items.processes
        }
      default:
        return state;
    }
}