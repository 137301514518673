import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { titleCase } from '../_helpers/content';
import { statusName } from '../_helpers/status';
import LinkTooltip from './LinkTooltip'; 
import CustomModal from '../components/Modal/CustomModal';
import { processActions } from '../_actions/process.actions';
import { alertActions } from "../_actions/alert.actions";
import { Link } from 'react-router-dom';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { history } from '../_helpers/history';
import { navigateActions } from '../_actions/navigate.actions';
import { orderActions } from '../_actions/order.actions';
import PrintSchedule from '../pages/schedule/PrintSchedule';
import { Col, Container, Row } from 'react-bootstrap';
import DynamicTop from './DynamicTop';
import { clearCurrentState, getSuppliersForSearch, setIsFormSubmit } from '../_helpers/session';
import { processConstants } from '../_constants/process.constants';
import EditProductModal from '../pages/product/EditProductModal';
import EditProductStocksModal from '../pages/product/stocks/EditProductStocksModal';

const { SearchBar } = Search;

class DynamicTable extends React.Component
{
  constructor (props) {
    super(props);

    this.state = {
      showModal:false,
      showCartModal: false,
      showProductStocksModal: false,
      modalContent: [],
      currencySymbol: '£',
      columns: [{dataField: '', text: ''}],
      items: [],
      hasProps: false,
      suppliers: [],
      categories: [],
      productSuppliersRecord: []
    }

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleShowCartModal = this.handleShowCartModal.bind(this);
    this.handleCloseCartModal = this.handleCloseCartModal.bind(this);

    this.handleProductStocksShowModal = this.handleProductStocksShowModal.bind(this);
    this.handleProductStocksCloseModal = this.handleProductStocksCloseModal.bind(this);

    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);

    if (history.location.pathname == '/product/list') {
      this.props.getSuppliersForSearch();
    }
  }

  handleShowModal = () => {this.setState({ showModal:true })};
  handleCloseModal = () => {this.setState({ showModal:false })};

  handleShowCartModal = () => {this.setState({ showCartModal: true })};
  handleCloseCartModal = () => {this.setState({ showCartModal: false })};

  handleProductStocksShowModal = () => { this.setState({ showProductStocksModal: true }) };
  handleProductStocksCloseModal = () => { this.setState({ showProductStocksModal: false }) };
  
  onAfterSaveCell = (oldValue, newValue, row, column) => {
    if (newValue) {
      this.props.updateRecord(row.id, processConstants.PROCESS_EDIT_PRODUCT, {id: row.id, suppliers: newValue});
    }
  }

  componentDidMount() {

    if (this.props.contents && this.props.contents.items) {
      const { contents, slug, buttons } = this.props; 
      const items = contents.items || {};
      const columns = [{dataField: '', text: '', editable: false}];
      
      if (items && Object.keys(items).length > 0) {

        items.map(function (value, index) {

          if (buttons && buttons.inline.length > 0) {
            let action = [];
            buttons.inline.map(function(element) {
              action.push(<LinkTooltip message={element.name} title={element.title} icon={element.icon} key={element.id} id={element.id} method={element.url} attributes={element.component_attributes} />);
            });
            value.action = action;
          }
  
          if ("status" in value) {
            value.status = statusName(slug, value.status);
          }
  
          if (buttons && buttons.table) {
            buttons.table.map(function (v, i) {
              const buttonType = v.button_type;
              if (buttonType.indexOf("link") > -1) {
                const linkColumn = buttonType.substring(18);
                value[linkColumn] = <span className="text-link" data-method={v.url} data-title={v.title} data-id={v.id} data-appendTitle={value[linkColumn]}>{value[linkColumn]}</span>
              }
            })
          }
        })
        
        if (buttons 
          && buttons.inline.length > 0 
          && columns.filter(v => v.dataField.toLowerCase().includes('action')).length == 0
        ) {
          columns.push({dataField: 'action', text: '', editable: false});
        }
        
        for (const header in items[0]) {
          if (header != 'action' && header != 'currency') {
            const text = titleCase(header.replace(/_/gi, " "));
            let property = {dataField: header, text: text, editable: false};

            if (header == 'price' || header.includes('amount') || header.includes('vat')) {
              property.text = text+' '+this.state.currencySymbol;
            }

            if (history.location.pathname == '/product/list') {
              if (header == 'suppliers_w_price') property.text = 'Suppliers (Cost Price '+this.state.currencySymbol+')';
              else if (header == 'company') {
                property.editable = (content, row, rowIndex, columnIndex) => ! content || content == undefined || content == "";
                property.editor = {
                  type: Type.SELECT,
                  getOptions: (setOptions, { row, column }) => {
                    return getSuppliersForSearch() || []
                  }
                }
              }
            }
            columns.push(property);
          }
        }
      }

      columns.shift();
      this.setState({ items: items, columns: columns });
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.contents && this.props.contents != prevProps.contents) {
      const { contents, slug, buttons } = this.props; 
      const items = contents.items || {};
      const columns = [{dataField: '', text: '', editable: false}];
      
      if (items && Object.keys(items).length > 0) {

        items.map(function (value, index) {

          if (buttons && buttons.inline.length > 0) {
            let action = [];
            buttons.inline.map(function(element) {
              action.push(<LinkTooltip message={element.name} title={element.title} icon={element.icon} key={element.id} id={element.id} method={element.url} attributes={element.component_attributes} />);
            });
            value.action = action;
          }
  
          if ("status" in value) {
            value.status = statusName(slug, value.status);
          }
  
          if (buttons && buttons.table) {
            buttons.table.map(function (v, i) {
              const buttonType = v.button_type;
              if (buttonType.indexOf("link") > -1) {
                const linkColumn = buttonType.substring(18);
                value[linkColumn] = <span className="text-link" data-method={v.url} data-title={v.title} data-id={v.id} data-appendTitle={value[linkColumn]}>{value[linkColumn]}</span>
              }
            })
          }
        })
        
        if (buttons 
          && buttons.inline.length > 0 
          && columns.filter(v => v.dataField.toLowerCase().includes('action')).length == 0
        ) {
          columns.push({dataField: 'action', text: '', editable: false});
        }
        
        for (const header in items[0]) {
          if (header != 'action' && header != 'currency') {
            const text = titleCase(header.replace(/_/gi, " "));
            let property = {dataField: header, text: text, sort: true, editable: false};

            if (header == 'price' || header.includes('amount') || header.includes('vat')) {
              property.text = text+' '+this.state.currencySymbol;
            }

            if (history.location.pathname == '/product/list') {
              if (header == 'suppliers_w_price') property.text = 'Suppliers (Cost Price '+this.state.currencySymbol+')';
              else if (header == 'company') {
                property.editable = (content, row, rowIndex, columnIndex) => ! content || content == undefined || content == "";
                property.editor = {
                  type: Type.SELECT,
                  getOptions: (setOptions, { row, column }) => {
                    return getSuppliersForSearch() || []
                  }
                }
              }
            }
            columns.push(property);
          }
        }
      }

      columns.shift();
      this.setState({ items: items, columns: columns });
    } else if (this.props.process && this.props.process != prevProps.process) {
      
      if (this.props.process.suppliers && this.props.process.suppliers != prevProps.process.suppliers) {
        this.setState({ suppliers: this.props.process.suppliers }, () => {
          if (this.state.categories.length > 0) {
            this.setState({ showModal: true });
          }
        });
      } else if (this.props.process.categories) {
        this.setState({ categories: this.props.process.categories }, () => {
          if (this.state.suppliers.length > 0) {
            this.setState({ showModal: true });
          }
        });
      } else if (this.props.process.productSuppliers && this.props.process.productSuppliers != prevProps.process.productSuppliers) {
        this.setState({ productSuppliersRecord: this.props.process.productSuppliers });
        this.handleProductStocksShowModal();
      } else if (this.props.process.productSuppliersUpdate) {
        this.handleProductStocksCloseModal();
      } else if (this.props.process.loading) {
        this.handleProductStocksCloseModal();
      }
    }
  }

  render () {
    const { contents, buttons } = this.props;
    const { columns, items } = this.state;
    
    if (items && Object.keys(items).length > 0) {

      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          let state = this.state;
          
          const method = e.target.dataset.method;
          const title = e.target.dataset.title;
          const processId = e.target.dataset.id;
          const appendTitle = e.target.dataset.appendTitle || '';

          let content = {
            dataset: {
              title: title,
              method: method,
              processId: processId,
              displayType: '',
              message: null
            },
            row
          };

          setIsFormSubmit(false);
          clearCurrentState();
          this.props.clearAlerts();
          this.setState({ hasProps: true });

          switch (method) {

            case 'disable': 

              const toDisableName = title.toLowerCase() == 'disable user' ? row.name+"'s account" : "this";

              content['dataset']['displayType'] = 'message';
              content['dataset']['message'] = 'Are you sure you want to disable '+toDisableName+'? This cannot be undone.';
              state['modalContent'] = content;

              this.handleShowModal();
            break;

            case 'destroy':

              let toDeleteName = title.toLowerCase() == 'delete product' ? 'Product ' : '';
              let toDeleteValue = title.toLowerCase() == 'delete product' ? row.description : ((row.name && row.name.toUpperCase()) || (row.company && row.company.toUpperCase()) || row.id);

              content['dataset']['displayType'] = 'message';
              content['dataset']['message'] = 'Are you sure you want to delete '+toDeleteName+toDeleteValue+'? This cannot be undone.';
              state['modalContent'] = content;

              this.handleShowModal();
            break;

            case 'edit':

              content['dataset']['displayType'] = 'edit';
              state['modalContent'] = content;

              if (content['dataset']['processId'] == processConstants.PROCESS_EDIT_PRODUCT) {
                this.props.getSupplierAndCategoriesOptions();
              } else {
                this.props.getFormById(row.id, processId, method);
                this.handleShowModal();
              }
            break;

            case 'show':
              content['dataset']['title'] = title + ' ' + (appendTitle ? appendTitle : (row.name || row.full_name || row.id));
              content['dataset']['displayType'] = 'info';
              state['modalContent'] = content;

              if (content['dataset']['processId'] == processConstants.PROCESS_EDIT_PRODUCT_STOCKS) {
                this.props.getProductSuppliersRecord(row.id);
              } else {
                this.props.getFormById(row.id, processId, method);
                this.handleShowModal();
              }
            break;

            case 'create':

              content['dataset']['displayType'] = 'form';
              content['dataset']['title'] = title;
              state['modalContent'] = content;
              this.props.getForm(processId, method);
              this.handleShowModal();
            break;

            case 'redirect':
              
              this.props.setCustomer(content);
              const redirect = JSON.parse(e.target.dataset.attrib).redirect;
              this.props.navigate(redirect.uri, redirect.key, redirect.parent_id);
              
            break;

            case 'print':
              state['modalContent'] = content;
              this.handleShowModal();
            break;

            default:

              if (history.location.pathname.substring(1) == 'customer/list') {
                content.dataset.title       = (row.company+' Information').toUpperCase();
                content.dataset.method      = processConstants.PROCESS_METHOD_EDIT;
                content.dataset.processId   = processConstants.PROCESS_UPDATE_CUSTOMER_INFO;
                content.dataset.displayType = 'edit';
                content.dataset.editable    = 'false';
                state['modalContent'] = content;

                this.props.getFormById(row.id, processConstants.PROCESS_UPDATE_CUSTOMER_INFO, processConstants.PROCESS_METHOD_EDIT);
                this.handleShowModal();
              }
              break;

          }
        }
      }

      const ShowModal = () => {
        const handleCloseModal = () => {
          let state = this.state;
          state['showModal'] = false;
          state['hasProps'] = false;
          this.handleCloseModal();

          setIsFormSubmit(false);
          clearCurrentState();
        };

        if (this.state.hasProps) {

          if (this.state.modalContent.dataset.method == 'print') {
            return <PrintSchedule 
              show={this.state.showModal}
              onHide={handleCloseModal}
              title= {this.state.modalContent.dataset.title}
              message={this.state.modalContent.dataset.message}
              handleClose={handleCloseModal}
              close="Close"
              save={this.state.modalContent.dataset.title}
              processId={this.state.modalContent.dataset.processId}
              method={this.state.modalContent.dataset.method}
              displayType={this.state.modalContent.dataset.displayType}
              record={this.state.modalContent.row}
            />
          } else if (this.state.modalContent.dataset.processId == processConstants.PROCESS_EDIT_PRODUCT) {
            return <EditProductModal 
              show={this.state.showModal}
              onHide={handleCloseModal}
              title= {this.state.modalContent.dataset.title}
              handleClose={handleCloseModal}
              close="Close"
              save={this.state.modalContent.dataset.title}
              displayType={this.state.modalContent.dataset.displayType}
              record={this.state.modalContent.row}
              suppliers={this.state.suppliers}
              categories={this.state.categories}
            />
          } 
      
          return <CustomModal 
              show={this.state.showModal}
              onHide={handleCloseModal}
              title= {this.state.modalContent.dataset.title}
              message={this.state.modalContent.dataset.message}
              handleClose={handleCloseModal}
              close="Close"
              save={this.state.modalContent.dataset.title}
              processId={this.state.modalContent.dataset.processId}
              method={this.state.modalContent.dataset.method}
              displayType={this.state.modalContent.dataset.displayType}
              record={this.state.modalContent.row}
              editable={this.state.modalContent.dataset.editable || null}
            />
        } else {
          return <></>;
        }
      }

      const ShowProductStocksModal = () => {
        const handleProductStocksCloseModal = () => {
          console.log('here');
          let state = this.state;
          state['showProductStocksModal'] = false;
          state['hasProps'] = false;
          this.handleProductStocksCloseModal();

          setIsFormSubmit(false);
          clearCurrentState();
        };

        return <EditProductStocksModal {...this.state} onClose={ handleProductStocksCloseModal }/>
      }

      const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
      }];

      return <div>
        {contents.loading && <em>Loading data...</em>}
        {contents.error && <span className="text-danger">ERROR: {contents.error}</span>}
        {items && <ToolkitProvider
            keyField="id"
            data={ items }
            columns={ columns }
            search
            bootstrap4
            defaultSorted={ defaultSorted }   
          >
            {
              props => (
                <Container>
                  <Row>
                    <Col sm="4" className="table-search">
                      <SearchBar { ...props.searchProps } />
                    </Col>
                    <Col sm="8">
                      <DynamicTop buttons={ buttons.top } />
                    </Col>
                  </Row>  
                  <BootstrapTable
                    { ...props.baseProps }
                    striped
                    rowEvents={ rowEvents }
                    headerClasses='bootstrap-table-header'
                    classes='bootstrap-table-data'
                    cellEdit={ cellEditFactory({
                      mode: 'dbclick',
                      blurToSave: true,
                      afterSaveCell: this.onAfterSaveCell
                    }) }
                  />
                </Container>
              )
            }
          </ToolkitProvider>
        }
        {this.state.showModal && <ShowModal />}
        {this.state.showProductStocksModal && <ShowProductStocksModal />}
      </div>
    }

    return <div>
      {contents.loading && <em>Loading data...</em>}
      {! contents.loading && <>
        <Container>
          <Row>
            <Col sm="4"></Col>
            <Col sm="8">
              <DynamicTop buttons={ buttons.top } />
            </Col>
          </Row> 
          <Row>
            <h6><i>No records found.</i></h6>
          </Row> 
        </Container>
      </>}
    </div>;
  }
}

function mapState(state) {
  const { contents, nav, process, formFields } = state;
  const { slug, processes } = nav;
  const { items } = process;
  return { state, contents, slug, processes, items, process, formFields };
}

const actionCreators = {
  getForm: processActions.getForm,
  getFormById: processActions.getFormById,
  deleteRecord: processActions.deleteRecord,
  clearAlerts: alertActions.clear,
  navigate: navigateActions.to,
  setCustomer: orderActions.setCustomer,
  getSupplierAndCategoriesOptions: processActions.getSupplierAndCategoriesOptions,
  getProductSuppliersRecord: processActions.getProductSuppliersRecord,
  getSuppliersForSearch: processActions.getSuppliersForSearch,
  updateRecord: processActions.updateRecord
}

export default connect(mapState, actionCreators)(DynamicTable);