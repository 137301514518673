const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const checklistFormat = () => {
  const localKeys = Object.keys(localStorage);
  let records = [], latestRound = 0, keysToDelete = [];

  localKeys.forEach(key => {
    
    if (key.includes('__round__checklist_') && (key.includes('discountPercentage') || key.includes('customerPrice') || key.includes('_status'))) {
      const mKey = key.substring(19)
      const keyArr = mKey.split('_');
      const field = camelToSnakeCase(keyArr[3]); // keyArr[3] == 'discountPercentage' ? 'discount_percentage' : 'customer_price';

      keysToDelete.push(key);

      if (latestRound == 0) {
        latestRound = keyArr[1];
      } else {
        if (Number(keyArr[1]) > Number(latestRound)) {
          latestRound = keyArr[1];
        }
      }

      if (latestRound == keyArr[1]) {
        if (! isNaN(keyArr[0])) {
          const recordsKey = keyArr[0]+'_'+keyArr[1]+'_'+keyArr[2];
          const rec = {
            reference: recordsKey,
            customer_id: keyArr[0],
            round_no: keyArr[1],
            id: keyArr[2],
            [field]: localStorage.getItem(key)
          }

          const exstRecord = records.find((obj) => obj.reference == recordsKey );
          if (exstRecord) {
            exstRecord[field] = localStorage.getItem(key);
            records = [...records.filter((obj) => obj.reference != recordsKey), exstRecord];          
          } else {
            records.push(rec);
          }
        }
      }

    }
  });

  return {
    data: records,
    keysToDelete: keysToDelete
  };
}

export const orderChecklistSession = (orderDetailsId, customerId, roundNo, checklistId) => {
  const localKeys = Object.keys(localStorage);
  const keyFormat = '__round__checklist_'+customerId+'_'+roundNo+'_'+checklistId+'_';
  let data = { id: orderDetailsId };
  let keysToUpdate = {};

  localKeys.forEach(key => {
    if (key.includes(keyFormat)) {
      const mKey = key.substring(19)
      const keyArr = mKey.split('_');
      
      let field = camelToSnakeCase(keyArr[3]);
      field = field == 'customer_price' ? 'discount_amount' : field;

      data[field] = localStorage.getItem(key)
      keysToUpdate[field] = keyArr[3];
    }
  })

  return {
    data: data,
    keysToUpdate: keysToUpdate
  }
}

export const removeChecklistSession = (orderDetailsId, customerId, roundNo, checklistId) => {
  const localKeys = Object.keys(localStorage);
  const keyFormat = '__round__checklist_'+customerId+'_'+roundNo+'_'+checklistId+'_';
  let data = { id: orderDetailsId };
  let keysToUpdate = {};

  localKeys.forEach(key => {
    if (key.includes(keyFormat)) {
      localStorage.removeItem(key);
      console.log(key);
    }
  })

  return {
    data: data,
    keysToUpdate: keysToUpdate
  }
} 