import { parseISO } from "date-fns";
import React from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { placeActions } from "../../../../_actions/place.actions";
import { formatDeliveryDate, getRoundIdByDate, getRoundIdByRoundNo } from "../../../../_helpers/calendar";
import { beautifyDate, getActiveRoundWeekByDate, getActiveRoundWeekBySelected, getRoundDefaultActiveKey, roundDays } from "../../../../_helpers/placeOrder";
import { getAccounts, getRoundSelected } from "../../../../_helpers/session";
import DriversTab from "./DriversTab";

class RoundsTab extends React.Component
{
  constructor (props) {
    super (props);

    let activeKey = getRoundDefaultActiveKey( new Date() );
    let selectedDate = new Date();

    if (this.props.extra && this.props.extra.activeRound) {
      selectedDate = new Date(formatDeliveryDate(this.props.extra.activeRound));
      activeKey = getRoundDefaultActiveKey(selectedDate);
    }

    this.state = {
      activeKey: activeKey,
      activeDates: getActiveRoundWeekBySelected('present').dates,
      selectedDate: selectedDate,
      hasCustomers: getAccounts() ? true : false,
      roundNoSearch: ''
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.extra && this.props.extra !== prevProps.extra && this.props.extra.activeRound) {
      this.props.isValidRoundNo(this.props.extra.activeRound);
    }

    if (this.props.customers 
      && this.props.customers != prevProps.customers 
      && this.state.hasCustomers == false
    ) {
      this.setState({ hasCustomers: true });
    }

    if (this.props.place && this.props.place != prevProps.place && this.props.place.roundNo) {
      Array.from(document.querySelectorAll("input")).forEach(
        input => {
          if (input.name == 'round_no') {
            return input.value = ""
          }
      });
      
      if (this.props.place.isValid == true) {
        const date = new Date(formatDeliveryDate(this.props.place.roundNo));
        const activeKey = getRoundDefaultActiveKey(date);
  
        this.setState({
          activeKey: activeKey,
          selectedDate: date,
          roundNoSearch: ''
        })
      } else {
        this.setState({ 
          activeKey: prevState.activeKey,
          activeDates: prevState.activeDates,
          selectedDate: prevState.selectedDate,
          hasCustomers: prevState.hasCustomers,
          roundNoSearch: '' 
        })  
      }
    }
  }

  handleDateChange = (date) => {
    const activeDates = getActiveRoundWeekByDate(date);
    this.setState({ 
      activeDates: activeDates.dates,
      activeKey: activeDates.defaultDay,
      selectedDate: activeDates.selectedDate
    })
  }

  handleWeekSelectedChange = (selected) => {
    const activeDates = getActiveRoundWeekBySelected(selected);

    this.setState({ 
      activeDates: activeDates.dates,
      activeKey: activeDates.defaultDay,
      selectedDate: activeDates.selectedDate
    })
  }
  
  handleDaySelectedChange = (key) => {
    const selectedDate = roundDays.find((obj) => obj.id == key);

    this.setState({
      activeKey: key,
      selectedDate: selectedDate && selectedDate.day ? new Date(this.state.activeDates[selectedDate.day.toLowerCase()]) : this.state.selectedDate
    })
  }

  handleRoundSearchOnKeyUp = (e) => {
    this.setState({ roundNoSearch: e.target.value });
  }

  handleRoundSearchOnClick = (e) => {
    this.props.isValidRoundNo(this.state.roundNoSearch);
  }

  render () {
    const { activeKey, activeDates, selectedDate, hasCustomers } = this.state;

    if (hasCustomers) {
      return <>
        <Container className="container-tab">
          <Row>
            <Col xs="6"><i>Round week: { beautifyDate(activeDates.sunday) } - { beautifyDate(activeDates.saturday) }</i></Col>
            <Col xs="2">Choose round date:</Col>
            <Col xs="2">
              <ReactDatePicker 
                dateFormat={"dd/MMM/yyyy"}
                selected={ selectedDate }
                onChange={ (date) => this.handleDateChange(date) }
                placeholderText="Pick a date"
                closeOnScroll={ true }
                shouldCloseOnSelect={ true }
              >
                <Container >
                  <Row className="container-date-picker text-link" onClick={ (e) => this.handleWeekSelectedChange('past') }>Last Week</Row>
                  <Row className="container-date-picker text-link" onClick={ (e) => this.handleWeekSelectedChange('present') }>This Week</Row>
                  <Row className="container-date-picker text-link" onClick={ (e) => this.handleWeekSelectedChange('future') }>Next Week</Row>
                </Container>
              </ReactDatePicker>
            </Col>
            <Col xs="2">
              <Form.Group as={Row}>
                <Col sm="10" style={{ paddingRight: "0" }}>
                  <Form.Control 
                    type="input"
                    name="round_no"
                    placeholder="Search by Round No."
                    defaultValue={ this.state.roundNoSearch }
                    onKeyUp={ (e) => this.handleRoundSearchOnKeyUp(e) }
                  />
                </Col>
                <Col sm="1">
                  <Button variant="primary" size="sm" onClick={ (e) => this.handleRoundSearchOnClick(e) } >
                    <i  className="fas fa-search icon" />
                  </Button>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Tabs
            id="roundDays"
            defaultActiveKey={ activeKey }
            activeKey={ activeKey }
            onSelect={ (key) => this.handleDaySelectedChange(key) }
          >
            { roundDays.map((round) => {
              const selectedDriver = this.props.place && this.props.place.roundNo && this.props.place.roundNo.length > 6 ? this.props.place.roundNo.substring(6) : '';
              let selectedRound = round;

              if (selectedDriver != '') {
                selectedRound = roundDays.filter((obj) => obj.id == getRoundIdByRoundNo(this.props.place.roundNo))[0] || round;
              }
              
              return <Tab eventKey={ round.id } title={ round.day } key={ round.id+selectedDate }>
                { activeKey == round.id && <DriversTab 
                  round={ selectedRound }
                  selectedDate={ selectedDate }  
                  onRowClick={ this.props.onRowClick }
                  isValid={ this.props.place && this.props.place.isValid ? this.props.place.isValid : false }
                  selectedDriver={ this.props.place && this.props.place.roundNo && this.props.place.roundNo.length > 6 ? this.props.place.roundNo.substring(6) : '' }
                />}
              </Tab>
            })}
          </Tabs>
        </Container>
      </>;
    } 
    return <>Loading..</>
  }
}

function mapState (state) {
  const { session, place } = state;
  const { customers } = session;
  return { customers, place };
}

const actionCreators = {
  getOrders: placeActions.getOrderRecords,
  isValidRoundNo: placeActions.isValidRoundNo
}

export default connect(mapState, actionCreators)(RoundsTab);