import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { processActions } from '../../_actions/process.actions';
import { processConstants } from '../../_constants/process.constants';
import AddNewProductForm from './AddNewProductForm';

class AddNewProductModal extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      suppliers: this.props.suppliers,
      categories: this.props.categories
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.process && this.props.process != prevProps.process) {
      if (this.props.process.saveForm && this.props.process.saveForm != prevProps.process.saveForm) {
        this.props.handleClose();
      }
    } else if (this.props.alert && this.props.alert.type == 'alert-success') {
      this.props.handleClose();
    }
  }

  handleOnChange = (name, value) => {
    let state = this.state;
    state['data'][name] = value;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.submitForm(processConstants.PROCESS_ADD_PRODUCT, this.state.data);
  }

  render() {
    const { alert } = this.props;
    return <>
      { !this.props.process.loading && ! this.state.suppliers && ! this.state.categories && <Loader
        className="loader"
        type="Oval"
        color="#db3d44"
        height={100}
        width={100}
      /> }
      { this.state.suppliers.length > 0 && this.state.categories.length > 0 && this.props.alert.type != 'alert-success' && <Modal 
        show={ this.props.show } 
        onHide={ this.props.onHide } 
        backdrop="static" 
        keyboard={false} 
        centered 
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        dialogClassName="modal-90w"
      >
      <Modal.Header closeButton>
        <Modal.Title><h6>{this.props.title}</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { alert.message && <Alert variant={alert.type.replace("alert-", "")}>
          {alert.message}
          {alert.fields && alert.fields.length > 0 && <ul>
            {alert.fields.map((field) => {
              return <li>{field}</li>;
            })}
          </ul>}
        </Alert> }
        <AddNewProductForm onChange={ this.handleOnChange } isModal={true} suppliers={this.state.suppliers} categories={this.state.categories} />
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={ this.props.handleClose }>Close</Button>
          <Button variant="primary" onClick={(e) => this.handleSubmit(e)}>Save</Button>
        </Modal.Footer>
    </Modal> }
    </>;
  }
}

function mapState (state) {
  const { process, alert } = state;
  return { process, alert };
}

const actionCreators = {
  submitForm: processActions.submitForm
}

export default connect(mapState, actionCreators)(AddNewProductModal);