import { searchConstants } from '../_constants/search.constants';
import { searchService } from '../_services/search.service';
import { alertActions } from './alert.actions';
import { history } from '../_helpers/history';
import Error from '../_helpers/error';
import { processConstants } from '../_constants/process.constants';

export const searchActions = {
  searchByKeyword,
  searchById,
  searchAll,
  searchByIdWithSchedule,
}

function searchByKeyword(keyword, processId, payload) {

  const slug = history.location.pathname.substring(1);
  const search = {keyword, processId, slug, payload};

  return  dispatch => {
    dispatch(request(search));

    searchService.searchByKeyword(keyword, slug, processId)
      .then (
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data, keyword, processId, payload));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      );
  };

  function request(search) { return { type: searchConstants.API_GET_REQUEST_SEARCH, search } }
  function success(contents, keyword, processId, payload) { return { type: searchConstants.API_GET_SUCCESS_SEARCH, contents, keyword, processId, payload } }
  function failure(error) { return { type: searchConstants.API_GET_FAILURE_SEARCH, error } }
}

function searchById (id, processId, payload) {
  const slug = history.location.pathname.substring(1);
  const search = {slug, id, processId, payload};
  let uri = slug+'/process/'+processId+'/item/'+id;

  return dispatch => {
    dispatch(request(search))

    searchService.searchByKeyword(id, slug, processId)
      .then (
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data, id, processId, payload));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      )
  }

  function request(search) { return { type: searchConstants.API_GET_REQUEST_SEARCH, search } }
  function success(contents, keyword, processId, payload) { return { type: searchConstants.API_GET_SUCCESS_SEARCH, contents, keyword, processId, payload } }
  function failure(error) { return { type: searchConstants.API_GET_FAILURE_SEARCH, error } }
}

function searchAll (processId) {

  const key = '__search__'+processId;
  const localSearch = JSON.parse(localStorage.getItem(key));
  const slug = history.location.pathname.substring(1);

  return  dispatch => {
    dispatch(request(processId))

    if (localSearch) {
      dispatch(success (localSearch, processId));
    }

    searchService.searchAll(slug, processId)
      .then (
        response => {
          if (response.data.status == 'success') {
            localStorage.setItem(key, JSON.stringify(response.data.data));
            dispatch(success(response.data.data, processId));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      );
  };

  function request(processId) { return { type: searchConstants.API_GET_REQUEST_SEARCH_ALL, processId } }
  function success(list, processId) { return { type: searchConstants.API_GET_SUCCESS_SEARCH_ALL, list, processId } }
  function failure(error) { return { type: searchConstants.API_GET_FAILURE_SEARCH_ALL, error } }
}

function searchByIdWithSchedule (id, processId, driverId, roundId, roundNo) {
  const payload = { id, processId, driverId, roundId, roundNo };

  return searchById (id, processId, payload);
}