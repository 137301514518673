import React from 'react';
import { Table } from 'react-bootstrap';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

class TableComponent extends React.Component
{
  render() {
    return <Table hover size="sm">
      <TableHeader columns={ this.props.columns } />
      <TableBody 
        columns={ this.props.columns } 
        data={ this.props.data || [] } 
        noRecord={ this.props.noRecord }  
        trFn={ this.props.trFn } 
        onKeyUp={ this.props.onKeyUp }
        onDelete={ this.props.onDelete }
      />
      { this.props.extra && <TableBody 
        extra={ this.props.extra }
      />}
    </Table>
  }
}

export default TableComponent;