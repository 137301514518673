import { modalConstants } from "../_constants/modal.constants";

export function modal (state = {}, action) {
  switch (action.type) {
    case modalConstants.API_GET_REQUEST_ITEMS:
      return {
        loading: true
      }
    case modalConstants.API_GET_SUCCESS_ITEMS:
      return {
        loading: false,
        items: action.data
      }
    case modalConstants.API_POST_FAILURE_ITEMS:
      return {
        loading: false
      }
    case modalConstants.API_CLEAR_ITEMS:
      return {}
    case modalConstants.API_POST_REQUEST_ITEMS:
      return {
        loading: true,
        saved: false
      }
    case modalConstants.API_POST_SUCCESS_ITEMS:
      return {
        loading: false,
        items: action.data,
        saved: true
      }
    case modalConstants.API_GET_FAILURE_ITEMS:
      return {
        loading: false, 
        items: action.data,
        saved: false
      }
    default:
      return state;
  }
}
