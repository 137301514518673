import { navigateConstants } from '../_constants/navigate.constants';
import { history } from '../_helpers/history';

export const navigateActions = {
  to,
  getSelectedNav,
  getSelectedNavByUri
};

function to (selected, key, parentId) {
  return dispatch => {
    dispatch({ type: navigateConstants.TO, selected });
    history.push({
      pathname: "/"+selected,
      state: { 
        key: key, 
        parentId: parentId, 
        slug: selected
      }
    });
  }
}

function getSelectedNav (navId, parentId, navigations) {
  let navigation = [], section = [], processes = [];
  navigations.forEach(function(nav, index) {
    if (parentId && nav.children && nav.id == parentId) {
      nav.children.forEach(function(child, i) {
        if (navId == child.id) {
          navigation = child;

          if (child.sections) {
            section = child.sections[0];

            if (child.sections[0]) {
              if (child.sections[0].processes) {
                processes = child.sections[0].processes;
              }
            }
              
          }
          return;
        }
      });
    } else {
      if (nav.id == navId) {
        navigation = nav;
        
        if (nav.sections) {
          section = nav.sections[0];

          if (nav.sections[0]) {
            if (nav.sections[0].processes) {
              processes = nav.sections[0].processes;
            }
          }
        }
        return;
      }
    }
  });

  const items = {navigation, section, processes};
  return { type: navigateConstants.SELECTED_NAVIGATION, items};
  return dispatch => { dispatch({ type: navigateConstants.SELECTED_NAVIGATION, items}) };
}

function getSelectedNavByUri (navigation) {
  const slug = history.location.pathname.substring(1);

  let dispatch = {};
  navigation.forEach(function (nav, index) {
    if (slug == nav.slug) {
      dispatch = getSelectedNav(nav.id, null, navigation);
      return;
    }

    if (nav.children) {
      nav.children.forEach(function (child, i) {
        if (slug == child.slug) {
          dispatch = getSelectedNav(child.id, nav.id, navigation);
          return;
        }
      })
    }
  });

  return dispatch;
}