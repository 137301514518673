export const contentConstants = {
  API_GET_REQUEST: 'CONTENT_API_GET_REQUEST',
  API_GET_SUCCESS: 'CONTENT_API_GET_SUCCESS',
  API_GET_FAILURE: 'CONTENT_API_GET_FAILURE',

  API_GET_FORM_FIELDS_REQUEST: 'API_GET_FORM_FIELDS_REQUEST',
  API_GET_FORM_FIELDS_SUCCESS: 'API_GET_FORM_FIELDS_SUCCESS',
  API_GET_FORM_FIELDS_FAILURE: 'API_GET_FORM_FIELDS_FAILURE',

  CONTENT_GET_PROCESS_POSITION: 'CONTENT_GET_PROCESS_POSITION',
};