import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { Provider } from "react-redux";
import store from "./_helpers/store";
import App from './App';
import ReduxToastr from 'react-redux-toastr';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
      <App />
      <ReduxToastr 
        preventDuplicates
        position="top-center"
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        getState={(state) => state.toastr} // This is the default
      />
  </Provider>,
  rootElement
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
