export const processConstants = {
  API_GET_REQUEST: 'PROCESS_API_GET_REQUEST',
  API_GET_SUCCESS: 'PROCESS_API_GET_SUCCESS',
  API_GET_FAILURE: 'PROCESS_API_GET_FAILURE',

  API_GET_REQUEST_SUPPLIERS: 'PROCESS_API_GET_REQUEST_SUPPLIERS',
  API_GET_SUCCESS_SUPPLIERS: 'PROCESS_API_GET_SUCCESS_SUPPLIERS',
  API_GET_FAILURE_SUPPLIERS: 'PROCESS_API_GET_FAILURE_SUPPLIERS',

  API_GET_REQUEST_PRODUCTS: 'PROCESS_API_GET_REQUEST_PRODUCTS',
  API_GET_SUCCESS_PRODUCTS: 'PROCESS_API_GET_SUCCESS_PRODUCTS',
  API_GET_FAILURE_PRODUCTS: 'PROCESS_API_GET_FAILURE_PRODUCTS',

  API_POST_REQUEST_ROUND_SCHEDULE: 'API_POST_REQUEST_ROUND_SCHEDULE',
  API_POST_SUCCESS_ROUND_SCHEDULE: 'API_POST_SUCCESS_ROUND_SCHEDULE',
  API_POST_FAILURE_ROUND_SCHEDULE: 'API_POST_FAILURE_ROUND_SCHEDULE',

  API_POST_REQUEST_DRIVER_ROUNDS: 'API_POST_REQUEST_DRIVER_ROUNDS',
  API_POST_SUCCESS_DRIVER_ROUNDS: 'API_POST_SUCCESS_DRIVER_ROUNDS',
  API_POST_FAILURE_DRIVER_ROUNDS: 'API_POST_FAILURE_DRIVER_ROUNDS',

  API_POST_REQUEST_CUSTOMER_CHECKLIST: 'API_POST_REQUEST_CUSTOMER_CHECKLIST',
  API_POST_SUCCESS_CUSTOMER_CHECKLIST: 'API_POST_SUCCESS_CUSTOMER_CHECKLIST',
  API_POST_FAILURE_CUSTOMER_CHECKLIST: 'API_POST_FAILURE_CUSTOMER_CHECKLIST',

  API_POST_SUCCESS_DRIVER_INFO: 'API_POST_SUCCESS_DRIVER_INFO',
  API_POST_SUCCESS_PO_FORM: 'API_POST_SUCCESS_PO_FORM',
  API_POST_SUCCESS_PO: 'API_POST_SUCCESS_PO',
  API_POST_SUCCESS_PRODUCT_STOCKS: 'API_POST_SUCCESS_PRODUCT_STOCKS',
  API_POST_SUCCESS_ORDER_NOTES: 'API_POST_SUCCESS_ORDER_NOTES',

  API_GET_SUCCESS_PO_FORM: 'API_GET_SUCCESS_PO_FORM', 
  API_GET_SUCCESS_PO_ALL: 'API_GET_SUCCESS_PO_ALL',
  API_GET_SUCCESS_PO_DETAILS: 'API_GET_SUCCESS_PO_DETAILS',
  API_GET_SUCCESS_SUPPLIER_LIST_SELECT: 'API_GET_SUCCESS_SUPPLIER_LIST_SELECT',
  API_GET_SUCCESS_CATEGORY_LIST_SELECT: 'API_GET_SUCCESS_CATEGORY_LIST_SELECT',
  API_GET_SUCCESS_PRODUCT_LIST_SELECT: 'API_GET_SUCCESS_PRODUCT_LIST_SELECT',
  API_GET_SUCCESS_PRODUCT_STOCKS: 'API_GET_SUCCESS_PRODUCT_STOCKS',

  API_PUT_SUCCESS_PO_FORM: 'API_PUT_SUCCESS_PO_FORM',

  API_POST_REQUEST: 'API_POST_REQUEST',
  API_POST_SUCCESS: 'API_POST_SUCCESS',
  API_POST_FAILURE: 'API_POST_FAILURE',

  API_PUT_REQUEST: 'API_PUT_REQUEST',
  API_PUT_SUCCESS: 'API_PUT_SUCCESS',
  API_PUT_FAILURE: 'API_PUT_FAILURE',

  CLEAR_FORM: 'CLEAR_FORM',
  NO_ACTION: 'NO_ACTION',
  CLEAR_SUPPLIERS: 'CLEAR_SUPPLIERS',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',

  EXTRA: 'EXTRA',

  PROCESS_UPDATE_CUSTOMER_INFO:27,
  PROCESS_ADD_PRODUCT_MODAL:35,
  PROCESS_ADD_PRODUCT:38,
  PROCESS_EDIT_PRODUCT_STOCKS:40,
  PROCESS_EDIT_PRODUCT:41,
  PROCESS_ADD_PRODUCT_STOCKS:45,
  PROCESS_MAKE_A_PURCHASE:46,
  PROCESS_IMPORT_CHECKLIST:47,
  PROCESS_SEARCH_CUSTOMER:51,
  PROCESS_ADD_CUSTOMER_TO_ROUND:57,
  PROCESS_SHOW_ORDER_DETAILS:62,
  PROCESS_SHOW_DRIVER_SELECT:64,
  PROCESS_ADD_PRODUCT_ON_CHECKLIST:65,
  PROCESS_SAVE_CHECKLISTS:66,
  PROCESS_SHOW_ROUND_DRIVERS:68,
  PROCESS_SHOW_CUSTOMERS:69,
  PROCESS_SHOW_SCHEDULES:70,
  PROCESS_SHOW_CHECKLIST:71,
  PROCESS_SAVE_CHECKLIST:72,
  PROCESS_SHOW_PRODUCTS_OF_SUPPLIER:73,
  PROCESS_SEARCH_CUSTOMER:74,
  PROCESS_GET_SESSION:75,
  PROCESS_SAVE_ROUND_STATUSES:76,
  PROCESS_SEARCH_SUPPLIER:79,
  PROCESS_SEARCH_SUPPLIER_2:80,
  PROCESS_SUPPLIER_PRICE_IMPORT:81,
  PROCESS_PRODUCT_BULK_UPDATE:82,

  PROCESS_METHOD_CREATE:'create',
  PROCESS_METHOD_SHOW:'show',
  PROCESS_METHOD_EDIT:'edit'
};