import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { processActions } from '../../../_actions/process.actions';
import EditProductStocks from './EditProductStocks';

class EditProductStocksModal extends React.Component
{
  state = {
    costPrice: []
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    let hasError = false;
    let errors = '';

    if (this.state.costPrice && this.state.costPrice.length == 0) {
      hasError = true;
      errors = errors + "<br />" + 'No changes to update';
    } else {
      this.state.costPrice.forEach((item) => {
        const currCostPrice = this.props.productSuppliersRecord.find((obj) => obj.id == item.id);
        if (isNaN(item.cost_price) || (! isNaN(item.cost_price) && Number(item.cost_price) <= 0) && Number(item.cost_price) != Number(currCostPrice.cost_price)) {
          hasError = true;
          errors = errors + "<br />" + 'Invalid cost price set for '+item.company;
        }
      })
    }

    if (! hasError) {
      this.props.updateCostPrice(this.props.modalContent.row.id, this.state.costPrice)
    } else {
      alert(errors);
    }
  }

  handleChange = (costPrice) => {
    this.setState({ costPrice: [...this.state.costPrice.filter((obj) => obj.id != costPrice.id), costPrice]})
  }

  render() {
    const record = this.props.modalContent.row;
    return <>
      <Modal
        show={ this.props.showProductStocksModal }
        onHide={ this.props.onClose }
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title><h6>Suppliers for Product ID # { record.id}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <i>Code: <b>{record.code}</b></i><br />
          <i>Description: <b>{record.description}</b></i><br />
          <i>Selling Price <b>{ this.props.currencySymbol }: {record.price}</b></i>
          <br />
          <br/>
          <EditProductStocks data={ this.props.productSuppliersRecord } onChange={ (value) => this.handleChange(value) } />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ this.props.onClose }>Close</Button>
          <Button variant="primary" onClick={ this.handleSubmit } disabled={ this.state.costPrice.length > 0 ? false : true }>Update</Button>
        </Modal.Footer>
      </Modal>
    </>;
  }
}

const actionCreators = {
  updateCostPrice: processActions.updateCostPrice
}

export default connect(null, actionCreators)(EditProductStocksModal);