import { contentConstants } from '../_constants/content.constants';
import { searchConstants } from '../_constants/search.constants';

export function contents(state = {}, action) {
  switch (action.type) {
    case contentConstants.API_GET_REQUEST:
      return {
        loading: true
      };
    case searchConstants.API_GET_SUCCESS_SEARCH:
      return {
        items: action.contents,
        from: 'search',
        keyword: action.keyword,
        processId: action.processId,
        payload: action.payload
      }
    case contentConstants.API_GET_SUCCESS:
      return {
        loading: false,
        items: action.contents,
        from: 'main'
      };
    case contentConstants.API_GET_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    case contentConstants.CONTENT_GET_PROCESS_POSITION:
      return {
        position: action
      };
    default:
      return state;
  }
}

export function formFields(state = {}, action) {
  switch (action.type) {
    case contentConstants.API_GET_FORM_FIELDS_REQUEST:
      return {
        loading: true
      };
    case contentConstants.API_GET_FORM_FIELDS_SUCCESS:
      return {
        loading: false,
        items: action.formFields[0].form_fields
      }
    case contentConstants.API_GET_FORM_FIELDS_FAILURE:
      return {
        loading: false,
        items: action.error
      }
    default:
      return state;
  }
}