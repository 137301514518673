import React from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatDateForPrinting, generateRoundNo, getRoundIdByDate } from '../../_helpers/calendar';
import { orderActions } from '../../_actions/order.actions';
import BootstrapTable from 'react-bootstrap-table-next';
import { orderAccountsColumns } from '../../_helpers/table';
import ReactToPrint from "react-to-print";
import ReactDatePicker from 'react-datepicker';

const orderDetailsColumns = [
  { dataField: 'code', text: 'Product Code' },
  { dataField: 'quantity', text: 'Quantity' },
  { 
    dataField: 'price', 
    text: 'Price', 
    formatter: (cell, row) => {
      return row.currency+" "+cell;
    }
  },
  {
    dataField: 'subtotal', 
    text: 'Subtotal', 
    formatter: (cell, row) => {
      return row.currency+" "+cell;
    }
  }
];

class PrintSchedule extends React.Component
{
  constructor () {
    super ();

    this.state = {
      selectedDate: '',
      orders: []
    }

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateSelect = (date) => { this.setState({ selectedDate: date }) };
  handleDateChange = (date) => {
    this.handleDateSelect(date);

    const { record } = this.props;
    const driverId = record.id;
    const roundId = getRoundIdByDate(date);
    const roundNo = generateRoundNo(driverId, date);

    this.props.setDeliveryDate(date);
    this.props.getRoundOrdersByDriver(driverId, roundId, roundNo);
  }

  componentWillMount () {
    const { date } = this.props;
    this.handleDateSelect(date ? new Date(date) : '');
  }

  render () {

    const { title, orders, record, loading } = this.props;
    const { selectedDate } = this.state;

    return <Modal
      show={this.props.show}
      onHide={this.props.onHide}
      backdrop="static"
      keyboard={false}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title><h6>{title+record.name}</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col sm="2">
              Select schedule:
            </Col>
            <Col sm="4">
              <ReactDatePicker 
                selected={this.state.selectedDate}
                onChange={(date) => this.handleDateChange(date)}
                placeholderText="Pick a date"
              />
            </Col>
            <Col sm="3"></Col>
            {orders && <Col sm="3">
              <ReactToPrint
                trigger={() => <span className="float-right text-link"><i className="fa fa-fw fa-print icon" /> Print schedule</span>}
                content={() => this.componentRef }
              />
            </Col>}
          </Row>
        </Container>
        {loading && <em>Loading data...</em>}
        {orders && <div ref={el => this.componentRef = el}>
          <hr />
          <Container className="container-modal page-break">
            <Row>
              <b>Date:</b>&nbsp;
              {formatDateForPrinting(selectedDate)}
              <Col>&nbsp;</Col>
              <b>Round: </b>&nbsp;
              {generateRoundNo(record.id, selectedDate)+" - "+record.name}
              <Col>&nbsp;</Col>
            </Row>
            <Row><br /></Row>
            <Row  className="page-break">
              <BootstrapTable 
                keyField="id"
                data={orders}
                columns={orderAccountsColumns()}
              />
              <Table bordered>
                <thead align="center">
                  <tr>
                    <th colSpan="2">Returns</th>
                  </tr>
                  <tr>
                    <th style={{ width: '50%' }}>Shop Name</th>
                    <th>Product</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Container>
          {orders.map((order) => {
            const orderDetails = JSON.parse(order.order_details);

            return <Container className="container-modal page-break">
              <hr />
              <Row>
                <Col>Company:</Col>
                <Col>{ order.company }</Col>
                <Col>Contact Person:</Col>
                <Col>{ order.last_name+", "+order.first_name }</Col>
              </Row>
              <Row>
                <Col>Address:</Col>
                <Col>{ order.address_line_1+" "+order.address_line_2+" "+order.address_line_3 }</Col>
                <Col>Contact Nos.:</Col>
                <Col>{ order.phone_no_1+", "+order.mobile_no_1}</Col>
              </Row>
              <BootstrapTable 
                keyField="id"
                data={ orderDetails }
                columns={ orderDetailsColumns }
              />
            </Container>;
          })}
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>;
  }
}

function mapState(state) {
  const { order } = state;
  const { orders, date, loading } = order;
  return { orders, date, loading };
}

const actionCreators = {
  setDeliveryDate: orderActions.setDeliveryDate,
  getRoundOrdersByDriver: orderActions.getRoundOrdersByDriver
}

export default connect(mapState, actionCreators)(PrintSchedule);