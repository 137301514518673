import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SideNavigation from '../navigation/SideNavigation';
import { userActions } from '../_actions/user.actions';


class Header extends React.Component 
{
  handleLogoutOnClick = (e) => {
    this.props.logout();
  }

  render () {
    return <>
      <div className="header">
        <Container className="no-gutters">
          <Row>
            <Col xs="9"><h5>Wholesale CRM Solution</h5></Col>
            {this.props.user && this.props.user.name &&
              <Col xs="3" className="welcome">Welcome <b>{this.props.user.name}</b> | <Link to="/login" onClick={ (e) => this.handleLogoutOnClick(e) }>Logout</Link></Col>
            }
          </Row>
        </Container>
        
      </div>
      {this.props.user && this.props.user.name && <SideNavigation />}
    </>;
  }
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {
  logout: userActions.logout
};

export default connect(mapState, actionCreators)(Header);