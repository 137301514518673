export const driverRoundsUpdateData = (data) => {
  let formattedRequest = {};
  let forDelete = [];
  let forAdd = [];

  data.forEach((datum, index) => {
    const record = { driver_id: datum.driver_id, round_id: datum.round_id };

    // Remove the list on record
    if (datum.value == false) {
      forDelete.push(record)
    }else if (datum.value == true) {
      forAdd.push(record)
    }
  });

  if (forDelete.length > 0) {
    formattedRequest.delete = forDelete;
  }

  if (forAdd.length > 0) {
    formattedRequest.store = forAdd;
  }
  
  return formattedRequest;
}