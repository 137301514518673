import { checkoutConstants } from "../_constants/checkout.constants";

export function checkout (state = {}, action) {
  switch (action.type) {
    case checkoutConstants.API_CHECKOUT_REQUEST:
      return {
        loading: true
      }
    case checkoutConstants.API_CHECKOUT_SUCCESS:
      return {
        loading: false,
        items: action.order
      }
    case checkoutConstants.API_CHECKOUT_FAILURE:
      return {
        loading: false
      }
    default: 
      return state;
  }
}