export const sessionConstants = {
  API_REQUEST_NONE: 'API_REQUEST_NONE',

  API_POST_REQUEST_CHECKLISTS: 'API_POST_REQUEST_CHECKLISTS',
  API_POST_SUCCESS_CHECKLISTS: 'API_POST_SUCCESS_CHECKLISTS',
  API_POST_FAILURE_CHECKLISTS: 'API_POST_FAILURE_CHECKLISTS',

  API_POST_REQUEST_ORDERS: 'API_POST_REQUEST_ORDERS',
  API_POST_SUCCESS_ORDERS: 'API_POST_SUCCESS_ORDERS',
  API_POST_FAILURE_ORDERS: 'API_POST_FAILURE_ORDERS',

  API_POST_SUCCESS_ORDER_DETAILS: 'API_POST_SUCCESS_ORDER_DETAILS',

  GET_SCHEDULE_LOCAL: 'GET_SCHEDULE_LOCAL',
  API_GET_REQUEST_SCHEDULE: 'API_GET_REQUEST_SCHEDULE',
  API_GET_SUCCESS_SCHEDULE: 'API_GET_SUCCESS_SCHEDULE',
  API_GET_FAILURE_SCHEDULE: 'API_GET_FAILURE_SCHEDULE',

  GET_PRODUCTS_LOCAL: 'GET_PRODUCTS_LOCAL',
  API_GET_REQUEST_PRODUCTS: 'API_GET_REQUEST_PRODUCTS',
  API_GET_SUCCESS_PRODUCTS: 'API_GET_SUCCESS_PRODUCTS',
  API_GET_FAILURE_PRODUCTS: 'API_GET_FAILURE_PRODUCTS',

  GET_CUSTOMERS_LOCAL: 'GET_CUSTOMERS_LOCAL', 
  API_GET_REQUEST_CUSTOMERS: 'API_GET_REQUEST_CUSTOMERS',
  API_GET_SUCCESS_CUSTOMERS: 'API_GET_SUCCESS_CUSTOMERS',
  API_GET_FAILURE_CUSTOMERS: 'API_GET_FAILURE_CUSTOMERS',

  GET_ROUND_DRIVERS_LOCAL: 'GET_ROUND_DRIVERS_LOCAL',
  API_GET_REQUEST_ROUND_DRIVERS: 'API_GET_REQUEST_ROUND_DRIVERS',
  API_GET_SUCCESS_ROUND_DRIVERS: 'API_GET_SUCCESS_ROUND_DRIVERS',
  API_GET_FAILURE_ROUND_DRIVERS: 'API_GET_FAILURE_ROUND_DRIVERS',

  API_GET_REQUEST_STOCKS: 'API_GET_REQUEST_STOCKS',
  API_GET_SUCCESS_STOCKS: 'API_GET_SUCCESS_STOCKS',
  API_GET_FAILURE_STOCKS: 'API_GET_FAILURE_STOCKS',

  SAVE_ROUND_STATE: 'SAVE_ROUND_STATE'
};