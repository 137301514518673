import React from "react";
import { connect } from "react-redux";
import { Alert, Button, Modal } from "react-bootstrap";
import EditProductForm from "./EditProductForm";
import { processActions } from "../../_actions/process.actions";
import { processConstants } from "../../_constants/process.constants";

class EditProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suppliers: this.props.suppliers,
      categories: this.props.categories,
      data: this.props.record,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.process && this.props.process != prevProps.process) {
      if (
        this.props.process.saveForm &&
        this.props.process.saveForm != prevProps.process.saveForm
      ) {
        this.props.handleClose();
      }
    } else if (this.props.alert && this.props.alert.type == "alert-success") {
      this.props.handleClose();
    }
  }

  handleOnChange = (name, value) => {
    let state = this.state;
    state["data"][name] = value;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { data } = this.state;
    let suppliers = data.suppliers;
    if (typeof data.suppliers == "string") {
      const suppliersArr = this.state.data.suppliers.split(", ");
      const suppliersValue = this.state.suppliers.filter((obj) => {
        const supplier = obj.label.split(" - ");
        return suppliersArr.includes(supplier[1]);
      });

      if (suppliersValue.length > 0) {
        suppliers = [];
        suppliersValue.forEach((obj) => {
          suppliers.push(obj.value);
        });
      }
    }

    let category = data.category;

    if (isNaN(category)) {
      const categoryArr = this.state.categories.find(
        (obj) => obj.label == this.state.data.product_category
      );
      if (category) category = categoryArr.value;
    }

    let record = {
      id: data.id,
      code: data.code || "",
      description: data.description || "",
      price: data.price || "",
      category: category || "",
      size_descriptor: data.size_descriptors || "",
      vat_percentage: data.vat_percentage || "",
      suppliers: suppliers,
    };

    this.props.updateRecord(
      this.state.data.id,
      processConstants.PROCESS_EDIT_PRODUCT,
      record,
      "edit"
    );
  };

  render() {
    const { alert } = this.props;

    return (
      <>
        {this.props.alert.type != "alert-success" && (
          <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            backdrop="static"
            keyboard={false}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>{this.props.title}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {alert.message && (
                <Alert variant={alert.type.replace("alert-", "")}>
                  {alert.message}
                  {alert.fields && alert.fields.length > 0 && (
                    <ul>
                      {alert.fields.map((field) => {
                        return <li>{field}</li>;
                      })}
                    </ul>
                  )}
                </Alert>
              )}
              <EditProductForm
                onChange={this.handleOnChange}
                isModal={true}
                suppliers={this.state.suppliers}
                categories={this.state.categories}
                record={this.props.record}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={(e) => this.handleSubmit(e)}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

function mapState(state) {
  const { process, alert } = state;
  return { process, alert };
}

const actionCreators = {
  updateRecord: processActions.updateRecord,
};

export default connect(mapState, actionCreators)(EditProductModal);
