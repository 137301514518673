import React from 'react';
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { processActions } from '../../_actions/process.actions';
import { processConstants } from '../../_constants/process.constants';

class AddProductStocksModal extends React.Component
{
  constructor () {
    super();

    this.state = {
      mappedSuppliers: [],
      suppliers: [],
      showModal: false,
      productId: '',
      selected: '',
      selectedText: '',
      quantity: ''
    }
  }

  UNSAFE_componentWillMount () {

    if (! this.props.loading) {

      if (this.props.mappedSuppliers || this.props.suppliers) {
        this.setState({
          productId: this.props.record.id,
          mappedSuppliers: this.props.mappedSuppliers,
          suppliers: this.props.suppliers,
          showModal: true
        });
      } else {
        if (this.props.record.id) {
          this.props.getProductSuppliers(this.props.record.id)
        }
      }
    }
  }


  handleFormSubmit = () => {

    const { mappedSuppliers, productId, selected, quantity } = this.state; 
    const data = {
      product_id: productId,
      supplier_id: mappedSuppliers && mappedSuppliers[0].company != null ? mappedSuppliers[0].supplier_id : selected,
      no_of_stocks: quantity
    }

    this.props.submitForm(processConstants.PROCESS_ADD_PRODUCT_STOCKS, data, 'create');
  }

  handleOnDropdownClick = (e) => {
    this.setState({
      selected: e.target.dataset.id,
      selectedText: e.target.dataset.value
    })
  }

  handleOnKeyup = (e) => {
    this.setState({ quantity: e.target.value })
  }

  handleClose = () => {
    this.props.clearSuppliers();
    this.props.handleClose();
  }

  render () {
    const { showModal, mappedSuppliers, suppliers } = this.state;

    return <>
      { showModal && <Modal
        show={ this.props.show }
        onHide={ this.props.onHide }
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
      <Modal.Header>
        <Modal.Title><h6>Add Stocks for Product { this.props.record.description }</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ this.handleFormSubmit }>
          <Form.Group as={Row}>
            <Form.Label column sm="2">Product:</Form.Label>
            <Col sm="10">
              <Form.Control className="form-control" plaintext readOnly defaultValue={ this.props.record.description } />
            </Col>
          </Form.Group>
          { mappedSuppliers && mappedSuppliers[0].company != null
            ? <Form.Group as={Row}>
              <Form.Label column sm="2">Supplier:</Form.Label>
              <Col sm="10">
                <Form.Control className="form-control" plaintext readOnly defaultValue={ mappedSuppliers[0].company } />
              </Col>
            </Form.Group>
            : <Form.Group as={Row}>
              <Form.Label column sm="2">Supplier:</Form.Label>
              <Col sm="10">
                <DropdownButton 
                  as={ButtonGroup}
                  // key={idx}
                  // id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={this.state.selectedText || "Select Supplier"}
                >
                  <Dropdown.Header className="form-control"><i>Existing Suppliers:</i></Dropdown.Header>
                  { mappedSuppliers && mappedSuppliers.map((supplier) => {
                    return <Dropdown.Item onClick={ (e) => this.handleOnDropdownClick(e) } data-id={supplier.supplier_id} data-value={supplier.company} eventKey={supplier.supplier_id} className="form-control">{ supplier.company || '(None)' }</Dropdown.Item>
                  })}
                  { suppliers && suppliers.length > 0 && <Dropdown.Header className="form-control"><i>Other Suppliers:</i></Dropdown.Header>}
                  { suppliers && suppliers.length > 0 && suppliers.map((supplier) => {
                    return <Dropdown.Item onClick={ (e) => this.handleOnDropdownClick(e) } data-id={supplier.supplier_id} data-value={supplier.company} eventKey={supplier.supplier_id} className="form-control">{ supplier.company || '(None)' }</Dropdown.Item>
                  })}
                </DropdownButton>
              </Col>
            </Form.Group>
          }
          <Form.Group as={Row}>
            <Form.Label column sm="2">Quantity:</Form.Label>
            <Col sm="10">
              <Form.Control className="form-control" type="input" defaultValue={ this.state.quantity } onKeyUp={ (e) => this.handleOnKeyup(e)} />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ this.handleClose }>{this.props.close}</Button>
        <Button variant="primary" onClick={ this.handleFormSubmit }>Save</Button>
      </Modal.Footer>
      </Modal>}
    </>;
  }
}

function mapState (state) {
  const { process } = state;
  const { mappedSuppliers, suppliers, isProductStocksModal, loading } = process;
  return { process, mappedSuppliers, suppliers, isProductStocksModal, loading };
}

const actionCreators = {
  getProductSuppliers: processActions.getProductSuppliers,
  clearSuppliers: processActions.clearSuppliers,
  submitForm: processActions.submitForm
}

export default connect(mapState, actionCreators)(AddProductStocksModal);