import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Button, Col, Container, Form, FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { searchActions } from '../../_actions/search.actions';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div id={suggestion.id}>
    {suggestion.name}
  </div>
);

class AutoSearch extends React.Component
{
  constructor () {
    super ();

    this.state = {
      id: '',
      value: '',
      suggestions: []
    }
  }

  componentWillMount () {
    const { procId, processId, list } = this.props;

    if ((! list || Object.keys(list).length == 0) || (processId != procId)) {
      this.props.searchAll(procId);
    }

    if (this.props.defaultId) {
      this.setState(() => ({
        id: this.props.defaultId
      }));

      if (list) {
        this.setState(() => ({
          value: list[this.props.defaultId]
        }))
      }
    }
  }

  onChange = (event, { newValue }) => {
    let state = this.state;
    let id = event.target.id;

    if (id) {
      if (id.includes("react-autowhatever-")) {
        const index = id.substring(19, id.lastIndexOf("--item-"));
        state['id'] = this.state.suggestions[index].id;
      } else {
        state['id'] = event.target.id;
      }
    } else {
      const { list } = this.props;
      const record = list ? list.find((element) => {

        if (element.name == newValue) {
          return element
        }
      }) : '';

      state['id'] = (record && record.id) ? record.id : '';
    }
    state['value'] = newValue;
  };

  onClick = () => {
    const { id, value } = this.state;
    let state = this.state;
    state['id'] = '';
    state['value'] = '';
    state['suggestions'] = '';
    
    this.props.onClick(id, value);
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let list = this.props.list || [];

    return inputLength === 0 ? [] : list.filter(item =>
      item.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render () {

    const { placeholder, buttonTitle, containerClass, label } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: placeholder || 'Search',
      value,
      onChange: this.onChange
    };

    if (containerClass && containerClass == 'container-section') {
      return <>
        <Form.Group as={Row} >
          <Form.Label column sm="2">{label}</Form.Label>
          <Col sm="4" className="float-left">
            <Autosuggest
              suggestions={ suggestions }
              onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
              onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
              getSuggestionValue={ getSuggestionValue }
              renderSuggestion={ renderSuggestion }
              inputProps={ inputProps }
            />
          </Col>
          <Col sm="1">
            <Button variant="primary" onClick={this.onClick}>{buttonTitle}</Button>
          </Col>
        </Form.Group>
      </>;
    }
    return <Container className="container-tab">
      <Row>
        <Col xs="2">
          <div className="float-left">
            <Autosuggest
              suggestions={ suggestions }
              onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
              onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
              getSuggestionValue={ getSuggestionValue }
              renderSuggestion={ renderSuggestion }
              inputProps={ inputProps }
            />
          </div>
        </Col>
        <Col xs="1">
          <div className="float-left">
            <Button variant="primary" onClick={this.onClick}>{buttonTitle}</Button>
          </div>
        </Col>
        <Col xs="9"></Col>
      </Row>
    </Container>;
  }
}

function mapState(state) {
  const { search } = state;
  const { list, processId } = search;
  return { list, processId };
}

const actionCreators = {
  searchAll: searchActions.searchAll
}

export default connect(mapState, actionCreators)(AutoSearch);