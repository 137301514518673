import { sessionConstants } from "../_constants/session.constants";
import { getAccounts, getProducts, getRoundDrivers, getSchedule } from "../_helpers/session";

export function session(state = {}, action) {

  const roundDrivers = getRoundDrivers();
  const customers = getAccounts();
  const schedule = getSchedule();
  const products = getProducts();
  const loading = false;
  const initialState = { roundDrivers, customers, schedule, products, loading };
  state = initialState;

  switch (action.type) {
    case sessionConstants.API_POST_REQUEST_CHECKLISTS:
    case sessionConstants.API_GET_REQUEST_CUSTOMERS:
    case sessionConstants.API_GET_REQUEST_SCHEDULE:
    case sessionConstants.API_GET_REQUEST_PRODUCTS:
      return {
        loading: true
      }
    case sessionConstants.API_POST_SUCCESS_CHECKLISTS:
      return {
        loading: false,
        type: 'checklist',
        updateChecklist: true,
        payload: action.payload,
        items: action.data
      }
    case sessionConstants.API_REQUEST_NONE:
    case sessionConstants.API_POST_FAILURE_CHECKLISTS:
      return {
        loading: false,
        type: 'checklist',
        updateChecklist: false,
        payload: action.payload
      }
    case sessionConstants.API_POST_SUCCESS_ORDER_DETAILS:
      return {
        loading: false,
        type: 'orderDetails',
        payload: action.payload,
        items: action.data
      }
    case sessionConstants.API_GET_SUCCESS_PRODUCTS:
      return {
        ...state,
        loading: false,
        type: 'session',
        products: action.product
      }
    case sessionConstants.API_GET_SUCCESS_SCHEDULE:
      return {
        ...state,
        loading: false,
        type: 'session',
        schedule: action.schedule
      }
    case sessionConstants.API_GET_SUCCESS_CUSTOMERS:
      return {
        ...state,
        loading: false,
        type: 'session',
        customers: action.customer
      }
    case sessionConstants.API_GET_SUCCESS_ROUND_DRIVERS:
      return {
        ...state,
        loading: false,
        type: 'session',
        roundDrivers: action.roundDrivers
      }
    case sessionConstants.API_GET_SUCCESS_STOCKS:
      return {
        ...state,
        loading: false,
        type: 'stocks',
        productStocks: action.data
      }
    case sessionConstants.SAVE_ROUND_STATE:
      return {
        roundState: action.state,
        roundProps: action.props
      }
    default:
      return state
  }
}