import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Customer extends React.Component
{
  render () {

    const { data } = this.props;

    return <Container>
      <Row>
        <Col xs="2"><p className="customer-info"><b>Account Name:</b></p></Col><Col xs="4"><p className="customer-info">{data.company}</p></Col>
        <Col xs="2"><p className="customer-info"><b>Contact:</b></p></Col><Col xs="4"><p className="customer-info">{data.first_name+' '+data.last_name}</p></Col>        
      </Row>
      <Row>
        <Col xs="2"><p className="customer-info"><b>Phone Number:</b></p></Col><Col xs="4"><p className="customer-info">{data.phone_no_1}</p></Col>
        <Col xs="2"><p className="customer-info"><b>Opening Time:</b></p></Col><Col xs="4"><p className="customer-info">{data.opening_time || '10:00 - 20:00'}</p></Col>
      </Row>
      <Row>
        <Col xs="2"><p className="customer-info"><b>Mobile Number:</b></p></Col><Col xs="4"><p className="customer-info">{data.mobile_no_1}</p></Col>
      </Row>
      <hr />
    </Container>
  }
}

export default Customer;