import client from "../api/ApiClient";

export const processService = {
  get,
  getForm,
  getFormById,
  submitForm,
  deleteRecord,
  updateRecord,
  submitRecords,
};

async function getForm(slug, processId, method) {
  const url =
    process.env.REACT_APP_API_URL +
    "/" +
    slug +
    "/process/" +
    processId +
    "/" +
    method;
  return await client.get(url);
}

async function getFormById(uri, method) {
  const url = process.env.REACT_APP_API_URL + "/" + uri;
  return await client.get(url);
}

async function get(uri) {
  const url = process.env.REACT_APP_API_URL + "/" + uri;
  return await client.get(url);
}

async function submitForm(uri, data, method) {
  const url = process.env.REACT_APP_API_URL + "/" + uri;

  if (method == "import") {
    const formData = new FormData();
    formData.append("file", new Blob([data], { type: "text/csv" }));
    return await client.post(url, formData);
  }

  return await client.post(url, data);
}

async function deleteRecord(uri) {
  const url = process.env.REACT_APP_API_URL + "/" + uri;
  return await client.delete(url);
}

async function updateRecord(uri, data) {
  const url = process.env.REACT_APP_API_URL + "/" + uri;
  console.log("Url --->", url);
  console.log("Data --->", data);

  return await client.put(url, data);
}

async function submitRecords(uri, data) {
  const url = process.env.REACT_APP_API_URL + "/" + uri;
  return await client.post(url, data);
}
