import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button } from 'react-bootstrap';

class RoundSorting extends React.Component
{
  constructor () {
    super();

    this.state = {
      items: []
    }
  }

  UNSAFE_componentWillMount () {
    this.setState({ items: this.props.items });
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }), () => {
      this.props.updateItems(this.state.items);
    });
  };

  handleClick = (e) => {

    if (e.target.tagName === 'I' || e.target.tagName === 'BUTTON') {
      this.setState({ items: [...this.state.items.filter((obj) => obj.id != e.target.id)]})
      this.props.updateItems(this.state.items);
    }
  }

  render () {

    const SortableItem = SortableElement(({value}) => (
      <tr style={{ cursor: "move"}} tabIndex={0}>
        <td>{ value.company_ref }</td>
        <td>{ value.company }</td>
        <td>
          <Button variant="link" size="sm" id={value.id}>
            <i id={value.id} className="fas fa-minus-circle icon-red" />
          </Button>
        </td>
      </tr>
    ));

    const SortableList = SortableContainer(({items}) => {
      return (
        <tbody>
          {items.map((value, index) => (
            <SortableItem key={`item-${value.id}`} index={index} value={value} onClick={ (e) => this.handleClick(e, value)} />
          ))}
        </tbody>
      );
    })

    return <SortableList items={ this.state.items } onSortEnd={ this.onSortEnd } shouldCancelStart={ this.handleClick } />;
  }
}

export default RoundSorting;