import { contentService } from '../_services/content.service';
import { alertActions } from './alert.actions';
import { contentConstants } from '../_constants/content.constants';
import { history } from '../_helpers/history';
import Error from '../_helpers/error';
import { saveDriverRoundsToStorage } from '../_helpers/placeOrder';
import { searchActions } from './search.actions';
import { processConstants } from '../_constants/process.constants';

export const contentActions = {
  getAll,
  /** add post, delete, update, import actions here */
  getProcessPosition,
  getFormFields,
  getWithFilter,
  getSupplierDueInDefault,
  getInvoices
};

function getAll () {
  const slug = history.location.pathname.substring(1);
  const localKey = '__'+slug.replace('/', '__');
  const localData = localStorage.getItem(localKey);

  return dispatch => {
    dispatch(request(slug));

    if (localData) {
      dispatch(success(JSON.parse(localData)));
    }
    
    contentService.getAll(slug)
      .then(
        response => {
          if (response.data.status == 'success') {

            if (slug == 'driver/list') {
              localStorage.setItem(localKey, JSON.stringify(saveDriverRoundsToStorage(response.data.data)));
            } else {
              localStorage.setItem(localKey, JSON.stringify(response.data.data));
            }
            dispatch(success(response.data.data));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      ).catch(function (error) {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      })
  };

  function request(slug) { return { type: contentConstants.API_GET_REQUEST, slug } }
  function success(contents) { return { type: contentConstants.API_GET_SUCCESS, contents } }
  function failure(error) { return { type: contentConstants.API_GET_FAILURE, error } }
}

function getProcessPosition (processes) {
  const top = [], inline = [], bottom = [];

  if (processes != null) {
    processes.forEach(element => {
      
      if (element.button_type.indexOf("top") > -1) {
        top.push(element);
      } else if (element.button_type.indexOf("inline")) {
        inline.push(element);
      } else if (element.button_type.indexOf("bottom")) {
        bottom.push(element);
      }
    });
  }

  const position = {
    top: top,
    inline: inline,
    bottom: bottom
  };

  return { type: contentConstants.CONTENT_GET_PROCESS_POSITION, position};
}

function getFormFields (formId) {
  return dispatch => {
    dispatch(request(formId));

    contentService.getFormFields(formId)
      .then(
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      ).catch(function(error) {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      });
  };

  function request(formId) { return { type: contentConstants.API_GET_FORM_FIELDS_REQUEST, formId } }
  function success(formFields) { return { type: contentConstants.API_GET_FORM_FIELDS_SUCCESS, formFields } }
  function failure(error) { return { type: contentConstants.API_GET_FORM_FIELDS_FAILURE, error } }
}

function getWithFilter(data) {
  const slug = history.location.pathname.substring(1);
  let filter = '';

  for (const name in data) {
    if (filter != '') filter = filter + "&";
    filter = filter + name + "=" + data[name];
  }

  return dispatch => {
    dispatch(request(slug));
    
    contentService.getWithFilter(slug, filter)
      .then(
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      ).catch(function (error) {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      })
  };

  function request(slug) { return { type: contentConstants.API_GET_REQUEST, slug } }
  function success(contents) { return { type: contentConstants.API_GET_SUCCESS, contents } }
  function failure(error) { return { type: contentConstants.API_GET_FAILURE, error } }
}

function getSupplierDueInDefault()
{
  return dispatch => {
    Promise.all([
      dispatch(getAll()),
      dispatch(searchActions.searchAll(processConstants.PROCESS_SEARCH_SUPPLIER_2))
    ])
  }
}

function getInvoices(data)
{
  const slug = 'orders/invoices';
  let filter = '';

  for (const name in data) {
    if (filter != '') filter = filter + "&";
    filter = filter + name + "=" + data[name];
  }

  return dispatch => {
    dispatch(request(slug));
    
    contentService.getWithFilter(slug, filter)
      .then(
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      ).catch(function (error) {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      })
  };

  function request(slug) { return { type: contentConstants.API_GET_REQUEST, slug } }
  function success(contents) { return { type: contentConstants.API_GET_SUCCESS, contents } }
  function failure(error) { return { type: contentConstants.API_GET_FAILURE, error } }
}