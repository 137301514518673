import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import AutoSearch from '../../components/Search/AutoSearch';
import { orderActions } from '../../_actions/order.actions';
import { orderConstants } from '../../_constants/order.constants';
import { processConstants } from '../../_constants/process.constants';
import { generateRoundNo, getRoundIdByDate } from '../../_helpers/calendar';

class BulkUpdate extends React.Component
{
  constructor () {
    super ();

    this.state = {
      selectedDate: '',
      searchId: '',
      searchValue: '', 
      orderIdsToUpdate: [],
      ordersToUpdate: []
    }

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnClickBulkUpdate = this.handleOnClickBulkUpdate.bind(this);
    this.handleCashChange = this.handleCashChange.bind(this);
  }

  setSelectedDate = (date)  => { this.setState({ selectedDate: date }) }
  setSearchId     = (id)    => { this.setState({ searchId: id }) }
  setSearchValue  = (value) => { this.setState({ searchValue: value }) }

  handleDateChange = (date) => { this.setSelectedDate(date) }
  handleSearch = (id, value) => {
    this.setSearchId(id);
    this.setSearchValue(value);

    const { selectedDate } = this.state;
    this.props.getRoundOrdersByDriver(id, getRoundIdByDate(selectedDate), generateRoundNo(id, selectedDate));
  }

  handleCashChange = (e, row, defaultValue) => {
    if (defaultValue != e.target.value) {
      let { orderIdsToUpdate, ordersToUpdate } = this.state;
      
      if (orderIdsToUpdate.includes(row.order_id)) {
        orderIdsToUpdate = orderIdsToUpdate.filter(o => o !== row.order_id);
        ordersToUpdate = ordersToUpdate.filter(function (obj) { return obj.id !== row.order_id});
      }

      const order = {
        id: row.order_id,
        delivery_cash: e.target.value,
        delivery_cheque: row.cheque,
        delivery_status: 'delivered',
        order_status: 'paid in full'
      }

      orderIdsToUpdate.push(row.order_id);
      ordersToUpdate.push(order);

      this.setState(() => ({
        orderIdsToUpdate: orderIdsToUpdate,
        ordersToUpdate: ordersToUpdate
      }))
    }
  }

  handleChequeChange = (e, row, defaultValue) => {
    if (defaultValue != e.target.value) {
      let { orderIdsToUpdate, ordersToUpdate } = this.state;

      if (orderIdsToUpdate.includes(row.order_id)) {
        orderIdsToUpdate = orderIdsToUpdate.filter(o => o !== row.order_id);
        ordersToUpdate = ordersToUpdate.filter(function (obj) { return obj.id !== row.order_id});
      }

      const order = {
        id: row.order_id,
        delivery_cash: row.cash,
        delivery_cheque: e.target.value,
        delivery_status: 'delivered',
        order_status: 'paid in full'
      }

      orderIdsToUpdate.push(row.order_id);
      ordersToUpdate.push(order);

      this.setState(() => ({
        orderIdsToUpdate: orderIdsToUpdate,
        ordersToUpdate: ordersToUpdate
      }))
    }
  }

  handleOnClickBulkUpdate = () => {
    const { orderIdsToUpdate, ordersToUpdate, searchId, selectedDate } = this.state;

    if (orderIdsToUpdate) {
      this.props.bulkUpdateOrders(ordersToUpdate, searchId, getRoundIdByDate(selectedDate), generateRoundNo(searchId, selectedDate));
    }
  }

  componentWillReceiveProps (nextProps) {
    const { driverId, date, action } = nextProps;
    let selectedDate = nextProps.date;

    if (driverId && driverId !== this.props.driverId) {
      this.setSearchId(driverId);
    }

    if (date && date !== this.props.date) {
      selectedDate = new Date(date);
      this.setSelectedDate(selectedDate);
    }

    if (action && action !== this.props.action && action === orderConstants.API_POST_SUCCESS_BULK_ORDERS) {
      this.props.getRoundOrdersByDriver(driverId, getRoundIdByDate(selectedDate), generateRoundNo(driverId, selectedDate));
    }
  }

  componentWillUnmount () {
    this.props.clearOrders();
  }

  render () {
    const { selectedDate, id, ordersToUpdate } = this.state;
    const { loading, orders, action } = this.props;

    const columns = [
      {dataField: 'order_id', text: 'ORDER ID', editable: false, headerAlign: 'center'},
      {dataField: 'customer_id', text: 'CUSTOMER ID', hidden: true, headerAlign: 'center'},
      {dataField: 'company_ref', text: 'ACCOUNT REF', editable: false, headerAlign: 'center'},
      {dataField: 'company', text: 'ACCOUNT NAME', editable: false, headerAlign: 'center'},
      {dataField: 'old_balance', text: 'OLD BALANCE', editable: false, headerAlign: 'center'},
      {dataField: 'todays_balance', text: 'TODAYS BALANCE', editable: false, headerAlign: 'center'},
      {
        dataField: 'cash', 
        text: 'PAID IN CASH',
        editable: false, 
        headerAlign: 'center',
        formatter: (cell, row) => {

          if (row.total_balance > 0) {
            return <Form.Group className="table-form-group">
              <Form.Control 
                type="text" 
                defaultValue={cell != 0 ? cell : ''} 
                data-id={row.order_id} 
                data-cash={cell}
                onInput={e => this.handleCashChange(e, row, cell)}    
              />
            </Form.Group>;
          }

          return cell;
        }
      },
      {
        dataField: 'cheque', 
        text: 'PAID IN CHEQUE', 
        editable: false, 
        headerAlign: 'center',
        formatter: (cell, row) => {

          if (row.total_balance > 0) {
            return <Form.Group className="table-form-group">
              <Form.Control 
                type="text"  
                defaultValue={cell != 0 ? cell : ''} 
                data-id={row.order_id} 
                data-cash={cell}
                onInput={e => this.handleChequeChange(e, row, cell)}    
              />
            </Form.Group>;
          }

          return cell;
        }
      },
      {dataField: 'total_balance', text: 'CURRENT BALANCE', editable: false, headerAlign: 'center'},
      {dataField: 'delivery_status', text: 'DELIVERY STATUS', editable: false, headerAlign: 'center'},
      {
        dataField: 'invoice_no', 
        text: 'INVOICE NUMBER', 
        editable: false, 
        headerAlign: 'center', 
        headerStyle: {
          backgroundColor: '#C2C2C2'
        },
        style: {
          backgroundColor: '#C2C2C2'
        }
      },
      {
        dataField: 'total_amount', 
        text: 'ORDER', 
        editable: false, 
        headerAlign: 'center',
        headerStyle: {
          backgroundColor: '#C2C2C2'
        },
        style: {
          backgroundColor: '#C2C2C2'
        }
      },
      {
        dataField: 'prepayment', 
        text: 'IN-CASH PAYMENT', 
        editable: false, 
        headerAlign: 'center',
        headerStyle: {
          backgroundColor: '#C2C2C2'
        },
        style: {
          backgroundColor: '#C2C2C2'
        }
      }
    ];

    return <>
      <h5>Update Orders by Rounds </h5>
      <Container>
        <Row>&nbsp;</Row>
        <Row>
          <Col sm="4">
            <Form.Group as={Row} >
              <Form.Label column sm="4">Select schedule:</Form.Label>
              <Col sm="4" className="float-left">
                <ReactDatePicker 
                  selected={selectedDate}
                  onChange={(date) => this.handleDateChange(date)}
                  placeholderText="Pick a date"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm="7" className="float-left">
            <AutoSearch 
              procId={processConstants.PROCESS_SHOW_DRIVER_SELECT} 
              placeholder="Search driver" 
              onClick={this.handleSearch} 
              buttonTitle="Search" 
              className="float-right" 
              containerClass="container-section"  
              label="Select Driver:"
              defaultId={this.state.driverId}
            />
          </Col>
        </Row>
        <Row>
          {orders && action === orderConstants.API_GET_SUCCESS_BY_ROUND_DRIVER && <BootstrapTable 
            keyField="order_id"
            data={orders}
            columns={columns}
          />}
        </Row>
        <Row>
          {orders && action === orderConstants.API_GET_SUCCESS_BY_ROUND_DRIVER && <Button variant="primary" size="sm" onClick={this.handleOnClickBulkUpdate}>Update Orders</Button>}
        </Row>
      </Container>
      {loading && <em>Loading data...</em>}
    </>;

  }
}

function mapState(state) {
  const { order } = state;
  const { loading, orders, driverId, date, action } = order;
  return { loading, orders, driverId, date, action };
}

const actionCreators = {
  getRoundOrdersByDriver: orderActions.getRoundOrdersByDriver,
  bulkUpdateOrders: orderActions.bulkUpdateOrders,
  clearOrders: orderActions.clearOrders
}

export default connect(mapState, actionCreators)(BulkUpdate);