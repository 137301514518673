import { orderConstants } from '../_constants/order.constants';
import { processConstants } from '../_constants/process.constants';
import { formatDeliveryDateForQuery } from '../_helpers/calendar';
import { history } from '../_helpers/history';
import { clearSchedule } from '../_helpers/session';
import { orderService } from '../_services/order.service';
import { processService } from '../_services/process.service';
import { alertActions } from './alert.actions';
import Error from '../_helpers/error';

export const orderActions = {
  setCustomer,
  getRoundOrders,
  getRoundOrdersByDriver,
  addRoundCustomer,
  setDeliveryDate,
  bulkUpdateOrders,
  clearOrders,
  getOrderDetails,
  applyDiscounts
}

function setCustomer (record, roundId, driverId, roundNo) {

  record.roundId = roundId;
  record.driverId = driverId;
  record.roundNo = roundNo;

  return { type: orderConstants.SET_CUSTOMER, record};
}

function getRoundOrders (dateId, date, day) {

  return dispatch => {
    dispatch(request({dateId, date, day}))

    orderService.getRoundOrders (date, day) 
      .then (
        response => {
          if (response.data.status == 'success') {
            dispatch(success(response.data.data, dateId, date));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }

      )
  }

  function request(date) { return { type: orderConstants.API_GET_REQUEST_ROUNDS, date } }
  function success(rounds, dateId, date) { return { type: orderConstants.API_GET_SUCCESS_ROUNDS, rounds, dateId, date } }
  function failure(error) { return { type: orderConstants.API_GET_FAILURE_ROUNDS, error } }
}

function getRoundOrdersByDriver (driverId, roundId, roundNo) {
  
  const roundDate = formatDeliveryDateForQuery(roundNo);
  const data = { driverId, roundId, roundNo, roundDate };
  const key = '__order_calendar_'+driverId+'_'+roundId+'_'+roundNo+'_'+roundDate;
  const storage = JSON.parse(localStorage.getItem(key));

  if (storage) {
    return dispatch => {
      dispatch(success (storage, data));
    }
  }

  return dispatch => {
    dispatch(request(data))

    orderService.getRoundOrdersByDriver(driverId, roundId, roundNo, roundDate)
    .then (
      response => {
        if (response.data.status == 'success') {
          
          localStorage.setItem(key, JSON.stringify(response.data.data));
          dispatch(success(response.data.data, data));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      error => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }

    )
  }

  function request(data) { return { type: orderConstants.API_GET_REQUEST_BY_ROUND_DRIVER, data } }
  function success(orders, params) { return { type: orderConstants.API_GET_SUCCESS_BY_ROUND_DRIVER, orders, params } }
  function failure(error) { return { type: orderConstants.API_GET_FAILURE_BY_ROUND_DRIVER, error } }
}

function addRoundCustomer (customerId, roundId, driverId) {
  const slug = 'place'; //history.location.pathname.substring(1);
  const processId = processConstants.PROCESS_ADD_CUSTOMER_TO_ROUND;
  const method = processConstants.PROCESS_METHOD_CREATE; 
  const data = {
    customer_id: customerId,
    round_id: roundId,
    driver_id: driverId
  };
  const form = { slug, processId, data, method};

  return dispatch => {
    dispatch (request(form));

    let uri = slug+'/process/'+processId;

    processService.submitForm(uri, data, method)
      .then(
        response => { 
          if (response.data.status == 'success') {
            clearSchedule();
            dispatch(success(response.data.data));
            dispatch(alertActions.success('Record successfully saved'));
          } else {
            const e = Error(response);
            dispatch(failure(e.system));
            dispatch(alertActions.error(e.message));
          }
        },
        error => {
          const e = Error(error);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      )
  }

  function request(form) { return { type: orderConstants.API_POST_REQUEST_BY_ROUND_DRIVER, form } }
  function success(data) { return { type: orderConstants.API_POST_SUCCESS_BY_ROUND_DRIVER, data } }
  function failure(error) { return { type: orderConstants.API_POST_FAILURE_BY_ROUND_DRIVER, error } }
}

function setDeliveryDate (date) {
  return { type: orderConstants.SET_DELIVERY_DATE, date }
}

function bulkUpdateOrders (data, driverId, day, roundNo) {

  const roundDate = formatDeliveryDateForQuery(roundNo);
  const uri = 'order/update/process/67/item/all';
  const orders = { data };
  const params = { driverId, day, roundDate, orders};

  return dispatch => {
    dispatch(request(orders))

    processService.submitRecords(uri, orders)
    .then (
      response => {
        if (response.data.status == 'success') {
          dispatch(success(response.data.data, params));
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      error => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }

    )
  }

  function request(orders) { return { type: orderConstants.API_POST_REQUEST_BULK_ORDERS, orders } }
  function success(orders, params) { return { type: orderConstants.API_POST_SUCCESS_BULK_ORDERS, orders, params } }
  function failure(error) { return { type: orderConstants.API_POST_FAILURE_BULK_ORDERS, error } }
}

function clearOrders () {
  return { type: orderConstants.CLEAR_ORDERS };
}

function getOrderDetails(orderId, type, customerId) {
  const uri = 'orders/invoices/'+orderId+'/'+type.toLowerCase()+'/'+customerId;

  return dispatch => {
    dispatch({ type: orderConstants.API_GET_REQUEST_INVOICE_ORDER_DETAILS, orderId: orderId })

    processService.get(uri)
    .then (
      response => {
        if (response.data.status == 'success') {
          dispatch({ type: orderConstants.API_GET_SUCCESS_INVOICE_ORDER_DETAILS, data: response.data.data });
        } else {
          const e = Error(response);
          dispatch(failure(e.system));
          dispatch(alertActions.error(e.message));
        }
      },
      error => {
        const e = Error(error);
        dispatch(failure(e.system));
        dispatch(alertActions.error(e.message));
      }

    )
  }

  function failure(error) { return { type: orderConstants.API_GET_FAILURE_INVOICE_ORDER_DETAILS, error } }
}

function applyDiscounts(orderId, data)
{
  return dispatch => {
    const uri = 'orders/discount/'+orderId;
    processService.updateRecord(uri, { data: data })
      .then (
        response => {
          if (response.data.status == 'success') {
            dispatch({ type: orderConstants.API_POST_SUCCESS_ORDER_DISCOUNT, data: response.data.data, payload: data });
          }
        }
      )
  } 
}
