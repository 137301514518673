import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const customStyles = {
  control: base => ({
    ...base,
    minHeight: '26px'
  }),
  // valueContainer: (provided, state) => ({
  //   ...provided,
  //   height: '26px',
  //   padding: '0 2px'
  // }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  // indicatorSeparator: state => ({
  //   display: 'none',
  // }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '26px',
  }),
};

class MultiSelect extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue || [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.resetValue != this.props.resetValue && this.props.resetValue == true) {
      this.setState({ value: [] });
    }
  }

  handleChange = (value) => {
    let val = [];

    value.forEach((element, key) => {
      val.push(element.value);
    });

    this.setState({ value: value });
    this.props.onChange(val);
  }

  render() {
    return <>
      <Select 
        closeMenuOnSelect={ false }
        components={ animatedComponents }
        defaultValue={ this.props.defaultValue || ''}
        isMulti
        options={ this.props.options }
        placeholder={ this.props.placeholder || 'Select..' }
        onChange={ this.handleChange }
        value={ this.state.value }
        styles={customStyles}
      />
    </>;
  }
}

export default connect(null, null)(MultiSelect);