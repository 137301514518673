import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { sessionActions } from "../../../_actions/session.actions";
import { history } from "../../../_helpers/history";
import {
  getActivePlaceTab,
  getRoundSelected,
  removeRoundSelected,
  setActivePlaceTab,
  setRoundSelected,
} from "../../../_helpers/session";
import Postits from "./Postits/Postits";
import RoundsTab from "./Rounds/RoundsTab";

class PlaceOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: "rounds",
      extra: [],
    };

    history.listen((location, action) => {
      if (location.pathname == "/place") {
        const key = getActivePlaceTab();
        if (key && key !== this.state.activeKey) {
          this.setState({ activeKey: key }, setActivePlaceTab(key));
        } else {
          setActivePlaceTab("rounds");
        }
      }
    });

    this.props.getSession();
  }

  onTabSelect = (key, extra) => {
    if (key == "postits" && !extra) {
      return;
    }

    if (key == "postits") {
      setRoundSelected(extra.roundNo);
      console.log(key, extra);
    }
    this.setState({ activeKey: key, extra: extra }, () => {
      setActivePlaceTab(key);

      if (key == "postits") {
        setRoundSelected(extra.roundNo);
        this.props.saveRoundState(extra.state, extra);
      } else {
        this.setState({ extra: { activeRound: getRoundSelected() } });
        // removeRoundSelected();
      }
    });
  };

  render() {
    const { activeKey } = this.state;

    return (
      <div className="content">
        <h5>Place Order</h5>
        <Tabs
          defaultActiveKey="rounds"
          id="placeOrder"
          activeKey={this.state.activeKey}
          onSelect={(key) => this.onTabSelect(key)}
        >
          <Tab eventKey="rounds" title="Rounds">
            {activeKey == "rounds" && (
              <RoundsTab onRowClick={this.onTabSelect} {...this.state} />
            )}
          </Tab>
          <Tab eventKey="postits" title={"Postits"}>
            {activeKey == "postits" && (
              <Postits
                onLoad={this.onTabSelect}
                onRowClick={this.onTabSelect}
                params={this.state.extra}
                activeKey={activeKey}
              />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const actionCreators = {
  getSession: sessionActions.getSession,
  saveRoundState: sessionActions.saveRoundState,
};

export default connect(null, actionCreators)(PlaceOrder);
