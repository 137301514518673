import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { placeActions } from '../../_actions/place.actions';
import { getRoundDrivers, getSchedule } from '../../_helpers/session';
import Accounts from './Accounts';

class Drivers extends React.Component
{
  constructor () {
    super ();

    this.state = {
      activeKey: "",
      showDrivers: false,
      hasSchedule: getSchedule() ? true : false
    }
  }

  UNSAFE_componentWillMount () {
    const { round } = this.props;
    const roundDrivers = getRoundDrivers();

    if (round && roundDrivers && roundDrivers[round.id]) {
      this.setState({ 
        showDrivers: true,
        activeKey: roundDrivers[round.id][0].driver_id
      });
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.schedule != this.props.schedule && ! this.props.schedule) {
      this.setState({ hasSchedule: false });
      this.props.getSchedules()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.roundDrivers && nextProps.roundDrivers !== this.props.roundDrivers) {
      this.setState({ showDrivers: true });
    }

    if (nextProps.schedule && nextProps.schedule !== this.props.schedule) {
      if (! getSchedule()) {
        this.setState({ hasSchedule: false });
      } else {  
        this.setState({ hasSchedule: true });
      }
    }
  }

  render () {
    const { activeKey, showDrivers, hasSchedule } = this.state;
    const { round } = this.props;
    const roundDrivers = getRoundDrivers();

    return <>
      <Container className="container-tab">
        { showDrivers && roundDrivers[round.id] 
          ? <Tabs 
              id="driversTab"
              defaultActiveKey={ activeKey || roundDrivers[round.id][0].driver_id }
              activeKey={activeKey || roundDrivers[round.id][0].driver_id }
              onSelect={ (key) => this.setState({ activeKey: key }) }
            >
              { roundDrivers[round.id].map((roundDriver) => {
                return <Tab key={ roundDriver.driver_id } eventKey={ roundDriver.driver_id } title={ roundDriver.name }>
                  { activeKey == roundDriver.driver_id && hasSchedule ? <Accounts roundId={ round.id } round={ roundDriver } /> : <>Loading..</> }
                </Tab>
              })}
            </Tabs>
          : <i>No driver's found.</i>
        }
      </Container>
    </>;
  }
}

function mapState (state) {
  const { session } = state;
  const { roundDrivers, schedule } = session;
  return { roundDrivers, schedule };
}

const actionCreators = {
  getSchedules: placeActions.getSchedules 
}

export default connect(mapState, actionCreators)(Drivers);