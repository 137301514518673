import axios from 'axios';
import { formatNavigation } from '../_helpers/navigation';

export const userService = {
    login,
    logout,
};

function login(username, password) {

  return axios.post(process.env.REACT_APP_API_URL+"/login", { username, password })
    .then(response => {
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify({name: response.data.data.name}));
        localStorage.setItem('token', JSON.stringify(response.data.data.token.access_token));
        localStorage.setItem('navigation', JSON.stringify(formatNavigation(response.data.data.navigations)));
      } else {
        logout();
      }
      return response.data;
  });  

  
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('navigation');
}
