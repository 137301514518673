import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { processActions } from '../../_actions/process.actions';
import { titleCase } from '../../_helpers/content';

const columnsNotToShow = ['password', 'remember_token', 'deleted_at', 'created_at', 'updated_at'];

class ShowSuppliersProductModal extends React.Component
{
  constructor () {
    super ();

    this.state = {
      showModal: false,
      supplierId: '',
      company: '',
      products: [],
      columns: [{dataField: '', text: ''}]
    }
  }

  UNSAFE_componentWillMount () {
    const { loading, products } = this.props;
    
    if (typeof loading !== 'undefined' && ! loading) {

      if (typeof products !== 'undefined') {
        const columns = [{dataField: '', text: ''}];
        const headers = products && products[0] || {} ;

        for (const header in headers) {
          if (! columnsNotToShow.includes(header)) {
            const text = titleCase(header.replace(/_/gi, " "));
            const property = {dataField: header, text: text};
            columns.push(property);
          }
        }
        columns.shift();

        this.setState({
          supplierId: this.props.record.id,
          company: this.props.record.company,
          products: this.props.products,
          columns: columns,
          showModal: true
        });
      } else {
       
      }
    } else {
      if (this.props.record.id) {
        this.props.getSuppliersProduct(this.props.record.id)
      }
    }
  }

  handleClose = () => {
    this.props.clearProducts();
    this.props.handleClose();
  }

  render () {
    const { showModal, products, columns } = this.state;

    return <>
      { showModal && <Modal
        show={ this.props.show }
        onHide={ this.props.onHide }
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title><h6>Products Supplied By { this.props.record.company }</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable 
            striped
            keyField="id"
            data={products}
            columns={columns}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ this.handleClose }>Close</Button>
        </Modal.Footer>
      </Modal>}
    </>;
  }
}

function mapState (state) {
  const { process } = state;
  const { products, loading } = process;
  return { products, loading };
}

const actionCreators = {
  getSuppliersProduct: processActions.getSuppliersProduct,
  clearProducts: processActions.clearProducts
}

export default connect(mapState, actionCreators)(ShowSuppliersProductModal);
