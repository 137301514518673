import React from 'react';
import Select from 'react-select';

class SingleSelect extends React.Component
{
  state = {
    value: this.props.defaultValue || ''
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.resetValue != this.props.resetValue && this.props.resetValue == true) {
      this.setState({ value: '' });
    }
  }

  handleChange = (value) => {
    this.setState({ value: value });
    this.props.onChange(value);
  }

  render() {
    return <Select
      defaultValue={ this.props.defaultValue || ''}
      value={ this.state.value }
      options={ this.props.options }
      onChange={ this.handleChange }
      placeholder={ this.props.placeholder || 'Select..' }
      { ...this.props.styles && { styles: this.props.styles }}
    />;
  }
}

export default SingleSelect;