import React from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { Button } from 'react-bootstrap';

class DownloadCSV extends React.Component
{
  render () {

    return <>
      <CSVLink
        data={ this.props.data }
        headers={ this.props.headers }
        filename={ this.props.filename+".csv" }
        // asyncOnClick={true}
        // onClick={(event, done) => {
        //   axios.post("/spy/user").then(() => {
        //     done(false); // Don't Proceed
        //   });
        // }}
      >
        <Button variant="primary" className="float-left">{ this.props.label }</Button>
      </CSVLink>;
    </>;
  } 
}

export default connect(null, null)(DownloadCSV);