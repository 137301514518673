import { combineReducers } from 'redux';

import {reducer as toastrReducer} from 'react-redux-toastr'
import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { contents, formFields } from './content.reducer';
import { nav } from './navigation.reducer';
import { process } from './process.reducer';
import { cart } from './cart.reducer';
import { order } from './order.reducer';
import { search } from './search.reducer';
import { checkout } from './checkout.reducer';
import { modal } from './modal.reducer';
import { place } from './place.reducer';
import { session } from './session.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  contents,
  nav,
  process,
  formFields,
  cart,
  order,
  search,
  checkout,
  modal,
  place,
  session,
  toastr: toastrReducer
});

export default rootReducer;