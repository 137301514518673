import React from 'react';
import { Button, Container, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import AutoSearch from '../../components/Search/AutoSearch';
import { orderActions } from '../../_actions/order.actions';
import { processActions } from '../../_actions/process.actions';
import { sessionActions } from '../../_actions/session.actions';
import { processConstants } from '../../_constants/process.constants';
import { getRoundSchedule } from '../../_helpers/placeOrder';
import { getAccounts, getSchedule } from '../../_helpers/session';
import RoundSorting from './RoundSorting';

class Accounts extends React.Component
{
  constructor (props) {
    super(props);

    this.state = {
      schedule: [],
      roundId: '',
      driverId: '',
      searchId: '',
      searchValue: '',
      hasSchedule: false,
      loading: false
    }

    this.props.getCustomers();
  }

  UNSAFE_componentWillMount () {
    const { round, roundId, session } = this.props;
    const localSchedule = getSchedule();
    const localAccounts = getAccounts();

    if (session && session.loading) {
      this.setState({ loading: true })
    } else {
      if (localSchedule && localAccounts) {
        const schedule = getRoundSchedule(roundId, round.driver_id, localSchedule, localAccounts);

        this.setState({ 
          schedule: schedule,
          roundId: roundId,
          driverId: round.driver_id,
          hasSchedule: true,
          loading: false,
          newSchedule: []
        });
      }
    }
  }

  onSearch = (id, value) => {
    let state = this.state;
    state['searchId'] = id;
    state['searchValue'] = value;

    const { roundId, driverId } = this.state;
    this.setState({ hasSchedule: false, schedule: [], loading: true })
    this.props.addRoundCustomer(id, roundId, driverId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (! this.state.hasSchedule) {
      const { roundId, driverId } = this.state;
      const sched = getSchedule();
      const accounts = getAccounts();

      if (sched && accounts) {
        const schedule = getRoundSchedule(roundId, driverId, getSchedule(), getAccounts());
        this.setState({
          schedule: schedule,
          hasSchedule: true,
          loading: false
        })
      }
    }

    if (this.props.postSchedule && this.props.postSchedule != prevProps.postSchedule) {
      this.setState({ newSchedule: [] });
    }

    if (this.props.customers && this.props.customers !== prevProps.customers) {
      this.state = prevState;
      const { round, roundId, session } = this.props;
      const localSchedule = getSchedule();

      if (localSchedule) {
        const schedule = getRoundSchedule(roundId, round.driver_id, localSchedule, getAccounts());
        this.setState({ 
          schedule: schedule,
          roundId: roundId,
          driverId: round.driver_id,
          hasSchedule: true,
          loading: false,
          newSchedule: []
        });
      }
    }
  }

  updateSchedules = (items) => {
    this.setState({ newSchedule: items });
  }

  onSaveChangesClick = (e) => {
    const { roundId, driverId, newSchedule } = this.state;
    this.props.updateRoundSchedule(roundId, driverId, newSchedule);
  }

  render () {
    const { hasSchedule, schedule, loading } = this.state;
    
    return <>
      <Container>
        <Row>
          <AutoSearch procId={processConstants.PROCESS_SEARCH_CUSTOMER} placeholder="Search account" onClick={ this.onSearch } buttonTitle="Add" className="float-right" />
        </Row>
        <Row>
          
        </Row>
        <Table size="sm" bordered>
          <thead style={{ textAlign: "center" }}>
            <tr>
              <th>ACCT REF</th>
              <th>ACCOUNT NAME</th>
              <th>ACTION</th>
            </tr>
          </thead>
          { hasSchedule && schedule && schedule.length > 0 
            ? <RoundSorting items={ schedule } updateItems={ this.updateSchedules } /> 
            : <tbody><tr><td colsPan="3"><i>No records found.</i></td></tr></tbody>
          }
        </Table>
        <Row><Button size="sm" onClick={ this.onSaveChangesClick } {...(this.state.newSchedule && this.state.newSchedule.length > 0 ? '' : {disabled: true})}>Save Changes</Button></Row>
      </Container>
    </>;
  }
}

function mapState(state) {
  const { order, session, process } = state;
  const { postSchedule } = process;
  return { order, session, postSchedule };
}

const actionCreators = {
  addRoundCustomer: orderActions.addRoundCustomer,
  updateRoundSchedule: processActions.updateRoundSchedule,
  getCustomers: sessionActions.getCustomers
}

export default connect(mapState, actionCreators)(Accounts);