import { history } from '../_helpers/history';

export const formatNavigation = (navigation) => {
  const tempNav = [];
  Object.keys(navigation).forEach(function(key) {
    const nav = navigation[key];

    if (nav.parent_id != null) {
      tempNav.find((element, index) => {

        if (element.id == nav.parent_id) {
          if (tempNav[index].children == null) {
            tempNav[index].children = [];
          }
          
          tempNav[index].children.push(nav);
        }
      })
    } else {

      if (nav.component_attributes == null) {
        nav.component_attributes = '{"className":"fa fa-fw fa-info-circle"}';
      }
      tempNav.push(nav);
    }
  });
  
  tempNav.sort((a,b) => (a.component_order > b.component_order) ? 1 : ((b.component_order > a.component_order) ? -1 : 0)); 
  return tempNav;
}

export const getSelectedNavByUriBak = () => {
  const slug = history.location.pathname.substring(1);
  const navigation = JSON.parse(localStorage.getItem('navigation'));

  navigation.forEach(function (nav, index) {
    if (slug == nav.slug) {
      return {id: nav.id, parentId: null, navigation: navigation};
    }

    if (nav.children) {
      nav.children.forEach(function (child, i) {
        if (slug == child.slug) {
          return {id: child.id, parentId: nav.id, navigation: navigation};
        }
      })
    }
  })
}

export const getSelectedNavByUri = (navigation) => {
  const slug = history.location.pathname.substring(1);

  navigation.forEach((nav, index) => {
    if (slug == nav.slug) {
      return getSelectedNav(nav.id, null, navigation);
    }

    if (nav.children) {
      nav.children.forEach(function (child, i) {
        if (slug == child.slug) {
          return getSelectedNav(child.id, nav.id, navigation);
        }
      })
    }
  })
}

const getSelectedNav = (navId, parentId, navigations) => {
  let navigation = [], section = [], processes = [];
  navigations.forEach(function(nav, index) {
    if (parentId && nav.children && nav.id == parentId) {
      nav.children.forEach(function(child, i) {
        if (navId == child.id) {
          navigation = child;

          if (child.sections) {
            section = child.sections[0];

            if (child.sections[0]) {
              if (child.sections[0].processes) {
                processes = child.sections[0].processes;
              }
            }
              
          }
          return;
        }
      });
    } else {
      if (nav.id == navId) {
        navigation = nav;
        
        if (nav.sections) {
          section = nav.sections[0];

          if (nav.sections[0]) {
            if (nav.sections[0].processes) {
              processes = nav.sections[0].processes;
            }
          }
        }
        return;
      }
    }
  });

  const items = {navigation, section, processes};
  return items;
}
