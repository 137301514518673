import React from "react";
import { Col, Button, Form, Modal, Row, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { processActions } from "../../../_actions/process.actions";
import { formatDateForQuery } from "../../../_helpers/calendar";

const currencySymbol = "£";

const computeTotal = (purchased) => {
  let total = 0;
  purchased.forEach((product) => {
    const subtotal = !isNaN(product.total_amount)
      ? Number(product.total_amount)
      : 0;
    total = total + subtotal;
  });

  return total.toFixed(2);
};

const computeOverallSubtotal = (purchased) => {
  let total = 0;
  purchased.forEach((product) => {
    const subtotal = !isNaN(product.subtotal) ? Number(product.subtotal) : 0;
    total = total + subtotal;
  });

  return total.toFixed(2);
};

const computeOverallVat = (purchased) => {
  let totalVat = 0;
  purchased.forEach((product) => {
    const vat = !isNaN(product.vat) ? Number(product.vat) : 0;
    const quantity = !isNaN(product.quantity) ? Number(product.quantity) : 0;
    totalVat = totalVat + vat * quantity;
  });

  return totalVat.toFixed(2);
};

const computeSubtotal = (quantity, price, vat) => {
  const qty = !isNaN(quantity) ? Number(quantity) : 0;
  const amount = !isNaN(price) ? Number(price) : 0;
  const evat = !isNaN(vat) ? Number(vat) : 0;

  return (
    (parseInt(qty) * (parseFloat(amount) + parseFloat(evat))).toFixed(2) || 0
  );
};

class PurchaseModal extends React.Component {
  constructor(props) {
    super();

    this.state = {
      supplier: "",
      company: "",
      products: [],
      purchased: [],
      total: 0.0,
      addProduct: {},
      showConfirmModal: false,
      deliveryDate: "",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.created && this.props.created !== prevProps.created) {
      this.props.onClose();
    }
  }

  onSupplierSelected = (e) => {
    e.preventDefault();

    const { supplier } = this.state;
    const value = e.target.value;
    const company = this.props.suppliers.filter((obj) => obj.id == value)[0]
      .company;
    const purchased = supplier == value ? this.state.purchased : [];
    const mappedProducts = this.props.productSupplierMapping.filter(
      (obj) => obj.supplier_id == value
    );
    let products = [];
    console.log("Products 000>", this.props.products);
    this.props.products.forEach((element) => {
      const found = mappedProducts.find((mapped) => mapped.id == element.id);

      if (found) {
        let newProduct = element;
        newProduct.price = found.cost_price;
        products.push(newProduct);
      }
    });

    products.forEach((product, key) => {
      product.quantity = "0";
      products[key] = product;
    });

    if (supplier != value) {
      Array.from(document.querySelectorAll("input")).forEach((input) => {
        if (
          input.name == "quantity" ||
          input.name == "price" ||
          input.name == "vat"
        ) {
          return (input.value = "0");
        }
      });
    }

    this.setState(
      {
        supplier: value,
        company: company,
        products: [],
        purchased: purchased,
      },
      () => {
        this.setState({ products: products });
      }
    );
  };

  handleInputChange = (e, row) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;
    const stateRecord = this.state.purchased.filter((obj) => obj.id == row.id);

    let record = stateRecord && stateRecord.length > 0 ? stateRecord[0] : row;
    record[name] = value;
    record.subtotal = computeSubtotal(record.quantity, record.price);
    record.total_amount = computeSubtotal(
      record.quantity,
      record.price,
      record.vat
    );
    record.supplier_id = this.state.supplier;
    record.product_id = row.id;
    record.type = 1;

    this.setState({
      purchased: [
        ...this.state.purchased.filter((obj) => obj.id != record.id),
        record,
      ],
    });
  };

  handleAddOnChange = (e) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;
    let addProduct = this.state.addProduct;

    if (name == "addCode") {
      addProduct = this.props.products.filter((obj) => obj.id == value)[0];
      addProduct.vat = "0";
      addProduct.price = "0";
      addProduct.quantity = "0";
      addProduct.product_id = addProduct.id;
      addProduct.removable = true;
    } else if (name == "addQuantity") {
      addProduct.quantity = value;
    } else if (name == "addPrice") {
      addProduct.price = value;
    } else if (name == "addVat") {
      addProduct.vat = value;
    }

    addProduct.subtotal = computeSubtotal(
      addProduct.quantity,
      addProduct.price
    );
    addProduct.total_amount = computeSubtotal(
      addProduct.quantity,
      addProduct.price,
      addProduct.vat
    );

    this.setState({ addProduct: addProduct });
  };

  handleAddProductOnClick = (e) => {
    e.preventDefault();

    this.setState(
      {
        products: [...this.state.products, this.state.addProduct],
        purchased: [...this.state.purchased, this.state.addProduct],
      },
      () => {
        this.setState({ addProduct: {} });
      }
    );

    Array.from(document.querySelectorAll("input")).forEach((input) => {
      if (
        input.name == "addVat" ||
        input.name == "addPrice" ||
        input.name == "addQuantity"
      ) {
        return (input.value = "0");
      }
    });

    Array.from(document.querySelectorAll("select")).forEach((select) => {
      if (select.name == "addCode") {
        return (select.value = "");
      }
    });
  };

  handleDeleteOnClick = (e) => {
    e.preventDefault();

    const id = e.target.id;

    this.setState({
      products: [...this.state.products.filter((obj) => obj.id != id)],
      purchased: [...this.state.purchased.filter((obj) => obj.id != id)],
    });
  };

  handleSubmit = (e, isPurchase) => {
    e.preventDefault();

    this.state.purchased.forEach((purchase) => {
      purchase.supplier_id = this.state.supplier;
      purchase.company = this.state.company;
      purchase.type = 1;
      purchase.quantity = Number(purchase.quantity);
      purchase.price = Number(purchase.price).toFixed(2);
      purchase.vat = !isNaN(purchase.vat)
        ? Number(purchase.vat).toFixed(2)
        : purchase.vat;
      delete purchase.id;
    });

    if (isPurchase) this.setState({ showConfirmModal: true });
    else this.handleConfirmPurchase(e, 0);
  };

  handleConfirmPurchase = (e, onOrder) => {
    e.preventDefault();

    const data = {
      products: this.state.purchased,
      purchase_details: {
        supplier_id: this.state.supplier,
        on_order: onOrder,
        date_to_deliver: formatDateForQuery(new Date(this.state.deliveryDate)),
      },
    };

    this.props.savePurchaseOrder(data);
    this.props.onClose();
    this.setState({ showConfirmModal: false });
  };

  closeConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  onDateChange = (date) => {
    this.setState({ deliveryDate: date });
  };

  render() {
    const { supplier, products, purchased, addProduct } = this.state;
    const total = computeTotal(this.state.purchased);

    return (
      <>
        {this.props.suppliers && (
          <Modal
            show={this.props.showModal}
            onHide={this.props.onClose}
            backdrop="static"
            keyboard={false}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Make a Purchase</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit} name="purchasedForm">
                <Form.Group as={Row} key="supplierGroup">
                  <Form.Label column sm="2">
                    Supplier:
                  </Form.Label>
                  <Col sm="3" className="float-left">
                    <Form.Control
                      as="select"
                      name="supplier"
                      label="Suppliers"
                      defaultValue=""
                      onChange={(e) => this.onSupplierSelected(e)}
                    >
                      <option value="">Choose a supplier</option>
                      {this.props.suppliers.map((supplier) => {
                        return (
                          <option value={supplier.id}>
                            {supplier.company}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Form.Label column sm="2">
                    Delivery Date:
                  </Form.Label>
                  <Col sm="3">
                    <ReactDatePicker
                      dateFormat={"dd/MMM/yyyy"}
                      selected={this.state.deliveryDate}
                      onChange={(date) => this.onDateChange(date)}
                      placeholderText="Choose date to deliver/collect"
                      closeOnScroll={true}
                      shouldCloseOnSelect={true}
                    />
                  </Col>
                  <Form.Label column sm="2">
                    Total {currencySymbol}:{" "}
                    <b>{Number(total).toFixed(2) || "0.00"}</b>
                  </Form.Label>
                </Form.Group>
                <Table>
                  <thead>
                    <tr>
                      <th>PRODUCT CODE</th>
                      <th>DESCRIPTION</th>
                      <th>QUANTITY</th>
                      <th>PRICE {currencySymbol}</th>
                      <th>VAT {currencySymbol}</th>
                      <th>AMOUNT {currencySymbol}</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.length == 0 && supplier != "" && (
                      <tr>
                        <td colSpan="7">
                          <i>No records found.</i>
                        </td>
                      </tr>
                    )}
                    {products.length == 0 && supplier == "" && (
                      <tr>
                        <td colSpan="7">
                          <i>No supplier selected.</i>
                        </td>
                      </tr>
                    )}
                    {products &&
                      products.map((product) => {
                        let p_purchased = purchased.filter(
                          (obj) => obj.id == product.id
                        );
                        p_purchased = p_purchased[0]
                          ? p_purchased[0]
                          : p_purchased;

                        return (
                          <tr>
                            <td>{product.code}</td>
                            <td>{product.description}</td>
                            <td className="table-row-action">
                              <Form.Control
                                type="input"
                                name="quantity"
                                data-id={product.id}
                                onKeyUp={(e) =>
                                  this.handleInputChange(e, product)
                                }
                                defaultValue={
                                  p_purchased.quantity || product.quantity || 0
                                }
                              />
                            </td>
                            <td className="table-row-action">
                              <Form.Control
                                type="input"
                                name="price"
                                data-id={product.id}
                                onKeyUp={(e) =>
                                  this.handleInputChange(e, product)
                                }
                                defaultValue={
                                  p_purchased.price || product.price || 0
                                }
                              />
                            </td>
                            <td className="table-row-action">
                              <Form.Control
                                type="input"
                                name="vat"
                                data-id={product.id}
                                onKeyUp={(e) =>
                                  this.handleInputChange(e, product)
                                }
                                defaultValue={
                                  p_purchased.vat || product.vat || 0
                                }
                              />
                            </td>
                            <td>
                              {!isNaN(p_purchased.total_amount)
                                ? Number(p_purchased.total_amount).toFixed(2)
                                : 0}
                            </td>
                            {p_purchased.removable ? (
                              <td>
                                <Button
                                  variant="link"
                                  size="sm"
                                  id={product.id}
                                  onClick={(e) => this.handleDeleteOnClick(e)}
                                >
                                  <i
                                    id={product.id}
                                    className="fas fa-minus-circle icon-red"
                                  />
                                </Button>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        );
                      })}
                    {supplier != "" && (
                      <tr>
                        <td colSpan="2">
                          <Form.Control
                            as="select"
                            name="addCode"
                            onChange={(e) => this.handleAddOnChange(e)}
                          >
                            <option value=""></option>
                            {this.props.products &&
                              this.props.products.map((product) => {
                                if (
                                  !products.find(
                                    (obj) => obj.product_id == product.id
                                  )
                                ) {
                                  return (
                                    <option value={product.id}>
                                      {product.code +
                                        " - " +
                                        product.description}
                                    </option>
                                  );
                                }
                              })}
                          </Form.Control>
                        </td>
                        <td className="table-row-action">
                          <Form.Control
                            type="input"
                            name="addQuantity"
                            defaultValue="0"
                            onKeyUp={(e) => this.handleAddOnChange(e)}
                            readOnly={addProduct.id ? false : true}
                          />
                        </td>
                        <td className="table-row-action">
                          <Form.Control
                            type="input"
                            name="addPrice"
                            defaultValue="0"
                            onKeyUp={(e) => this.handleAddOnChange(e)}
                            readOnly={addProduct.id ? false : true}
                          />
                        </td>
                        <td className="table-row-action">
                          <Form.Control
                            type="input"
                            name="addVat"
                            defaultValue="0"
                            onKeyUp={(e) => this.handleAddOnChange(e)}
                            readOnly={addProduct.id ? false : true}
                          />
                        </td>
                        <td>{addProduct.total_amount || 0}</td>
                        <td>
                          <Button
                            variant="link"
                            size="sm"
                            {...(addProduct.id ? "" : { disabled: true })}
                            onClick={(e) => this.handleAddProductOnClick(e)}
                          >
                            <i className="fas fa-plus-circle icon" />
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={(e) => this.handleSubmit(e, false)}
                disabled={this.state.purchased.length > 0 ? false : true}
              >
                Save
              </Button>
              <Button
                variant="primary"
                onClick={(e) => this.handleSubmit(e, true)}
                disabled={this.state.purchased.length > 0 ? false : true}
              >
                Purchase
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showConfirmModal && (
          <Modal
            show={this.state.showConfirmModal}
            onHide={this.closeConfirmModal}
            backdrop="static"
            keyboard={false}
            centered
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>Confirm Purchase for {this.state.company}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <b>Products Ordered:</b>
              </p>
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price {currencySymbol}</th>
                    <th>Vat {currencySymbol}</th>
                    <th>Amount {currencySymbol}</th>
                  </tr>
                </thead>
                <tbody>
                  {purchased.length > 0 &&
                    purchased.map((purchase) => {
                      return (
                        <tr>
                          <td>
                            {purchase.code} - {purchase.description}
                          </td>
                          <td>{purchase.quantity}</td>
                          <td>{purchase.price}</td>
                          <td>{!isNaN(purchase.vat) ? purchase.vat : "-"}</td>
                          <td>{purchase.total_amount}</td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan="4" style={{ textAlign: "right" }}>
                      <b>Subtotal {currencySymbol}:</b>
                    </td>
                    <td>
                      <b>{computeOverallSubtotal(purchased)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" style={{ textAlign: "right" }}>
                      <b>Vat {currencySymbol}:</b>
                    </td>
                    <td>
                      <b>{computeOverallVat(purchased)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" style={{ textAlign: "right" }}>
                      <b>Total Amount {currencySymbol}:</b>
                    </td>
                    <td>
                      <b>{computeTotal(purchased)}</b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.closeConfirmModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={(e) => this.handleConfirmPurchase(e, 1)}
              >
                Purchase
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

function mapState(state) {
  const { process } = state;
  const { items, po_form, created } = process;
  return { items, po_form, created };
}

const actionCreators = {
  savePurchaseOrder: processActions.savePurchaseOrder,
};

export default connect(mapState, actionCreators)(PurchaseModal);
