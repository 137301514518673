import React from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { connect } from "react-redux";
import { navigateActions } from '../_actions/navigate.actions';
import { withRouter } from 'react-router';
import { history } from '../_helpers/history';

class SideNavigation extends React.PureComponent {

  constructor() {
    super();
  }

  handleSelected = (selected, key, parentId) => {
    this.props.navigate(selected, key, parentId);
  }

  handleOnClick = (event) => {
    const slug = history.location.pathname.substring(1);
    if (event.target.dataset.slug === slug) {
      window.location.reload(false);
    }
  }

  render () {
    const { user } = this.props;

    if (user && user.navigation) {
      const { navigation } = user;
      const uri = history.location.pathname.substring(1);

      return <SideNav
        style={{ position: "fixed" }}
        // onClick={(e) => this.handleOnClick(e)}
        onSelect={(selected, event) => {
          this.handleSelected(selected, event.target.dataset.id, event.target.dataset.parentid);
        }}
      >
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={uri||"place"}>
          {navigation.map(item =>
          <NavItem eventKey={item.slug} key={item.id}>
            <NavIcon><i className={JSON.parse(item.component_attributes).className} style={{ fontSize: '1.75em' }} /></NavIcon>
            <NavText data-slug={item.slug} data-parentid="" data-id={item.id}>{item.name}</NavText>
            {item.children && item.children.map(child =>
              <NavItem eventKey={child.slug} key={child.id}>
                <NavText data-slug={child.slug} data-parentid={item.id} data-id={child.id}>{child.name}</NavText>
              </NavItem>
            )}
          </NavItem>
        )}
        </SideNav.Nav>
      </SideNav>;
    }

    return <></>;
  }
}

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { user } = authentication;

  let navigation = [];
  if (user && user.navigation) {
    navigation = user.navigation;
  }

  return {
    user,
    navigation
  }
}

const actionCreators = {
  navigate: navigateActions.to
};

export default withRouter(connect(mapStateToProps, actionCreators)(SideNavigation));

