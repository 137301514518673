import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { processActions } from "../../../../_actions/process.actions";
import {
  getAccounts,
  getPaperWorksCreated,
  getPostitsNotes,
  getSchedule,
  setPostitsNotes,
} from "../../../../_helpers/session";
import AccountInfo from "./AccountInfo";
import Checklist from "./Checklist";

class Postits extends React.Component {
  constructor() {
    super();

    this.state = {
      checklist: [],
      customerId: "",
      orderId: "",
      roundNo: "",
      showContent: false,
      notes: "",
      readOnly: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { params } = this.props;
    const states = [];
    let showContent = false;

    if (getAccounts() && getSchedule()) {
      showContent = true;
    }

    if (params) {
      for (const state in params) {
        states.push(state);
        this.setState({ [state]: params[state] });
      }

      this.setState({ showContent: showContent }, () => {
        const { roundNo, customerId } = this.state;
        const hasPaperworkCreated = getPaperWorksCreated(roundNo);
        this.setState({
          notes: params.orders.notes || "",
          readOnly:
            hasPaperworkCreated && hasPaperworkCreated == "true" ? true : false,
        });
      });
    }

    if (!states.includes("customerId") || !states.includes("roundNo")) {
      alert(
        "Kindly select an schedule first before checking the customer product list. Thank you!"
      );
      this.props.onLoad("rounds");
    }
  }

  handleOnKeyUp = (e) => {
    e.preventDefault();

    setPostitsNotes(this.state.roundNo, this.state.customerId, e.target.value);
    this.setState({ notes: e.target.value });
  };

  handleOnBlur = (e) => {
    e.preventDefault();
    this.props.saveNotes(this.props.params.orderId, e.target.value);
  };

  render() {
    const { showContent, customerId, roundNo, orderId } = this.state;
    console.log("Positis --->", this.props);
    return (
      <>
        <Container className="no-gutter">
          <Row>{showContent && <AccountInfo customerId={customerId} />}</Row>
          <Row>
            {showContent && (
              <Checklist {...this.state} onRowClick={this.props.onRowClick} />
            )}
          </Row>
          <Row>
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Notes:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  cols={200}
                  onKeyUp={(e) => this.handleOnKeyUp(e)}
                  onBlur={(e) => this.handleOnBlur(e)}
                  defaultValue={this.state.notes}
                  readOnly={this.state.readOnly}
                />
              </Form.Group>
            </Form>
          </Row>
        </Container>
      </>
    );
  }
}

function mapState(state) {
  return { state };
}

const actionCreators = {
  saveNotes: processActions.saveNotes,
};

export default connect(null, actionCreators)(Postits);
