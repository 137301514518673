import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import MultiSelect from "../../components/Form/MultiSelect";
import SingleSelect from "../../components/Form/SingleSelect";
import { processActions } from "../../_actions/process.actions";
import { processConstants } from "../../_constants/process.constants";
import { currencySymbol } from "../../_helpers/content";

class EditProductForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suppliers: this.props.suppliers,
      categories: this.props.categories,
      data: this.props.record,
      resetValue: false,
    };
    console.log("Test ---");
    console.log("This State Data <--->", this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("Test ---");
    console.log("This State Data <--->", this.state);
    if (this.props.process && this.props.process != prevProps.process) {
      if (
        this.props.process.saveForm &&
        this.props.process.saveForm != prevProps.process.saveForm
      ) {
        this.setState({ data: {}, resetValue: true });
        document.getElementById("editProductForm").reset();
      }
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("D <----> ", e);
    // this.setState({ resetValue: false });
    // this.props.submitForm(
    //   processConstants.PROCESS_EDIT_PRODUCT,
    //   this.state.data
    // );
  };

  handleChange = (e) => {
    e.preventDefault();

    let state = this.state;
    console.log("E Target Name--->", e.target.name);

    state["data"][e.target.name] = e.target.value;
    // console.log("Test ---");
    // console.log("This State Data <--->", this.state);
    if (this.props.isModal) {
      this.props.onChange(e.target.name, e.target.value);
    }
  };

  handleSelectChange = (value) => {
    let data = this.state.data;
    data.category = value.value;

    if (this.props.isModal) {
      this.props.onChange("category", value.value);
    }
  };

  handleSupplierSelectChange = (value) => {
    let data = this.state.data;
    data.suppliers = value.value;

    if (this.props.isModal) {
      this.props.onChange("suppliers", value.value);
    }
  };

  render() {
    const category = this.state.categories.find(
      (obj) => obj.label == this.state.data.product_category
    );
    const supplier = this.state.suppliers.find(
      (obj) => obj.value == this.state.data.supplier_id
    );
    let suppliersArr = "";
    let suppliers = "";

    if (typeof this.state.data.suppliers == "string") {
      suppliersArr = this.state.data.suppliers.split(", ");
      suppliers = this.state.suppliers.filter((obj) => {
        const supplier = obj.label.split(" - ");
        return suppliersArr.includes(supplier[1]);
      });
    }

    return (
      <Form id="editProductForm" onSubmit={this.handleFormSubmit}>
        <Form.Group as={Row} key="editProductFormGroupCode">
          <Form.Label column sm="2">
            Product Code<span>*</span>
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="code"
              placeholder="Enter Product Code"
              onKeyUp={this.handleChange}
              defaultValue={this.state.data.code || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="editProductFormGroupDesc">
          <Form.Label column sm="2">
            Product Description<span>*</span>
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="description"
              placeholder="Enter Product Description"
              onKeyUp={this.handleChange}
              defaultValue={this.state.data.description || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="editProductFormGroupPrice">
          <Form.Label column sm="2">
            Recommended Sale Price {currencySymbol}
            <span>*</span>
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="price"
              placeholder="Enter Recommended Sale Prices"
              onKeyUp={this.handleChange}
              defaultValue={this.state.data.price || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="editProductFormGroupSize">
          <Form.Label column sm="2">
            Case/Size Descriptor
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="size_descriptor"
              placeholder="Enter Case/Size Descriptor"
              onKeyUp={this.handleChange}
              defaultValue={this.state.data.size_descriptors || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="editProductFormGroupVat">
          <Form.Label column sm="2">
            VAT Percentage
          </Form.Label>
          <Col sm="4" className="float-left">
            <Form.Control
              type="input"
              name="vat_percentage"
              placeholder="Enter VAT Percentage"
              onChange={this.handleChange}
              defaultValue={this.state.data.vat_percentage || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="editProductFormCategory">
          <Form.Label column sm="2">
            Product Category
          </Form.Label>
          <Col sm="4" className="float-left">
            <SingleSelect
              options={this.state.categories}
              placeholder="Select a Product Category"
              onChange={this.handleSelectChange}
              resetValue={this.state.resetValue}
              defaultValue={category || ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} key="editProductFormGroupCompany">
          <Form.Label column sm="2">
            Supplier
          </Form.Label>
          <Col sm="4" className="float-left">
            {this.state.data.supplier_id ? (
              <Form.Control
                type="input"
                name="supplier"
                placeholder="Supplier"
                defaultValue={supplier.label}
                editable={false}
                readOnly={true}
              />
            ) : (
              <SingleSelect
                options={this.state.suppliers}
                placeholder="Select a Supplier"
                onChange={this.handleSupplierSelectChange}
                resetValue={this.state.resetValue}
                defaultValue={supplier || ""}
              />
            )}
          </Col>
        </Form.Group>
      </Form>
    );
  }
}

function mapState(state) {
  const { process } = state;
  return { process };
}

const actionCreators = {
  submitForm: processActions.submitForm,
};

export default connect(mapState, actionCreators)(EditProductForm);
