import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

class LinkTooltip extends React.Component
{
  constructor () {
    super ();
  }

  onClick = (e) => {
    this.props.onClick(e);
  }

  render () {

    const { icon, message, id, method, attributes, title } = this.props;

    return <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>{message}</Tooltip>
      }
    >
    {this.props.type=='span' 
      ? <span>
          <i 
            className={icon} 
            style={{ fontSize: '1.75em' }} 
          />
          </span>
      : <Button 
          variant="link" 
          size="sm" 
          {...(this.props.onClick ? { onClick: this.onClick } : '')}
          {...(this.props.disabled ? { disabled: this.props.disabled } : '')}
        >
          <i 
            className={icon + ' icon'} 
            data-method={method}
            data-title={title || message}  
            data-id={id}
            data-attrib={attributes}
          />
        </Button>
    }
    </OverlayTrigger>;
  }
}

export default LinkTooltip;