import React from 'react';
import { Button, Container, Form, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { placeActions } from '../../_actions/place.actions';
import { processActions } from '../../_actions/process.actions';
import { processConstants } from '../../_constants/process.constants';
import { generateChecklistToUpdate } from '../../_helpers/checklist';
import { checklistStatuses } from '../../_helpers/options';
import { color, computeCustomerPriceByPercentage, computeDiscountPercentageByCustomerPrice, getFloatValue } from '../../_helpers/placeOrder';
import { getChecklist, getProducts } from '../../_helpers/session';

const removeChecklistOnProducts = (products, checklist) => {
  let newProducts = products;
  
  if (checklist) {
    checklist.forEach(list => {
      newProducts = newProducts.filter((obj) => obj.id !== list.product_id);
    });
  }
  return newProducts;
}

class CustomerChecklist extends React.Component
{
  constructor() {
    super();

    this.state = {
      show: false,
      customerId: null,
      checklist: [],
      products: [],
      productOptions: [],
      discountPercentage: [],
      customerPrice: [],
      status: [],
      newRows: [],
      addRow: {},
      enableUpdateButton: false
    }
  }

  UNSAFE_componentWillMount() {
    const { customerId } = this.props;
    this.setState({ 
      show: false,
      customerId: customerId,
      products: getProducts()
    }, () => {
      this.setState({ 
        productOptions:  this.state.products ? removeChecklistOnProducts(getProducts(), this.state.checklist) : []
      })
    })
    this.props.getChecklist(customerId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.place && this.props.place !== prevProps.place && this.props.place.checklist) {
      this.setState({
        show: true,
        checklist: getChecklist(this.state.customerId)
      })
    }

    if (this.props.process && this.props.process !== prevProps.process && this.props.process.items) {
      this.setState({
        show: true,
        checklist: getChecklist(this.state.customerId),
        discountPercentage: [],
        customerPrice: [],
        status: [],
        newRows: [],
        addRow: {},
        enableUpdateButton: false
      })
    }
  }

  handleInputChange = (e, row) => {

    const id = parseInt(e.target.dataset.id);
    const column = e.target.dataset.column;
    const value = e.target.value;
    const stateValue = { id: id, value: value };

    const stateToUpdate = column == 'discountPercentage' ? 'customerPrice' : 'discountPercentage';
    const computedDiscount = column == 'discountPercentage' ? computeCustomerPriceByPercentage(row.price, value) : computeDiscountPercentageByCustomerPrice(row.price, value);
    const computedDiscountState = { id: id, value: computedDiscount };

    this.setState({
      [column]: [...this.state[column].filter((obj) => obj.id !== id), stateValue],
      [stateToUpdate]: [...this.state[stateToUpdate].filter((obj) => obj.id !== id), computedDiscountState]
    });

    let checklist = this.state.checklist;

    checklist = checklist.map((obj, index) => {
      if (obj.checklist == id) {
        if (column == 'discountPercentage') {
          obj.discount_percentage = value;
          obj.customer_price = computedDiscount;
        } else if (column == 'customerPrice') {
          obj.customer_price = value;
          obj.discount_percentage = computedDiscount;
        }
      }
      return obj;
    })    
    
    this.setState({
      checklist: checklist,
      enableUpdateButton: true
    });

    document.querySelector("#discountPercentage"+id).value = column == 'discountPercentage' ? value : computedDiscount;
    document.querySelector("#customerPrice"+id).value = column == 'customerPrice' ? value : computedDiscount;
  }

  handleSelectChange = (e, row) => {
    const id = parseInt(e.target.dataset.id);
    const column = e.target.dataset.column;
    const value = e.target.value;
    const stateValue = { id: id, value: value };

    this.setState({
      [column]: [...this.state[column].filter((obj) => obj.id !== id), stateValue],
      enableUpdateButton: true
    });
  }

  handleAddProductOptionChange = (e) => {
    let newProduct = this.state.products.find((obj) => obj.id == e.target.value);
    newProduct.status = 'Active';
    this.setState({ addRow: newProduct });
  }

  handleAddRowInputChange = (e) => {
    let addRow = this.state.addRow;
    const column = e.target.dataset.column; 
    const value = e.target.value;
    addRow[column] = value;

    if (column == 'discountPercentage') {
      addRow.customerPrice = computeCustomerPriceByPercentage(addRow.price, value);
    } else if (column == 'customerPrice') {
      addRow.discountPercentage = computeDiscountPercentageByCustomerPrice(addRow.price, value);
    }
    
    this.setState({ addRow: addRow});  

    document.querySelector("#addDiscountPercentage").value = column == 'discountPercentage' ? value : addRow.discountPercentage;
    document.querySelector("#addCustomerPrice").value = column == 'customerPrice' ? value : addRow.customerPrice;
  }

  handleAddProductOnClick = (e) => {
    e.preventDefault();
    const newRow = this.state.addRow;
    this.setState({ 
      newRows: [...this.state.newRows, newRow],
      productOptions: [...this.state.products.filter((obj) => obj.id !== newRow.id)],
      addRow: {},
      enableUpdateButton: true
    })

    document.querySelector("#addDiscountPercentage").value = '';
    document.querySelector("#addCustomerPrice").value = '';
    document.querySelector("#addProductCodes").value = '';

  }

  handleRemoveProductOnClick = (e, row) => {
    e.preventDefault();
    this.setState({ 
      newRows: [...this.state.newRows.filter((obj) => obj.id !== row.id)],
      enableUpdateButton: true 
    });
  }

  handleNewRowInputChange = (e, row) => {
    e.preventDefault();
    const column = e.target.dataset.column; 
    const value = e.target.value;
    row[column] = value;

    if (column == 'discountPercentage') {
      row.customerPrice = computeCustomerPriceByPercentage(row.price, value);
    } else if (column == 'customerPrice') {
      row.discountPercentage = computeDiscountPercentageByCustomerPrice(row.price, value);
    }

    this.setState({ 
      newRows: [...this.state.newRows.filter((obj) => obj.id !== row.id), row],
      enableUpdateButton: true
    });

    document.querySelector("#newDiscountPercentage"+row.id).value = row.discountPercentage;
    document.querySelector("#newCustomerPrice"+row.id).value = row.customerPrice;
  }

  handleUpdateOnClick = (e) => {
    
    const data = generateChecklistToUpdate(
      this.state.customerId, 
      this.state.newRows, 
      this.state.discountPercentage, 
      this.state.customerPrice, 
      this.state.status
    );
    this.props.updateChecklist(this.state.customerId, data);
  }

  render() {
    const { checklist, products, productOptions, addRow, enableUpdateButton } = this.state;
    return <>
      <Container>
        <Row>
          <Table size="sm" bordered>
            <thead stye={{ textAlign: "center" }}>
              <tr>
                <th>PRODUCT CODE</th>
                <th>DESCRIPTION</th>
                <th>REG. PRICE</th>
                <th>DISCOUNT (%)</th>
                <th>CUSTOMER PRICE</th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              { ! this.state.show && <tr><td colSpan="6"><i>Loading records...</i></td></tr>}
              { this.state.show && checklist && checklist.length > 0 && <>
                { checklist.map((list, index) => {
                  
                  const product = products.find((obj) => obj.id == list.product_id);
                  const statusObj = this.state.status.length > 0 ? this.state.status.find((obj) => obj.id == list.checklist_id) : null;
                  const status = statusObj != null ? statusObj.value : list.status;
                  const discountPercentage = getFloatValue(this.state.discountPercentage.find((obj) => obj.id == list.checklist_id), list.discount_percentage);
                  const customerPrice = getFloatValue(this.state.customerPrice.find((obj) => obj.id == list.checklist_id), list.discounted_price);
                  if (!product) {
                    return;
                  }
                  return <tr 
                    key={ list.checklist_id }
                    className={ "table-color-"+color(status)}
                  >
                    <td>{ list.code }</td>
                    <td>{ list.description }</td>
                    <td>{ product?.price }</td>
                    <td>
                      <Form.Group key={ 'discount_percentage-'+index } className="table-form-group">
                        <Form.Control 
                          type="input"
                          id={"discountPercentage"+list.checklist_id} 
                          name="discount_percentage" 
                          data-id={ list.checklist_id } 
                          data-column="discountPercentage" 
                          onKeyUp={ (e) => this.handleInputChange(e, list) }
                          defaultValue={ getFloatValue(this.state.discountPercentage.find((obj) => obj.id == list.checklist_id), list.discount_percentage) }
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group key={ 'customer-price-'+index } className="table-form-group">
                        <Form.Control 
                          type="input" 
                          id={"customerPrice"+list.checklist_id} 
                          name="customer_price" 
                          data-id={ list.checklist_id } 
                          data-column="customerPrice" 
                          onKeyUp={ (e) => this.handleInputChange(e, list) } 
                          defaultValue={ customerPrice }
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group key={ 'status-'+index } className="table-form-group">
                        <Form.Control 
                          key={'status-'+list.checklist_id}
                          as="select" 
                          name="status" 
                          data-id={ list.checklist_id } 
                          data-column="status"
                          onChange={ (e) => this.handleSelectChange(e, list) }
                          defaultValue={ status }  
                        >
                          { checklistStatuses.map(option =>
                            <option key={"status-option"+option} value={option}>{option}</option>
                          )}
                        </Form.Control>
                      </Form.Group>
                    </td>
                    <td></td>
                  </tr>
                })}</>}
                { this.state.show && ((checklist && checklist.length == 0) || ! checklist) && <tr><td colSpan="6"><i>No records found.</i></td></tr> }
                { this.state.newRows.map((item, idx) => {
                  if (item.id) {
                    return <tr key={"newRow-"+idx}>
                      <td>{ item.code }</td>
                      <td>{ item.description }</td>
                      <td>{ item.price }</td>
                      <td>
                        <Form.Group className="table-form-group">
                          <Form.Control 
                            type="input" 
                            id={"newDiscountPercentage"+item.id}
                            name="new_discount_percentage" 
                            data-column="discountPercentage" 
                            onKeyUp={ (e) => this.handleNewRowInputChange(e, item) } 
                            defaultValue={ item.discountPercentage }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="table-form-group">
                          <Form.Control 
                            type="input" 
                            id={"newCustomerPrice"+item.id}
                            name="new_discount_amount" 
                            data-column="customerPrice" 
                            onKeyUp={ (e) => this.handleNewRowInputChange(e, item) } 
                            defaultValue={ item.customerPrice }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="table-form-group">
                          <Form.Control 
                            as="select" 
                            name="new_status" 
                            data-column="status"
                            onChange={ (e) => this.handleNewRowSelectChange(e, item) }
                            defaultValue={ item.status }  
                          >
                            { checklistStatuses.map(option =>
                              <option value={option}>{option}</option>
                            )}
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Button variant="link" size="sm" onClick={ (e) => this.handleRemoveProductOnClick(e, item) }>
                          <i className="fas fa-minus-circle icon-red" />
                        </Button>
                      </td>
                    </tr>
                  }
                })}
                <tr>
                  <td colSpan="2">
                    <Form.Group className="table-form-group">
                      <Form.Control 
                        as="select"
                        id="addProductCodes"
                        name="productCodes"
                        defaultValue=""
                        onChange={ (e) => this.handleAddProductOptionChange(e)}
                      >
                        <option value="">Add New Product</option>
                        { productOptions.map((product) => 
                          <option 
                            key={product.id}
                            value={product.id} 
                            data-code={product.code}
                            data-description={product.description}
                            data-price={product.price}
                          >
                            {product.code+" - "+product.description}
                          </option>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </td>
                  <td>{ addRow.price }</td>
                  <td>
                    <Form.Group className="table-form-group">
                      <Form.Control 
                        type="input" 
                        id="addDiscountPercentage"
                        name="add_discount_percentage" 
                        data-column="discountPercentage" 
                        onKeyUp={ (e) => this.handleAddRowInputChange(e) } 
                        defaultValue={ addRow.discountPercentage }
                        readOnly={ addRow.id ? false : true }
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group className="table-form-group">
                      <Form.Control 
                        type="input" 
                        id="addCustomerPrice"
                        name="add_discount_amount" 
                        data-column="customerPrice" 
                        onKeyUp={ (e) => this.handleAddRowInputChange(e) } 
                        defaultValue={ addRow.customerPrice }
                        readOnly={ addRow.id ? false : true }
                      />
                    </Form.Group>
                  </td>
                  <td>{ addRow.status }</td>
                  <td>
                    <Button variant="link" size="sm" {...(addRow.id ? '' : {disabled: true})} onClick={ (e) => this.handleAddProductOnClick(e) }>
                      <i className="fas fa-plus-circle icon" />
                    </Button>
                  </td>
                </tr>
            </tbody>
          </Table>
        </Row>
        <Row>
          <Button variant="primary" {...(enableUpdateButton ? '' : {disabled: true})} onClick={ (e) => this.handleUpdateOnClick(e) }>Update Checklist</Button>
        </Row>
      </Container>
    </>;
  }
}

function mapState(state) {
  const { place, process, alert } = state;
  return { place, process, alert };
}

const actionCreators = {
  getChecklist: placeActions.getChecklistByCustomerId,
  updateChecklist: processActions.updateCustomerChecklist
}

export default connect(mapState, actionCreators)(CustomerChecklist);