import { titleCase } from './content';

export const headerWithCurrency = ['price', 'vat', 'vat_rate'];

export const getColumns = (item, displayType, showAction) => {

  const columns = [{dataField: '', text: '', editable: false, headerAlign: 'center'}];

  const hasAction = columns.find((element) => {
    return element.dataField === 'action';
  })

  if (showAction && ! hasAction) {
    columns.push({
      dataField: 'action', 
      text: '', 
      editable: false, 
      headerAlign: 'center',
      hidden: (displayType && displayType == 'placeOrder') ? true : false
    });
  }

  for (const header in item) {
    let text = titleCase(header.replace(/_/gi, " "));
    let hidden = false;

    if (displayType && displayType == 'cart' && header == 'product_supplier_id') {
      text = 'ID';
    }

    if (displayType && displayType == 'placeOrder') {
      if (header == 'status') {
        hidden = true;
      }
    }

    if (header == 'discount_percentage') {
      text = 'Discount (%)';
    }
    
    const column = {
      dataField: header, 
      text: text,
      hidden: hidden, 
      editable: false, 
      headerAlign: 'center'
    };
    columns.push(column);
  }

  if (displayType) {

    if (displayType == 'cart') {
      const hasQuantity = columns.find((element) => {
        return element.dataField === 'quantity';
      })

      if (! hasQuantity) {
        columns.push({
          dataField: 'quantity', 
          text: 'Quantity'
        });
      }
    }
  }

  return columns;
}

export const getSuppliersColumn = (item, displayType, showAction) => {
  const columns = [{dataField: '', text: '', editable: false, headerAlign: 'center'}];

  const hasAction = columns.find((element) => {
    return element.dataField === 'action';
  })

  if (showAction && ! hasAction) {
    columns.push({
      dataField: 'action', 
      text: '',
      hidden: (displayType && displayType == 'placeOrder') ? true : false,
      editable: false, 
      headerAlign: 'center'
    });
  }

  for (const header in item) {
    let text = titleCase(header.replace(/_/gi, " "));
    let hidden = false;

    if (displayType && displayType == 'cart' && header == 'product_supplier_id') {
      text = 'ID';
    }

    if (displayType && displayType == 'placeOrder') {
      if (header == 'status') {
        hidden = true;
      }
    }

    if (header == 'discount_percentage') {
      text = 'Discount (%)';
    }
    
    const column = {
      dataField: header, 
      text: text,
      hidden: hidden,
      editable: false, 
      headerAlign: 'center'
    };
    columns.push(column);
  }

  if (displayType) {

    if (displayType == 'cart') {
      const hasQuantity = columns.find((element) => {
        return element.dataField === 'quantity';
      })

      if (! hasQuantity) {
        columns.push({
          dataField: 'quantity', 
          text: 'Quantity',
          editable: false, 
          headerAlign: 'center'
        });
      }
    }
  }

  return columns;
}

export const expandedSupplierColumns = () => {
  const columns = [
    { dataField: 'id', text: 'ID', editable: false, headerAlign: 'center' },
    { dataField: 'supplier', text: 'Supplier', editable: false, headerAlign: 'center' },
    { dataField: 'currency', text: 'Currency', editable: false, headerAlign: 'center' },
    { dataField: 'price', text: 'Selling Price', editable: false, headerAlign: 'center' },
    { dataField: 'no_of_stocks', text: 'Total Stocks', editable: false, headerAlign: 'center' },
    { dataField: 'quantity', text: 'Add Quantity', type: 'number', editable: false, headerAlign: 'center' },
  ];

  return columns;
}

export const orderAccountsColumns = () =>{
  const columns = [
    {dataField: 'customer_id', text: 'ID', hidden: true, headerAlign: 'center'},
    {dataField: 'company_ref', text: 'ACCOUNT REF', editable: false, headerAlign: 'center'},
    {dataField: 'company', text: 'ACCOUNT NAME', editable: false, headerAlign: 'center'},
    {dataField: 'old_balance', text: 'OLD BALANCE', editable: false, headerAlign: 'center'},
    {dataField: 'todays_balance', text: 'TODAYS BALANCE', editable: false, headerAlign: 'center'},
    {dataField: 'total_balance', text: 'TOTAL BALANCE', editable: false, headerAlign: 'center'},
    {dataField: 'cash', text: 'PAID IN CASH', type: 'number', headerAlign: 'center'},
    {dataField: 'cheque', text: 'PAID IN CHEQUE', type: 'number', headerAlign: 'center'},
    {
      dataField: 'new_balance', 
      text: 'NEW BALANCE', 
      editable: false, 
      headerAlign: 'center',
      formatter: (cell, row) => {
        const cash = row.cash ? row.cash : 0;
        const cheque = row.cheque ? row.cheque : 0;

        let newBalance = parseFloat(row.total_balance - (cash + cheque)).toFixed(2);

        if (newBalance > 0) {
          return newBalance;
        }
        return cell;
      }
    },
    {dataField: 'status_id', hidden: true, editable: false, headerAlign: 'center'},
    {
      dataField: 'invoice_no', 
      text: 'INVOICE NUMBER', 
      editable: false, 
      headerAlign: 'center', 
      headerStyle: {
        backgroundColor: '#C2C2C2'
      },
      style: {
        backgroundColor: '#C2C2C2'
      }
    },
    {
      dataField: 'total_amount', 
      text: 'ORDER', 
      editable: false, 
      headerAlign: 'center',
      headerStyle: {
        backgroundColor: '#C2C2C2'
      },
      style: {
        backgroundColor: '#C2C2C2'
      }
    },
    {
      dataField: 'prepayment', 
      text: 'IN-CASH PAYMENT', 
      editable: false, 
      headerAlign: 'center',
      headerStyle: {
        backgroundColor: '#C2C2C2'
      },
      style: {
        backgroundColor: '#C2C2C2'
      }
    }
  ]

  return columns;
}