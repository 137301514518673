import { processConstants } from '../_constants/process.constants';
import { processService } from '../_services/process.service';

export function process(state = {}, action) {
  switch (action.type) {
    case processConstants.API_GET_REQUEST:
    case processConstants.API_POST_REQUEST:
    case processConstants.API_GET_REQUEST_PRODUCTS:
    case processConstants.API_GET_REQUEST_SUPPLIERS:
    case processConstants.API_POST_REQUEST_ROUND_SCHEDULE:
    case processConstants.API_POST_REQUEST_DRIVER_ROUNDS:
    case processConstants.API_POST_REQUEST_CUSTOMER_CHECKLIST:
    case processConstants.API_PUT_REQUEST:
      return {
        loading: true
      };
    case processConstants.API_GET_SUCCESS:
      return {
        loading: false,
        items: action.data,
        form: action.extra
      };
    case processConstants.API_POST_FAILURE:
    case processConstants.API_GET_FAILURE:
    case processConstants.API_POST_FAILURE_ROUND_SCHEDULE:
    case processConstants.API_POST_FAILURE_DRIVER_ROUNDS:
    case processConstants.API_POST_FAILURE_CUSTOMER_CHECKLIST:
    case processConstants.API_PUT_FAILURE:
      return {
        loading: false,
        items: action.error
      }
    case processConstants.API_POST_SUCCESS:
    case processConstants.API_POST_SUCCESS_DRIVER_ROUNDS:
    case processConstants.API_POST_SUCCESS_CUSTOMER_CHECKLIST:
      return {
        loading: false,
        items: action.form,
        saveForm: true
      }
    case processConstants.CLEAR_FORM:
      return {}
    
    case processConstants.API_GET_SUCCESS_SUPPLIERS:
      return {
        loading: false,
        isProductStocksModal: true,
        mappedSuppliers: action.data.mapped_suppliers,
        suppliers: action.data.suppliers
      }
    case processConstants.API_GET_FAILURE_PRODUCTS:
    case processConstants.API_GET_FAILURE_SUPPLIERS:
      return {
        loading: false,
        isProductStocksModal: true
      }
    case processConstants.API_GET_SUCCESS_PRODUCTS:
      return {
        loading: false,
        products: action.data
      }
    case processConstants.CLEAR_SUPPLIERS:
    case processConstants.CLEAR_PRODUCTS:
      return {}; 
    case processConstants.API_POST_SUCCESS_ROUND_SCHEDULE:
      return {
        loading: false,
        items: action.form,
        postSchedule: true
      }
    case processConstants.API_POST_SUCCESS_DRIVER_INFO:
      return {
        loading: false,
        items: action.form,
        postDriverInfo: true
      }
    case processConstants.API_GET_SUCCESS_PO_FORM:
      return {
        loading: false,
        items: action.form,
        po_form: true
      }
    case processConstants.API_POST_SUCCESS_PO_FORM:
      return {
        loading: false,
        items: action.form,
        // po_form: true,
        created: true
      }
    case processConstants.API_GET_SUCCESS_PO_ALL:
    case processConstants.API_PUT_SUCCESS_PO_FORM:
      return {
        loading: false,
        items: action.data,
        po_content: true
      }
    case processConstants.API_GET_SUCCESS_PO_DETAILS:
      return {
        loading: false,
        items: action.data,
        po_details: true
      }
    case processConstants.API_POST_SUCCESS_PO:
      return {
        loading: false,
        items: action.data,
        po_content: true
      }
    case processConstants.API_GET_SUCCESS_SUPPLIER_LIST_SELECT:
      return {
        loading: false,
        suppliers: action.data,
        supplier_list_select: true
      }
    case processConstants.API_GET_SUCCESS_CATEGORY_LIST_SELECT:
      return {
        loading: false,
        categories: action.data,
        category_list_select: true
      }
    case processConstants.API_GET_SUCCESS_PRODUCT_LIST_SELECT:
      return  {
        loading: false,
        product_list_select: action.data
      }
    case processConstants.EXTRA:
      return {
        extra: action.data
      }
    case processConstants.API_GET_SUCCESS_PRODUCT_STOCKS:
      return {
        loading: false,
        productSuppliers: action.data
      }
    case processConstants.API_POST_SUCCESS_PRODUCT_STOCKS:
      return {
        loading: false,
        productSuppliersUpdate: true
      }
    default:
      return state;
  }
}