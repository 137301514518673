import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getRoundDays } from '../../_helpers/placeOrder';
import Drivers from './Drivers';

class Rounds extends React.Component
{
  constructor () {
    super ();

    this.state = {
      activeKey: '1'
    }
  }
  render () {
    const rounds = getRoundDays();

    return <div className="content">
      <h5>Rounds</h5>
      <Tabs
        defaultActiveKey="1"
        id="rounds"
        activeKey={ this.state.activeKey }
        onSelect={ (key) => this.setState({ activeKey: key}) }
      >
      { rounds.map((round) => {
          return <Tab eventKey={round.id} title={round.day}><Drivers round={round} /></Tab>
        })
      }
      </Tabs>
    </div>;
  }
}

export default connect(null, null)(Rounds);