export const addToPropCart = (record, cart, products, totalAmount, currency) => {
  let updateCart = true;
  if (cart && cart.customerId && cart.customerId != record.customerId) {
    if (window.confirm("You have unfinished cart for account "+cart.customerInfo.company+". Do you want to proceed creating new cart for account "+record.customerInfo.company+"?")) {
      updateCart = true;
    } else {
      updateCart = false;
    }
  }

  if (updateCart) {

    if (! record.customerInfo.roundId && ! record.customerInfo.driverId && ! record.customerInfo.roundNo) {
      alert('Kindly select customer delivery schedule under Calender tab to proceed.');
    } else {
      cart ={
        customerId: record.customerId,
        customerInfo: record.customerInfo,
        vatRate: 0, // TODO: update from record 
        subtotal: totalAmount, 
        totalAmount: totalAmount, // TODO: compute based on the given vat
        currency: currency,
        products: products
      }
    }
  }

  return cart;
}

export const getDefaultQuantity = (productId, cart, record) => {

  let quantity = null;
  
  if (cart && cart.products && record.customerId === cart.customerId) {

    cart.products.map(function (product) {

      if (product.productId === productId) {
        quantity = product.quantity;
        return;
      }
      
    });
  }
  
  return quantity;
}

export const computeTotalAmountOnCart = (cart) => {

  let totalAmount = 0.00;

  if (cart && cart.products) {
    cart.products.map(function (product) {

      product.supplier.map(function (supplier) {
        totalAmount = totalAmount + supplier.subtotal;
      })
    })
  }

  return (Math.round(totalAmount * 100) / 100).toFixed(2);
}

export const getProductIdOnCart = (cart) => {

  let products = [];

  if (cart && cart.products) {
    cart.products.map(function (product) {
      products = products.concat([product.productId]);
    })
  }

  return products;
}

export const productExists = (cart, productId) => {

  let productExists = false;

  if (cart && cart.products) {
    productExists = cart.products.find(function(product) {
      return product.productId === productId;
    });
  }

  return productExists;
}

export const checkoutData = (products) => {

  let data = [];

  products.map(function (product) {
    const datum = {
      product_id: product.productId,
      code: product.data.code,
      description: product.data.description,
      discounted_price: product.data.discounted_price,
      quantity: product.quantity,
      subtotal: product.subtotal,
      currency: product.data.currency
    }

    data.push(datum);
  });

  return data;
}

export const computeAmountPayableOnDelivery = (totalAmount, inCashAmount, paymentType) => {

  let payableOnDelivery = 0;

  if (paymentType === 'amount') {
    payableOnDelivery = totalAmount - inCashAmount;
  } else if (paymentType === 'percentage') {
    payableOnDelivery = totalAmount - (totalAmount * ( inCashAmount / 100 ))
  }

  return payableOnDelivery.toFixed(2);
}

export const computeAmountPayableInCash = (totalAmount, firstPaymentAmount, paymentType) => {

  let payableInCash = 0;

  if (paymentType === 'amount') {
    payableInCash = firstPaymentAmount;
  } else if (paymentType === 'percentage') {
    payableInCash = totalAmount * ( firstPaymentAmount / 100 )
  }

  return parseFloat(payableInCash).toFixed(2);
}

export const computeAmountPayableInPercentage = (totalAmount, inCashAmount) => {

  let percentage = (parseFloat(inCashAmount) / parseFloat(totalAmount)) * 100;
  
  return percentage.toFixed(2);
}

export const splitOrders = (products, amount) => {

  let totalAmount = 0;
  let orders = [];

  const sorted = products.sort((a, b) => parseFloat(b.data.discounted_price) - parseFloat(a.data.discounted_price));

  sorted.map(function (product) {

    for (let i=1; i<=product.quantity; i++) {
      const tempAmount = parseFloat(totalAmount) + parseFloat(product.data.discounted_price);
      if (totalAmount <= amount && tempAmount <= amount) {
        console.log(i, product.data.code, product.data.discounted_price, totalAmount, tempAmount, amount);
        totalAmount = tempAmount;
        orders.push(product.data);
      } else {
        break;
      }
      
      if (totalAmount == amount) {
        return;
      }
    }
    
  })

  return {
    orders: refineBatchOrder(orders),
    total: totalAmount.toFixed(2)
  }
}

const refineBatchOrder = (products) => {
  let newBatch = [];
  products.map(function (product) {

    if (newBatch.hasOwnProperty(product.product_id)) {
      const totalAmount = parseFloat(newBatch[product.product_id].discounted_price * newBatch[product.product_id].quantity).toFixed(2);;
      newBatch[product.product_id].quantity = Number(newBatch[product.product_id].quantity) + 1;
      newBatch[product.product_id].subtotal = totalAmount;
      newBatch[product.product_id].totalAmount = totalAmount;

    } else {
      newBatch[product.product_id] = {
        id: product.product_id,
        code: product.code,
        quantity: 1,
        currency: product.currency,
        price: product.price,
        discount_percentage: product.discount_percentage,
        discounted_price: product.discounted_price,
        vat_rate: product.vat_rate,
        subtotal: parseFloat(product.discounted_price).toFixed(2),
        totalAmount: parseFloat(product.discounted_price).toFixed(2)
      }
    }
  })
  return newBatch;
}