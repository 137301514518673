import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getCustomerDetails } from '../../../../_helpers/placeOrder';

class AccountInfo extends React.Component
{
  render () {
    const customer = getCustomerDetails(this.props.customerId);

    return <>
      { customer && <Container className="container-tab">
        <Row>
          <Col xs="2"><p className="customer-info"><b>Account Name:</b></p></Col><Col xs="4"><p className="customer-info">{customer.company}</p></Col>
          <Col xs="2"><p className="customer-info"><b>Contact:</b></p></Col><Col xs="4"><p className="customer-info">{(customer.first_name || '')+' '+(customer.last_name || '')}</p></Col>        
        </Row>
        <Row>
          <Col xs="2"><p className="customer-info"><b>Phone Number:</b></p></Col><Col xs="4"><p className="customer-info">{customer.phone_no_1 || ''}</p></Col>
          <Col xs="2"><p className="customer-info"><b>Opening Time:</b></p></Col><Col xs="4"><p className="customer-info">{customer.opening_time || ''}</p></Col>
        </Row>
        <Row>
          <Col xs="2"><p className="customer-info"><b>Mobile Number:</b></p></Col><Col xs="4"><p className="customer-info">{customer.mobile_no_1 || ''}</p></Col>
        </Row>
        <hr />
      </Container>
      }
    </>
  }
}

export default AccountInfo;