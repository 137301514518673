import React from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";

import { cartActions } from "../../_actions/cart.actions";
import { processActions } from "../../_actions/process.actions";
import { addToPropCart, getDefaultQuantity } from "../../_helpers/cart";
import Checkout from "./checkout/Checkout";
import ProductModal from "./checklist/ProductModal";
import { modalActions } from "../../_actions/modal.actions";

const { SearchBar } = Search;

const statusType = [
  { name: "Active", color: "white" },
  { name: "Warning", color: "orange" },
  { name: "Removed", color: "red" },
];

class ChecklistTable extends React.Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
      showProductModal: false,
      products: [],
      currency: "",
      totalAmount: "0.00",
      updateTotal: false,
    };

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowProductModal = this.handleShowProductModal.bind(this);
    this.handleCloseProductModal = this.handleCloseProductModal.bind(this);
    this.handleSubmitProductModal = this.handleSubmitProductModal.bind(this);
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
    window.location.reload(false);
  };

  handleShowProductModal = () => {
    this.setState({ showProductModal: true });
  };
  handleCloseProductModal = () => {
    this.setState({ showProductModal: false });
  };
  handleSubmitProductModal = () => {
    this.handleCloseProductModal();
    window.location.reload(false);
  };

  componentWillMount() {
    const { cart, customer } = this.props;

    if (cart && cart.items && customer) {
      const { items } = cart;

      if (items.customerId === customer.customerId) {
        this.setState({
          currency: items.currency,
          totalAmount: items.totalAmount,
          products: items.products,
          updateTotal: true,
        });
      }
    }
  }

  computeTotal = (products, currency) => {
    let total = 0;

    if (products) {
      products.map((product) => {
        let subtotal = total + Number(product.subtotal);
        total = subtotal;
      });
    }

    return Number(total).toFixed(2);
  };

  handleChange = (event, row) => {
    const quantity = Number(event.target.value);
    let isValid = true;
    let state = this.state;
    state["updateTotal"] = false;
    state["currency"] = row.currency;

    if (quantity > row.no_of_stocks) {
      isValid = false;
      alert(
        "Quantity must be less than or equal to the current product stock."
      );
    }

    if (quantity > 0 && isValid) {
      const subtotal = (quantity * Number(row.discounted_price)).toFixed(2);
      const exists =
        state["products"] &&
        state["products"].find((element) => {
          return element.productId === row.product_id;
        });

      if (!exists) {
        const products = {
          productId: row.product_id,
          subtotal: subtotal,
          quantity: quantity,
          data: row,
        };
        state["products"].push(products);
      } else {
        state["products"].map(function (product) {
          if (product.productId === row.product_id) {
            product.quantity = quantity;
            product.subtotal = subtotal;
            product.data = row;
            return;
          }
        });
      }

      state["totalAmount"] = this.computeTotal(state["products"], row.currency);

      this.setState((previousState) => {
        return {
          updateTotal: !previousState.updateTotal,
        };
      });

      const cart = addToPropCart(
        this.props.customer,
        this.props.cart.items,
        this.state.products,
        this.state.totalAmount,
        this.state.currency
      );
      this.props.addToCart(cart);
    }
  };

  handleOnClick = (e) => {
    this.handleShowModal();
  };

  handleOnAddNewProductClick = (e) => {
    this.handleShowProductModal();
    this.props.getProducts();
  };

  render() {
    const { checklist, customer, cart } = this.props;

    const columns = [
      { dataField: "id", text: "ID", hidden: true },
      { dataField: "product_id", text: "", hidden: true },
      {
        dataField: "code",
        text: "PRODUCT CODE",
        editable: false,
        headerAlign: "center",
      },
      {
        dataField: "description",
        text: "PRODUCT DESCRIPTION",
        editable: false,
        headerAlign: "center",
      },
      {
        dataField: "price",
        text: "SELLING PRICE",
        editable: false,
        headerAlign: "center",
        formatter: (cell, row) => {
          return <>{row.currency + " " + cell}</>;
        },
      },
      {
        dataField: "discount_percentage",
        text: "DISCOUNT (%)",
        editable: false,
        headerAlign: "center",
        formatter: (cell, row) => {
          if (cell == 0) {
            return <>-</>;
          }
          return <>{cell}</>;
        },
      },
      {
        dataField: "discounted_price",
        text: "DISCOUNTED PRICE",
        editable: false,
        headerAlign: "center",
        formatter: (cell, row) => {
          return <>{row.currency + " " + cell}</>;
        },
      },
      {
        dataField: "no_of_stocks",
        text: "STOCKS",
        editable: false,
        headerAlign: "center",
      },
      {
        dataField: "quantity",
        text: "QUANTITY",
        editable: false,
        headerAlign: "center",
        editable: false,
        formatter: (cell, row) => {
          return (
            <FormGroup className="table-form-group">
              <FormControl
                type="text"
                defaultValue={getDefaultQuantity(
                  row.product_id,
                  cart.items,
                  customer
                )}
                onInput={(e) => this.handleChange(e, row)}
                readOnly={row.no_of_stocks > 0 ? false : true}
              />
            </FormGroup>
          );
        },
      },
      {
        dataField: "status",
        text: "STATUS",
        headerAlign: "center",
        editor: {
          type: Type.SELECT,
          options: [
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "Warning",
              label: "Warning",
            },
            {
              value: "Removed",
              label: "Removed",
            },
          ],
        },
        formatter: (cell, row) => {
          if (!cell) {
            return "Active";
          }
          return cell;
        },
      },
    ];

    const { updateTotal, currency, totalAmount } = this.state;
    const { updateRecord } = this.props;

    const cellEdit = {
      mode: "click",
      blurToSave: true,
      beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue != newValue) {
          if (newValue == "Removed") {
            if (
              window.confirm(
                "Product " +
                  row.code +
                  " will be removed from the list. Do you want to continue?"
              )
            ) {
              const data = {
                id: row.checklist_id,
                status: newValue,
              };

              updateRecord(row.checklist_id, 61, data, "update");
              done(true);
              return { async: true };
            }
          } else {
            if (
              window.confirm(
                "Updating product " + row.code + " status to " + newValue + "?"
              )
            ) {
              const data = {
                id: row.checklist_id,
                status: newValue,
              };

              updateRecord(row.checklist_id, 61, data, "update");
              done(true);
              return { async: true };
            }
          }
        }

        done(false);
      },
      afterSaveCell: (oldValue, newValue, row, column) => {
        this.forceUpdate();
      },
    };

    const rowClasses = (row, rowIndex) => {
      const { status } = row;

      if (status) {
        const color = statusType.find((element) => {
          if (element.name == status) {
            return element;
          }
        });
        if (color) {
          return "table-color-" + color.color;
        }
      }
      return "table-color";
    };

    const CheckoutModal = () => {
      const handleCloseModal = () => {
        this.handleCloseModal();
      };

      return <Checkout show={true} onHide={handleCloseModal} />;
    };

    const AddProductModal = () => {
      const handleCloseProductModal = () => {
        this.handleCloseProductModal();
      };
      const handleSubmitProductModal = () => {
        this.handleSubmitProductModal();
      };
      const { checklist, customer } = this.props;

      return (
        <ProductModal
          show={true}
          onHide={handleCloseProductModal}
          handleClose={handleCloseProductModal}
          onSubmit={handleSubmitProductModal}
          checklist={checklist}
          customerId={customer.customerId}
        />
      );
    };

    return (
      <>
        <ToolkitProvider
          keyField="checklist_id"
          data={checklist}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <Container className="no-gutter">
                <Row>
                  <Col sm="2" className="table-search">
                    <SearchBar {...props.searchProps} />
                  </Col>
                  <Col sm="2" className="table-search">
                    <Button onClick={this.handleOnAddNewProductClick}>
                      <i className="fa fa-fw fa-plus" /> Add New Product
                    </Button>
                  </Col>
                  <Col sm="5"></Col>
                  <Col
                    sm="2"
                    className="table-search"
                    style={{ fontSize: "0.9rem" }}
                  >
                    <b>
                      Total Amount:{" "}
                      {updateTotal ? currency + " " + totalAmount : "0.00"}
                    </b>
                  </Col>
                  <Col sm="1" className="table-search">
                    <Button onClick={this.handleOnClick}>Checkout</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="table_div">
                      <BootstrapTable
                        className="border border-dark"
                        style={{ border: "1px solid red" }}
                        {...props.baseProps}
                        cellEdit={cellEditFactory(cellEdit)}
                        rowClasses={rowClasses}
                        hover
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </ToolkitProvider>
        {this.state.showModal && <CheckoutModal />}
        {this.state.showProductModal && <AddProductModal />}
      </>
    );
  }
}

function mapState(state) {
  const { cart } = state;
  return { cart, state };
}

const actionCreators = {
  getFormById: processActions.getFormById,
  addToCart: cartActions.addToCart,
  updateRecord: processActions.updateRecord,
  getProducts: modalActions.getProducts,
};

export default connect(mapState, actionCreators)(ChecklistTable);
