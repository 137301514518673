import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { connect } from 'react-redux';
import LinkTooltip from '../../../components/LinkTooltip';
import AutoSearch from '../../../components/Search/AutoSearch';
import { orderActions } from '../../../_actions/order.actions';
import { processActions } from '../../../_actions/process.actions';
import { searchActions } from '../../../_actions/search.actions';
import { processConstants } from '../../../_constants/process.constants';
import { getDayId } from '../../../_helpers/calendar';

const statusType = [
  {name: 'Not Contacted', color: 'white'},
  {name: 'Order Placed', color: 'green'},
  {name: 'No Order Required', color: 'yellow'},
  {name: 'Call Back', color: 'red'},
];

const roundColumns = [
  {dataField: 'id', text: 'DRIVER ID', headerAlign: 'center'},
  {dataField: 'name', text: 'DRIVER', headerAlign: 'center'},
  {dataField: 'round', text: 'ROUND NO', headerAlign: 'center'},
  {dataField: 'date', text: 'DATE', headerAlign: 'center'}
]

const orderAccountsColumns = [
  // {dataField: 'id', text: 'ID'},
  {dataField: 'action', text: '', headerAlign: 'center', editable: false},
  {dataField: 'customer_id', text: 'ID', hidden: true, headerAlign: 'center'},
  {dataField: 'company_ref', text: 'ACCOUNT REF', editable: false, headerAlign: 'center'},
  {dataField: 'company', text: 'ACCOUNT NAME', editable: false, headerAlign: 'center'},
  {dataField: 'old_balance', text: 'OLD BALANCE', editable: false, headerAlign: 'center'},
  {dataField: 'todays_balance', text: 'TODAYS BALANCE', editable: false, headerAlign: 'center'},
  {dataField: 'total_balance', text: 'TOTAL BALANCE', editable: false, headerAlign: 'center'},
  {dataField: 'cash', text: 'PAID IN CASH', editable: false, headerAlign: 'center'},
  {dataField: 'cheque', text: 'PAID IN CHEQUE', editable: false, headerAlign: 'center'},
  {dataField: 'new_balance', text: 'NEW BALANCE', editable: false, headerAlign: 'center'},
  {dataField: 'status_id', hidden: true, editable: false, headerAlign: 'center'},
  {
    dataField: 'status', 
    text: 'STATUS',
    headerAlign: 'center',
    editor: {
      type: Type.SELECT,
      options: [{
        value: 'Not Contacted',
        label: 'Not Contacted'
      }, {
        value: 'Order Placed',
        label: 'Order Placed'
      }, {
        value: 'No Order Required',
        label: 'No Order Required'
      }, {
        value: 'Call Back',
        label: 'Call Back'
      }]
    },
    formatter: (cell, row) => {
      if (! cell) {
        return 'Not Contacted';
      }
      return cell;
    }
  }
]

const rowClasses = (row, rowIndex) => {

  const { status } = row;

  if (status) {
    const color = statusType.find((element) => {
      if (element.name == status) {
        return element;
      }
    })

    if (color) {
      return 'table-color-'+color.color;
    }
  }

  return 'table-color';
};

class RoundOrder extends React.Component
{
  constructor () {
    super ();

    this.state = {
      rounds: [],
      orders: [],
      loading: false, 
      expandedLoading: false,
      searchId: '',
      searchValue: '',
      selectedRoundId: '',
      selectedDriverId: '',
      selectedRoundNo: '',
    }
  }

  componentWillMount() {
    const { dateId, date, day, rounds } = this.props;

    let state = this.state;
    state['selectedRoundId'] = getDayId(this.props.day);
    state['rounds'] = [];
    state['loading'] = true;
    this.props.getRoundOrders(dateId, date, day);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rounds && nextProps.rounds != this.props.rounds) {
      let state = this.state;
      state['rounds'] = nextProps.rounds;
      state['loading'] = false;
    }

    if (nextProps.orders && nextProps.orders != this.props.orders) {
      let state = this.state;
      state['orders'] = nextProps.orders;
      state['expandedLoading'] = false;
    }

    if (nextProps.showChecklist && nextProps.showChecklist !== this.props.showChecklist
      && nextProps.schedule && nextProps.schedule !== this.props.schedule
      && nextProps.schedule.customer_id
    ) {
      const { selectedDriverId, selectedRoundNo } = this.state;
      this.props.searchByIdWithSchedule(
        nextProps.schedule.customer_id, 
        processConstants.PROCESS_SEARCH_CUSTOMER,
        selectedDriverId, 
        getDayId(this.props.day), 
        selectedRoundNo
      );
    }

    if (nextProps.reloadOrders && nextProps.reloadOrders != this.props.reloadOrders) {
      const { selectedDriverId, selectedRoundNo } = this.state;
      this.props.getRoundOrdersByDriver(selectedDriverId, getDayId(this.props.day), selectedRoundNo);
    }
  }

  onSearch = (id, value) => {
    let state = this.state;
    state['searchId'] = id;
    state['searchValue'] = value;

    const { selectedRoundId, selectedDriverId } = this.state;

    this.props.addRoundCustomer(id, selectedRoundId, selectedDriverId);
  }

  render () {

    const expandRow = {
      showExpandColumn: true,
      onlyOneExpanding: true,
      expandByColumnOnly: true,
      parentClassName: 'table-selected',
      className: 'table-expanded',
      onExpandAll: (isExpandAll, results, e) => {
        e.preventDefault();
        if (isExpandAll) {
          console.log(isExpandAll, results, e, e.preventDefault())
          
        }
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return "";
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          
          return (
            <span><b className="link-hide">-</b></span>
          );
        }
        return (
          <span><b className="link-show">+</b></span>
        );
      },
      onExpand: (row, isExpand, rowIndex, e) => {
    
        if (isExpand) {
          let state = this.state;
          state['selectedDriverId'] = row.id;
          state['selectedRoundNo'] = row.round;
          state['expandedLoading'] = true;
          this.props.getRoundOrdersByDriver(row.id, getDayId(this.props.day), row.round);
        }
      },
      renderer: row => {
    
        const { orders, expandedLoading, selectedRoundId, selectedDriverId, selectedRoundNo } = this.state;
        const { day, submitForm, updateRecord, getRoundOrdersByDriver } = this.props;
        let state = this.state;

        const cellEdit = {
          mode: 'click', 
          blurToSave: true,
          beforeSaveCell (oldValue, newValue, row, column, done) {
            
            if (oldValue != newValue) {
              if (window.confirm('Updating '+row.company+' status to '+newValue+'?')) {
                const data = {
                  customer_id: row.customer_id,
                  driver_id: selectedDriverId,
                  round_no: selectedRoundNo,
                  round_id: selectedRoundId,
                  status: newValue
                }
                
                if (row.status_id) {
                  data.id = row.status_id;
                  updateRecord(row.status_id, 60, data, 'update');
                } else {
                  submitForm(58, data, 'create');
                }
                
                done(true);
              } else {
                done(false);
              }
              return { async: true };
            }
            done(false);
          },
          afterSaveCell: (oldValue, newValue, row, column) => {
            state['expandedLoading'] = true;
            getRoundOrdersByDriver(selectedDriverId, getDayId(day), selectedRoundNo);
          }
        }

        const rowEvents = {
          onClick: (e, row, rowIndex) => {
            const method = e.target.dataset.method;

            switch (method) {
              case 'show':
                row.id = row.customer_id;
                this.props.setCustomer(row, selectedRoundId, selectedDriverId, selectedRoundNo);
            }
          }
        }

        if (orders && Object.keys(orders).length > 0) {
          orders.map(function (value, index) {

            if (! value.order_id) {
              const action = <LinkTooltip message="Place Order" icon="fa fa-fw fa-shopping-cart" key="50" id="50" method="show" />
              value.action = action;
            }
          })
          
        }
        return (
          <div>
            <AutoSearch procId="56" placeholder="Search account" onClick={this.onSearch} buttonTitle="Add" className="float-right" /> 
            <BootstrapTable 
              columns={orderAccountsColumns} 
              data={orders} 
              keyField="customer_id"
              headerClasses='bootstrap-table-header'
              classes='bootstrap-table-data'
              noDataIndication={ () => <i>No records found.</i> }
              loading={ expandedLoading }
              overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
              cellEdit={ cellEditFactory(cellEdit)}
              rowClasses={ rowClasses }
              rowEvents={ rowEvents }
            />
          </div>
        )
    }
    };

    return <>
      <BootstrapTable 
        keyField="id"
        data={ this.state.rounds }
        columns={ roundColumns }
        expandRow={ expandRow }
        noDataIndication={ () => <i>No records found.</i> }
        loading={ this.state.loading }
        overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
      />
    </>;
  }
}

function mapState(state) {
  const { order } = state;
  const { rounds, orders, reloadOrders, loading, showChecklist, schedule } = order;
  return { rounds, orders, reloadOrders, loading, showChecklist, schedule };
}

const actionCreators = {
  searchByIdWithSchedule: searchActions.searchByIdWithSchedule,
  getRoundOrders: orderActions.getRoundOrders,
  getRoundOrdersByDriver: orderActions.getRoundOrdersByDriver,
  addRoundCustomer: orderActions.addRoundCustomer,
  submitForm: processActions.submitForm,
  updateRecord: processActions.updateRecord,
  setCustomer: orderActions.setCustomer
}

export default connect(mapState, actionCreators)(RoundOrder);