import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { contentActions } from "../../../_actions/content.actions";
import { orderActions } from "../../../_actions/order.actions";
import { placeActions } from "../../../_actions/place.actions";
import { formatDateForQuery } from "../../../_helpers/calendar";
import { currencySymbol } from "../../../_helpers/content";
import ReportFilter from "../../reports/ReportFilter";
import OrderDetailsModal from "./OrderDetailsModal";

const { SearchBar } = Search;

const today = new Date();
const startDate = today.setDate(today.getDate() - 14);
const endDate = new Date();

const searchButton = {
  name: "search_invoices",
  type: "button",
  label: "Search",
};

class InvoiceManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      order: {},
      openNewTab: false,
    };

    let data = {};
    data.start_date = formatDateForQuery(new Date(startDate));
    data.end_date = formatDateForQuery(new Date(endDate));
    this.props.getInvoices(data);
  }

  onSearch = (data) => {
    if (!data.start_date) {
      alert("Please select start date");
    } else if (!data.end_date) {
      alert("Please select end date");
    } else {
      this.setState({
        startDate: data.start_date,
        endDate: data.end_date,
      });

      data.start_date = formatDateForQuery(data.start_date);
      data.end_date = formatDateForQuery(data.end_date);
      this.props.getInvoices(data);
    }
  };

  onClick = (e, row) => {
    this.setState({ order: row, openNewTab: true });
    this.props.printOrderInvoice(row.id);
  };

  render() {
    if (this.props.printInvoice && this.state.openNewTab) {
      const url =
        process.env.REACT_APP_API_URL +
        "/../invoice/" +
        this.state.order.invoice_number +
        ".pdf";
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
      this.setState({ openNewTab: false });
    }

    const reportFields = [
      {
        name: "start_date",
        type: "datepicker",
        label: "Start date:",
        placeholder: "Select start date...",
        default: this.state.startDate,
      },
      {
        name: "end_date",
        type: "datepicker",
        label: "End date:",
        placeholder: "Select end date...",
        default: this.state.endDate,
      },
    ];

    const columns = [
      { dataField: "invoice_number", text: "INVOICE NO." },
      { dataField: "delivery_date", text: "DATE" },
      { dataField: "company", text: "NAME" },
      { dataField: "amount", text: "TOTAL AMOUNT " + currencySymbol },
      { dataField: "id", text: "ORDER ID" },
      {
        dataField: "paid_status",
        text: "PAID STATUS",
        formatter: (cell, row) => {
          if (
            row.invoice_number == row.cash_invoice_no &&
            parseFloat(row.amount) >= parseFloat(row.cash_paid)
          ) {
            return "PAID";
          }

          if (
            row.invoice_number == row.invoice_no &&
            parseFloat(row.amount) >=
              parseFloat(row.cheque_paid) + parseFloat(row.invoice_paid)
          ) {
            return "PAID";
          }
        },
      },
      {
        dataField: "action",
        text: "PRINT INVOICE",
        formatter: (cell, row) => {
          if (row.invoice_no || row.cash_invoice_no) {
            return (
              <u className="text-link" onClick={(e) => this.onClick(e, row)}>
                Print
              </u>
            );
          }
          return "";
        },
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.className && e.target.className == "text-link") {
          return;
        }

        this.setState({ order: row });
        this.props.getOrderDetails(row.id, row.type, row.customer_id);
      },
    };

    return (
      <div className="content">
        <h5>Invoice Management</h5>
        {this.props.show && this.props.details && (
          <OrderDetailsModal
            {...this.state}
            {...this.props}
            onClose={() => {
              this.props.clearOrders();
            }}
          />
        )}
        {this.props.items && (
          <ToolkitProvider
            keyField="invoice_number"
            columns={columns}
            data={this.props.items}
            search
            bootstrap4
            defaultSorted={[{ dataField: "id", order: "asc" }]}
          >
            {(props) => (
              <Container className="no-gutter">
                <Row>
                  <Col sm="12">
                    <ReportFilter
                      fields={reportFields}
                      searchButton={searchButton}
                      search={this.onSearch}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2" className="table-search">
                    <SearchBar {...props.searchProps} />
                  </Col>
                  <Col sm="10"></Col>
                </Row>
                <Row>
                  <Col>
                    <BootstrapTable
                      {...props.baseProps}
                      striped
                      headerClasses="bootstrap-table-header"
                      classes="bootstrap-table-data"
                      rowEvents={rowEvents}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </ToolkitProvider>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { contents, order, place } = state;
  console.log("Place --->", place);
  const { items } = contents;
  console.log("Items --->", items);
  const { show, details } = order;
  const { printInvoice } = place;
  return { items, show, details, printInvoice, place };
}

const actionCreators = {
  getInvoices: contentActions.getInvoices,
  getOrderDetails: orderActions.getOrderDetails,
  clearOrders: orderActions.clearOrders,
  printOrderInvoice: placeActions.printOrderInvoice,
};

export default connect(mapState, actionCreators)(InvoiceManagement);
