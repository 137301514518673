import React from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { cartActions } from '../../../_actions/cart.actions';
import { checkoutActions } from '../../../_actions/checkout.actions';
import { formatDeliveryDate } from '../../../_helpers/calendar';
import { checkoutData, computeAmountPayableInCash, computeAmountPayableOnDelivery, computeAmountPayableInPercentage, splitOrders } from '../../../_helpers/cart';

class Checkout extends React.Component
{
  constructor () {
    super ();

    this.state = {
      splitPayment: false,
      selectedPaymentOption: 'full',
      showSplitPaymentField: false,
      splitPaymentOption: '',
      firstPaymentValue: '',
      firstPaymentOrders: [],
      firstPaymentNewAmount: 0,
      showComputation: false
    }

    this.onPaymentOptionChange = this.onPaymentOptionChange.bind(this);
    this.onSplitPaymentChange = this.onSplitPaymentChange.bind(this);
    this.onSplitPaymentInput = this.onSplitPaymentInput.bind(this);
    this.onClickPaymentComputation = this.onClickPaymentComputation.bind(this);
  }

  onOrderCancelled = () => {
    if (window.confirm('Are you sure you want to cancel the order? This cannot be undone.')) {
      this.props.clearCart();
      this.props.onHide();
    }
  }

  onOrderCheckOut = () => {

    const { selectedPaymentOption, firstPaymentNewAmount, firstPaymentOrders } = this.state;

    let cart = this.props.cart.items;
    cart.paymentOption = selectedPaymentOption;
    cart.prepayment = firstPaymentNewAmount;
    cart.inCashOrders = [];
    cart.inCashOrdersById = firstPaymentOrders;

    firstPaymentOrders.map((order) => {
      cart.inCashOrders.push(order);
    })

    this.props.doCheckout(cart);
  }

  onPaymentOptionChange = (e) => {
    const option = e.target.value;
    this.setState({ 
      selectedPaymentOption: option,
      splitPayment: option === 'split' ? true : false
    });
  }

  onSplitPaymentChange = (e) => {

    const option = e.target.value;

    if (option) {
      this.setState({ 
        showSplitPaymentField: true,
        splitPaymentOption: option
      });
    }
  }

  onSplitPaymentInput = (e) => { this.setState({ firstPaymentValue: e.target.value }) }

  onClickPaymentComputation = () => {
    const payable = computeAmountPayableInCash(
      this.props.cart.items.totalAmount, 
      this.state.firstPaymentValue,
      this.state.splitPaymentOption
    );

    const firstPayment = splitOrders(this.props.cart.items.products, payable);

    this.setState({ 
      firstPaymentOrders: firstPayment.orders,
      firstPaymentNewAmount: firstPayment.total,
      showComputation: true 
    });
  }

  componentDidMount () {
    if (this.props.checkout && this.props.checkout.items) {
      this.props.clearCart();
      this.props.onHide();
    }
  }

  render () {
    const { cart, show, onHide } = this.props;

    if (! cart && ! cart.items) {
      return alert('No cart found!');
    }

    const columns = [
      { dataField: 'product_id', text: 'PRODUCT ID', hidden: true, footer: '' },
      { dataField: 'code', text: 'PRODUCT CODE', footer: '', headerAlign: 'center' },
      { dataField: 'description', text: 'PRODUCT DESCRIPTION', footer: '', headerAlign: 'center' },
      { 
        dataField: 'discounted_price', 
        text: 'PRICE',
        formatter: (cell, row) => {
          return row.currency+" "+cell;
        }, 
        footer: '', 
        headerAlign: 'center'
      },
      { dataField: 'quantity', text: 'QUANTITY', footer: '', headerAlign: 'center' },
      { 
        dataField: 'vat', 
        text: 'VAT', 
        formatter: (cell, row) => {
          return cell || '0';
        },
        footer: 'TOTAL:', 
        headerAlign: 'center' 
      },
      { 
        dataField: 'subtotal', 
        text: 'SUBTOTAL', 
        formatter: (cell, row) => {
          return row.currency+" "+cell;
        }, 
        footer: cart.items.currency+" "+cart.items.totalAmount, 
        headerAlign: 'center'
      }
    ]

    const SplitPayment = () => {
      return <Form>
        <Form.Group as={Row}>
          <Form.Label column sm="4">How do you want to split payment? </Form.Label>
          <Col sm="3">
            <Form.Control 
              as="select" 
              name="split_payment" 
              label="Choose split payment option:"
              defaultValue={ this.state.splitPaymentOption }
              onChange={ this.onSplitPaymentChange } 
            >
              <option value=""></option>
              <option value="percentage">Percentage</option>
              <option value="amount">Amount</option>
            </Form.Control>
          </Col>
          { this.state.showSplitPaymentField && <>
            <Col sm="3">
              <Form.Control 
                size="sm" 
                type="text"
                name="splitOption"
                placeholder={"Enter estimated "+this.state.splitPaymentOption+" to pay in cash"} 
                onBlur={ this.onSplitPaymentInput } 
                defaultValue={ this.state.firstPaymentValue }
              />
            </Col>
            <Col sm="2">
              <Button variant="primary" onClick={ this.onClickPaymentComputation }>Compute</Button>
            </Col>
            </>
          }
          
        </Form.Group>
        {
          this.state.showComputation &&
          <Form.Group as={Row}>
            { this.state.splitPaymentOption == 'percentage' && 
              <Form.Label column sm="12">
                Pre-computed percentage to be paid in cash: <b>{ computeAmountPayableInPercentage(cart.items.totalAmount, this.state.firstPaymentNewAmount)+"%"}</b>
              </Form.Label>
            }
            <Form.Label column sm="12">
              Pre-computed amount to be paid in cash: <b>{ cart.items.currency + " " +this.state.firstPaymentNewAmount}</b>
            </Form.Label>
            <Form.Label column sm="12">
              Remaining amount to be paid upon delivery: <b>{ cart.items.currency + " " +computeAmountPayableOnDelivery(
                cart.items.totalAmount, 
                this.state.firstPaymentNewAmount, 
                'amount'
              )}</b>
            </Form.Label>
            <Form.Label column sm="12">
              <b>In-Cash Orders:</b>
            </Form.Label>
            { this.state.firstPaymentOrders.map((order, i) =>
              <Form.Label column sm="12">
                {order.quantity} x {order.code} = {order.currency} {order.subtotal}
              </Form.Label>
            )}
          </Form.Group>
        }
      </Form>
    }

    return <Modal
      show={ show }
      onHide={ onHide }
      backdrop="static"
      keyboard={ false }
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title><h6>Order Summary for {cart.items.customerInfo.company}</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>Date of Delivery: <b>{formatDeliveryDate(cart.items.customerInfo.roundNo)}</b></Row>
        </Container>
        <BootstrapTable 
          keyField="product_id"
          data={ checkoutData(cart.items.products) }
          columns={ columns }
        />

        <Form>
          <Form.Group as={Row}>
            <Form.Label as="payment_option" column sm="4">How the customer wants to pay?</Form.Label>
            <Col sm="2">
              <Form.Check
                type="radio"
                id="paymentOptionFull"
                value="full"
                label="Full Payment"
                checked={ this.state.selectedPaymentOption === 'full' }
                onChange={ this.onPaymentOptionChange }
              />
            </Col>
            <Col sm="2">
              <Form.Check
                type="radio"
                id="paymentOptionSplit"
                value="split"
                label="Split Payment"
                checked={ this.state.selectedPaymentOption === 'split' }
                onChange={this.onPaymentOptionChange}
              />
            </Col>
          </Form.Group>
        </Form>
        { this.state.splitPayment && <SplitPayment />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onHide } >Close</Button>
        <Button variant="danger" onClick={ this.onOrderCancelled }>Cancel Order</Button>
        <Button variant="primary" onClick={ this.onOrderCheckOut }>Proceed to Checkout</Button>
      </Modal.Footer>
    </Modal>;
  }
}

function mapState(state) {
  const { cart, checkout } = state;
  return { cart, checkout };
}

const actionCreators = {
  clearCart: cartActions.clearCart,
  doCheckout: checkoutActions.doCheckout
}

export default connect(mapState, actionCreators)(Checkout);