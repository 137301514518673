export const placeConstants = {
  GET_ROUND_DRIVERS_LOCAL: 'GET_ROUND_DRIVERS_LOCAL',
  GET_CUSTOMERS_LOCAL: 'GET_CUSTOMERS_LOCAL', 
  GET_SCHEDULE_LOCAL: 'GET_SCHEDULE_LOCAL',
  GET_CHECKLIST_LOCAL: 'GET_CHECKLIST_LOCAL',
  GET_ORDERS_LOCAL: 'GET_ORDERS_LOCAL',

  API_GET_REQUEST_ROUND_DRIVERS: 'API_GET_REQUEST_ROUND_DRIVERS',
  API_GET_SUCCESS_ROUND_DRIVERS: 'API_GET_SUCCESS_ROUND_DRIVERS',
  API_GET_FAILURE_ROUND_DRIVERS: 'API_GET_FAILURE_ROUND_DRIVERS',

  API_GET_REQUEST_CUSTOMERS: 'API_GET_REQUEST_CUSTOMERS',
  API_GET_SUCCESS_CUSTOMERS: 'API_GET_SUCCESS_CUSTOMERS',
  API_GET_FAILURE_CUSTOMERS: 'API_GET_FAILURE_CUSTOMERS',

  API_GET_REQUEST_SCHEDULE: 'API_GET_REQUEST_SCHEDULE',
  API_GET_SUCCESS_SCHEDULE: 'API_GET_SUCCESS_SCHEDULE',
  API_GET_FAILURE_SCHEDULE: 'API_GET_FAILURE_SCHEDULE',

  API_GET_REQUEST_CHECKLIST: 'API_GET_REQUEST_CHECKLIST',
  API_GET_SUCCESS_CHECKLIST: 'API_GET_SUCCESS_CHECKLIST',
  API_GET_FAILURE_CHECKLIST: 'API_GET_FAILURE_CHECKLIST',

  API_GET_REQUEST_ORDERS: 'API_GET_REQUEST_ORDERS',
  API_GET_SUCCESS_ORDERS: 'API_GET_SUCCESS_ORDERS',
  API_GET_FAILURE_ORDERS: 'API_GET_FAILURE_ORDERS',

  API_POST_REQUEST_ROUND_STATUSES: 'API_POST_REQUEST_ROUND_STATUSES',
  API_POST_SUCCESS_ROUND_STATUSES: 'API_POST_SUCCESS_ROUND_STATUSES',
  API_POST_FAILURE_ROUND_STATUSES: 'API_POST_FAILURE_ROUND_STATUSES',

  API_GET_REQUEST_ROUND_STATUSES: 'API_GET_REQUEST_ROUND_STATUSES',
  API_GET_SUCCESS_ROUND_STATUSES: 'API_GET_SUCCESS_ROUND_STATUSES',
  API_GET_FAILURE_ROUND_STATUSES: 'API_GET_FAILURE_ROUND_STATUSES',

  API_GET_REQUEST_VALIDATE: 'API_GET_REQUEST_VALIDATE',
  API_GET_SUCCESS_VALIDATE: 'API_GET_SUCCESS_VALIDATE',
  API_GET_FAILURE_VALIDATE: 'API_GET_FAILURE_VALIDATE',

  API_POST_REQUEST_PAPERWORKS_GENERATE: 'API_POST_REQUEST_PAPERWORKS_GENERATE',
  API_POST_SUCCESS_PAPERWORKS_GENERATE: 'API_POST_SUCCESS_PAPERWORKS_GENERATE',
  API_POST_FAILURE_PAPERWORKS_GENERATE: 'API_POST_FAILURE_PAPERWORKS_GENERATE',

  API_POST_REQUEST_ORDER_DETAILS_CHECKLIST: 'API_POST_REQUEST_ORDER_DETAILS_CHECKLIST',
  API_POST_SUCCESS_ORDER_DETAILS_CHECKLIST: 'API_POST_SUCCESS_ORDER_DETAILS_CHECKLIST',
  API_POST_FAILURE_ORDER_DETAILS_CHECKLIST: 'API_POST_FAILURE_ORDER_DETAILS_CHECKLIST',

  API_GET_REQUEST_INVOICE: 'API_GET_REQUEST_INVOICE',
  API_GET_SUCCESS_INVOICE: 'API_GET_SUCCESS_INVOICE',
  API_GET_FAILURE_INVOICE: 'API_GET_FAILURE_INVOICE',

  API_POST_REQUEST_GENERATE_ROUND_INVOICE: 'API_POST_REQUEST_GENERATE_ROUND_INVOICE',
  API_POST_SUCCESS_GENERATE_ROUND_INVOICE: 'API_POST_SUCCESS_GENERATE_ROUND_INVOICE',
  API_POST_FAILURE_GENERATE_ROUND_INVOICE: 'API_POST_FAILURE_GENERATE_ROUND_INVOICE',

  API_POST_REQUEST_GENERATE_INVOICE: 'API_POST_REQUEST_GENERATE_INVOICE',
  API_POST_SUCCESS_GENERATE_INVOICE: 'API_POST_SUCCESS_GENERATE_INVOICE',
  API_POST_FAILURE_GENERATE_INVOICE: 'API_POST_FAILURE_GENERATE_INVOICE',
  
  API_POST_REQUEST_EMAIL_INVOICE: 'API_POST_REQUEST_EMAIL_INVOICE',
  API_POST_SUCCESS_EMAIL_INVOICE: 'API_POST_SUCCESS_EMAIL_INVOICE',
  API_POST_FAILURE_EMAIL_INVOICE: 'API_POST_FAILURE_EMAIL_INVOICE',

  API_GET_REQUEST_PRINT_NOTES: 'API_GET_REQUEST_PRINT_NOTES',
  API_GET_SUCCESS_PRINT_NOTES: 'API_GET_SUCCESS_PRINT_NOTES',
  API_GET_FAILURE_PRINT_NOTES: 'API_GET_FAILURE_PRINT_NOTES',

  API_POST_REQUEST_PRINT_INVOICE: 'API_POST_REQUEST_PRINT_INVOICE',
  API_POST_SUCCESS_PRINT_INVOICE: 'API_POST_SUCCESS_PRINT_INVOICE',
  API_POST_FAILURE_PRINT_INVOICE: 'API_POST_FAILURE_PRINT_INVOICE',

  API_GET_REQUEST_PRINT_INVOICE: 'API_GET_REQUEST_PRINT_INVOICE',
  API_GET_SUCCESS_PRINT_INVOICE: 'API_GET_SUCCESS_PRINT_INVOICE',
  API_GET_FAILURE_PRINT_INVOICE: 'API_GET_FAILURE_PRINT_INVOICE',

  API_GET_REQUEST_PRINT_DRIVERS_LIST: 'API_GET_REQUEST_PRINT_DRIVERS_LIST',
  API_GET_SUCCESS_PRINT_DRIVERS_LIST: 'API_GET_SUCCESS_PRINT_DRIVERS_LIST',
  API_GET_FAILURE_PRINT_DRIVERS_LIST: 'API_GET_FAILURE_PRINT_DRIVERS_LIST',

  API_POST_REQUEST_ORDER_SORT: 'API_POST_REQUEST_ORDER_SORT',
  API_POST_SUCCESS_ORDER_SORT: 'API_POST_SUCCESS_ORDER_SORT',
  API_POST_FAILURE_ORDER_SORT: 'API_POST_FAILURE_ORDER_SORT'
}