import React from 'react';
import { connect } from 'react-redux';
import AddNewProductForm from './AddNewProductForm';

class AddNewProduct extends React.Component
{
  render() {
    return <div className="content">
      <h5>Add Product</h5>
      <AddNewProductForm />
    </div>;
  }
}

export default connect(null, null)(AddNewProduct);