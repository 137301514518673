import React from 'react';
import { connect } from 'react-redux';
import { getDriverRounds } from '../../_helpers/session';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DynamicTop from '../../components/DynamicTop';
import { processLocation } from '../../_helpers/content';
import { navigateActions } from '../../_actions/navigate.actions';
import { contentActions } from '../../_actions/content.actions';
import { driverRoundsUpdateData } from '../../_helpers/rounds';
import { processActions } from '../../_actions/process.actions';
import { computeSubtotal } from '../../_helpers/placeOrder';


const roundDayMap = {
  'sunday': 1,
  'monday': 2,
  'tuesday': 3,
  'wednesday': 4,
  'thursday': 5,
  'friday': 6,
  'saturday': 7
};

class Drivers extends React.Component
{
  constructor () {
    super();

    this.state = {
      show: false,
      drivers: [],
      processes: [],
      changes: [],
      deleted: [],
      showModal: false,
      modalData: {}
    }
  }

  UNSAFE_componentWillMount () {
    this.props.getContent();
    this.props.getSelectedNavByUri(this.props.user.navigation);
  }

  componentDidUpdate(prevProps) {
    if (this.props.contents && this.props.contents.items && this.props.contents !== prevProps.contents) {
      const localKey = '__driver__list';
      this.setState({ show: true, drivers: JSON.parse(localStorage.getItem(localKey)) });
    }

    if (this.props.processes && this.props.processes != prevProps.processes) {
      this.setState({ processes: this.props.processes });
    }

    if (this.props.process && this.props.process.items && this.props.process !== prevProps.process) {
      const localKey = '__driver__list';
      let drivers = JSON.parse(localStorage.getItem(localKey));
      
      if (this.props.process.postDriverInfo) {        
        const deleted = this.state.deleted;

        if (deleted.length > 0) {
          deleted.forEach((driver) => {
            drivers = [...drivers.filter((obj) => obj.id != driver.id)]
          })
        }

        this.setState({ show: false, drivers: [] }, () => {
          this.setState({ show: true, drivers: drivers });
        });
        this.handleCloseModal()
      } else {
        this.setState({ 
          show: true, 
          drivers: drivers,
          changes: [],
          deleted: [],
          modalData: {},
          showModal: false
        });      
      }
     }
  }

  handleDayOnChecked = (e, row) => {
    const isChecked = e.target.checked;
    const roundDay = e.target.id;
    
    if (row[roundDay] == isChecked) {
      // Make sure it is excluded in the list of changes to update
      this.setState({ changes: [...this.state.changes.filter((obj) => ! (obj.driver_id == row.id && obj.round == roundDay))] });
    } else {
      // Add to list of changes for update
      const changed = {
        driver_id: row.id,
        round: roundDay,
        round_id: roundDayMap[roundDay],
        value: isChecked
      }
      this.setState({ changes: [...this.state.changes.filter((obj) => ! (obj.driver_id == row.id  && obj.round == roundDay)), changed] })
    }
  }

  handleSaveOnClick = (e) => {
    const data = driverRoundsUpdateData(this.state.changes); 
    data.driver_delete = this.state.deleted;
    this.props.updateDriverRounds(data);
  }

  handleRevertOnClick = () => {
    this.setState({
      show: false,
      drivers: []
    }, () => {
      this.setState({
        show: true, 
        drivers: JSON.parse(localStorage.getItem('__driver__list')), 
        changes: [],
        deleted: [],
        modalData: {},
        showModal: false
      })  
    })
  }

  handleDeleteOnClick = (e, row) => {
    this.setState({ 
      deleted: [...this.state.deleted, e.target.id],
      drivers: [...this.state.drivers.filter((obj) => obj.id != row.id)]
    });
  }

  handleEditOnClick = (e, row) => {
    this.setState({ 
      showModal: true, 
      modalData: row
    })
  }

  handleCloseModal = () => { this.setState({ showModal: false })};

  handleUpdateModalOnClick = () => {
    const { modalData } = this.state;

    this.props.updateDriverInfo({
      id: modalData.id,
      name: modalData.updatedName || modalData.full_name
    });
  }

  handleDriverNameChange = (e) => {
    let modalData = this.state.modalData;
    modalData.updatedName = e.target.value;
    this.setState({ modalData: modalData});
  }

  render () {
    const { processes, drivers } = this.state;
    let processButtons = [];

    if (processes) {
      processButtons = processLocation(processes);
    }

    return <div className="content">
      <h5>Drivers</h5>
      <Container>
        <Row>
          <Col></Col>
          <Col><DynamicTop buttons={ processButtons.top } /></Col>
        </Row>
        <Row>
          <Table>
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>ACTION</th>
                <th>DRIVER ID</th>
                <th>DRIVER'S NAME</th>
                <th>SUNDAY</th>
                <th>MONDAY</th>
                <th>TUESDAY</th>
                <th>WEDNESDAY</th>
                <th>THURSDAY</th>
                <th>FRIDAY</th>
                <th>SATURDAY</th>
              </tr>
            </thead>
            <tbody>
              { this.state.show && drivers.map((driver) => {
                return <tr key={ driver.id } className="table-form-group"  style={{ textAlign: "center" }}>
                  <td>
                    <Button variant="link" size="sm" id={driver.id} onClick={ (e) => this.handleEditOnClick(e, driver) }>
                      <i id={driver.id} className="fas fa-pencil-alt icon" />
                    </Button>
                    <Button variant="link" size="sm" id={driver.id} onClick={ (e) => this.handleDeleteOnClick(e, driver) }>
                      <i id={driver.id} className="fas fa-minus-circle icon-red" />
                    </Button>
                  </td>
                  <td>{ driver.id }</td>
                  <td  style={{ textAlign: "left" }}>{ driver.full_name }</td>
                  <td>
                    <Form.Group key={ 'sunday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" id="sunday" aria-label="sunday" defaultChecked={ driver.sunday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group key={ 'monday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" aria-label="monday" id="monday" defaultChecked={ driver.monday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group key={ 'tuesday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" aria-label="tuesday" id="tuesday" defaultChecked={ driver.tuesday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group key={ 'wednesday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" aria-label="wednesday" id="wednesday" defaultChecked={ driver.wednesday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group key={ 'thursday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" aria-label="thursday" id="thursday" defaultChecked={ driver.thursday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group key={ 'friday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" aria-label="friday" id="friday" defaultChecked={ driver.friday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group key={ 'saturday-'+driver.id } className="table-form-group">
                      <Form.Check type="checkbox" aria-label="saturday" id="saturday" defaultChecked={ driver.saturday } onChange={ (e) => this.handleDayOnChecked(e, driver) } />
                    </Form.Group>
                  </td>
                </tr>
              })}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col sm="1">
            <Button style={{ width: '100%'}} {...(this.state.changes.length > 0 || this.state.deleted.length > 0 ? '' : {disabled: true})} onClick={ this.handleSaveOnClick }>Save</Button>
          </Col>
          <Col sm="1">
          <Button style={{ width: '100%'}} variant="secondary" {...(this.state.changes.length > 0 || this.state.deleted.length > 0 ? '' : {disabled: true})} onClick={ this.handleRevertOnClick }>Revert</Button>
          </Col>
        </Row>
      </Container>
      <Modal show={ this.state.showModal } onHide={ this.handleCloseModal }>
        <Modal.Header closeButton>
          <Modal.Title>Edit Driver ({ this.state.modalData.full_name })</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>Driver ID:</Form.Label>
            <Col sm="8">
              <Form.Control type="input" readOnly value={ this.state.modalData.id } />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4}>Driver Name:</Form.Label>
            <Col sm="8">
              <Form.Control type="input" defaultValue={ this.state.modalData.full_name } onChange={ (e) => this.handleDriverNameChange(e) } />
            </Col>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={ this.handleCloseModal }>Close</Button>
          <Button variant="primary" onClick={ this.handleUpdateModalOnClick }>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>;
  }
}

function mapState (state) {
  const { contents, nav, authentication, process } = state;
  const { user } = authentication;
  const { processes } = nav;
  return { contents, processes, user, process };
}

const actionCreators = {
  getSelectedNavByUri: navigateActions.getSelectedNavByUri,
  getContent: contentActions.getAll,
  updateDriverRounds: processActions.updateDriverRounds,
  updateDriverInfo: processActions.updateDriverInfo
};

export default connect(mapState, actionCreators)(Drivers);