import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import DynamicTop from '../../components/DynamicTop';
import LinkTooltip from '../../components/LinkTooltip';
import { sessionActions } from '../../_actions/session.actions';
import { processLocation } from '../../_helpers/content';
import { getAccounts } from '../../_helpers/session';
import CustomerChecklist from './CustomerChecklist';

const columns = [
  {
    dataField: '', 
    text: '', 
    editable: false, 
    headerAlign: 'center', 
    formatter: (cell, row) => {
      return <LinkTooltip icon="fas fa-list-alt" message="Expand to View Saved Orders" />
    }
  },
  {dataField: 'id', text: 'CUSTOMER ID', editable: false, headerAlign: 'center'},
  {dataField: 'company_ref', text: 'COMPANY REF', editable: false, headerAlign: 'center'},
  {dataField: 'company', text: 'COMPANY', editable: false, headerAlign: 'center'},
  {
    dataField: 'full_name', 
    text: 'CONTACT PERSON', 
    editable: false, 
    headerAlign: 'center',
    formatter: (cell, row) => {
      if (row.first_name && row.last_name) {
        return row.first_name+" "+row.last_name;
      } else if (row.first_name) {
        return row.first_name;
      } else if (row.last_name) {
        return row.last_name
      }

      return cell;
    }
  },
];

const { SearchBar } = Search;

class Customers extends React.Component
{
  constructor () {
    super();

    this.state = {
      customers: [],
      expanded: []
    };
  }

  UNSAFE_componentWillMount () {
    this.props.getCustomers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.customers && prevProps.customers != this.props.customers) {
      this.setState({ customers: this.props.customers });
    }

    if (this.props.process && prevProps.process != this.props.process) {
      this.setState({ expanded: [] });
    }
  }

  render () {
    const { customers } = this.state;

    const expandRow = {
      renderer: row => (
        <><CustomerChecklist customerId={row.id} /></>
      ),
      showExpandColumn: false,
      onlyOneExpanding: true,
      parentClassName: 'bootstrap-table-expanded-row',
      onExpand: (row, isExpand, rowIndex, e) => {
        if (isExpand) {
          this.setState(() => ({
            expanded: [row.id]
          }));
        } else {
          this.setState(() => ({
            expanded: this.state.expanded.filter(x => x !== row.id)
          }));
        }
      },
      expanded: this.state.expanded
    };
    
    if (customers.length > 0) {
      return <div className="content">
        <h5>Customer Saved Orders</h5>
        <ToolkitProvider
          columns={ columns }
          data={ customers }
          keyField={ "id" }
          search
        >
          {
            props => (
              <Container>
                <Row>
                  <Col sm="4" className="table-search">
                    <SearchBar { ...props.searchProps } />
                  </Col>
                  <Col sm="8">
                    <DynamicTop buttons={ this.props.buttons.top } />
                  </Col>
                </Row>  
                <BootstrapTable
                  { ...props.baseProps }
                  headerClasses='bootstrap-table-header'
                  classes='bootstrap-table-data'
                  noDataIndication={ () => <i>No records found.</i> }
                  expandRow={ expandRow }
                />
              </Container>
            )
          }
        </ToolkitProvider>
      </div>;
    }
    return <></>;
  }
}

function mapState(state) {
  const { session, process } = state;
  const { customers } = session;
  return { customers, process };
}

const actionCreators = {
  getCustomers: sessionActions.getCustomers
}

export default connect(mapState, actionCreators)(Customers);