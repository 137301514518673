export const processLocation = (processes) => {
  const top = [], inline = [], bottom = [], form = [], table = [];

  if (processes != null) {
    processes.forEach(element => {
      
      if (element.button_type.indexOf("top") > -1) {
        top.push(element);
      } else if (element.button_type.indexOf("inline") > -1) {
        inline.push(element);
      } else if (element.button_type.indexOf("bottom") > -1) {
        bottom.push(element);
      } else if (element.button_type.indexOf("form") > -1) {
        form.push(element);
      } else if (element.button_type.indexOf("table") > -1) {
        table.push(element);
      }
    });
  }

  return {
    top: top,
    inline: inline,
    bottom: bottom,
    form: form,
    table: table
  };
}

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}

export const currencySymbol = '£';