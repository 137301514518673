import { orderConstants } from '../_constants/order.constants';
import { getDriverRounds } from '../_helpers/calendar';

export function order(state = {}, action) {
  switch (action.type) {
    case orderConstants.SET_CUSTOMER:
      return {
        customer: action.record
      }
    case orderConstants.API_GET_SUCCESS_ROUNDS:
      return {
        dateId: action.dateId,
        date: action.date,
        rounds: getDriverRounds(action.rounds, action.dateId, action.date)
      }
    case orderConstants.API_GET_REQUEST_BY_ROUND_DRIVER:
    case orderConstants.API_POST_REQUEST_BULK_ORDERS:
    case orderConstants.API_GET_REQUEST_INVOICE_ORDER_DETAILS:
      return {
        loading: true
      }
    case orderConstants.API_GET_SUCCESS_BY_ROUND_DRIVER:
    case orderConstants.API_POST_SUCCESS_BULK_ORDERS:
      return {
        loading: false,
        driverId: action.params.driverId,
        day: action.params.day,
        date: action.params.roundDate,
        orders: action.orders,
        action: action.type
      }
    case orderConstants.API_POST_SUCCESS_BY_ROUND_DRIVER:
      return {
        loading: false,
        showChecklist: true,
        schedule: action.data.schedules
        // reloadOrders: true
      }
    case orderConstants.API_GET_SUCCESS_INVOICE_ORDER_DETAILS:
      return {
        loading: false,
        show: true,
        details: action.data
      }
    case orderConstants.API_GET_FAILURE_BY_ROUND_DRIVER:
    case orderConstants.API_POST_FAILURE_BULK_ORDERS:
    case orderConstants.API_GET_FAILURE_INVOICE_ORDER_DETAILS:
      return {
        loading: false
      }
    case orderConstants.SET_DELIVERY_DATE:
      return {
        date: action.date
      }
    case orderConstants.CLEAR_ORDERS:
      return {};
    default:
      return state;
  }
}