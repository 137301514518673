export const statusName = (slug, statusId) => {
  switch (slug.toLowerCase()) {
    case 'user/list':
      const status = [
        { id: 0, value: 'Inactive' },
        { id: 1, value: 'Active' },
      ];

      const hasValue = status.find(element => element.id === statusId)

      return hasValue ? hasValue.value : statusId;
    default:
      return statusId;
  }
}