import { placeConstants } from "../_constants/place.constants";
import { getAccounts, getRoundDrivers, getSchedule, setActivePlaceTab } from "../_helpers/session";

setActivePlaceTab('rounds');
const roundDrivers = getRoundDrivers();
const customers = getAccounts();
const schedule = getSchedule();
const loading = false;
const printInvoice = false;
const initialState = { roundDrivers, customers, schedule, loading, printInvoice };

export function place (state = initialState, action) {

  switch (action.type) {
    case placeConstants.API_GET_SUCCESS_ROUND_DRIVERS:
    case placeConstants.GET_ROUND_DRIVERS_LOCAL:
      return {
        loading: false,
        roundDrivers: action.roundDrivers || state.roundDrivers
      }
    case placeConstants.API_GET_SUCCESS_CUSTOMERS:
    case placeConstants.GET_CUSTOMERS_LOCAL:
      return {
        loading: false,
        customers: action.customers || state.customers
      }
    case placeConstants.API_GET_SUCCESS_SCHEDULE:
    case placeConstants.GET_SCHEDULE_LOCAL:
      return {
        loading: false,
        schedule: action.schedule || state.schedule
      }
    case placeConstants.API_GET_SUCCESS_CHECKLIST:
    case placeConstants.GET_CHECKLIST_LOCAL:
      return {
        loading: false,
        checklist: action.checklist || state.checklist
      }
    case placeConstants.API_GET_SUCCESS_ORDERS:
      return {
        loading: false,
        orders: action.orders,
        orderDetails: action.orderDetails,
        paperWorksGenerated: action.paperWorksGenerated,
        total: action.total
      }
    case placeConstants.API_GET_REQUEST_ROUND_DRIVERS:
    case placeConstants.API_GET_REQUEST_CUSTOMERS:
    case placeConstants.API_GET_REQUEST_SCHEDULE:
    case placeConstants.API_GET_REQUEST_CHECKLIST:
    case placeConstants.API_GET_REQUEST_VALIDATE:
    case placeConstants.API_POST_REQUEST_ROUND_STATUSES:
    case placeConstants.API_POST_REQUEST_PAPERWORKS_GENERATE:
    case placeConstants.API_POST_REQUEST_GENERATE_INVOICE:
    case placeConstants.API_POST_REQUEST_EMAIL_INVOICE:
    case placeConstants.API_POST_REQUEST_ORDER_SORT:
      return {
        loading: true
      }
    case placeConstants.API_GET_FAILURE_ROUND_DRIVERS:
    case placeConstants.API_GET_FAILURE_CUSTOMERS:
    case placeConstants.API_GET_FAILURE_SCHEDULE:
    case placeConstants.API_GET_FAILURE_CHECKLIST:
    case placeConstants.API_POST_FAILURE_ROUND_STATUSES:
    case placeConstants.API_POST_FAILURE_PAPERWORKS_GENERATE:
    case placeConstants.API_POST_REQUEST_GENERATE_ROUND_INVOICE:
    case placeConstants.API_POST_FAILURE_PRINT_INVOICE:
    case placeConstants.API_POST_SUCCESS_ORDER_SORT:
    case placeConstants.API_POST_FAILURE_ORDER_SORT:
      return {
        loading: false
      }
    case placeConstants.API_GET_SUCCESS_VALIDATE:
    case placeConstants.API_GET_FAILURE_VALIDATE:
      return {
        isValid: action.isValid,
        roundNo: action.roundNo,
        loading: false
      }
    case placeConstants.API_POST_SUCCESS_ROUND_STATUSES:
      return {
        roundUpdated: true,
        lading: false
      }
    case placeConstants.API_POST_SUCCESS_ORDER_DETAILS_CHECKLIST:
      return {
        orderDetailsChecklist: true,
        loading: false,
        orderDetails: action.data.order_details
      }
    case placeConstants.API_POST_SUCCESS_PAPERWORKS_GENERATE:
      return {
        loading: false,
        paperworks_generated: true,
        path: action.data
      }
    case placeConstants.API_GET_SUCCESS_INVOICE:
    case placeConstants.API_POST_SUCCESS_GENERATE_INVOICE:
      return {
        loading: false,
        order: action.data
      }
    case placeConstants.API_POST_SUCCESS_EMAIL_INVOICE:
      return {
        loading: false,
        emailInvoice: true
      }
    case placeConstants.API_POST_SUCCESS_GENERATE_ROUND_INVOICE:
      return {
        loading: false,
        orders: action.data.orders,
        orderDetails: action.data.orderDetails,
        paperWorksGenerated: action.data.paperWorksGenerated,
        total: action.data.total,
        roundInvoice: true
      }
    case placeConstants.API_GET_REQUEST_PRINT_NOTES:
      return {
        loading: true,
        printNotes: false
      }
    case placeConstants.API_GET_SUCCESS_PRINT_NOTES:
      return {
        loading: false,
        path: action.data.path,
        printNotes: true
      }
    case placeConstants.API_POST_REQUEST_PRINT_INVOICE:
    case placeConstants.API_GET_REQUEST_PRINT_INVOICE:
      return {
        loading: true,
        printInvoice: false
      }
    case placeConstants.API_POST_SUCCESS_PRINT_INVOICE:
    case placeConstants.API_GET_SUCCESS_PRINT_INVOICE:
      return {
        loading: false,
        printInvoice: true,
        items: action.data
      }
    case placeConstants.API_GET_REQUEST_PRINT_DRIVERS_LIST:
      return {
        loading: true,
        printDriversList: false
      }
    case placeConstants.API_GET_SUCCESS_PRINT_DRIVERS_LIST:
      return {
        loading: false,
        printDriversList: true,
        items: action.data
      }
    default: 
      return state;
  }
}