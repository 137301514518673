export const modalConstants = {
  API_GET_REQUEST_ITEMS: 'API_GET_REQUEST_ITEMS',
  API_GET_SUCCESS_ITEMS: 'API_GET_SUCCESS_ITEMS',
  API_GET_FAILURE_ITEMS: 'API_GET_FAILURE_ITEMS',

  API_CLEAR_ITEMS: 'API_CLEAR_ITEMS',

  API_POST_REQUEST_ITEMS: 'API_POST_REQUEST_ITEMS',
  API_POST_SUCCESS_ITEMS: 'API_POST_SUCCESS_ITEMS',
  API_POST_FAILURE_ITEMS: 'API_POST_FAILURE_ITEMS',
}