import React from 'react';
import { Button, Col, Container, Form, FormControl, FormLabel, Row, Tab, Tabs } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { alertActions } from '../../_actions/alert.actions';
import { getWeekDate, daysOfTheWeek } from '../../_helpers/calendar';
import RoundOrder from './calendar/RoundOrder';

class Calendar extends React.Component
{

  constructor (props) {
    super (props);

    const now = new Date();
    
    this.state = {
      activeDay: daysOfTheWeek[now.getDay()],
      activeDates: getWeekDate('present').dates,
      prevActiveDay: daysOfTheWeek[now.getDay()]
    }
  }

  handleChange = (e) => {
    const weekDate = getWeekDate(e.target.value);
    this.setState({ activeDay: daysOfTheWeek[weekDate.defaultDay] });
    this.setState({ activeDates: weekDate.dates })
    this.setState({ prevActiveDay: '' })
  }

  componentDidUpdate (nextProps) {

    if (this.state.activeDay != this.state.prevActiveDay) {
      this.setState({ prevActiveDay: this.state.activeDay });
      this.props.clearAlerts()
    }
  }

  render () {

    const { activeDates, prevActiveDay } = this.state;

    return <>
      <Container>
        <Row>
          <Col xs="7"><i>Round week: {this.state.activeDates.sunday.formatted} - {this.state.activeDates.saturday.formatted}</i></Col>
          <Col xs="5">
            <Form inline onSubmit={this.handleFormSubmit} className="float-right">
            <FormLabel><b>Show</b>&nbsp;</FormLabel>
            <FormControl 
              as="select" 
              name="roundWeek" 
              label="Show" 
              defaultValue="present"
              onChange={(e) => this.handleChange(e)} 
            >
              <option value="past">Last Week</option>
              <option value="present">Current Week</option>
              <option value="future">Next Week</option>
            </FormControl>
          </Form>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Tabs defaultActiveKey={this.state.activeDay} id="week-days" activeKey={this.state.activeDay} onSelect={(k) => this.setState({ activeDay: k })}>
              {daysOfTheWeek.map(function(day, index) {
                const title = day.charAt(0).toUpperCase() + day.substring(1);
                return <Tab eventKey={day} title={title} key={index}>
                  <Container className="container-tab">
                    <Row>
                      <Col>{day == prevActiveDay && <RoundOrder date={activeDates[day].formatted} dateId={activeDates[day].default} day={day} />}</Col>
                    </Row>
                  </Container>
                  
                </Tab>
              })}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>;
  }
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(null, actionCreators)(Calendar)