import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import { beautifyDate } from "../../../_helpers/placeOrder";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    color: "#343a40",
  },
  section: {
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 10,
  },
  subSection: {
    marginLeft: 10,
    paddingLeft: 10,
    fontStyle: "italic",
    fontSize: 9,
  },
  viewTable: {
    margin: 10,
    padding: 10,
    width: "auto",
  },
  tableCell: {
    fontSize: 9,
    paddingTop: 3,
    paddingBottom: 3,
    color: "black",
  },
  dataTableCell: {
    fontSize: 9,
    marginLeft: 1,
    paddingTop: 3,
    paddingBottom: 3,
  },
});

class SupplierPDF extends React.Component {
  render() {
    const { pdf, startDate, endDate } = this.props;

    return (
      <Document>
        {pdf &&
          pdf.map((item) => {
            return (
              <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section}>
                  <Text>{item.company}</Text>
                </View>
                <View style={styles.subSection}>
                  <Text>
                    Date Covered: {beautifyDate(startDate)} -{" "}
                    {beautifyDate(endDate)}
                  </Text>
                </View>
                <View style={styles.viewTable}>
                  <Table data={item.products}>
                    <TableHeader textAlign={"center"}>
                      <TableCell style={styles.tableCell} weighting={0.6}>
                        PRODUCT ID
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={1}>
                        PRODUCT CODE
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={1}>
                        PRODUCT DESCRIPTION
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={0.6}>
                        CUSTOMER ID
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={0.5}>
                        ACCT REF
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={1}>
                        COMPANY
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={0.5}>
                        QUANTITY
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={0.7}>
                        ROUND NO
                      </TableCell>
                      <TableCell style={styles.tableCell} weighting={1}>
                        ORDER ID
                      </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={0.6}
                        getContent={(r) => r.product_id}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={1}
                        getContent={(r) => r.code}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={1}
                        getContent={(r) => r.description}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={0.6}
                        getContent={(r) => r.customer_id}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={0.5}
                        getContent={(r) => r.company_ref}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={1}
                        getContent={(r) => r.company}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={0.5}
                        getContent={(r) => r.quantity}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={0.7}
                        getContent={(r) => r.round_no}
                      />
                      <DataTableCell
                        style={styles.dataTableCell}
                        weighting={1}
                        getContent={(r) => r.id}
                      />
                    </TableBody>
                  </Table>
                </View>
              </Page>
            );
          })}
      </Document>
    );
  }
}

export default SupplierPDF;
